import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import SingleTool from "./SingleTool";

const Panel = styled.div`
  .visible-panel {
    @keyframes moveup {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    @keyframes moveupinvisible {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    width: calc(83.33% + 15px);
    min-height: 55vh;
    border: 1px solid black;
    border-bottom: none;
    background-color: white;
    bottom: -100%;
    margin: 0 auto;
    display: block;
    position: fixed;
    animation-name: moveup;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 10;
    .close-button {
      background-color: gray;
      border: 1px gray solid;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      color: white;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .invisible-panel {
    display: block;
    position: fixed;
    left: 0;
    color: transparent;
    width: 100%;
    height: 100%;
    animation-name: moveupinvisible;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 5;
    cursor: pointer;
  }
`;

const EditToolPanel = ({
  editTool,
  onDeselectTool,
  onEditTool,
  manufacturers,
  employees,
  categories,
  locations,
}) => (
  <Panel>
    <div className="visible-panel">
      <button onClick={onDeselectTool} className="close-button">
        <FontAwesomeIcon icon={faX} />
      </button>
      <SingleTool
        editTool={editTool}
        onDeselectTool={onDeselectTool}
        onEditTool={onEditTool}
        manufacturers={manufacturers}
        employees={employees}
        categories={categories}
        locations={locations}
      />
    </div>
    <div onClick={onDeselectTool} className="invisible-panel" />
  </Panel>
);

EditToolPanel.propTypes = {
  editTool: PropTypes.array,
  onDeselectTool: PropTypes.func,
  onEditTool: PropTypes.func,
  manufacturers: PropTypes.array,
  employees: PropTypes.array,
  locations: PropTypes.array,
  categories: PropTypes.array,
};

export default EditToolPanel;
