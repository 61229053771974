import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "../../styles/Style";
import styled from "styled-components";
import { API_URL } from "../../env";

const MenuDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  .password-reset {
    margin: 0px auto;
    width: 400px;
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: 10px;
  }
  h2 {
    text-transform: uppercase;
  }
  label {
    font-weight: bold;
  }
  select {
    margin-left: 10px;
    text-transform: capitalize;
  }
`;

const Settings = ({
  id,
  favorites,
  name,
  notifications,
  permission,
  updateAuth,
}) => {
  const [loading, setLoading] = useState(true);
  const [currentFavorites, setCurrentFavorites] = useState(["", "", "", ""]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentNotifications, setCurrentNotifications] = useState({});

  useEffect(() => {
    favorites !== "" && setCurrentFavorites(JSON.parse(favorites));
    notifications !== "" && setCurrentNotifications(notifications);
    favorites !== "" && notifications !== "" && setLoading(false);
  }, [favorites, notifications]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    setCurrentFavorites((prevState) => {
      prevState[index] = value;
      return [...prevState];
    });
  };

  const handleActiveChange = (e) => {
    const { checked, name } = e.target;
    setCurrentNotifications((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: password }),
    };
    fetch(`${API_URL}/update/employeepwd/${id}`, requestOptions).then(
      (response) => response.json()
    );
  };

  const handleFavoriteSubmit = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ favorites: JSON.stringify(currentFavorites) }),
    };
    fetch(`${API_URL}/update/employee/${id}`, requestOptions)
      .then((response) => response.json())
      .then(() => updateAuth());
  };

  const handleNotificationSubmit = () => {
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...currentNotifications }),
    };
    fetch(`${API_URL}/update/employee/${id}`, requestOptions)
      .then((response) => response.json())
      .then(() => updateAuth());
  };
  const favoritesList =
    permission === "ADMIN"
      ? [
          "time",
          "salary timecards",
          "hourly timecards",
          "create timecard",
          "time clock dashboard",
          "daily reports",
          "field work orders",
          "expense reports",
          "transfer tool",
          "new tool",
          "export time",
          "upload jobs",
          "upload employees",
        ]
      : permission === "PM"
      ? [
          "time",
          "create timecard",
          "daily reports",
          "field work orders",
          "expense reports",
          "time clock dashboard",
          "tools",
        ]
      : [
          "create timecard",
          "daily reports",
          "field work orders",
          "new tool",
          "tools",
        ];
  return (
    !loading && (
      <MenuDiv>
        <h2>{name.slice(-1) != "s" ? `${name}'s` : `${name}'`} Settings</h2>
        <div>
          <h3>Reset Password</h3>
          <div style={{ height: "25px" }}>
            {password !== confirmPassword && (
              <span style={{ color: "red" }}>Passwords Do Not Match</span>
            )}
          </div>
          <div className="password-reset">
            <label style={{ textAlign: "right" }}>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label style={{ textAlign: "right" }}>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <br />
          <br />
          <Button
            onClick={(e) => handlePasswordSubmit(e)}
            disabled={password !== confirmPassword || password.length < 5}
          >
            Save Password
          </Button>
        </div>
        <div>
          <h3>Favorites</h3>
          <label>
            Favorite #1
            <select
              value={currentFavorites[0]}
              onChange={(e) => handleChange(e, 0)}
            >
              <option>Select A Favorite</option>
              {favoritesList.map((favorite) => (
                <option key={favorite} value={favorite}>
                  {favorite}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Favorite #2
            <select
              value={currentFavorites[1]}
              onChange={(e) => handleChange(e, 1)}
            >
              <option value="">Select A Favorite</option>
              {favoritesList.map((favorite) => (
                <option key={favorite} value={favorite}>
                  {favorite}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Favorite #3
            <select
              value={currentFavorites[2]}
              onChange={(e) => handleChange(e, 2)}
            >
              <option>Select A Favorite</option>
              {favoritesList.map((favorite) => (
                <option key={favorite} value={favorite}>
                  {favorite}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Favorite #4
            <select
              value={currentFavorites[3]}
              onChange={(e) => handleChange(e, 3)}
            >
              <option>Select A Favorite</option>
              {favoritesList.map((favorite) => (
                <option key={favorite} value={favorite}>
                  {favorite}
                </option>
              ))}
            </select>
          </label>
          <br />
          <br />
          <Button
            onClick={(e) => handleFavoriteSubmit(e)}
            disabled={
              (favorites === null &&
                currentFavorites[0] === "" &&
                currentFavorites[1] === "" &&
                currentFavorites[2] === "" &&
                currentFavorites[3] === "") ||
              favorites === JSON.stringify(currentFavorites)
            }
          >
            Save Favorites
          </Button>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <h3 style={{ marginBottom: "10px" }}>Notification Settings</h3>
          <p style={{ marginTop: "0px" }}>
            Check box to receive emails for all submitted reports of that type.
          </p>
          <label>
            Field Work Order Notification
            <input
              type="checkbox"
              name="workOrderNotification"
              id="workOrderNotification"
              checked={currentNotifications.workOrderNotification || false}
              onChange={handleActiveChange}
            />
          </label>
          <br />
          <br />
          <label>
            Daily Report Notification
            <input
              type="checkbox"
              name="dailyReportNotification"
              id="dailyReportNotification"
              checked={currentNotifications.dailyReportNotification || false}
              onChange={handleActiveChange}
            />
          </label>
          <br />
          <br />
          <label>
            Expense Report Notification
            <input
              type="checkbox"
              name="expenseNotification"
              id="expenseNotification"
              checked={currentNotifications.expenseNotification || false}
              onChange={handleActiveChange}
            />
          </label>
          <br />
          <br />
          <Button
            onClick={handleNotificationSubmit}
            disabled={
              notifications.workOrderNotification ===
                currentNotifications.workOrderNotification &&
              notifications.expenseNotification ===
                currentNotifications.expenseNotification &&
              notifications.dailyReportNotification ===
                currentNotifications.dailyReportNotification
            }
          >
            Save Notifications
          </Button>
        </div>
      </MenuDiv>
    )
  );
};

Settings.propTypes = {
  id: PropTypes.string,
  favorites: PropTypes.string,
  name: PropTypes.string,
  notifications: PropTypes.object,
  permission: PropTypes.string,
  updateAuth: PropTypes.func,
};

export default Settings;
