import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../styles/Style";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { API_URL } from "../../env";

const ExpenseImageContainer = styled.div`
  span {
    cursor: pointer;
    font-weight: bold;
    font-size: 25px;
  }
`;

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
    margin-top: 100px;
    th {
      color: black;
    }
  }
`;

const DateContainer = styled.div`
  @media print {
    display: none;
  }
  h2 {
    text-transform: uppercase;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: right;
    display: inline-flex;
    margin-bottom: 25px;

    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      .react-datepicker__input-container,
      input {
        width: 200px;
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const ExpenseReportTable = styled.table`
  width: 100%;
  table {
    border-collapse: collapse;
    margin: 0px auto;
    tr:nth-child(even) {
      background-color: #f0f0f0;
    }
    tr {
      td,
      th {
        padding: 0px 10px;
        span {
          cursor: pointer;
        }
      }
    }
    tfoot {
      background-color: gainsboro;
      font-weight: bold;
    }
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const ExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [startDate, setStartDate] = useState(new Date(start.toISOString()));
  const [endDate, setEndDate] = useState(new Date(end.toISOString()));
  const [image, setImage] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/expenses/${startDate.toISOString()}/${endDate.toISOString()}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedExpensesByEmployee = _.chain(json)
          .groupBy("userId")
          .map((value, key) => ({
            user: key,
            value,
          }))
          .value();
        setExpenses(sortedExpensesByEmployee);
      });
  }, []);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setStartDate(start);
    setEndDate(newEnd);
  };

  const onDateFilter = () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/expenses/${startDate.toISOString()}/${endDate.toISOString()}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedExpensesByEmployee = _.chain(json)
          .groupBy("userId")
          .map((value, key) => ({
            user: key,
            value,
          }))
          .value();
        setExpenses(sortedExpensesByEmployee);
      });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return image ? (
    <ExpenseImageContainer>
      <div>
        <span onClick={() => setImage(null)}>Close</span>
      </div>
      <img src={image} />
    </ExpenseImageContainer>
  ) : (
    <div>
      <DateContainer>
        <h2>Expense Reports</h2>
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(startDate, 1), subWeeks(endDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(startDate, 1), subDays(endDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(startDate, 1), addDays(endDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(startDate, 1), addWeeks(endDate, 1)])
            }
          >
            {">>"}
          </Button>
          <Button className="button-margin-1" onClick={onDateFilter}>
            GO
          </Button>
        </div>
        <div style={{ width: "95%" }}>
          <Button
            style={{ display: "block", marginLeft: "auto" }}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </div>
      </DateContainer>
      <div>
        {expenses.length > 0 ? (
          expenses.map(({ user, value }) => {
            const total = value.reduce((prev, cur) => prev + cur.amount, 0);
            return (
              <PageBreak key={user}>
                <h2>
                  {value[0].user.firstName} {value[0].user.lastName}
                </h2>
                <h3>Week Ending: {dayjs(endDate).format("MM/DD/YY")}</h3>
                <ExpenseReportTable>
                  <table>
                    <thead>
                      <tr
                        style={{
                          gridTemplateColumns: "repeat(6, 150px)",
                          backgroundColor: "black",
                          color: "white",
                        }}
                      >
                        <th>Date</th>
                        <th>Job</th>
                        <th>Cost Code</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {value &&
                        value.map((expenseItem) => (
                          <tr key={expenseItem.id}>
                            <td>
                              {dayjs(expenseItem.purchaseDate).format(
                                "MM/DD/YY"
                              )}
                            </td>
                            <td>{`${expenseItem.job.jobNumber}-${expenseItem.job.jobName}`}</td>
                            <td>{`${expenseItem.task.taskCode}-${expenseItem.task.taskName}`}</td>
                            <td>{expenseItem.description}</td>
                            <td>${formatter.format(expenseItem.amount)}</td>
                            <td>
                              {expenseItem.image && (
                                <span
                                  onClick={() => setImage(expenseItem.image)}
                                >
                                  <FontAwesomeIcon icon={faImage} />
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr
                        style={{
                          gridTemplateColumns: "repeat(6, 150px)",
                        }}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>${formatter.format(total)}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </ExpenseReportTable>
              </PageBreak>
            );
          })
        ) : (
          <div>
            <h3>No Expenses This Period</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseReport;
