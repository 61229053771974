import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditJobPanel from "./EditJobPanel";
import Table from "../Table/Table";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import ConfirmDelete from "./ConfirmDelete";
import { API_URL } from "../../env";

const columns = [
  ["Active", "active"],
  ["Job Number", "jobNumber"],
  ["Job Name", "jobName"],
  ["Customer", "customerName"],
  ["PM", "projectManagerName"],
  ["Foreman", "foremanName"],
  ["Coords", "coords"],
];

const Jobs = () => {
  // const location = useLocation();
  const [state, setState] = useState({
    jobs: [],
    editJob: "",
  });
  const [onToggleConfirm, setOnToggleConfirm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [foremen, setForemen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ active: "true" });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
    fetch(`${API_URL}/projectmanagers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setProjectManagers(json));
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) => setForemen(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/jobs/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                jobs: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/jobs?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Active",
      field: "active",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Customer",
      field: "customerNumber",
      options: customers.map((customer) => ({
        label: customer.name,
        value: customer.customerNumber,
      })),
      isMulti: true,
    },
    {
      label: "PM",
      field: "isSalary",
      options: projectManagers.map((projectManager) => ({
        label: `${projectManager.firstName} ${projectManager.lastName}`,
        value: projectManager.id,
      })),
      isMulti: true,
    },
    {
      label: "Foreman",
      field: "permission",
      options: foremen.map((foreman) => ({
        label: `${foreman.firstName} ${foreman.lastName}`,
        value: foreman.id,
      })),
      isMulti: true,
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const job = state.jobs.filter((job) => job.id === id);
    setState((prevState) => ({
      ...prevState,
      editJob: job,
    }));
  };

  const onEditJob = (id, job) => {
    const index = state.jobs.findIndex((job) => job.id === id);
    let newState = state.jobs;
    newState[index] = {
      ...state.jobs[index],
      ...job,
    };
    setState((prevState) => ({
      ...prevState,
      jobs: newState,
    }));
  };

  const onDeselectJob = () =>
    setState((prevState) => ({
      ...prevState,
      editJob: "",
    }));

  const onDeleteJob = (id) => {
    const requestOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/job/delete/${id}`, requestOptions)
      .then((res) => res.json())
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          jobs: prevState.jobs.filter((job) => job.id !== id),
          filtered: prevState.filtered.filter((job) => job.id !== id),
          editJob: "",
        }));
      });
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { jobs, editJob } = state;

  return (
    <div>
      {onToggleConfirm && editJob && (
        <ConfirmDelete
          setOnToggleConfirm={setOnToggleConfirm}
          onDeleteBatch={onDeleteJob}
          selectedJob={editJob[0]}
        />
      )}
      {editJob && (
        <EditJobPanel
          editJob={editJob}
          onDeselectJob={onDeselectJob}
          onEditJob={onEditJob}
          setOnToggleConfirm={setOnToggleConfirm}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Job"}
        buttonPath={"/create/job"}
        pageTitle={"Jobs"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={jobs}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Jobs;
