import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import L from "leaflet";
import dayjs from "dayjs";
import greenIcon from "../../static/greenMarker.svg";
import redIcon from "../../static/redMarker.svg";
import "leaflet/dist/leaflet.css";
import { API_URL } from "../../env";

const inIcon = L.icon({
  iconUrl: greenIcon,
  iconSize: [23, 36],
  iconAnchor: [12, 41],
});

const outIcon = L.icon({
  iconUrl: redIcon,
  iconSize: [23, 36],
  iconAnchor: [12, 41],
});

const TimeForm = styled.form`
  display: grid;
  grid-template-columns: 3fr;
  .map-container {
    height: 30vh;
    width: 30vw;
    grid-row: 1/3;
    .note_button {
      border: none;
      padding: 5px 10px;
      margin-right: 3px;
      margin-bottom: 3px;
      font-weight: bold;
      cursor: pointer;
    }
    .leaflet-container {
      height: 100%;
    }
    .leaflet-bar {
      border: none;
    }
    .out-icon {
      filter: hue-rotate(120deg);
    }
  }
  fieldset {
    border: none;
  }
  .datepickerborder {
    border: 2px solid red;
  }
  .datepickerlabeltext {
    color: red;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    text-align: left;
    .width-80 {
      width: 80%;
      .full-width {
        width: 100%;
      }
    }
    label {
      padding-left: 10px;
      font-weight: bold;
    }
    .photos {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      .in-photo,
      .out-photo {
        display: grid;
        grid-template-rows: 1fr 7fr;
        justify-content: center;
        align-content: center;
      }
    }
    .imageIn {
      max-height: 100%;
      max-width: 100%;
      margin-top: 15px;
    }
    .imageOut {
      max-height: 100%;
      max-width: 100%;
      margin-top: 15px;
    }
    .allocate {
      grid-column: 1;
      grid-gap: 10px;
      grid-template-columns: 1fr 2fr 1fr;
      display: grid;
      button {
        grid-column: 2;
        text-transform: uppercase;
        margin: 10px;
      }
    }
    .buttons {
      grid-column: 2;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      display: grid;
      .delete-button {
        text-transform: uppercase;
        margin: 10px;
      }
      .submit-button {
        text-transform: uppercase;
        margin: 10px;
      }
    }
  }
`;

const SingleTime = ({
  editTime,
  onEditTime,
  onDeleteTime,
  onDeselectTime,
  jobs,
  tasks,
  onAllocateBatch,
}) => {
  const [state, setState] = useState({
    time: { ...editTime[0] },
    updatedTime: "",
    error: "",
  });
  const [images, setImages] = useState([]);
  const [users, setUsers] = useState([]);
  const [startTime, setStartTimeChange] = useState(
    new Date(editTime[0].clockIn)
  );
  const [endTime, setEndTimeChange] = useState(new Date(editTime[0].clockOut));
  const [timeDiff, setTimeDiff] = useState(false);
  const [viewNote, setViewNote] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/clockaction/images/${state.time.id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => setImages(json));
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUsers(json));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedTime: { ...prevState.updatedTime, [name]: value },
    }));
  };

  const handleJobChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      updatedTime: { ...prevState.updatedTime, jobId: e.value },
    }));
  };

  const handleInTimeChange = (value) => {
    setStartTimeChange(value);
    setState((prevState) => ({
      ...prevState,
      updatedTime: { ...prevState.updatedTime, clockIn: value },
    }));
    setTimeDiff(
      state?.updatedTime?.clockOut
        ? dayjs(state.updatedTime.clockOut).diff(dayjs(value)) >= 86400000
        : dayjs(state.time.clockOut).diff(dayjs(value)) >= 86400000
    );
  };

  const handleOutTimeChange = (value) => {
    setEndTimeChange(value);
    setState((prevState) => ({
      ...prevState,
      updatedTime: { ...prevState.updatedTime, clockOut: value },
    }));
    setTimeDiff(
      state.updatedTime?.clockIn
        ? dayjs(value).diff(dayjs(state.updatedTime.clockIn)) >= 86400000
        : dayjs(value).diff(dayjs(state.time.clockIn)) >= 86400000
    );
  };

  const averageLat =
    state.time.inLat && state.time.outLat
      ? (state.time.inLat + state.time.outLat) / 2
      : state.time.inLat
      ? state.time.inLat
      : state.time.outLat;
  const averageLng =
    state.time.inLng && state.time.outLng
      ? (state.time.inLng + state.time.outLng) / 2
      : state.time.inLng
      ? state.time.inLng
      : state.time.outLng;

  const jobOptions = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const { time, updatedTime } = state;
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Edit Time
        </h3>
        <TimeForm
          onSubmit={async (e) => {
            e.preventDefault();
            const requestOptions = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...updatedTime }),
            };
            await fetch(
              `${API_URL}/update/clockaction/${time.id}`,
              requestOptions
            )
              .then((res) => res.json())
              .then(onEditTime(time.id, updatedTime))
              .then(onDeselectTime());
          }}
        >
          <fieldset>
            <div className="fields">
              <div className="width-80">
                <label
                  htmlFor="user"
                  className="employees"
                  style={{ width: "300px" }}
                >
                  Employee
                  <br />
                  <select
                    value={
                      updatedTime.userId
                        ? updatedTime.userId
                        : state.time.userId
                    }
                    name="userId"
                    id="userId"
                    required
                    onChange={handleChange}
                    className="input-field full-width"
                  >
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.firstName +
                          (user.middleName
                            ? " " + user.middleName + " " + user.lastName
                            : " " + user.lastName)}
                      </option>
                    ))}
                  </select>
                </label>
                <br />
                <label htmlFor="jobs" className="job">
                  Device
                  <br />
                  <input
                    type="text"
                    value={
                      state.time?.device?.name
                        ? state.time.device.name
                        : "Website"
                    }
                    readOnly
                    className="full-width"
                  />
                </label>
              </div>
              <div className="width-80">
                <label htmlFor="jobs" className="job">
                  Job
                  <br />
                  <Select
                    options={jobOptions}
                    isSearchable
                    placeholder={"Select Job..."}
                    value={
                      jobOptions[
                        jobOptions.findIndex((jobOpt) =>
                          updatedTime?.jobId
                            ? updatedTime.jobId === jobOpt.value
                            : time.jobId === jobOpt.value
                        )
                      ]
                    }
                    onChange={(e) => handleJobChange(e)}
                  />
                </label>
                <br />
                <label htmlFor="tasks" className="cost-code">
                  Cost Code
                  <br />
                  <select
                    value={
                      updatedTime.taskId ? updatedTime.taskId : time.taskId
                    }
                    name="taskId"
                    id="taskId"
                    required
                    onChange={handleChange}
                    className="input-field full-width"
                  >
                    {tasks.map((task) => (
                      <option key={task.id} value={task.id}>
                        {task.taskCode} - {task.taskName}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="map-container">
                {state.time.note && (
                  <div>
                    <button
                      className="note_button"
                      style={{
                        backgroundColor: viewNote ? "lightGray" : "blue",
                        color: viewNote ? "black" : "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setViewNote(false);
                      }}
                    >
                      Map
                    </button>
                    <button
                      className="note_button"
                      style={{
                        backgroundColor: !viewNote ? "lightGray" : "blue",
                        color: !viewNote ? "black" : "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setViewNote(true);
                      }}
                    >
                      Note
                    </button>
                  </div>
                )}
                {viewNote ? (
                  <div>
                    <h4>Note</h4>
                    <p>{state.time.note}</p>
                  </div>
                ) : (
                  ((averageLat && averageLng) ||
                    (time.job.jobLat && time.job.jobLng)) && (
                    <MapContainer
                      // Make average of Lat and Lng
                      center={
                        averageLat && averageLng
                          ? [averageLat, averageLng]
                          : [time.job.jobLat, time.job.jobLng]
                      }
                      zoom={13}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {time.job.jobLat && time.job.jobLng && (
                        <Circle
                          center={[time.job.jobLat, time.job.jobLng]}
                          fillColor="blue"
                          radius={800}
                        />
                      )}
                      {time.inLat && time.inLng && (
                        <Marker
                          position={[time.inLat, time.inLng]}
                          icon={inIcon}
                        />
                      )}
                      {time.outLat && time.outLng && (
                        <Marker
                          position={[time.outLat, time.outLng]}
                          icon={outIcon}
                        />
                      )}
                    </MapContainer>
                  )
                )}
              </div>

              {images && (
                <div className="photos width-80">
                  <div className="in-photo">
                    <div>IN</div>
                    <div style={{ alignContent: "center" }}>
                      {images.imageIn && (
                        <img
                          src={images.imageIn}
                          className="imageIn"
                          onError={(event) => {
                            event.target.style.display = "none";
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="out-photo">
                    <div>OUT</div>
                    <div>
                      {images.imageOut && (
                        <img
                          src={images.imageOut}
                          className="imageOut"
                          onError={(event) => {
                            event.target.style.display = "none";
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="width-80">
                <span
                  className="datepickerlabeltext"
                  style={{ fontWeight: "bold" }}
                >
                  {timeDiff && "Can't Be Greater than 24 Hour Difference"}
                </span>
                <br />
                <label htmlFor="time-in" className="start">
                  <span
                    className={
                      new Date(startTime).toISOString() !==
                      new Date(0).toISOString()
                        ? ""
                        : "datepickerlabeltext"
                    }
                  >
                    {new Date(startTime).toISOString() !==
                    new Date(0).toISOString()
                      ? "Start Time"
                      : "Start Time - No Clock In"}
                  </span>
                  <br />
                  <DateTimePicker
                    name="clockIn"
                    id="clockIn"
                    onChange={handleInTimeChange}
                    value={
                      new Date(startTime).toISOString() !==
                      new Date(0).toISOString()
                        ? startTime
                        : new Date(endTime)
                    }
                    className={
                      new Date(startTime).toISOString() !==
                      new Date(0).toISOString()
                        ? ""
                        : "datepickerborder"
                    }
                    calendarType={"US"}
                    disableClock
                  />
                </label>
                <br />
                <label htmlFor="time-out" className="end">
                  <span
                    className={
                      new Date(endTime).toISOString() !==
                      new Date(0).toISOString()
                        ? ""
                        : "datepickerlabeltext"
                    }
                  >
                    {new Date(endTime).toISOString() !==
                    new Date(0).toISOString()
                      ? "End Time"
                      : "End Time - No Clock Out"}
                  </span>
                  <br />
                  <DateTimePicker
                    name="clockOut"
                    id="clockOut"
                    onChange={handleOutTimeChange}
                    value={
                      new Date(endTime).toISOString() !==
                      new Date(0).toISOString()
                        ? endTime
                        : new Date(startTime)
                    }
                    className={
                      new Date(endTime).toISOString() !==
                      new Date(0).toISOString()
                        ? ""
                        : "datepickerborder"
                    }
                    calendarType={"US"}
                    disableClock
                  />
                </label>
              </div>
              <div className="allocate width-80">
                <br />
                <Button
                  onClick={() => onAllocateBatch([{ ...time, ...updatedTime }])}
                  style={{ background: "green" }}
                >
                  Allocate
                </Button>
              </div>
              <div className="buttons width-80">
                <Button
                  className="delete-button"
                  style={{ backgroundColor: "red" }}
                  onClick={async (e) => {
                    e.preventDefault();
                    const requestOptions = {
                      method: "DELETE",
                      withCredentials: true,
                      credentials: "include",
                      headers: { "Content-Type": "application/json" },
                    };
                    await fetch(
                      `${API_URL}/clockaction/delete/${time.id}`,
                      requestOptions
                    )
                      .then((res) => res.json())
                      .then(onDeleteTime(time.id))
                      .then(onDeselectTime());
                  }}
                >
                  Delete
                </Button>
                <Button
                  className="submit-button"
                  type="submit"
                  disabled={timeDiff}
                >
                  Save
                </Button>
              </div>
            </div>
          </fieldset>
        </TimeForm>
      </div>
    </div>
  );
};

SingleTime.propTypes = {
  id: PropTypes.string,
  editTime: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onEditTime: PropTypes.func,
  onDeselectTime: PropTypes.func,
  onDeleteTime: PropTypes.func,
  onAllocateBatch: PropTypes.func,
  jobs: PropTypes.array,
  tasks: PropTypes.array,
};

export default SingleTime;
