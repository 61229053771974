import React, { useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const DateButtons = styled.div`
  grid-column-start: 2;
  justify-content: right;
  display: inline-flex;
  margin-bottom: 25px;
  .button-margin-1 {
    margin-left: 1px;
  }
  .react-datepicker-wrapper {
    width: auto;

    .react-datepicker__input-container,
    input {
      height: 100%;
      padding: 0px;
      border: none;
      outline: 1px solid black;
      outline-offset: -1px;
      text-align: center;
    }
  }
`;

const HoursDetailTable = styled.table`
  border-collapse: collapse;
  margin: 10px auto 10px auto;
  th,
  td {
    border: 1px solid black;
    padding: 0px 10px;
    text-align: left;
  }
  td {
    text-align: left;
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const AbsenteeReport = () => {
  const [absentEmployees, setAbsentEmployees] = useState([]);
  const [firstDate, setFirstDate] = useState(new Date(start.toISOString()));
  const [lastDate, setLastDate] = useState(new Date(end.toISOString()));

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setFirstDate(new Date(start.toISOString()));
    setLastDate(new Date(newEnd.toISOString()));
  };

  const onDateFilter = () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/reports/absentee/${firstDate.toISOString()}/${lastDate.toISOString()}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setAbsentEmployees(json));
  };

  return (
    <div>
      <h1>Absentee Report</h1>
      <div style={{ width: "500px", margin: "0px auto 0px auto" }}>
        <DateButtons>
          <Button
            onClick={() =>
              onDateChange([subWeeks(firstDate, 1), subWeeks(lastDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(firstDate, 1), subDays(lastDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={firstDate}
            endDate={lastDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(firstDate, 1), addDays(lastDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(firstDate, 1), addWeeks(lastDate, 1)])
            }
          >
            {">>"}
          </Button>
          <Button className="button-margin-1" onClick={onDateFilter}>
            GO
          </Button>
        </DateButtons>
      </div>
      <div>
        {absentEmployees?.length > 0 && (
          <HoursDetailTable>
            <thead>
              <tr>
                <th>Name</th>
                {/* <th>Last Worked</th> */}
                <th>Deactivate</th>
              </tr>
            </thead>
            <tbody>
              {absentEmployees.map((employee) => (
                <tr key={employee.id}>
                  <td>{`${employee.firstName} ${employee.lastName}`}</td>
                  {/* <td>
                    {employee.timeRecords.length > 0 &&
                      dayjs(employee.timeRecords[0].clockIn).format(
                        "MM/DD/YYYY"
                      )}
                  </td> */}
                  <td>
                    <Button>Deactivate</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </HoursDetailTable>
        )}
      </div>
    </div>
  );
};

export default AbsenteeReport;
