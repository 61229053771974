import React from "react";
import PropTypes from "prop-types";
import { API_URL } from "../../env";

const requestOptions = {
  method: "POST",
  withCredentials: true,
  credentials: "include",
  headers: { "Content-Type": "application/json" },
};

const Logout = ({ loggedInState }) => (
  <span
    type="button"
    className="logout-button"
    onClick={async (e) => {
      e.preventDefault();
      await fetch(`${API_URL}/logout`, requestOptions).then(() =>
        loggedInState(false, "", "", "", "", "")
      );
    }}
    style={{ cursor: "pointer" }}
  >
    Logout
  </span>
);

Logout.propTypes = {
  loggedInState: PropTypes.func,
};

export default Logout;
