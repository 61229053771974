import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TimePicker from "react-time-picker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roundToNearestMinutes } from "date-fns";
import { faCircle, faX } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import CustomStyles from "../../styles/SelectCustomStyle";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const AllocatePanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  .allocate {
    height: 100%;
    background: #edf0f1;
    padding: 10px;
  }
  .allocate-job-delete {
    margin-left: 10px;
    padding: 3px 7px;
  }

  .allocate-job-delete:hover {
    background-color: red;
  }

  .timeline {
    margin: 0px 0px 20px 50px;
    cursor: pointer;
  }
  .timeline ::before {
    left: 30px;
    border-radius: 0.25rem;
    background: #dee2e6;
    width: 4px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .timeline-label {
    padding: 3px 5px;
    background: #032bdf;
    color: white;
    font-weight: bold;
    width: fit-content;
  }
  .timeline-times {
    margin-left: 10px;
    background: white;
    width: fit-content;
    margin-bottom: 10px;
    padding: 5px;
    font-weight: bold;
  }
  .timeline-circle {
    position: absolute;
    z-index: 20;
    left: 24px;
  }
  .close-button {
    background-color: gray;
    border: 1px gray solid;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
  }
  .employees {
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    cursor: default;
    span {
      padding: 5px;
      border: 1px solid black;
      font-weight: bold;
      margin: 0px 5px 0px 0px;
    }
  }
  .input-field {
    padding: 5px;
  }
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0px 20px 0px;
    .job-cost-select {
    }
    .time-select {
      text-align: left;
      margin-left: 50px;
    }
  }
`;

const AllocateBatchForm = styled.form`
  h3 {
    margin: 15px 0px 5px 0px;
    height: 20px;
  }
  .react-datepicker-wrapper {
    text-align: left;
  }
  fieldset {
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    ul {
      list-style-type: none;
    }
    label {
      font-weight: bold;
    }
  }
  .buttons {
    width: 100%;
    grid-column: 1/3;
    margin: 50px 0px 0px 0px;
  }
  label {
    font-weight: bold;
  }
`;

const AllocateTime = ({
  jobs,
  tasks,
  allocateBatch,
  onAllocateBatch,
  onSubmitAllocate,
  onDeselectTime,
}) => {
  const [state, setState] = useState({
    allocated: [
      {
        id: allocateBatch[0].id,
        jobId: allocateBatch[0].jobId,
        job: {
          jobId: allocateBatch[0].jobId,
          jobName: allocateBatch[0].job.jobName,
          jobNumber: allocateBatch[0].job.jobNumber,
          label: `${allocateBatch[0].job.jobNumber} - ${allocateBatch[0].job.jobName}`,
        },
        taskId: allocateBatch[0].taskId,
        task: {
          taskId: allocateBatch[0].taskId,
          taskName: allocateBatch[0].task.taskName,
          taskCode: allocateBatch[0].task.taskCode,
          label: `${allocateBatch[0].task.taskCode} - ${allocateBatch[0].task.taskName}`,
        },
        clockIn: allocateBatch[0].clockIn,
        clockOut: allocateBatch[0].clockOut,
      },
    ],
  });
  const [notes] = useState(allocateBatch[0].note);
  const [error, setError] = useState("");
  const [currentEdit, setCurrentEdit] = useState(0);
  const [uniqueEmployees, setUniqueEmployees] = useState([]);

  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const taskList = tasks.map((task) => ({
    value: task.id,
    label: `${task.taskCode} - ${task.taskName}`,
  }));

  useEffect(() => {
    const selectedEmployees = allocateBatch.map((clockAction) => ({
      name: `${clockAction.user.firstName} ${clockAction.user.lastName}`,
      id: clockAction.user.id,
    }));
    const employees = [...new Set(selectedEmployees)];
    setUniqueEmployees(employees);
  }, []);

  const handleJobChange = (e) => {
    const { value } = e;
    const jobName = jobs[jobs.findIndex((job) => value === job.id)].jobName;
    const jobNumber = jobs[jobs.findIndex((job) => value === job.id)].jobNumber;
    setState((prevState) => {
      prevState.allocated[currentEdit] = {
        ...prevState.allocated[currentEdit],
        jobId: value,
        job: {
          jobId: value,
          jobName,
          jobNumber,
          label: `${jobNumber} - ${jobName}`,
        },
      };
      return { ...prevState };
    });
  };

  const handleTaskChange = (e) => {
    const { value } = e;
    const taskName =
      tasks[tasks.findIndex((task) => value === task.id)].taskName;
    const taskCode =
      tasks[tasks.findIndex((task) => value === task.id)].taskCode;
    setState((prevState) => {
      prevState.allocated[currentEdit] = {
        ...prevState.allocated[currentEdit],
        taskId: value,
        task: {
          taskId: value,
          taskName,
          taskCode,
          label: `${taskCode} - ${taskName}`,
        },
      };
      return { ...prevState };
    });
  };

  const handleInTimeChange = (value) => {
    const time = value.split(":");
    setState((prevState) => {
      const updateAllocateBatch = prevState.allocated;
      updateAllocateBatch[currentEdit].clockIn = dayjs(
        updateAllocateBatch[currentEdit].clockIn
      )
        .set("hour", time[0])
        .set("minute", time[1])
        .toISOString();
      return { ...prevState, allocated: [...updateAllocateBatch] };
    });
  };

  const handleOutTimeChange = (value) => {
    const time = value.split(":");
    setState((prevState) => {
      const updateAllocateBatch = prevState.allocated;
      updateAllocateBatch[currentEdit].clockOut = dayjs(
        updateAllocateBatch[currentEdit].clockOut
      )
        .set("hour", time[0])
        .set("minute", time[1])
        .toISOString();
      return { ...prevState, allocated: [...updateAllocateBatch] };
    });
  };

  const handleAddAllocate = (e) => {
    e.preventDefault();
    setState((prevState) => {
      const lastClock = allocateBatch[0].clockOut;
      const test = prevState.allocated;
      test.push({
        jobId: "",
        job: { jobId: "", jobName: "", jobNumber: "", label: "" },
        taskId: "",
        task: { taskId: "", taskName: "", taskCode: "", label: "" },
        clockIn: prevState.allocated[currentEdit].clockOut,
        clockOut: lastClock,
      });
      return { ...prevState, allocated: test };
    });
    setCurrentEdit(currentEdit + 1);
  };

  const handleDeleteAllocate = (e, index) => {
    e.preventDefault();
    setState((prevState) => {
      prevState.allocated.splice(index, 1);
      return { ...prevState };
    });
    setCurrentEdit(
      index === currentEdit ? (index === 0 ? 0 : index - 1) : currentEdit
    );
  };

  const { allocated } = state;
  return (
    allocateBatch.length > 0 && (
      <div style={{ position: "absolute", height: "100%", width: "100%" }}>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setCurrentEdit(0);
            setState((prevState) => ({
              ...prevState,
              allocated: [],
            }));
            onAllocateBatch([]);
            onDeselectTime();
          }}
          className="close-button"
        >
          <FontAwesomeIcon icon={faX} />
        </button>
        <AllocatePanel>
          <div className="allocate">
            {allocated.map((time, timeIndex) => (
              <div className="timeline" key={timeIndex}>
                <div className="timeline-label">
                  <span onClick={() => setCurrentEdit(timeIndex)}>
                    {time.job.label ? `${time.job.label}` : `Select Job`}
                  </span>
                  {allocated.length > 1 && (
                    <span
                      className="allocate-job-delete"
                      onClick={(e) => handleDeleteAllocate(e, timeIndex)}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </span>
                  )}
                </div>
                <div>
                  <div
                    className="timeline-times"
                    onClick={() => setCurrentEdit(timeIndex)}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="timeline-circle"
                    />
                    <span>{`${
                      time?.clockIn
                        ? dayjs(time.clockIn).format("h:mm a")
                        : "Select In"
                    } - ${
                      time?.clockOut
                        ? dayjs(time.clockOut).format("h:mm a")
                        : "Select Out"
                    } (${
                      time.task.label ? time.task.label : "Select Cost Code"
                    }) ${
                      time?.clockIn && time?.clockOut
                        ? `${
                            dayjs(
                              roundToNearestMinutes(new Date(time.clockOut), {
                                nearestTo: 15,
                              }).toISOString()
                            ).diff(
                              dayjs(
                                roundToNearestMinutes(new Date(time.clockIn), {
                                  nearestTo: 15,
                                }).toISOString()
                              )
                            ) / 3600000
                          }hrs`
                        : ""
                    }`}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <AllocateBatchForm
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                const requestOptions = {
                  method: "POST",
                  withCredentials: true,
                  credentials: "include",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    newClocks: uniqueEmployees
                      .map((employee) =>
                        state.allocated.map((clockAction) => {
                          return {
                            userId: employee.id,
                            jobId: clockAction.jobId,
                            taskId: clockAction.taskId,
                            clockIn: clockAction.clockIn,
                            clockOut: clockAction.clockOut,
                          };
                        })
                      )
                      .flat(),
                    originalClocks: allocateBatch.map(
                      (clockAction) => clockAction.id
                    ),
                  }),
                };
                await fetch(`${API_URL}/allocate/clockaction`, requestOptions)
                  .then((res) => {
                    {
                      if (res.status !== 200) {
                        throw new Error(res.statusText);
                      }
                      return res.json();
                    }
                  })
                  .then(() => {
                    onSubmitAllocate();
                  })
                  .then(() => {
                    setCurrentEdit(0);
                    onAllocateBatch([]);
                  });
              } catch (error) {
                setError(error.message);
              }
            }}
          >
            <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
              Allocate Time
            </h3>
            <div style={{ height: "25px", margin: "20px" }}>
              {error && <span style={{ color: "red" }}>{error}</span>}
            </div>
            <fieldset>
              <div>
                <div>
                  {notes && <p>Note: {notes}</p>}
                  <div style={{ textAlign: "left" }}>
                    <div className="employees">
                      {uniqueEmployees.map((employee) => (
                        <span key={employee.id}>{employee.name}</span>
                      ))}
                    </div>
                    <div className="inputs">
                      <div className="job-cost-select">
                        <label htmlFor="jobs" className="job">
                          Job
                          <br />
                          <Select
                            styles={CustomStyles}
                            options={jobList}
                            name="jobId"
                            onChange={(e) => handleJobChange(e)}
                            value={
                              allocated[currentEdit].job.label
                                ? allocated[currentEdit].job
                                : ""
                            }
                            placeholder="Select Job"
                          />
                        </label>
                        <br />
                        <label htmlFor="tasks" className="cost-code">
                          Cost Code
                          <br />
                          <Select
                            styles={CustomStyles}
                            options={taskList}
                            name="taskId"
                            onChange={(e) => handleTaskChange(e)}
                            value={
                              allocated[currentEdit].task.label
                                ? allocated[currentEdit].task
                                : ""
                            }
                            placeholder="Select Cost Code"
                          />
                        </label>
                      </div>
                      <div className="time-select">
                        <label htmlFor="time-in" className="start">
                          Start
                          <br />
                          <TimePicker
                            onChange={handleInTimeChange}
                            disabled={currentEdit === 0}
                            value={new Date(allocated[currentEdit].clockIn)}
                            disableClock
                            minTime={new Date(allocateBatch[0].clockIn)}
                          />
                        </label>
                        <br />
                        <br />
                        <label htmlFor="time-out" className="end">
                          End
                          <br />
                          <TimePicker
                            onChange={handleOutTimeChange}
                            value={new Date(allocated[currentEdit].clockOut)}
                            format={"h:mm a"}
                            disableClock
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <Button
                    style={{ marginRight: "5px" }}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      onAllocateBatch([]);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginRight: "5px" }}
                    type="button"
                    onClick={(e) => handleAddAllocate(e)}
                  >
                    New
                  </Button>
                  <Button type="submit">Allocate</Button>
                </div>
              </div>
            </fieldset>
          </AllocateBatchForm>
        </AllocatePanel>
      </div>
    )
  );
};

AllocateTime.propTypes = {
  allocateBatch: PropTypes.array,
  onAllocateBatch: PropTypes.func,
  setAllocateBatch: PropTypes.func,
  onSubmitAllocate: PropTypes.func,
  onDeselectTime: PropTypes.func,
  jobs: PropTypes.array,
  tasks: PropTypes.array,
};

export default AllocateTime;
