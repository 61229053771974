import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const ExpenseForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: none;
  }
  .form-buttons {
    grid-column: 1/3;
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 75px 75px 75px;
    justify-items: left;
    grid-gap: 5px;
    align-items: center;
    padding: 10px;
    label {
      font-weight: bold;
      text-align: left;
      align-content: left;
    }
    button {
      grid-column: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const SingleExpense = ({
  editExpense,
  onEditExpense,
  onDeselectExpense,
  onDeleteExpense,
}) => {
  const [state, setState] = useState({
    expense: { ...editExpense[0] },
    updatedExpense: "",
    error: "",
  });

  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [costCodes, setCostCodes] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCostCodes(json));
    fetch(`${API_URL}/expense/${editExpense[0].id}/image`, requestOptions)
      .then((res) => res.json())
      .then((json) => setAttachment(json));
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedExpense: { ...prevState.updatedExpense, [name]: value },
    }));
  };

  const handleDateChange = (value) => {
    setPurchaseDate(value);
    setState((prevState) => ({
      ...prevState,
      updatedExpense: {
        ...prevState.updatedExpense,
        purchaseDate: new Date(value).toISOString(),
      },
    }));
  };
  const { expense, updatedExpense } = state;
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Edit Expense
        </h3>
        <ExpenseForm
          onSubmit={async (e) => {
            e.preventDefault();
            const requestOptions = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...updatedExpense }),
            };
            await fetch(
              `${API_URL}/update/form/expensereport/${expense.id}`,
              requestOptions
            ).then((res) => res.json());
            onEditExpense(expense.id, updatedExpense);
            onDeselectExpense();
          }}
        >
          <fieldset>
            <div className="image">
              {attachment && <img src={attachment} width="500px" />}
            </div>
            <div className="fields">
              <label htmlFor="employees" className="employees">
                Employee
                <br />
                <select
                  value={
                    updatedExpense.userId
                      ? updatedExpense.userId
                      : state.expense.userId
                  }
                  name="userId"
                  id="userId"
                  required
                  disabled
                  onChange={handleChange}
                  className="input-field"
                >
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName +
                        (employee.middleName
                          ? " " + employee.middleName + " " + employee.lastName
                          : " " + employee.lastName)}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="jobs" className="job">
                Job
                <br />
                <select
                  value={
                    updatedExpense.jobId
                      ? updatedExpense.jobId
                      : state.expense.jobId
                  }
                  name="jobId"
                  id="jobId"
                  required
                  onChange={handleChange}
                  className="input-field"
                >
                  {jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.jobNumber} - {job.jobName}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="tasks" className="cost-code">
                Cost Code
                <br />
                <select
                  value={
                    updatedExpense.taskId
                      ? updatedExpense.taskId
                      : state.expense.taskId
                  }
                  name="taskId"
                  id="taskId"
                  required
                  onChange={handleChange}
                  className="input-field"
                >
                  {costCodes.map((task) => (
                    <option key={task.id} value={task.id}>
                      {task.taskCode} - {task.taskName}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Date
                <DatePicker
                  selected={purchaseDate}
                  onChange={handleDateChange}
                />
              </label>
              <label>
                Description
                <br />
                <input
                  type="text"
                  name="description"
                  id="description"
                  defaultValue={expense.description}
                  onChange={handleChange}
                  placeholder="Description"
                  autoComplete="none"
                />
              </label>
              <label>
                Amount
                <br />
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  defaultValue={expense.amount}
                  onChange={handleChange}
                  placeholder="Amount*"
                  required
                  autoComplete="none"
                />
              </label>
              {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
              <div className="form-buttons">
                <Button
                  style={{ background: "red" }}
                  onClick={() => onDeleteExpense(expense.id)}
                  type="button"
                >
                  Delete
                </Button>
                <Button type="submit">Save Changes</Button>
              </div>
            </div>
          </fieldset>
        </ExpenseForm>
      </div>
    </div>
  );
};

SingleExpense.propTypes = {
  id: PropTypes.string,
  editExpense: PropTypes.array,
  onEditExpense: PropTypes.func,
  onDeselectExpense: PropTypes.func,
  onDeleteExpense: PropTypes.func,
};

export default SingleExpense;
