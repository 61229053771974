import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

const PaginationDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .pagination-link {
    padding: 4px 8px;
    margin: 0 2px;
    text-decoration: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }

  .pagination-link:hover {
    background-color: #f0f0f0;
  }

  .pagination-link.active {
    background-color: #000;
    color: #fff;
    border-color: #000;
    font-weight: bold;
    cursor: default;
  }
`;

const Pagination = ({ pages, queryParams, pageParam }) => {
  return (
    <PaginationDiv>
      {pages > 1 &&
        [...Array(pages)].map((_, index) => {
          queryParams.set("page", index);
          return (
            <Link
              key={index + 1}
              to={`?page=${index + 1}`}
              className={`pagination-link ${
                pageParam === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </Link>
          );
        })}
    </PaginationDiv>
  );
};

Pagination.propTypes = {
  pages: PropTypes.number,
  queryParams: PropTypes.any,
  pageParam: PropTypes.number,
};

export default Pagination;
