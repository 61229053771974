/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from "recharts";
import styled from "styled-components";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  // isWeekend,
  isWithinInterval,
} from "date-fns";
import { API_URL } from "../../../env";

const MetricsContainer = styled.div`
  .mini-metrics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 20px;
    .data-value {
      font-size: 3rem;
      font-weight: bold;
    }
  }
  .large-metrics {
    display: grid;
    grid-template-columns: 3fr 2fr;
    .large-metrics-left {
    }
    .large-metrics-right {
    }
  }
  .custom-tooltip {
    background: white;
    padding: 10px;
  }
`;

// const barData = [
//   {
//     name: "Fixtures",
//     material: 35,
//     labor: 25,
//   },
//   {
//     name: "Devices",
//     material: 13,
//     labor: 45,
//   },
//   {
//     name: "Branch",
//     material: 20,
//     labor: 40,
//   },
//   {
//     name: "HVAC",
//     material: 8,
//     labor: 18,
//   },
//   {
//     name: "LT Controls",
//     material: 90,
//     labor: 5,
//   },
//   {
//     name: "Feeders",
//     material: 50,
//     labor: 50,
//   },
//   {
//     name: "Dist. Equip.",
//     material: 25,
//     labor: 75,
//   },
// ];

const chartColors = [
  "#0073E6", // Royal blue
  "#FF5733", // Vivid orange
  "#C70039", // Deep red
  "#2ECC71", // Emerald green
  "#FF9F7F", // Coral
  "#0095FF", // Blue
  "#9999FF", // Light blue
  "#00CC99", // Teal
  "#33CCFF", // Sky blue
  "#1ABC9C", // Turquoise
  "#FFEB3B", // Yellow
  "#FDCB6E", // Peach
  "#9E0059", // Violet red
  "#F0E68C", // Light yellow
  "#17A2B8", // Medium turquoise
  "#E74C3C", // Red orange
  "#D35400", // Orange
  "#69D2E7", // Light blue (alternative)
  "#FFC0CB", // Pink
  "#A2A2A2", // Light gray
];

const ProjectMetrics = ({ jobId, budget }) => {
  const [schedule, setSchedule] = useState([]);
  const [cost, setCost] = useState([]);
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/job/${jobId}/cost`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCost(json));
    fetch(`${API_URL}/job/${jobId}/schedule`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        const projectSchedule = json.projectSchedule
          .map((task) => ({
            ...task,
            taskMonths: eachMonthOfInterval({
              start: new Date(task.startDate),
              end: new Date(task.endDate),
            }),
            taskDays: eachDayOfInterval({
              start: new Date(task.startDate),
              end: new Date(task.endDate),
            }),
          }))
          .map((task) =>
            task.taskMonths.map((month) => [
              {
                name: format(new Date(month), "MMM-yy"),
                [task.name]: Math.round(
                  (task.taskDays.reduce((prev, cur) => {
                    const day = new Date(cur);
                    return (
                      prev +
                      (isWithinInterval(day, {
                        start: new Date(month),
                        end: new Date(endOfMonth(new Date(month))),
                      })
                        ? 1
                        : 0)
                    );
                  }, 0) /
                    task.taskDays.length) *
                    task.estimatedMaterial.reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedMaterialUnitPricex1M / 1000000) +
                        cur.quantityEstimated *
                          ((cur.estimatedLaborUnitsx1M / 1000000) * 56),
                      0
                    )
                ),
              },
            ])
          );

        setSchedule(projectSchedule.flat().flat());
        setAreas(json.projectSchedule.map((sched) => sched.name));
        setLoading(false);
      });
  }, []);

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip">
  //         <h5>{label}</h5>
  //         <p className="label">{`${payload[0].name} : ${payload[0].value}%`}</p>
  //         <p className="label">{`${payload[1].name} : ${payload[1].value}%`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  // console.log(reorganizeData(schedule));

  function reorganizeData(inputArray) {
    // Get all unique property names except 'name'
    let propertyNames = inputArray.reduce((names, item) => {
      return names.concat(Object.keys(item).filter((key) => key !== "name"));
    }, []);
    propertyNames = Array.from(new Set(propertyNames));

    // Create an empty object to store data
    let newData = {};

    // Initialize newData with objects for each unique name
    inputArray.forEach((item) => {
      newData[item.name] = { name: item.name };
      propertyNames.forEach((prop) => {
        newData[item.name][prop] = 0;
      });
    });

    // Iterate over the input array
    inputArray.forEach((item) => {
      // Add the value to the corresponding property
      Object.keys(item).forEach((key) => {
        if (key !== "name") {
          newData[item.name][key] = item[key];
        }
      });
    });

    // Convert the newData object into an array of objects
    let resultArray = Object.values(newData);

    return resultArray;
  }

  const materialCost = cost?.materialCost?.reduce(
    (prev, cur) =>
      prev +
      cur.item.purchaseOrderItems.reduce(
        (prev1, cur1) =>
          prev1 + cur.estimatedMaterialQuantity * cur1.unit * cur1.unitPrice,
        0
      ),
    0
  );

  const laborCost = cost?.laborCost?.reduce(
    (prev, cur) =>
      prev +
      cur.duration * ((cur.rateX100 / 100) * (cur.payClass === "OT" ? 1.5 : 1)),
    0
  );

  const hoursOT = cost?.laborCost?.reduce(
    (prev, cur) => prev + (cur.payClass === "OT" ? cur.duration : 0),
    0
  );

  const totalHours = cost?.laborCost?.reduce(
    (prev, cur) => prev + cur.duration,
    0
  );

  return (
    !loading && (
      <MetricsContainer>
        <div className="mini-metrics">
          <div className="mini-chart">
            <span className="data-value">
              {(
                ((materialCost + laborCost) /
                  (budget.materialBudget + budget.laborBudget)) *
                100
              ).toFixed(2)}
              %
            </span>
            <h4>Budget Used %</h4>
          </div>
          <div className="mini-chart">
            <span className="data-value">
              {((laborCost / budget.laborBudget) * 100).toFixed(2)}%
            </span>
            <h4>Labor vs Labor Budget</h4>
          </div>
          <div className="mini-chart">
            <span className="data-value">
              {((materialCost / budget.materialBudget) * 100).toFixed(2)}%
            </span>
            <h4>Material vs Material Budget</h4>
          </div>
          <div className="mini-chart">
            <span className="data-value">
              {((hoursOT / totalHours) * 100).toFixed(2)}%
            </span>
            <h4>OT %</h4>
          </div>
          <div className="mini-chart">
            <span className="data-value">{}%</span>
            <h4>In Budget Ratio</h4>
          </div>
        </div>
        <div className="large-metrics">
          <div className="large-metrics-left">
            <h3>Estimated Material & Labor by Month</h3>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                width={500}
                height={400}
                data={reorganizeData(schedule)}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("en-US", {
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(value)
                  }
                />
                <Tooltip />
                {areas.map((area, index) => (
                  <Area
                    key={area}
                    dataKey={area}
                    stackId="1"
                    stroke={chartColors[index]}
                    fill={chartColors[index]}
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="large-metrics-right">
            <h3>Estimated Material & Labor by Month</h3>
            <ResponsiveContainer width="100%" height={400}>
              <CartesianGrid strokeDasharray="3 3" />
              <BarChart
                width={500}
                height={300}
                data={reorganizeData(schedule)}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {areas.map((area, index) => (
                  <Bar
                    key={area}
                    dataKey={area}
                    stackId="1"
                    fill={chartColors[index]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </MetricsContainer>
    )
  );
};

ProjectMetrics.propTypes = {
  jobId: PropTypes.string,
  budget: PropTypes.object,
};

export default ProjectMetrics;
