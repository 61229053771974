import styled from "styled-components";

const DataTable = styled.table`
  border: 0px;
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  .tableHead {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    background-color: black;
    color: white;
    padding: 0;
    th {
      text-align: center;
      padding: 0;
      cursor: pointer;
    }
    .descending::after th {
      content: "&#8593";
    }
    .ascending::after th {
      content: "&#8596";
    }
  }
  .tableTimeHead {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    background-color: black;
    color: white;
    padding: 0;
    th {
      text-align: center;
      padding: 0;
      cursor: pointer;
    }
    .descending::after th {
      content: "&#8593";
    }
    .ascending::after th {
      content: "&#8596";
    }
    th:first-child {
      width: 40px;
    }
    th:nth-child(2) {
      width: 40px;
    }
    th:nth-child(3) {
      width: 100px;
    }
    th:nth-child(7) {
      width: 350px;
    }
    th:nth-child(8) {
      width: 70px;
    }
  }

  .tableProcessedTimeHead {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    background-color: black;
    color: white;
    padding: 0;
    th {
      text-align: center;
      padding: 0;
      cursor: pointer;
    }
    .descending::after th {
      content: "&#8593";
    }
    .ascending::after th {
      content: "&#8596";
    }
    th:first-child {
      width: 40px;
    }
    th:nth-child(2) {
      width: 75px;
    }
    th:nth-child(5) {
      width: 350px;
    }
    th:nth-child(6) {
      width: 200px;
    }
    th:nth-child(7) {
      width: 200px;
    }
  }

  .tableBodyContainer {
    width: 100%;
    height: 700px;
    overflow: auto;
    .tableBody {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      cursor: pointer;
      tr:hover {
        background-color: gainsboro;
      }
      tr:nth-child(even) {
        background-color: #f0f0f0;
      }
      tr:nth-child(even):hover {
        background-color: gainsboro;
      }
      td {
        text-align: center;
      }
    }
  }
  .tableBodyContainerTime {
    width: 100%;
    max-height: 700px;
    overflow: auto;
    user-select: none;
    .tableBody {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      cursor: pointer;
      tr:hover {
        background-color: gainsboro;
      }
      tr:nth-child(even) {
        background-color: #f0f0f0;
      }
      tr:nth-child(even):hover {
        background-color: gainsboro;
      }
      td {
        text-align: center;
        .check-box {
          cursor: pointer;
          transform: scale(1.33);
        }
      }
      td:first-child {
        width: 40px;
      }
      td:nth-child(2) {
        width: 40px;
      }
      td:nth-child(3) {
        width: 100px;
      }
      td:nth-child(7) {
        width: 350px;
      }
      td:nth-child(8) {
        width: 70px;
      }
      .highlighted {
        background-color: gainsboro !important;
      }
    }
  }
  .tableBodyContainerProcessedTime {
    width: 100%;
    max-height: 700px;
    overflow: auto;
    .tableBody {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      cursor: pointer;
      tr:nth-child(even) {
        background-color: #f0f0f0;
      }
      td {
        text-align: center;
        .check-box {
          cursor: pointer;
          transform: scale(1.33);
        }
      }
      td:first-child {
        width: 40px;
      }
      td:nth-child(2) {
        width: 75px;
      }
      td:nth-child(5) {
        width: 350px;
      }
      td:nth-child(6) {
        width: 200px;
      }
      td:nth-child(7) {
        width: 200px;
      }
      .highlighted {
        background-color: gainsboro !important;
      }
    }
  }
`;

export { DataTable };
