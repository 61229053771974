import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditToolPanel from "./EditToolPanel";
import Table from "../Table/Table";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import { API_URL } from "../../env";

const columns = [
  ["Name", "name"],
  ["Manufacturer", "manufacturer.name"],
  ["Category", "toolCategory.name"],
  ["Current Location", "currentLocation.name"],
  ["Operational", "operational"],
];

const Tools = () => {
  const [state, setState] = useState({
    tools: [],
    editTool: "",
  });
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ operational: "true" });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    // fetch(`${API_URL}/tools`, requestOptions)
    //   .then((res) => res.json())
    //   .then((json) =>
    //     setState((prevState) => ({
    //       ...prevState,
    //       tools: json.map((tool) => ({
    //         ...tool,
    //         manufacturerName: tool.manufacturer.name,
    //         toolCategoryName: tool.toolCategory.name,
    //       })),
    //     }))
    //   );
    fetch(`${API_URL}/manufacturers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setManufacturers(json));
    fetch(`${API_URL}/locations`, requestOptions)
      .then((res) => res.json())
      .then((json) => setLocations(json));
    fetch(`${API_URL}/categories`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCategories(json));
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/tools/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                tools: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/tools?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Manufacturer",
      field: "manufacturerId",
      options: manufacturers.map((manufacturer) => ({
        label: manufacturer.name,
        value: manufacturer.id,
      })),
      isMulti: true,
    },
    {
      label: "Category",
      field: "toolCategoryId",
      options: categories.map((category) => ({
        label: category.name,
        value: category.id,
      })),
      isMulti: true,
    },
    {
      label: "Current Location",
      field: "currentLocationId",
      options: locations.map((location) => ({
        label: location.name,
        value: location.id,
      })),
      isMulti: true,
    },
    {
      label: "Operational",
      field: "operational",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const tool = state.tools.filter((tool) => tool.id === id);
    setState((prevState) => ({
      ...prevState,
      editTool: tool,
    }));
  };

  const onEditTool = (id, tool) => {
    const index = state.tools.findIndex((tool) => tool.id === id);
    let newState = state.tools;
    newState[index] = {
      ...state.tools[index],
      ...tool,
    };
    setState((prevState) => ({
      ...prevState,
      tools: newState,
    }));
  };

  const onDeselectTool = () => {
    setState((prevState) => ({
      ...prevState,
      editTool: "",
    }));
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { tools, editTool } = state;
  return (
    <div>
      {editTool && (
        <EditToolPanel
          editTool={editTool}
          onDeselectTool={onDeselectTool}
          onEditTool={onEditTool}
          manufacturers={manufacturers}
          employees={employees}
          categories={categories}
          locations={locations}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Tool"}
        buttonPath={"/create/tool"}
        pageTitle={"Tools"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={tools}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Tools;
