import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditLocationPanel from "./EditLocationPanel";
import Table from "../../Table/Table";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import { API_URL } from "../../../env";

const columns = [
  ["Name", "name"],
  ["Type", "locationType"],
  ["Manager First Name", "locationManager.firstName"],
  ["Manager Last Name", "locationManager.lastName"],
];

const Locations = () => {
  const [state, setState] = useState({
    locations: [],
    editLocation: "",
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ active: "true" });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/locations/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                locations: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/tool/locations?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Active",
      field: "active",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Type",
      field: "locationType",
      options: [
        { label: "Jobsite", value: "Jobsite" },
        { label: "Warehouse", value: "Warehouse" },
        { label: "Vehicle", value: "Vehicle" },
      ],
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const location = state.locations.filter((location) => location.id === id);
    setState((prevState) => ({
      ...prevState,
      editLocation: location,
    }));
  };

  const onEditLocation = (id, location) => {
    const index = state.locations.findIndex((location) => location.id === id);
    let newState = state.locations;
    newState[index] = {
      ...state.locations[index],
      ...location,
    };
    setState((prevState) => ({
      ...prevState,
      locations: newState,
    }));
  };

  const onDeselectLocation = () => {
    setState((prevState) => ({
      ...prevState,
      editLocation: "",
    }));
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { locations, editLocation } = state;
  return (
    <div>
      {editLocation && (
        <EditLocationPanel
          editLocation={editLocation}
          onDeselectLocation={onDeselectLocation}
          onEditLocation={onEditLocation}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Location"}
        buttonPath={"/tool/create/location"}
        pageTitle={"Locations"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={locations}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Locations;
