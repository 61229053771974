import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const TimeForm = styled.form`
  display: grid;
  grid-template-columns: 5fr;
  .react-datepicker-wrapper {
    text-align: left;
  }
  fieldset {
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    ul {
      list-style-type: none;
    }
    label {
      font-weight: bold;
      text-align: center;
      padding-left: 10px;
    }
    .buttons {
      grid-column: 2;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      .delete-button {
        text-transform: uppercase;
        height: 40px;
      }
      .submit-button {
        text-transform: uppercase;
        height: 40px;
      }
    }
  }
`;

const MultipleTime = ({
  editBatch,
  onEditBatch,
  setOnToggleConfirm,
  onDeselectBatch,
  onAllocateBatch,
  jobs,
  tasks,
}) => {
  const [state, setState] = useState({
    editBatch,
    apiUpdatedBatch: [],
    updatedBatch: [],
    error: "",
  });
  const [jobId, setJobId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [startTime, setStartTimeChange] = useState("");
  const [endTime, setEndTimeChange] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const [timeDiff, setTimeDiff] = useState(false);

  const handleJobChange = (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedBatch:
        prevState.updatedBatch.length > 0
          ? prevState.updatedBatch.map((clockAction) => ({
              ...clockAction,
              id: clockAction.id,
              jobId: value,
              job: {
                id: value,
                jobNumber:
                  jobs[jobs.findIndex((job) => value === job.id)].jobNumber,
                jobName:
                  jobs[jobs.findIndex((job) => value === job.id)].jobName,
                active: jobs[jobs.findIndex((job) => value === job.id)].active,
                customerNumber:
                  jobs[jobs.findIndex((job) => value === job.id)]
                    .customerNumber,
                jobAddress:
                  jobs[jobs.findIndex((job) => value === job.id)].jobAddress,
                jobAddress2:
                  jobs[jobs.findIndex((job) => value === job.id)].jobAddress2,
                jobCity:
                  jobs[jobs.findIndex((job) => value === job.id)].jobCity,
                jobState:
                  jobs[jobs.findIndex((job) => value === job.id)].jobState,
                jobZip: jobs[jobs.findIndex((job) => value === job.id)].jobZip,
                jobLat: jobs[jobs.findIndex((job) => value === job.id)].jobLat,
                jobLng: jobs[jobs.findIndex((job) => value === job.id)].jobLng,
                projectManagerId:
                  jobs[jobs.findIndex((job) => value === job.id)]
                    .projectManagerId,
                foremanId:
                  jobs[jobs.findIndex((job) => value === job.id)].foremanId,
              },
            }))
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              jobId: value,
              job: {
                id: value,
                jobNumber:
                  jobs[jobs.findIndex((job) => value === job.id)].jobNumber,
                jobName:
                  jobs[jobs.findIndex((job) => value === job.id)].jobName,
                active: jobs[jobs.findIndex((job) => value === job.id)].active,
                customerNumber:
                  jobs[jobs.findIndex((job) => value === job.id)]
                    .customerNumber,
                jobAddress:
                  jobs[jobs.findIndex((job) => value === job.id)].jobAddress,
                jobAddress2:
                  jobs[jobs.findIndex((job) => value === job.id)].jobAddress2,
                jobCity:
                  jobs[jobs.findIndex((job) => value === job.id)].jobCity,
                jobState:
                  jobs[jobs.findIndex((job) => value === job.id)].jobState,
                jobZip: jobs[jobs.findIndex((job) => value === job.id)].jobZip,
                jobLat: jobs[jobs.findIndex((job) => value === job.id)].jobLat,
                jobLng: jobs[jobs.findIndex((job) => value === job.id)].jobLng,
                projectManagerId:
                  jobs[jobs.findIndex((job) => value === job.id)]
                    .projectManagerId,
                foremanId:
                  jobs[jobs.findIndex((job) => value === job.id)].foremanId,
              },
            })),
      apiUpdatedBatch:
        prevState.apiUpdatedBatch.length > 0
          ? prevState.apiUpdatedBatch.map((clockAction) => ({
              ...clockAction,
              id: clockAction.id,
              jobId: value,
            }))
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              jobId: value,
            })),
    }));
    setJobId(value);
  };

  const handleTaskChange = (e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedBatch:
        prevState.updatedBatch.length > 0
          ? prevState.updatedBatch.map((clockAction) => ({
              ...clockAction,
              id: clockAction.id,
              taskId: value,
              task: {
                id: tasks[tasks.findIndex((task) => value === task.id)].id,
                taskCode:
                  tasks[tasks.findIndex((task) => value === task.id)].taskCode,
                taskName:
                  tasks[tasks.findIndex((task) => value === task.id)].taskName,
                isPublic:
                  tasks[tasks.findIndex((task) => value === task.id)].isPublic,
              },
            }))
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              taskId: value,
              task: {
                id: tasks[tasks.findIndex((task) => value === task.id)].id,
                taskCode:
                  tasks[tasks.findIndex((task) => value === task.id)].taskCode,
                taskName:
                  tasks[tasks.findIndex((task) => value === task.id)].taskName,
                isPublic:
                  tasks[tasks.findIndex((task) => value === task.id)].isPublic,
              },
            })),
      apiUpdatedBatch:
        prevState.apiUpdatedBatch.length > 0
          ? prevState.apiUpdatedBatch.map((clockAction) => ({
              ...clockAction,
              id: clockAction.id,
              taskId: value,
            }))
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              taskId: value,
            })),
    }));
    setTaskId(value);
  };

  const handleInTimeChange = (value) => {
    setStartTimeChange(value);
    const time = value.split(":");
    setState((prevState) => ({
      ...prevState,
      updatedBatch:
        prevState.updatedBatch.length > 0
          ? prevState.updatedBatch.map((clockAction) => {
              const changeClockIn = clockAction?.clockIn
                ? clockAction.clockIn
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockIn;
              return {
                ...clockAction,
                clockIn: new Date(
                  new Date(changeClockIn).setHours(time[0], time[1])
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockIn: new Date(
                new Date(clockAction.clockIn).setHours(time[0], time[1])
              ).toISOString(),
            })),
      apiUpdatedBatch:
        prevState.apiUpdatedBatch.length > 0
          ? prevState.apiUpdatedBatch.map((clockAction) => {
              const changeClockIn = clockAction?.clockIn
                ? clockAction.clockIn
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockIn;
              return {
                ...clockAction,
                clockIn: new Date(
                  new Date(changeClockIn).setHours(time[0], time[1])
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockIn: new Date(
                new Date(clockAction.clockIn).setHours(time[0], time[1])
              ).toISOString(),
            })),
    }));
  };

  const handleInDateChange = (value) => {
    setStartDate(value);
    const date = new Date(value).getDate();
    const month = new Date(value).getMonth();
    const year = new Date(value).getFullYear();
    setState((prevState) => ({
      ...prevState,
      updatedBatch:
        prevState.updatedBatch.length > 0
          ? prevState.updatedBatch.map((clockAction) => {
              const changeClockInDate = clockAction?.clockIn
                ? clockAction.clockIn
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockIn;
              return {
                ...clockAction,
                clockIn: new Date(
                  new Date(changeClockInDate).setFullYear(year, month, date)
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockIn: new Date(
                clockAction?.clockIn ? clockAction.clockIn : value
              ).toISOString(),
            })),
      apiUpdatedBatch:
        prevState.apiUpdatedBatch.length > 0
          ? prevState.apiUpdatedBatch.map((clockAction) => {
              const changeClockOutDate = clockAction?.clockOut
                ? clockAction.clockOut
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockOut;
              return {
                ...clockAction,
                clockOut: new Date(
                  new Date(changeClockOutDate).setFullYear(year, month, date)
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockOut: new Date(
                new Date(
                  clockAction?.clockOut ? clockAction.clockOut : value
                ).setDate(date)
              ).toISOString(),
            })),
    }));
  };

  const handleOutTimeChange = (value) => {
    setEndTimeChange(value);
    const time = value.split(":");
    setState((prevState) => ({
      ...prevState,
      updatedBatch:
        prevState.updatedBatch.length > 0
          ? prevState.updatedBatch.map((clockAction) => {
              const changeClockOut = clockAction?.clockOut
                ? clockAction.clockOut
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockOut;
              return {
                ...clockAction,
                clockOut: new Date(
                  new Date(changeClockOut).setHours(time[0], time[1])
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockOut: new Date(
                new Date(clockAction.clockOut).setHours(time[0], time[1])
              ).toISOString(),
            })),
      apiUpdatedBatch:
        prevState.apiUpdatedBatch.length > 0
          ? prevState.apiUpdatedBatch.map((clockAction) => {
              const changeClockOut = clockAction?.clockOut
                ? clockAction.clockOut
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockOut;
              return {
                ...clockAction,
                clockOut: new Date(
                  new Date(changeClockOut).setHours(time[0], time[1])
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockOut: new Date(
                new Date(clockAction.clockOut).setHours(time[0], time[1])
              ).toISOString(),
            })),
    }));
  };

  const handleOutDateChange = (value) => {
    setEndDate(value);
    const date = new Date(value).getDate();
    const month = new Date(value).getMonth();
    const year = new Date(value).getFullYear();
    setState((prevState) => ({
      ...prevState,
      updatedBatch:
        prevState.updatedBatch.length > 0
          ? prevState.updatedBatch.map((clockAction) => {
              const changeClockOutDate = clockAction?.clockOut
                ? clockAction.clockOut
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockOut;
              return {
                ...clockAction,
                clockOut: new Date(
                  new Date(changeClockOutDate).setFullYear(year, month, date)
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockOut: new Date(
                new Date(
                  clockAction?.clockOut ? clockAction.clockOut : value
                ).setDate(date)
              ).toISOString(),
            })),
      apiUpdatedBatch:
        prevState.apiUpdatedBatch.length > 0
          ? prevState.apiUpdatedBatch.map((clockAction) => {
              const changeClockOutDate = clockAction?.clockOut
                ? clockAction.clockOut
                : editBatch[
                    editBatch.findIndex(
                      (batchItem) => batchItem.id === clockAction.id
                    )
                  ].clockOut;
              return {
                ...clockAction,
                clockOut: new Date(
                  new Date(changeClockOutDate).setFullYear(year, month, date)
                ).toISOString(),
              };
            })
          : prevState.editBatch.map((clockAction) => ({
              id: clockAction.id,
              clockOut: new Date(
                new Date(
                  clockAction?.clockOut ? clockAction.clockOut : value
                ).setDate(date)
              ).toISOString(),
            })),
    }));
  };

  const sameJob = editBatch.every(
    (clockAction) => clockAction.jobId === editBatch[0].jobId
  );
  const sameCostCode = editBatch.every(
    (clockAction) => clockAction.taskId === editBatch[0].taskId
  );

  const employeeList = editBatch.map((clockAction) => ({
    ...clockAction,
    employeeName: `${clockAction.user.firstName} ${clockAction.user.lastName}`,
  }));

  const selectedEmployeeList = _.chain(employeeList)
    .groupBy("employeeName")
    .map((value, key) => ({ name: key, clockAction: value }))
    .value();

  const { apiUpdatedBatch, updatedBatch } = state;
  return (
    <div>
      <div>
        <h3
          style={{
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Edit Time
        </h3>
        <TimeForm
          onSubmit={async (e) => {
            e.preventDefault();
            const requestOptions = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(apiUpdatedBatch),
            };
            await fetch(`${API_URL}/update/clockactions`, requestOptions)
              .then((res) => res.json())
              .then(onEditBatch(updatedBatch))
              .then(onDeselectBatch());
          }}
        >
          <fieldset>
            <div className="fields">
              <div
                htmlFor="user"
                className="employees"
                style={{ marginLeft: "20%" }}
              >
                <label>Selected Records</label>
                <br />
                <ul>
                  {selectedEmployeeList.map(({ name, clockAction }) => (
                    <li key={name}>{`${name} (${clockAction.length})`}</li>
                  ))}
                </ul>
              </div>
              <div style={{ textAlign: "left" }}>
                <label htmlFor="jobs" className="job">
                  Job
                  <br />
                  <select
                    value={jobId ? jobId : sameJob ? editBatch[0].jobId : ""}
                    name="jobId"
                    id="jobId"
                    onChange={handleJobChange}
                    className="input-field"
                    style={{ width: "100%" }}
                  >
                    <option value="">Select Job</option>
                    {jobs.map((job) => (
                      <option key={job.id} value={job.id}>
                        {job.jobNumber} - {job.jobName}
                      </option>
                    ))}
                  </select>
                </label>
                <br />
                <label htmlFor="tasks" className="cost-code">
                  Cost Code
                  <br />
                  <select
                    value={
                      taskId ? taskId : sameCostCode ? editBatch[0].taskId : ""
                    }
                    name="taskId"
                    id="taskId"
                    onChange={handleTaskChange}
                    className="input-field"
                    style={{ width: "100%" }}
                  >
                    <option value="">Select Cost Code</option>
                    {tasks.map((task) => (
                      <option key={task.id} value={task.id}>
                        {task.taskCode} - {task.taskName}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div style={{ textAlign: "left" }}>
                <label htmlFor="time-in" className="start">
                  Start
                  <br />
                  <DatePicker
                    name="clockIn"
                    id="clockIn"
                    selected={startDate}
                    onChange={(date) => handleInDateChange(date)}
                    placeholderText="Select A Date"
                  />
                  <TimePicker
                    name="clockIn"
                    id="clockIn"
                    onChange={handleInTimeChange}
                    value={startTime}
                    disableClock
                  />
                </label>
                <br />
                <label htmlFor="time-out" className="end">
                  End
                  <br />
                  <DatePicker
                    name="clockOut"
                    id="clockOut"
                    selected={endDate}
                    onChange={(date) => handleOutDateChange(date)}
                    placeholderText="Select A Date"
                  />
                  <TimePicker
                    name="clockOut"
                    id="clockOut"
                    onChange={handleOutTimeChange}
                    value={endTime}
                    format={"h:mm a"}
                    disableClock
                  />
                </label>
              </div>
              <div className="allocate width-80">
                <br />
                <Button
                  onClick={() => onAllocateBatch(editBatch)}
                  style={{ background: "green" }}
                  disabled={
                    !editBatch.every(
                      (clockAction) =>
                        clockAction.jobId === editBatch[0].jobId &&
                        dayjs(editBatch[0].clockIn).isSame(
                          clockAction.clockIn,
                          "day"
                        )
                    )
                  }
                >
                  Allocate
                </Button>
              </div>
              <div className="buttons">
                <Button
                  className="delete-button"
                  style={{ backgroundColor: "red" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOnToggleConfirm(true);
                  }}
                >
                  Delete All
                </Button>
                <Button
                  className="submit-button"
                  type="submit"
                  // disabled={timeDiff}
                >
                  Save All
                </Button>
              </div>
            </div>
          </fieldset>
        </TimeForm>
      </div>
    </div>
  );
};

MultipleTime.propTypes = {
  id: PropTypes.string,
  editBatch: PropTypes.array,
  onEditBatch: PropTypes.func,
  onDeselectBatch: PropTypes.func,
  setOnToggleConfirm: PropTypes.func,
  onAllocateBatch: PropTypes.func,
  jobs: PropTypes.array,
  tasks: PropTypes.array,
};

export default MultipleTime;
