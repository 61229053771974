import React, { useState } from "react";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { API_URL } from "../../env";
import { Button } from "../../styles/Style";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const DateButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: center;
    display: inline-flex;
    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const ExportHourly = () => {
  const [loading, setLoading] = useState(false);
  const [exportType, setExportType] = useState("");
  const [csv, setCSV] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(start.toISOString()));
  const [endDate, setEndDate] = useState(new Date(end.toISOString()));

  const getTime = () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/timerecords/${startDate.toISOString()}/${endDate.toISOString()}/${exportType}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setData(json));
    if (exportType === "CHAMPION") {
      setCSV(
        data
          .map((item) => {
            return [
              item.user.employeeNumber,
              item.job.jobNumber,
              item.clockAction.task.taskCode,
              item.duration,
              dayjs(item.clockIn).format("MM/DD/YYYY"),
              item.payClass,
            ];
          })
          .sort((a, b) => a[0] - b[0] || new Date(a[4]) - new Date(b[4]))
      );
    } else {
      setCSV(
        data.map((item) => {
          return [
            `${item.user.firstName} ${item.user.lastName}`,
            item.job.jobNumber,
            `${item.job.jobNumber} ${item.job.jobName}`,
            dayjs(item.clockIn).format("MM/DD/YYYY"),
            item.duration,
            item.job.jobAddress,
            item.job.jobCity,
            item.job.jobState,
            item.job.jobZip,
          ];
        })
      );
    }
    setLoading(false);
  };
  const handleExportTypeChange = (e) => {
    const { value } = e.target;
    setExportType(value);
    setCSV([]);
  };
  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setStartDate(start);
    setEndDate(newEnd);
    setCSV([]);
  };
  return (
    <div>
      <h2>Export Time</h2>
      <DateButton>
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(startDate, 1), subWeeks(endDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(startDate, 1), subDays(endDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(startDate, 1), addDays(endDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(startDate, 1), addWeeks(endDate, 1)])
            }
          >
            {">>"}
          </Button>
        </div>
      </DateButton>
      <select onChange={handleExportTypeChange} value={exportType}>
        <option value="">Select Time To Export</option>
        <option value="RAMS">RAMS</option>
        <option value="CTS">CTS</option>
        <option value="PREMIER">PREMIER</option>
        <option value="CHAMPION">CHAMPION</option>
      </select>
      <br />
      <br />
      {csv.length > 0 && !loading ? (
        <CSVLink
          data={csv}
          style={{
            background: "blue",
            color: "white",
            padding: "5px",
          }}
          filename={`${exportType}_WE_${dayjs(endDate).format("MM-DD-YY")}.csv`}
        >
          Download
        </CSVLink>
      ) : loading ? (
        <Button>Loading</Button>
      ) : (
        <Button
          disabled={exportType === ""}
          onClick={() => {
            setLoading(true);
            getTime();
          }}
        >
          Get Time
        </Button>
      )}
    </div>
  );
};

export default ExportHourly;
