import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const ExpenseForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-row-start: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddExpenseForm = () => {
  const [state, setState] = useState({
    userId: "",
    purchaseDate: new Date(),
    jobId: "",
    taskId: "",
    amount: 0.0,
    description: "",
  });

  const handleDateChange = (value) => {
    setState((prevState) => ({ ...prevState, purchaseDate: value }));
  };

  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [costCodes, setCostCodes] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCostCodes(json));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ [name]: value });
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...state }),
    };
    fetch(`${API_URL}/create/expense`, requestOptions).then((response) =>
      response.json()
    );
  };

  const { userId, purchaseDate, jobId, taskId, amount, description } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Expense
      </h1>
      <div>
        <ExpenseForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/forms/expenses");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="employees" className="employees">
                Employee
                <br />
                <select
                  value={userId}
                  name="userId"
                  id="userId"
                  required
                  onChange={handleChange}
                  className="input-field"
                >
                  <option>Select Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName +
                        (employee.middleName
                          ? " " + employee.middleName + " " + employee.lastName
                          : " " + employee.lastName)}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="jobs" className="job">
                Job
                <br />
                <select
                  value={jobId}
                  name="jobId"
                  id="jobId"
                  required
                  onChange={handleChange}
                  className="input-field"
                >
                  <option>Select Job</option>
                  {jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.jobNumber} - {job.jobName}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="tasks" className="cost-code">
                Cost Code
                <br />
                <select
                  value={taskId}
                  name="taskId"
                  id="taskId"
                  required
                  onChange={handleChange}
                  className="input-field"
                >
                  <option>Select Cost Code</option>
                  {costCodes.map((task) => (
                    <option key={task.id} value={task.id}>
                      {task.taskCode} - {task.taskName}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Date
                <DatePicker
                  selected={purchaseDate}
                  onChange={handleDateChange}
                />
              </label>
              <label>
                Description
                <br />
                <input
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                  onChange={handleChange}
                  placeholder="Description"
                  autoComplete="none"
                />
              </label>
              <label>
                Amount
                <br />
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  value={amount}
                  onChange={handleChange}
                  placeholder="Amount*"
                  required
                  autoComplete="none"
                />
              </label>
              <Button type="submit">Create Expense</Button>
            </div>
          </fieldset>
        </ExpenseForm>
      </div>
    </div>
  );
};

export default AddExpenseForm;
