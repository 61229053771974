import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CustomStyles from "../../../styles/SelectCustomStyle";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const SelectPurchaseOrder = styled.div``;

const DeliveryContainer = styled.div`
  text-align: left;
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
    }
    .submit-button {
      display: flex;
      justify-content: right;
      height: 50px;
    }
    .order-requisitions {
      button {
        margin-right: 5px;
      }
    }
  }
  .reqDetails {
    display: grid;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
        h3 {
          margin: 3px 0px;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    table {
      width: 100%;
    }
  }
`;

const CreateDelivery = ({ userId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [attachments] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [purchaseOrderId, setPurchaseOrderId] = useState("");
  const [delivery, setDelivery] = useState([]);
  const [date, setDate] = useState(new Date());
  const [notes, setNotes] = useState("");
  const [employees, setEmployees] = useState([]);
  const [additionalRecipientEmails, setAdditionalRecipientEmails] =
    useState(null);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/orders/active`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setPurchaseOrders(json);
        setLoading(false);
      });
    fetch(`${API_URL}/employee/emails`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setEmployees(json);
      });
  }, []);

  useEffect(() => {
    if (purchaseOrderId) {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(`${API_URL}/orderdelivery/${purchaseOrderId}`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setPurchaseOrder(json);
          setDelivery(
            json.items.map((item) => ({
              itemId: item.itemId,
              quantityDelivered: 0,
              quantityBackordered: 0,
              quantityDamaged: 0,
              quantityMissing: 0,
              totalReceived:
                json.Deliveries.length > 0
                  ? json.Deliveries.reduce(
                      (prev, cur) =>
                        prev +
                        cur.items.reduce(
                          (prev, cur) =>
                            prev + cur.itemId === item.itemId
                              ? cur.quantityDelivered
                              : 0,
                          0
                        ),
                      0
                    )
                  : 0,
            }))
          );
          setLoading(false);
        });
    }
  }, [purchaseOrderId]);

  const poList = purchaseOrders.map((purchaseOrder) => ({
    value: purchaseOrder.id,
    label: `${purchaseOrder.purchaseOrderNumber} ${purchaseOrder.job.jobName}`,
  }));
  const employeeList = employees.map((employee) => ({
    value: employee.email,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const handleAdditionalEmailChange = (e) => {
    const selectedEmails = e.map((e) => e.value);
    setAdditionalRecipientEmails(selectedEmails);
  };

  const handleQuantityChange = (e, index, amtDelivered) => {
    const { name, value } = e.target;
    setDelivery((prevState) => {
      const items = [...prevState];
      if (name === "quantityDelivered") {
        items[index] = {
          ...items[index],
          [name]: value,
          totalReceived: parseInt(value) + amtDelivered,
        };
      } else {
        items[index] = {
          ...items[index],
          [name]: value,
        };
      }
      return [...items];
    });
  };

  const handleReceiveAll = () => {
    setDelivery((prevState) => {
      const items = [];
      for (let i = 0; i < purchaseOrder.items.length; i++) {
        const item = prevState[i];
        item.quantityDelivered =
          purchaseOrder.Deliveries.length > 0
            ? purchaseOrder.items[i].quantityOrdered -
              purchaseOrder.Deliveries.reduce(
                (prev, cur) =>
                  prev +
                  cur.items.reduce(
                    (prev, cur) =>
                      prev +
                      (cur.itemId === purchaseOrder.items[i].itemId
                        ? cur.quantityDelivered
                        : 0),
                    0
                  ),
                0
              )
            : purchaseOrder.items[i].quantityOrdered;
        item.quantityBackordered =
          -1 *
          purchaseOrder.Deliveries.reduce(
            (prev, cur) =>
              prev +
              cur.items.reduce(
                (prev, cur) =>
                  prev +
                  (cur.itemId === purchaseOrder.items[i].itemId
                    ? cur.quantityBackordered
                    : 0),
                0
              ),
            0
          );
        item.quantityDamaged =
          -1 *
          purchaseOrder.Deliveries.reduce(
            (prev, cur) =>
              prev +
              cur.items.reduce(
                (prev, cur) =>
                  prev +
                  (cur.itemId === purchaseOrder.items[i].itemId
                    ? cur.quantityDamaged
                    : 0),
                0
              ),
            0
          );
        item.quantityMissing =
          -1 *
          purchaseOrder.Deliveries.reduce(
            (prev, cur) =>
              prev +
              cur.items.reduce(
                (prev, cur) =>
                  prev +
                  (cur.itemId === purchaseOrder.items[i].itemId
                    ? cur.quantityMissing
                    : 0),
                0
              ),
            0
          );
        item.totalReceived = purchaseOrder.items[i].quantityOrdered;
        items.push(item);
      }
      return [...items];
    });
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        deliveryData: {
          userId,
          jobId: purchaseOrder.jobId,
          vendorId: purchaseOrder.vendorId,
          orderId: purchaseOrder.id,
          notes,
          items: {
            createMany: {
              data: delivery
                .map((item) => ({
                  itemId: parseInt(item.itemId),
                  quantityDelivered: parseInt(item.quantityDelivered),
                  quantityBackordered: parseInt(item.quantityBackordered),
                  quantityDamaged: parseInt(item.quantityDamaged),
                  quantityMissing: parseInt(item.quantityMissing),
                }))
                .filter(
                  (item) =>
                    item.quantityDelivered !== 0 ||
                    item.quantityBackordered !== 0 ||
                    item.quantityDamaged !== 0 ||
                    item.quantityMissing !== 0
                ),
            },
          },
          additionalRecipientEmails,
        },
        orderState: purchaseOrder.items.every(
          (item, index) =>
            item.quantityOrdered === delivery[index].totalReceived
        )
          ? "COMPLETE"
          : "INCOMPLETE",
      }),
    };
    fetch(`${API_URL}/delivery/receive/${purchaseOrderId}`, requestOptions)
      .then((res) => res.json())
      .then((json) => navigate(`/delivery/${json.id}`));
  };
  return (
    purchaseOrders.length > 0 && (
      <div>
        {purchaseOrderId ? (
          purchaseOrder && (
            <DeliveryContainer>
              <div className="order-header">
                <div>
                  <h1 className="po-title">
                    Receive{" "}
                    <Link to={`/purchaseorder/${purchaseOrder.id}`}>
                      {purchaseOrder.purchaseOrderNumber}
                    </Link>
                  </h1>
                  <div>
                    <Button onClick={() => handleReceiveAll()}>
                      Receive All - No Issues
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="reqDetails"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>
                        <h3>Order Details</h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Job</th>
                      <td>{`${purchaseOrder.job.jobNumber} ${purchaseOrder.job.jobName}`}</td>
                    </tr>
                    <tr>
                      <th>Received by</th>
                      <td>
                        {`${purchaseOrder.user.firstName} ${purchaseOrder.user.lastName}`}
                      </td>
                    </tr>
                    <tr>
                      <th>Received On</th>
                      <td>
                        <DatePicker
                          selected={date}
                          onChange={(date) => setDate(date)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <h3>Accounting Details</h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Vendor</th>
                      <td>
                        <span>{purchaseOrder.vendor.vendorName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Additional Email</th>
                      <td>
                        <Select
                          styles={CustomStyles}
                          options={employeeList}
                          isLoading={loading}
                          name="additionalRecipientEmails"
                          onChange={handleAdditionalEmailChange}
                          defaultValue={additionalRecipientEmails}
                          placeholder="Select Addtional Recipient"
                          isMulti
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <h3>Notes</h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <textarea
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <AttachmentBanner
                attachments={attachments}
                addAttachment={true}
              />
              <div>
                <table className="item-table">
                  <thead>
                    <tr>
                      <th
                        style={{ background: "black", color: "white" }}
                        colSpan={"100%"}
                      >
                        <h3>Items</h3>
                      </th>
                    </tr>
                    <tr style={{ textAlign: "center" }}>
                      <th colSpan={2}></th>
                      <th colSpan={2}></th>
                      <th colSpan={2}></th>
                      <th colSpan={2}>Received</th>
                      <th colSpan={2}>Backordered</th>
                      <th colSpan={2}>Damaged</th>
                      <th colSpan={2}>Missing</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Order Qty.</th>
                      <th colSpan={2}>Unit</th>
                      <th colSpan={2}>Description</th>
                      <th style={{ textAlign: "center" }}>Prev</th>
                      <th style={{ textAlign: "center" }}>Cur</th>
                      <th style={{ textAlign: "center" }}>Prev</th>
                      <th style={{ textAlign: "center" }}>Cur</th>
                      <th style={{ textAlign: "center" }}>Prev</th>
                      <th style={{ textAlign: "center" }}>Cur</th>
                      <th style={{ textAlign: "center" }}>Prev</th>
                      <th style={{ textAlign: "center" }}>Cur</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrder.items.length > 0 &&
                      purchaseOrder.items.map((item, itemIndex) => {
                        const amtDelivered =
                          purchaseOrder.Deliveries.length > 0
                            ? purchaseOrder.Deliveries.reduce(
                                (prev, cur) =>
                                  prev +
                                  cur.items.reduce(
                                    (prev, cur) =>
                                      prev +
                                      (cur.itemId === item.itemId
                                        ? cur.quantityDelivered
                                        : 0),
                                    0
                                  ),
                                0
                              )
                            : 0;
                        const amtBackordered =
                          purchaseOrder.Deliveries.length > 0
                            ? purchaseOrder.Deliveries.reduce(
                                (prev, cur) =>
                                  prev +
                                  cur.items.reduce(
                                    (prev, cur) =>
                                      prev +
                                      (cur.itemId === item.itemId
                                        ? cur.quantityBackordered
                                        : 0),
                                    0
                                  ),
                                0
                              )
                            : 0;

                        const amtDamaged =
                          purchaseOrder.Deliveries.length > 0
                            ? purchaseOrder.Deliveries.reduce(
                                (prev, cur) =>
                                  prev +
                                  cur.items.reduce(
                                    (prev, cur) =>
                                      prev +
                                      (cur.itemId === item.itemId
                                        ? cur.quantityDamaged
                                        : 0),
                                    0
                                  ),
                                0
                              )
                            : 0;
                        const amtMissing =
                          purchaseOrder.Deliveries.length > 0
                            ? purchaseOrder.Deliveries.reduce(
                                (prev, cur) =>
                                  prev +
                                  cur.items.reduce(
                                    (prev, cur) =>
                                      prev +
                                      (cur.itemId === item.itemId
                                        ? cur.quantityMissing
                                        : 0),
                                    0
                                  ),
                                0
                              )
                            : 0;
                        const borderQuantity =
                          amtDelivered +
                            amtBackordered +
                            amtDamaged +
                            amtMissing +
                            parseInt(delivery[itemIndex].quantityDelivered) +
                            parseInt(delivery[itemIndex].quantityBackordered) +
                            parseInt(delivery[itemIndex].quantityDamaged) +
                            parseInt(delivery[itemIndex].quantityMissing) >
                          item.quantityOrdered
                            ? "red"
                            : "black";
                        return (
                          <tr key={item.itemId}>
                            <td
                              style={{
                                borderColor: borderQuantity,
                              }}
                              colSpan={2}
                            >
                              {item.quantityOrdered}
                            </td>
                            <td
                              style={{
                                borderColor: borderQuantity,
                              }}
                              colSpan={2}
                            >
                              {item.item.unitOfMeasure.code}
                            </td>
                            <td
                              style={{
                                borderColor: borderQuantity,
                              }}
                              colSpan={2}
                            >
                              {item.item.description}
                            </td>
                            <td
                              style={{
                                borderColor: borderQuantity,
                                width: "5%",
                                textAlign: "center",
                              }}
                            >
                              {amtDelivered}
                            </td>
                            <td
                              style={{
                                borderColor: borderQuantity,
                                width: "5%",
                                textAlign: "center",
                              }}
                            >
                              <input
                                style={{ width: "100px", textAlign: "center" }}
                                name="quantityDelivered"
                                type="number"
                                value={delivery[itemIndex].quantityDelivered}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    e,
                                    itemIndex,
                                    amtDelivered
                                  )
                                }
                              />
                            </td>
                            <td
                              style={{
                                width: "5%",
                                borderColor: borderQuantity,
                                textAlign: "center",
                              }}
                            >
                              {amtBackordered}
                            </td>
                            <td
                              style={{
                                borderColor: borderQuantity,
                                width: "5%",
                                textAlign: "center",
                              }}
                            >
                              <input
                                style={{ width: "100px", textAlign: "center" }}
                                name="quantityBackordered"
                                type="number"
                                value={delivery[itemIndex].quantityBackordered}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    e,
                                    itemIndex,
                                    amtDelivered
                                  )
                                }
                              />
                            </td>
                            <td
                              style={{
                                width: "5%",
                                borderColor: borderQuantity,
                                textAlign: "center",
                              }}
                            >
                              {amtDamaged}
                            </td>
                            <td
                              style={{
                                width: "5%",
                                borderColor: borderQuantity,
                                textAlign: "center",
                              }}
                            >
                              <input
                                style={{ width: "100px", textAlign: "center" }}
                                name="quantityDamaged"
                                type="number"
                                value={delivery[itemIndex].quantityDamaged}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    e,
                                    itemIndex,
                                    amtDelivered
                                  )
                                }
                              />
                            </td>
                            <td
                              style={{
                                width: "5%",
                                borderColor: borderQuantity,
                                textAlign: "center",
                              }}
                            >
                              {amtMissing}
                            </td>
                            <td
                              style={{
                                width: "5%",
                                borderColor: borderQuantity,
                                textAlign: "center",
                              }}
                            >
                              <input
                                style={{ width: "100px", textAlign: "center" }}
                                name="quantityMissing"
                                type="number"
                                value={delivery[itemIndex].quantityMissing}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    e,
                                    itemIndex,
                                    amtDelivered
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={handleSubmit}>Submit</Button>
              </div>
            </DeliveryContainer>
          )
        ) : (
          <SelectPurchaseOrder>
            <div className="order-header">
              <div>
                <h1 className="po-title">Select Purchase Order For Delivery</h1>
                <div>
                  <Select
                    styles={CustomStyles}
                    options={poList}
                    isLoading={loading}
                    name="purchaseOrderId"
                    onChange={(e) => setPurchaseOrderId(e.value)}
                    defaultValue={purchaseOrderId}
                    placeholder="Select Purchase Order"
                  />
                </div>
              </div>
            </div>
          </SelectPurchaseOrder>
        )}
      </div>
    )
  );
};

CreateDelivery.propTypes = {
  userId: PropTypes.string,
};

export default CreateDelivery;
