import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { NavContainer, Links } from "./styles/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import logo from "../../static/champelec_logo_white_text.png";

const Nav = ({ isLoggedIn, permission, id, benefitEnrollmentEnabled }) => {
  !localStorage.getItem("navState") &&
    localStorage.setItem(
      "navState",
      JSON.stringify({
        toggleTimecard: false,
        toggleForms: false,
        toggleUpload: false,
        toggleSettings: false,
        toggleReports: false,
        toggleTools: false,
        toggleDashboard: false,
        toggleMaterial: false,
        toggleExport: false,
      })
    );
  const [toggleNav, setToggleNav] = useState(
    JSON.parse(localStorage.getItem("navState"))
  );
  const {
    toggleForms,
    toggleMaterial,
    toggleReports,
    toggleSettings,
    toggleTimecard,
    toggleTools,
    toggleDashboard,
    toggleUpload,
    toggleExport,
  } = toggleNav;
  const handleToggleNav = (section, status) => {
    setToggleNav((prevState) => {
      prevState[section] = !status;
      localStorage.setItem("navState", JSON.stringify(prevState));
      return { ...prevState };
    });
  };

  const TimeCardNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleTimecard", toggleTimecard)}>
        <span className="text">TIMECARDS</span>
        {toggleTimecard ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleTimecard && (
        <ul>
          <li>
            <NavLink
              to="/create/mytimecard"
              activeclassname="active"
              className="text"
            >
              New Timecard
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/timecards/user/${id}`}
              activeclassname="active"
              className="text"
            >
              My Timecards
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/create/timecard"
              activeclassname="active"
              className="text"
            >
              Create Timecard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/timecards"
              activeclassname="active"
              className="text"
              end
            >
              All Timecards
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const FormsNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleForms", toggleForms)}>
        <span className="text">Forms</span>
        {toggleForms ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleForms && (
        <ul>
          <li>
            <NavLink
              to="/forms/dailies"
              activeclassname="active"
              className="text"
            >
              Daily Reports
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/forms/workorders"
              activeclassname="active"
              className="text"
            >
              Field Work Orders
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/forms/expenses"
              activeclassname="active"
              className="text"
            >
              Expense Reports
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const ToolsNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleTools", toggleTools)}>
        <span className="text">Tools</span>
        {toggleTools ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleTools && (
        <ul>
          <li>
            <NavLink to="/tools" activeclassname="active" className="text">
              Tools
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tool/locations"
              activeclassname="active"
              className="text"
            >
              Locations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tool/manufacturers"
              activeclassname="active"
              className="text"
            >
              Manufacturers
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tool/categories"
              activeclassname="active"
              className="text"
            >
              Categories
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const MaterialNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleMaterial", toggleMaterial)}>
        <span className="text">Material</span>
        {toggleMaterial ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleMaterial && (
        <ul>
          <li>
            <NavLink to="/estimates" activeclassname="active" className="text">
              Estimates
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/material/requisitions"
              activeclassname="active"
              className="text"
            >
              Requisitions
            </NavLink>
          </li>
          <li>
            <NavLink to="/rfqs" activeclassname="active" className="text">
              Request For Quotes
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/purchaseorders"
              activeclassname="active"
              className="text"
            >
              Purchase Orders
            </NavLink>
          </li>
          <li>
            <NavLink to="/deliveries" activeclassname="active" className="text">
              Delivieries
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/export/purchaseorders"
              activeclassname="active"
              className="text"
            >
              Export PO
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const ReportsNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleReports", toggleReports)}>
        <span className="text">Reports</span>
        {toggleReports ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleReports && (
        <ul>
          <li>
            <NavLink
              to="/reports/jobhoursdetail"
              activeclassname="active"
              className="text"
            >
              Job Hours Detail
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/jobhourssummary"
              activeclassname="active"
              className="text"
            >
              Job Hours Summary
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/gangsheets"
              activeclassname="active"
              className="text"
            >
              Gang Sheets
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/alltimecards"
              activeclassname="active"
              className="text"
            >
              All Timecards
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/hourlytimecards"
              activeclassname="active"
              className="text"
            >
              Hourly Timecards
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/salarytimecards"
              activeclassname="active"
              className="text"
            >
              Salary Timecards
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/expenses"
              activeclassname="active"
              className="text"
            >
              Expenses
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/jobworkorders"
              activeclassname="active"
              className="text"
            >
              Time vs. Work Order
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/absentee"
              activeclassname="active"
              className="text"
            >
              Absentee Report
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports/manpower"
              activeclassname="active"
              className="text"
            >
              Manpower Report
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const ExportNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleExport", toggleExport)}>
        <span className="text">Export</span>
        {toggleExport ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleExport && (
        <ul>
          <li>
            <NavLink
              to="/export/hourly"
              activeclassname="active"
              className="text"
            >
              Export Hourly
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/export/salary"
              activeclassname="active"
              className="text"
            >
              Export Salary
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const UploadNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleUpload", toggleUpload)}>
        <span className="text">Upload</span>
        {toggleUpload ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleUpload && (
        <ul>
          <li>
            <NavLink
              to="/jobs/upload"
              activeclassname="active"
              className="text"
            >
              Jobs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/costcodes/upload"
              activeclassname="active"
              className="text"
            >
              Cost Codes
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/employees/upload"
              activeclassname="active"
              className="text"
            >
              Employees
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/customers/upload"
              activeclassname="active"
              className="text"
            >
              Customers
            </NavLink>
          </li>
          {/* <li>
                    <NavLink
                      to="/create/timecard"
                      activeclassname="active"
                      className="text"
                    >
                      Tools
                    </NavLink>
                  </li> */}
        </ul>
      )}
    </>
  );

  const SettingsNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleSettings", toggleSettings)}>
        <span className="text">Settings</span>
        {toggleSettings ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleSettings && (
        <ul>
          <li>
            <NavLink to="/jobs" activeclassname="active" className="text" end>
              Jobs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/employees"
              activeclassname="active"
              className="text"
              end
            >
              Employees
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/costcodes"
              activeclassname="active"
              className="text"
              end
            >
              Cost Codes
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/departments"
              activeclassname="active"
              className="text"
              end
            >
              Departments
            </NavLink>
          </li>
          <li>
            <NavLink to="/devices" activeclassname="active" className="text">
              Devices
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/customers"
              activeclassname="active"
              className="text"
              end
            >
              Customers
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/vendors"
              activeclassname="active"
              className="text"
              end
            >
              Vendors
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/benefit-plans"
              activeclassname="active"
              className="text"
              end
            >
              Add Benefit Plans
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/benefit-enrollment"
              activeclassname="active"
              className="text"
              end
            >
              Benefit Enrollment
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/material/settings"
              activeclassname="active"
              className="text"
            >
              Material Settings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings/admin"
              activeclassname="active"
              className="text"
            >
              Admin Settings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/download/app"
              activeclassname="active"
              className="text"
            >
              App Download
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  const DashboardNavContainer = () => (
    <>
      <div onClick={() => handleToggleNav("toggleDashboard", toggleDashboard)}>
        <span className="text">Dashboard</span>
        {toggleDashboard ? (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleUp} />
          </span>
        ) : (
          <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {toggleDashboard && (
        <ul>
          <li>
            <NavLink
              to="/project-dashboard"
              activeclassname="active"
              className="text"
            >
              Project Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/time-clock-dashboard"
              activeclassname="active"
              className="text"
            >
              Time Map Dashboard
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );

  return (
    <NavContainer>
      {isLoggedIn && (
        <Links>
          {/* Make only salaried employees able to access create time card */}

          {permission === "ADMIN" && (
            <div className={`salary`}>
              <div>
                <Link to="/">
                  <img
                    src={logo}
                    alt="champion-logo"
                    className="champion-logo"
                  />
                </Link>
              </div>
              <div>
                <NavLink to="/time" activeclassname="active" className="text">
                  Time
                </NavLink>
              </div>
              <TimeCardNavContainer />
              <FormsNavContainer />
              <ToolsNavContainer />
              <MaterialNavContainer />
              <DashboardNavContainer />
              <ReportsNavContainer />
              <ExportNavContainer />
              <UploadNavContainer />
              {benefitEnrollmentEnabled && (
                <div>
                  <NavLink
                    to="/benefits/select-plan"
                    activeclassname="active"
                    className="text"
                  >
                    Benefits
                  </NavLink>
                </div>
              )}
              <SettingsNavContainer />
            </div>
          )}
          {(permission === "FOREMAN" || permission === "PM") && (
            <div className={`salary`}>
              <div>
                <Link to="/">
                  <img
                    src={logo}
                    alt="champion-logo"
                    className="champion-logo"
                  />
                </Link>
              </div>
              <div
                onClick={() =>
                  handleToggleNav("toggleTimecard", toggleTimecard)
                }
              >
                <span className="text">TIMECARDS</span>
                {toggleTimecard ? (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </span>
                ) : (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                )}
              </div>
              {toggleTimecard && (
                <ul>
                  <li>
                    <NavLink
                      to="/create/mytimecard"
                      activeclassname="active"
                      className="text"
                    >
                      New Timecard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/timecards/user/${id}`}
                      activeclassname="active"
                      className="text"
                    >
                      My Timecards
                    </NavLink>
                  </li>
                </ul>
              )}
              {/* <div
                onClick={() =>
                  handleToggleNav("toggleMaterial", toggleMaterial)
                }
              >
                <span className="text">Material</span>
                {toggleMaterial ? (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </span>
                ) : (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                )}
              </div>
              {toggleMaterial && (
                <ul>
                <li>
                    <NavLink
                      to="/material/estimates"
                      activeclassname="active"
                      className="text"
                    >
                      Estimates
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/material/requisitions"
                      activeclassname="active"
                      className="text"
                    >
                      Requisitions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/rfqs"
                      activeclassname="active"
                      className="text"
                    >
                      Request For Quotes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/purchaseorders"
                      activeclassname="active"
                      className="text"
                    >
                      Purchase Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/material/deliveries"
                      activeclassname="active"
                      className="text"
                    >
                      Delivieries
                    </NavLink>
                  </li>
                </ul>
              )} */}
              <div onClick={() => handleToggleNav("toggleForms", toggleForms)}>
                <span className="text">Forms</span>
                {toggleForms ? (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </span>
                ) : (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                )}
              </div>
              {toggleForms && (
                <ul>
                  <li>
                    <NavLink
                      to="/forms/dailies"
                      activeclassname="active"
                      className="text"
                    >
                      Daily Reports
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/forms/workorders"
                      activeclassname="active"
                      className="text"
                    >
                      Field Work Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/forms/expenses"
                      activeclassname="active"
                      className="text"
                    >
                      Expense Reports
                    </NavLink>
                  </li>
                </ul>
              )}
              <div onClick={() => handleToggleNav("toggleTools", toggleTools)}>
                <span className="text">Tools</span>
                {toggleTools ? (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </span>
                ) : (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                )}
              </div>
              {toggleTools && (
                <ul>
                  <li>
                    <NavLink
                      to="/tools"
                      activeclassname="active"
                      className="text"
                    >
                      Tools
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tool/locations"
                      activeclassname="active"
                      className="text"
                    >
                      Locations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tool/manufacturers"
                      activeclassname="active"
                      className="text"
                    >
                      Manufacturers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tool/categories"
                      activeclassname="active"
                      className="text"
                    >
                      Categories
                    </NavLink>
                  </li>
                </ul>
              )}
              <div>
                <NavLink
                  to="/time-clock-dashboard"
                  activeclassname="active"
                  className="text"
                >
                  Dashboard
                </NavLink>
              </div>
              <div
                onClick={() => handleToggleNav("toggleReports", toggleReports)}
              >
                <span className="text">Reports</span>
                {toggleReports ? (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </span>
                ) : (
                  <span className="arrow">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                )}
              </div>
              {toggleReports && (
                <ul>
                  <li>
                    <NavLink
                      to="/reports/expenses"
                      activeclassname="active"
                      className="text"
                    >
                      Expenses
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/jobhoursdetail"
                      activeclassname="active"
                      className="text"
                    >
                      Job Hours Detail
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/jobhourssummary"
                      activeclassname="active"
                      className="text"
                    >
                      Job Hours Summary
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/jobworkorders"
                      activeclassname="active"
                      className="text"
                    >
                      Job Work Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/absentee"
                      activeclassname="active"
                      className="text"
                    >
                      Absentee Report
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports/manpower"
                      activeclassname="active"
                      className="text"
                    >
                      Manpower Report
                    </NavLink>
                  </li>
                </ul>
              )}
              <div>
                <NavLink
                  to="/jobs"
                  activeclassname="active"
                  className="text"
                  end
                >
                  Jobs
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/employees"
                  activeclassname="active"
                  className="text"
                  end
                >
                  Employees
                </NavLink>
              </div>
            </div>
          )}
        </Links>
      )}
    </NavContainer>
  );
};

Nav.propTypes = {
  permission: PropTypes.string,
  id: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  benefitEnrollmentEnabled: PropTypes.bool,
};

export default Nav;
