import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";
import { FormContainer, Column } from "../Styles/MaterialFormStyles";
import MaterialTableBody from "../Components/MaterialTableBody";
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableRowNumberHeaderCell,
  TableHeaderCell,
} from "../Styles/MaterialTableStyles";
import CustomStyles from "../../../styles/SelectCustomStyle";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import "react-datepicker/dist/react-datepicker.css";

const PurchaseOrderContainer = styled.div`
  text-align: left;
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
      h1 {
        margin: 0px;
      }
      h3 {
        margin-top: 0px;
      }
    }
    .submit-button {
      display: flex;
      justify-content: right;
      button {
        margin: 20px;
      }
    }
  }
  .reqDetails {
    display: grid;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
        h3 {
          margin: 3px 0px;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .attachments {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px 0px;
    margin-bottom: 10px;
    h3 {
      margin: 3px 0px;
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    .text-input {
      width: 85%;
      height: 36.5px;
      margin: 0px;
      padding: 0px 10px;
      border: none;
    }
    .text-input:focus {
      outline: none;
      border: 1px solid black;
    }
    .reqButtons {
      justify-self: right;
      button {
        margin: 5px 50px 5px 5px;
        padding: 15px 25px;
        font-weight: bold;
        background: blue;
        border: none;
        color: white;
        cursor: pointer;
      }
    }
    table {
      width: 100%;
    }
  }
`;

const CreatePurchaseOrder = ({ user, cart }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([["", "", ""]]);
  const [fieldContactName, setFieldContactName] = useState("");
  const [fieldContactNumber, setFieldContactNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState("");
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState("");
  const [description, setDescription] = useState("");
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [needByDate, setNeedByDate] = useState(new Date());
  const [needByWindow, setNeedByWindow] = useState("");
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [billingDetails, setBillingDetails] = useState(
    "2875 Jupiter Park Dr. Ste 500, Jupiter, FL 33458"
  );
  const [generalLedgerCodes, setGeneralLedgerCodes] = useState([]);
  const [generalLedger, setGeneralLedger] = useState("");
  const [taxCodes, setTaxCodes] = useState([]);
  const [taxCode, setTaxCode] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [attachments] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState("");
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activejobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setJobs(json);
      });
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json);
      });
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTasks(json);
      });
    fetch(`${API_URL}/fieldcontacts`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setEmployees(json);
      });
    fetch(`${API_URL}/material/glcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setGeneralLedgerCodes(json);
      });
    fetch(`${API_URL}/material/uoms`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUnitsOfMeasure(json));
    fetch(`${API_URL}/material/taxcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTaxCodes(json);
      });
  }, []);

  useEffect(() => {
    jobs.length > 0 && setLoading(false);
  }, [jobs]);

  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const uomList = unitsOfMeasure.map((uom) => ({
    value: uom.id,
    label: uom.code,
  }));

  const employeeList = employees.map((employee) => ({
    value: employee.id,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const vendorList = vendors.map((vendor) => ({
    value: vendor.id,
    label: vendor.vendorName,
  }));
  const glCodeList = generalLedgerCodes.map((gl) => ({
    value: gl.id,
    label: `${gl.generalLedgerCode} - ${gl.generalLedgerName}`,
  }));
  const costCodeList = tasks.map((taskCode) => ({
    value: taskCode.id,
    label: `${taskCode.taskCode} - ${taskCode.taskName}`,
  }));
  const taxCodeList = taxCodes.map((tax) => ({
    value: tax.id,
    label: tax.taxCode,
  }));
  const windowList = [
    { value: "ASAP", label: "ASAP" },
    { value: "AMEarly", label: "7AM - 10AM" },
    { value: "AM", label: "10AM - 12PM" },
    { value: "PM", label: "12PM - 3PM" },
    { value: "PMLate", label: "3PM - 6PM" },
    { value: "CUSTOM", label: "Custom Time Window" },
  ];

  useEffect(() => {
    if (!loading) {
      if (location.state?.key === "cart" && location.state?.jobId) {
        handleSelectJob(location.state.jobId);
        const convertCartToPO = _.chain(cart)
          .groupBy("itemIdNumber")
          .map((cartItem) => cartItem)
          .value();
        setPurchaseOrderItems(
          convertCartToPO.map((cartItem) => [
            cartItem.reduce((prev, cur) => prev + cur.quantity, 0),
            cartItem[0].unitOfMeasureId,
            cartItem[0].description,
          ])
        );
      }
    }
  }, [location, loading]);

  const handleSelectJob = (value) => {
    setJob(value);
    const selectedJob = jobs[jobs.findIndex((job) => job.id === value)];
    setDeliveryAddress(
      `${selectedJob.jobAddress}${
        selectedJob.jobAddress2 ? ` ${selectedJob.jobAddress2} ` : ` `
      }${selectedJob.jobCity}, ${selectedJob.jobState} ${selectedJob.jobZip}`
    );
    setPurchaseOrderNumber(
      `${selectedJob.jobNumber}-${String(
        selectedJob.nextPurchaseOrderNumber
      ).padStart(3, "0")}`
    );
  };

  const handleFieldContactChange = (value) => {
    const employeeData =
      employees[employees.findIndex((employee) => employee.id === value)];
    setEmployee(value);
    setFieldContactName(`${employeeData.firstName} ${employeeData.lastName}`);
    setFieldContactNumber(employeeData.phoneNumber);
  };

  const createOrder = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        details: {
          purchaseOrderNumber,
          user: { connect: { id: user.id } },
          job: {
            connect: { id: job },
          },
          vendor: { connect: { id: vendor } },
          description,
          billingDetails,
          deliveryAddress,
          fieldContactName,
          fieldContactNumber,
          needByDate,
          needByWindow,
          deliveryInstructions,
          generalLedger: { connect: { id: generalLedger } },
          Task: { connect: { id: task } },
          tax: { connect: { id: taxCode } },
        },
        items: purchaseOrderItems.map((item, index) => ({
          purchaseOrderItemDetails: {
            quantityOrdered: item[0],
          },
          itemDetails: {
            lineNumber: index,
            description: item[2],
            unitOfMeasure: { connect: { id: item[1] } },
            quantity: item[0],
          },
        })),
        jobId: job,
      }),
    };

    fetch(`${API_URL}/create/blankorder`, requestOptions)
      .then((res) => res.json())
      .then((json) => navigate(`/purchaseorder/${json.order.id}`));
  };
  return (
    <PurchaseOrderContainer>
      <div className="order-header">
        <div className="po-title">
          <h1>PO# {purchaseOrderNumber}</h1>
          <h3>{job.jobName}</h3>
        </div>
        <div className="submit-button">
          <Button onClick={() => createOrder()}>Create Purchase Order</Button>
        </div>
      </div>
      <FormContainer>
        <Column>
          <div className="form-group">
            <label>Requested By</label>
            <input type="text" className="custom-input" value={user.name} />
          </div>
          <div className="form-group">
            <label>Job</label>
            {!loading &&
              jobList.length > 0 &&
              (location?.state?.key === "cart" ? (
                <p style={{ fontWeight: "bold" }}>
                  {
                    jobList[
                      jobList.findIndex(
                        (jobListItem) => jobListItem.value === job
                      )
                    ]?.label
                  }
                </p>
              ) : (
                <Select
                  options={jobList}
                  isLoading={loading}
                  name="jobId"
                  onChange={(e) => handleSelectJob(e.value)}
                  value={job}
                  placeholder="Select Job"
                />
              ))}
          </div>
          <div className="form-group">
            <label>Vendor</label>
            <Select
              styles={CustomStyles}
              options={vendorList}
              isLoading={loading}
              name="vendorId"
              onChange={(e) => setVendor(e.value)}
              defaultValue={vendor}
              placeholder="Select Vendor"
            />
          </div>
          <div className="form-group-double">
            <div className="form-group">
              <label>Need By Date</label>
              <DatePicker
                className="custom-input-double"
                selected={needByDate}
                onChange={(date) => setNeedByDate(date)}
              />
            </div>
            <div className="form-group">
              <label>Need By Window</label>
              <Select
                styles={CustomStyles}
                options={windowList}
                isLoading={loading}
                name="timeWindow"
                onChange={(e) => setNeedByWindow(e.value)}
                defaultValue={needByWindow}
                placeholder="Select Window"
              />
            </div>
          </div>
        </Column>
        <Column>
          <div className="form-group-double">
            <div className="form-group">
              <label>Field Contact</label>
              <Select
                styles={CustomStyles}
                options={employeeList}
                isLoading={loading}
                name="employeeId"
                onChange={(e) => handleFieldContactChange(e.value)}
                defaultValue={employee}
                placeholder="Select Field Contact"
              />
            </div>
            <div className="form-group">
              <label>Contact Phone #</label>
              <input
                className="custom-input-double"
                type="tel"
                value={
                  fieldContactNumber &&
                  fieldContactNumber.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3"
                  )
                }
                onChange={(e) => setFieldContactNumber(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <label>Delivery Address</label>
            <input
              type="text"
              className="custom-input"
              value={deliveryAddress}
            />
          </div>
          <div className="form-group">
            <label>Delivery Instructions</label>
            <textarea
              className="custom-input"
              value={deliveryInstructions}
              onChange={(e) => setDeliveryInstructions(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Order Description</label>
            <input
              className="custom-input"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </Column>
        <Column>
          <div className="form-group">
            <label>GL Code</label>
            <Select
              styles={CustomStyles}
              options={glCodeList}
              isLoading={loading}
              name="glCodeId"
              onChange={(e) => setGeneralLedger(e.value)}
              defaultValue={generalLedger}
              placeholder="Select GL Code"
            />
          </div>
          <div className="form-group">
            <label>Cost Code</label>
            <Select
              styles={CustomStyles}
              options={costCodeList}
              isLoading={loading}
              name="costCodeId"
              onChange={(e) => setTask(e.value)}
              defaultValue={task}
              placeholder="Select Cost Code"
            />
          </div>
          <div className="form-group">
            <label>Tax Code</label>
            <Select
              styles={CustomStyles}
              options={taxCodeList}
              isLoading={loading}
              name="taxCodeId"
              onChange={(e) => setTaxCode(e.value)}
              defaultValue={taxCode}
              placeholder="Select Tax"
            />
          </div>
          <div className="form-group">
            <label>Billing Details</label>
            <input
              className="custom-input"
              type="text"
              value={billingDetails}
              onChange={(e) => setBillingDetails(e.target.value)}
            />
          </div>
        </Column>
      </FormContainer>
      <AttachmentBanner attachments={attachments} addAttachment={true} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableRowNumberHeaderCell>#</TableRowNumberHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
              <TableHeaderCell>Unit of Measure</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
            </TableRow>
          </TableHead>
          {uomList.length > 0 && (
            <MaterialTableBody
              loading={loading}
              uomList={uomList}
              data={purchaseOrderItems}
              setData={setPurchaseOrderItems}
              columns={[null, null, null]}
            />
          )}
        </Table>
      </TableContainer>
    </PurchaseOrderContainer>
  );
};

CreatePurchaseOrder.propTypes = {
  user: PropTypes.object,
  cart: PropTypes.array,
};

export default CreatePurchaseOrder;
