import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ConfirmDelete from "../../Jobs/ConfirmDelete";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const EstimateContainer = styled.div`
  margin: 0px 50px;
  margin-bottom: 50px;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  .estimate-table {
    th,
    td {
      text-align: left;
      border: 1px solid black;
    }
    tr {
      height: 50px;
    }
  }

  .itemizedTable {
    border: 1px solid black;
    border-bottom-width: 0px;
    th,
    td {
      border: 1px solid black;
      border-bottom-width: 0px;
    }
  }

  .segmentFooter {
    border: 1px solid black;
    border-bottom-width: 0px;
    font-weight: bold;
  }

  .segmentFooterTitle {
    text-align: right;
    padding-right: 20px;
  }

  .groupedFooter {
    border: 1px solid black;
    font-weight: bold;
  }

  .groupedFooterTitle {
    text-align: right;
    padding-right: 20px;
  }

  .nested-table-1st-col-td {
  }
  .nested-table-2nd-col-td {
  }
  .nested-table-3rd-col-td {
  }

  .view-buttons {
    button {
      margin: 0px 5px;
    }
  }

  .search-menu-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .search {
      text-align: left;
      input {
        height: 100%;
        width: 20rem;
        padding-left: 2rem;
      }
    }
    .collapse {
      text-align: right;
    }
  }
`;

const SingleEstimate = ({ cart, setCart }) => {
  const params = useParams();
  let navigate = useNavigate();
  const { id } = params;
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [onToggleConfirm, setOnToggleConfirm] = useState(false);
  const [listFormat, setListFormat] = useState("ITEM");
  const [groupedMaterials, setGroupedMaterials] = useState([]);
  const [costCodedMaterials, setCostCodedMaterials] = useState([]);
  const [expandedRows, setExpandedRows] = useState({ phases: [] });
  const [expandedMaterialRows, setExpandedMaterialRows] = useState([]);
  const [expandedCostCodeRows, setExpandedCostCodeRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/estimate/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setEstimate(json);
        setGroupedMaterials(
          _.chain(json.materials)
            .groupBy("itemIdNumber")
            .map((value) => {
              const quantityEstimated = value.reduce(
                (prev, cur) => prev + cur.quantityEstimated,
                0
              );
              const materialTotalUnitCost = value.reduce(
                (prev, cur) =>
                  prev +
                  cur.quantityEstimated *
                    (cur.estimatedMaterialUnitPricex1M / 1000000),
                0
              );
              return {
                itemIdNumber: value[0].itemIdNumber,
                quantityEstimated,
                quantity: "",
                orderedItems: value
                  .map((item) => [...item.bookedEstimatedMaterial])
                  .flat(),
                estimatedMaterialUnitPricex1M: materialTotalUnitCost,
                unitOfMeasureId: value[0].unitOfMeasureId,
                unitOfMeasure: value[0].unitOfMeasure,
                description: value[0].description,
                items: value.map((element) => ({ ...element, quantity: "" })),
              };
            })
            .value()
            .sort((a, b) =>
              a.description < b.description
                ? -1
                : a.description > b.description
                ? 1
                : 0
            )
        );
        setCostCodedMaterials(
          _.chain(json.materials)
            .groupBy("taskId")
            .map((value) => {
              const materialTotalUnitCost = value.reduce(
                (prev, cur) =>
                  prev +
                  cur.quantityEstimated *
                    (cur.estimatedMaterialUnitPricex1M / 1000000),
                0
              );
              return {
                task: `${value[0]?.task?.taskCode}-${value[0]?.task?.taskName}`,
                taskId: value[0].taskId,
                orderedItems: value
                  .map((item) => [...item.bookedEstimatedMaterial])
                  .flat(),
                estimatedMaterialUnitPricex1M: materialTotalUnitCost,
                items: value,
              };
            })
            .value()
        );
        setLoading(false);
      });
  }, []);

  const onDeleteEstimate = () => {
    const requestOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/estimate/delete/${id}`, requestOptions).then((res) => {
      {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        navigate("/estimates");
      }
    });
  };

  const handleRowClick = (type, id) => {
    setExpandedRows((prevExpandedRows) => {
      const updatedExpandedRows = { ...prevExpandedRows }; // Create a copy

      // Initialize updatedExpandedRows[type] if it's undefined
      if (!updatedExpandedRows[type]) {
        updatedExpandedRows[type] = [];
      }

      // Handle removal efficiently using filter
      if (updatedExpandedRows[type].includes(id)) {
        updatedExpandedRows[type] = updatedExpandedRows[type].filter(
          (itemId) => itemId !== id
        );
      } else {
        // Add the ID if not already present (assuming this is the desired behavior)
        updatedExpandedRows[type] = [...updatedExpandedRows[type], id];
      }

      return updatedExpandedRows;
    });
  };

  const handleMaterialRowClick = (id) => {
    setExpandedMaterialRows((prevExpandedRows) => {
      let updatedExpandedRows = [...prevExpandedRows]; // Create a copy

      // Handle removal efficiently using filter
      if (updatedExpandedRows.includes(id)) {
        updatedExpandedRows = updatedExpandedRows.filter(
          (itemId) => itemId !== id
        );
      } else {
        // Add the ID if not already present (assuming this is the desired behavior)
        updatedExpandedRows = [...updatedExpandedRows, id];
      }

      return updatedExpandedRows;
    });
  };

  const handleCostCodeRowClick = (id) => {
    setExpandedCostCodeRows((prevExpandedRows) => {
      let updatedExpandedRows = [...prevExpandedRows]; // Create a copy

      // Handle removal efficiently using filter
      if (updatedExpandedRows.includes(id)) {
        updatedExpandedRows = updatedExpandedRows.filter(
          (itemId) => itemId !== id
        );
      } else {
        // Add the ID if not already present (assuming this is the desired behavior)
        updatedExpandedRows = [...updatedExpandedRows, id];
      }

      return updatedExpandedRows;
    });
  };

  const handleQuantityChange = (value, itemIdNumber) => {
    setGroupedMaterials((prevState) => {
      const newState = [...prevState];
      newState[
        newState.findIndex((item) => item.itemIdNumber === itemIdNumber)
      ].quantity = value;
      return newState;
    });
  };

  const renderSegment = (segmentType, segment, handleRowClick) => {
    if (!segment) return null;
    const nextSegmentType =
      segmentType === "phases"
        ? "subPhases"
        : segmentType === "subPhases"
        ? "levels"
        : segmentType === "levels"
        ? "subLevels"
        : segmentType === "subLevels"
        ? "areas"
        : "";
    const filteredMaterials = segment.materials.filter((material) =>
      material.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const estimatedCost = filteredMaterials.reduce(
      (prev, cur) =>
        prev +
        cur.quantityEstimated * (cur.estimatedMaterialUnitPricex1M / 1000000),
      0
    );
    const orderedCost = filteredMaterials.reduce(
      (prev, cur) =>
        prev +
        cur.bookedEstimatedMaterial.reduce(
          (prev1, cur1) =>
            prev1 +
            cur1.item.purchaseOrderItems.reduce(
              (prev2, cur2) =>
                prev2 +
                cur1.estimatedMaterialQuantity * cur2.unit * cur2.unitPrice,
              0
            ),
          0
        ),
      0
    );
    const amountRemaining = estimatedCost - orderedCost;
    return (
      filteredMaterials.length > 0 && (
        <div
          key={segment.id}
          style={{ marginLeft: segmentType != "phases" ? "20px" : "0px" }}
        >
          <div
            style={{
              cursor: "pointer",
              outline: "1px solid black",
              margin: "1px",
              textAlign: "left",
              padding: "15px 20px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            }}
            onClick={() => handleRowClick(segmentType, segment.id)}
          >
            <h3 style={{ margin: "0px" }}>{segment.name}</h3>
            <h3 style={{ margin: "0px", textAlign: "right" }}>
              Est: $
              {estimatedCost.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </h3>
            <h3 style={{ margin: "0px", textAlign: "right" }}>
              Ordered: $
              {orderedCost.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </h3>
            <h3
              style={{
                margin: "0px",
                textAlign: "right",
                color: `${amountRemaining >= 0 ? "black" : "red"}`,
              }}
            >
              Remain: $
              {amountRemaining.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </h3>
          </div>
          {expandedRows[segmentType]?.includes(segment.id) &&
            (segment[nextSegmentType].length > 0 &&
            segment[nextSegmentType]
              .map((segmentMtl) => ({
                ...segmentMtl,
                materials: segmentMtl.materials.filter((mtl) =>
                  mtl.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ),
              }))
              .filter((segmentLen) => segmentLen.materials.length > 0).length >
              0 ? (
              segment[nextSegmentType]?.map((child) =>
                renderSegment(nextSegmentType, child, handleRowClick)
              )
            ) : (
              <div style={{ marginLeft: "20px" }}>
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          textAlign: "center",
                          width: "10%",
                        }}
                      >
                        Qty Estimated
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          textAlign: "center",
                          width: "10%",
                        }}
                      >
                        Prev Ordered
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          textAlign: "center",
                          width: "5%",
                        }}
                      >
                        UOM
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          width: "40%",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          width: "10%",
                        }}
                      >
                        Est Matl Cost
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          width: "10%",
                        }}
                      >
                        Ordered Amt
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          borderTopWidth: "0px",
                          width: "15%",
                        }}
                      >
                        Amt Remaining
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredMaterials.map((material) => {
                      const estimatedMatl =
                        (material.estimatedMaterialUnitPricex1M / 1000000) *
                        material.quantityEstimated;
                      const orderedAmount =
                        material.bookedEstimatedMaterial.reduce(
                          (prev, cur) =>
                            prev +
                            cur.item.purchaseOrderItems.reduce(
                              (prev1, cur1) =>
                                prev1 +
                                cur.estimatedMaterialQuantity *
                                  cur1.unit *
                                  cur1.unitPrice,
                              0
                            ),
                          0
                        );
                      const amountRemain = estimatedMatl - orderedAmount;
                      return (
                        <tr key={material.id}>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              borderBottomWidth: "0px",
                              width: "10%",
                            }}
                          >
                            {material.quantityEstimated.toLocaleString("en-US")}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              borderBottomWidth: "0px",
                              width: "10%",
                            }}
                          >
                            {material.bookedEstimatedMaterial.reduce(
                              (prev, cur) =>
                                prev +
                                cur?.item?.purchaseOrderItems.reduce(
                                  (prev1, cur1) => prev1 + cur1.quantityOrdered,
                                  0
                                ),
                              0
                            )}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              borderBottomWidth: "0px",
                              width: "5%",
                            }}
                          >
                            {material.unitOfMeasure.code}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderBottomWidth: "0px",
                              width: "45%",
                            }}
                          >
                            {material.description}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderBottomWidth: "0px",
                              width: "10%",
                            }}
                          >
                            {estimatedMatl.toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              borderBottomWidth: "0px",
                              width: "10%",
                            }}
                          >
                            {orderedAmount.toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td
                            style={{
                              color: `${amountRemain >= 0 ? "black" : "red"}`,
                              border: "1px solid black",
                              borderBottomWidth: "0px",
                              width: "10%",
                            }}
                          >
                            {amountRemain.toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        className="segmentFooter segmentFooterTitle"
                        colSpan={4}
                      >
                        {`${segment.name} Total`}
                      </td>
                      <td className="segmentFooter">
                        {filteredMaterials
                          .reduce(
                            (prev, cur) =>
                              prev +
                              (cur.estimatedMaterialUnitPricex1M / 1000000) *
                                cur.quantityEstimated,
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                      </td>
                      <td className="segmentFooter">
                        {orderedCost.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td className="segmentFooter">
                        {amountRemaining.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            ))}
        </div>
      )
    );
  };

  return !loading ? (
    <EstimateContainer>
      {onToggleConfirm && (
        <ConfirmDelete
          setOnToggleConfirm={setOnToggleConfirm}
          onDeleteBatch={() => onDeleteEstimate()}
        />
      )}
      <div>
        <div>
          <h1>{`${estimate.job.jobNumber} - ${estimate.job.jobName}`}</h1>
          <h3>Estimated Material</h3>
          <div className="view-buttons">
            <Button onClick={() => setListFormat("ITEM")}>View by Item</Button>
            <Button onClick={() => setListFormat("SEGMENT")}>
              View by Segment
            </Button>
            <Button onClick={() => setListFormat("TASK")}>
              View by Cost Code
            </Button>
          </div>
          <br />
          <Button onClick={() => setOnToggleConfirm(true)}>
            Delete Estimate
          </Button>
        </div>
        <div>
          <h5>
            Estimate Total:{" "}
            {(
              estimate.phases.reduce(
                (prev, cur) =>
                  prev +
                  cur.materials.reduce(
                    (prev1, cur1) =>
                      prev1 +
                      ((cur1.estimatedLaborUnitsx1M / 1000000) *
                        cur1.quantityEstimated *
                        Number(estimate.estimatedLaborRate) +
                        (cur1.estimatedMaterialUnitPricex1M / 1000000) *
                          1.07 *
                          cur1.quantityEstimated),
                    0
                  ),
                0
              ) +
              JSON.parse(estimate.quotes).reduce(
                (prev, cur) => prev + Number(cur.materialCost) * 1.07,
                0
              ) +
              JSON.parse(estimate.allowances).reduce(
                (prev, cur) =>
                  prev +
                  (Number(cur.materialCost) * 1.07 + Number(cur.laborCost)),
                0
              ) +
              Number(estimate.estimatedIndirectLabor)
            ).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </h5>
        </div>
        <div className="search-menu-container">
          <div className="search">
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
            />
          </div>
          <div className="collapse">
            <Button
              onClick={() =>
                listFormat === "SEGMENT"
                  ? setExpandedRows([])
                  : listFormat === "ITEM"
                  ? setExpandedMaterialRows([])
                  : setExpandedCostCodeRows([])
              }
            >
              Collapse All
            </Button>
          </div>
        </div>
      </div>
      <br />
      <div>
        {listFormat === "SEGMENT"
          ? estimate?.phases?.length > 0 &&
            estimate.phases.map((phase) =>
              renderSegment("phases", phase, handleRowClick)
            )
          : listFormat === "ITEM"
          ? groupedMaterials?.length > 0 && (
              <div>
                <table>
                  <thead
                    style={{
                      position: "sticky",
                      top: "0",
                      background: "white",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "10%",
                        }}
                      >
                        Qty Est.
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "10%",
                        }}
                      >
                        Prev Ordered
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "10%",
                        }}
                      >
                        Current Order
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          width: "5%",
                        }}
                      >
                        UOM
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                          border: "1px solid black",
                          width: "35%",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "10%",
                        }}
                      >
                        Est Matl Cost
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "10%",
                        }}
                      >
                        Ordered Amt
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "10%",
                        }}
                      >
                        Amt Remaining
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedMaterials
                      .filter((item) =>
                        item.description
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((material) => {
                        const orderedAmount = material.orderedItems.reduce(
                          (prev, cur) =>
                            prev +
                            cur.item.purchaseOrderItems.reduce(
                              (prev1, cur1) =>
                                prev1 +
                                cur.estimatedMaterialQuantity *
                                  cur1.unit *
                                  cur1.unitPrice,
                              0
                            ),
                          0
                        );
                        return (
                          <Fragment key={material.itemIdNumber}>
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleMaterialRowClick(material.itemIdNumber)
                              }
                            >
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {material.quantityEstimated.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {material?.orderedItems
                                  ?.reduce(
                                    (prev, cur) =>
                                      prev + cur?.estimatedMaterialQuantity,
                                    0
                                  )
                                  .toLocaleString("en-us")}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {material.quantity !== "" && (
                                  <>
                                    {`${cart.reduce(
                                      (prev, cur) =>
                                        prev +
                                        (material.items.some(
                                          (matl) => matl.id === cur.id
                                        )
                                          ? Number(cur.quantity)
                                          : 0),
                                      0
                                    )}/`}{" "}
                                  </>
                                )}
                                <input
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    padding: "0px",
                                    border: "0px",
                                    outline: "none",
                                  }}
                                  type="text"
                                  value={material.quantity}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      e.target.value,
                                      material.itemIdNumber
                                    )
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "5%",
                                }}
                              >
                                {material.unitOfMeasure.code}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "35%",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                {material.description}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                $
                                {material.estimatedMaterialUnitPricex1M.toLocaleString(
                                  "en-US",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                $
                                {orderedAmount.toLocaleString("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td
                                style={{
                                  color: `${
                                    material.estimatedMaterialUnitPricex1M -
                                      orderedAmount >=
                                    0
                                      ? "black"
                                      : "red"
                                  }`,
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                $
                                {(
                                  material.estimatedMaterialUnitPricex1M -
                                  orderedAmount
                                ).toLocaleString("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                            {expandedMaterialRows.includes(
                              material.itemIdNumber
                            ) && (
                              <tr>
                                <td colSpan={9} style={{ paddingLeft: "20px" }}>
                                  <table className="itemizedTable">
                                    <thead
                                      style={{
                                        position: "sticky",
                                        top: "44px",
                                        background: "white",
                                      }}
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Location
                                        </th>
                                        <th>Qty Est.</th>
                                        <th>Qty Ordered</th>
                                        <th>Est Mtl Cost</th>
                                        <th>Ordered Cost</th>
                                        <th>Amt Remaining</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {_.sortBy(material.items, (element) => [
                                        element?.phase?.name,
                                        element?.subPhase?.name,
                                        element?.level?.name,
                                        element?.subLevel?.name,
                                        element?.area?.name,
                                      ]).map((item, itemIndex) => {
                                        const itemOrderTotal =
                                          material.items.reduce(
                                            (prev, cur) =>
                                              prev + Number(cur.quantity),
                                            0
                                          );
                                        const estimatedCost =
                                          item.quantityEstimated *
                                          (item.estimatedMaterialUnitPricex1M /
                                            1000000);
                                        const orderedCost =
                                          item.bookedEstimatedMaterial.reduce(
                                            (prev, cur) =>
                                              prev +
                                              cur.item.purchaseOrderItems.reduce(
                                                (prev1, cur1) =>
                                                  prev1 +
                                                  cur.estimatedMaterialQuantity *
                                                    cur1.unit *
                                                    cur1.unitPrice,
                                                0
                                              ),
                                            0
                                          );
                                        return (
                                          <tr
                                            key={`${item.itemIdNumber}-${itemIndex}`}
                                            style={{ cursor: "copy" }}
                                            onClick={() => {
                                              const testAmt =
                                                material.quantity >
                                                itemOrderTotal +
                                                  item.quantityEstimated
                                                  ? item.quantityEstimated
                                                  : material.quantity -
                                                    itemOrderTotal;
                                              if (testAmt > 0) {
                                                setCart((prevState) => [
                                                  ...prevState,
                                                  {
                                                    ...item,
                                                    jobId: estimate.jobId,
                                                    job: estimate.job,
                                                    quantity: testAmt,
                                                  },
                                                ]);
                                                setGroupedMaterials(
                                                  (prevState) => {
                                                    const newState = [
                                                      ...prevState,
                                                    ];
                                                    newState[
                                                      newState.findIndex(
                                                        (item) =>
                                                          item.itemIdNumber ===
                                                          material.itemIdNumber
                                                      )
                                                    ].items[
                                                      itemIndex
                                                    ].quantity = testAmt;
                                                    return newState;
                                                  }
                                                );
                                              }
                                            }}
                                          >
                                            <td
                                              style={{
                                                textAlign: "left",
                                                paddingLeft: "10px",
                                              }}
                                            >{`${
                                              item?.phase?.name
                                                ? `${item.phase.name}`
                                                : ""
                                            }${
                                              item?.subPhase?.name
                                                ? ` > ${item.subPhase.name}`
                                                : ""
                                            }${
                                              item?.level?.name
                                                ? ` > ${item.level.name}`
                                                : ""
                                            }${
                                              item?.subLevel?.name
                                                ? ` > ${item.subLevel.name}`
                                                : ""
                                            }${
                                              item?.area?.name
                                                ? ` > ${item.area.name}`
                                                : ""
                                            }`}</td>
                                            <td>
                                              {item.quantityEstimated.toLocaleString(
                                                "en-US"
                                              )}
                                            </td>
                                            <td>
                                              {item.bookedEstimatedMaterial.reduce(
                                                (prev, cur) =>
                                                  prev +
                                                  cur.estimatedMaterialQuantity,
                                                0
                                              )}
                                            </td>
                                            <td>
                                              {estimatedCost.toLocaleString(
                                                "en-US",
                                                {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                            </td>
                                            <td>
                                              $
                                              {orderedCost.toLocaleString(
                                                "en-US",
                                                {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                color: `${
                                                  estimatedCost - orderedCost >=
                                                  0
                                                    ? "black"
                                                    : "red"
                                                }`,
                                              }}
                                            >
                                              $
                                              {(
                                                estimatedCost - orderedCost
                                              ).toLocaleString("en-US", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        className="groupedFooter groupedFooterTitle"
                        colSpan={5}
                      >
                        Total
                      </td>
                      <td className="groupedFooter">
                        $
                        {groupedMaterials
                          .filter((item) =>
                            item.description
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .reduce(
                            (prev, cur) =>
                              prev + cur.estimatedMaterialUnitPricex1M,
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                      </td>
                      <td className="groupedFooter">
                        $
                        {groupedMaterials
                          .filter((item) =>
                            item.description
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .reduce(
                            (prev, cur) =>
                              prev +
                              cur.orderedItems.reduce(
                                (prev1, cur1) =>
                                  prev1 +
                                  cur1.item.purchaseOrderItems.reduce(
                                    (prev2, cur2) =>
                                      prev2 +
                                      cur1.estimatedMaterialQuantity *
                                        cur2.unit *
                                        cur2.unitPrice,
                                    0
                                  ),
                                0
                              ),
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                      </td>
                      <td className="groupedFooter">
                        $
                        {(
                          groupedMaterials
                            .filter((item) =>
                              item.description
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .reduce(
                              (prev, cur) =>
                                prev + cur.estimatedMaterialUnitPricex1M,
                              0
                            ) -
                          groupedMaterials
                            .filter((item) =>
                              item.description
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            )
                            .reduce(
                              (prev, cur) =>
                                prev +
                                cur.orderedItems.reduce(
                                  (prev1, cur1) =>
                                    prev1 +
                                    cur1.item.purchaseOrderItems.reduce(
                                      (prev2, cur2) =>
                                        prev2 +
                                        cur1.estimatedMaterialQuantity *
                                          cur2.unit *
                                          cur2.unitPrice,
                                      0
                                    ),
                                  0
                                ),
                              0
                            )
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )
          : costCodedMaterials?.length > 0 && (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          textAlign: "left",
                          paddingLeft: "10px",
                          width: "20%",
                        }}
                      >
                        Cost Code
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "20%",
                        }}
                      >
                        Est Matl Cost
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "20%",
                        }}
                      >
                        Ordered Amt
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          width: "20%",
                        }}
                      >
                        Amt Remaining
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {costCodedMaterials
                      .sort((a, b) =>
                        a.task < b.task ? -1 : a.task > b.task ? 1 : 0
                      )
                      .map((material) => {
                        const filteredMaterials = material.items.filter(
                          (item) =>
                            item.description
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        );
                        const estimatedAmount = filteredMaterials.reduce(
                          (prev, cur) =>
                            prev +
                            cur.quantityEstimated *
                              (cur.estimatedMaterialUnitPricex1M / 1000000),
                          0
                        );
                        const orderedAmount = filteredMaterials
                          .map((fi) => ({
                            orderedItems: fi.bookedEstimatedMaterial,
                          }))
                          .reduce(
                            (prev, cur) =>
                              prev +
                              cur.orderedItems.reduce(
                                (prev1, cur1) =>
                                  prev1 +
                                  cur1.item.purchaseOrderItems.reduce(
                                    (prev2, cur2) =>
                                      prev2 +
                                      cur1.estimatedMaterialQuantity *
                                        cur2.unit *
                                        cur2.unitPrice,
                                    0
                                  ),
                                0
                              ),
                            0
                          );
                        return (
                          filteredMaterials.length > 0 && (
                            <Fragment key={material.taskId}>
                              <tr
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleCostCodeRowClick(material.taskId)
                                }
                              >
                                <td
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                    borderBottomWidth: "0px",
                                    width: "10%",
                                  }}
                                >
                                  {material.task}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    borderBottomWidth: "0px",
                                    width: "10%",
                                  }}
                                >
                                  $
                                  {estimatedAmount.toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid black",
                                    borderBottomWidth: "0px",
                                    width: "10%",
                                  }}
                                >
                                  $
                                  {orderedAmount.toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td
                                  style={{
                                    color: `${
                                      estimatedAmount - orderedAmount >= 0
                                        ? "black"
                                        : "red"
                                    }`,
                                    border: "1px solid black",
                                    borderBottomWidth: "0px",
                                    width: "20%",
                                  }}
                                >
                                  $
                                  {(
                                    estimatedAmount - orderedAmount
                                  ).toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              {expandedCostCodeRows.includes(
                                material.taskId
                              ) && (
                                <tr>
                                  <td
                                    colSpan={8}
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    <table className="itemizedTable">
                                      <thead>
                                        <tr>
                                          <th>Qty Est.</th>
                                          <th>Prev Ordered</th>
                                          <th>Description</th>
                                          <th>Est Mtl Cost</th>
                                          <th>Ordered Cost</th>
                                          <th>Amt Remaining</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filteredMaterials.map(
                                          (item, index) => {
                                            const estimatedCost =
                                              item.quantityEstimated *
                                              (item.estimatedMaterialUnitPricex1M /
                                                1000000);
                                            const orderedCost =
                                              item.bookedEstimatedMaterial.reduce(
                                                (prev, cur) =>
                                                  prev +
                                                  cur.item.purchaseOrderItems.reduce(
                                                    (prev1, cur1) =>
                                                      prev1 +
                                                      cur.estimatedMaterialQuantity *
                                                        cur1.unit *
                                                        cur1.unitPrice,
                                                    0
                                                  ),
                                                0
                                              );

                                            return (
                                              <tr
                                                key={`${item.taskId}-${index}`}
                                              >
                                                <td>
                                                  {item.quantityEstimated.toLocaleString(
                                                    "en-US"
                                                  )}
                                                </td>
                                                <td>0</td>
                                                <td>{item.description}</td>
                                                <td>
                                                  {estimatedCost.toLocaleString(
                                                    "en-US",
                                                    {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>

                                                <td>
                                                  $
                                                  {orderedCost.toLocaleString(
                                                    "en-US",
                                                    {
                                                      maximumFractionDigits: 2,
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    color: `${
                                                      estimatedCost -
                                                        orderedCost >=
                                                      0
                                                        ? "black"
                                                        : "red"
                                                    }`,
                                                  }}
                                                >
                                                  $
                                                  {(
                                                    estimatedCost - orderedCost
                                                  ).toLocaleString("en-US", {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                  })}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          )
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="groupedFooter groupedFooterTitle">
                        Total
                      </td>
                      <td className="groupedFooter">
                        $
                        {costCodedMaterials
                          .reduce(
                            (prev, cur) =>
                              prev +
                              cur.items
                                .filter((item) =>
                                  item.description
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                )
                                .reduce((prev1, cur1) => {
                                  return (
                                    prev1 +
                                    cur1.quantityEstimated *
                                      (cur1.estimatedMaterialUnitPricex1M /
                                        1000000)
                                  );
                                }, 0),
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                      </td>
                      <td className="groupedFooter">
                        $
                        {costCodedMaterials
                          .reduce(
                            (prev, cur) =>
                              prev +
                              cur.orderedItems.reduce(
                                (prev1, cur1) =>
                                  prev1 +
                                  (cur1.item.description
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                    ? cur1.item.purchaseOrderItems.reduce(
                                        (prev2, cur2) =>
                                          prev2 +
                                          cur1.estimatedMaterialQuantity *
                                            cur2.unit *
                                            cur2.unitPrice,
                                        0
                                      )
                                    : 0),
                                0
                              ),
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                      </td>
                      <td className="groupedFooter">
                        $
                        {(
                          costCodedMaterials.reduce(
                            (prev, cur) =>
                              prev +
                              cur.items
                                .filter((item) =>
                                  item.description
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                )
                                .reduce((prev1, cur1) => {
                                  return (
                                    prev1 +
                                    cur1.quantityEstimated *
                                      (cur1.estimatedMaterialUnitPricex1M /
                                        1000000)
                                  );
                                }, 0),
                            0
                          ) -
                          costCodedMaterials.reduce(
                            (prev, cur) =>
                              prev +
                              cur.orderedItems.reduce(
                                (prev1, cur1) =>
                                  prev1 +
                                  (cur1.item.description
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                    ? cur1.item.purchaseOrderItems.reduce(
                                        (prev2, cur2) =>
                                          prev2 +
                                          cur1.estimatedMaterialQuantity *
                                            cur2.unit *
                                            cur2.unitPrice,
                                        0
                                      )
                                    : 0),
                                0
                              ),
                            0
                          )
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
      </div>
    </EstimateContainer>
  ) : (
    <h5>Loading...</h5>
  );
};

SingleEstimate.propTypes = {
  cart: PropTypes.array,
  setCart: PropTypes.func,
};

export default SingleEstimate;
