import styled from "styled-components";

const NavContainer = styled.div`
  @media print {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  text-align: left;
  .logo-link {
    width: 100%;
  }
  .champion-logo {
    width: 28vh;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 10px 0;
    .champion-logo {
      width: 100%;
    }
  }
  @media screen and (max-width: 350px) {
    .champion-logo {
      display: none;
    }
  }
`;

const Links = styled.div`
  @media print {
    display: none;
  }
  span {
    cursor: pointer;
  }
  .salary {
    display: flex;
    flex-flow: row wrap;
    ul {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0px 10px;
      margin-left: 20px;
      li {
        margin: 10px 0px;
      }
      a {
        color: white;
        padding: 0px 10px;
        text-decoration: none;
      }
      .active {
        background-color: black;
      }
    }
    div {
      margin: 10px 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
      a {
        width: 100%;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0px 10px;
      }
      .text {
        margin-left: 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        padding: 0px 10px;
      }
      .arrow {
        color: white;
      }
      .active {
        background-color: black;
      }
    }
  }
  .hourly {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
`;

export { NavContainer, Links };
