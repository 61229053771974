import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const EmployeeField = (props) => {
  const { employees, handleUserChange, userId, disabled } = props;
  const options = employees.map((employee) => ({
    value: employee.id,
    label:
      employee.firstName +
      (employee.middleName
        ? " " + employee.middleName + " " + employee.lastName
        : " " + employee.lastName),
  }));
  return (
    <Select
      isDisabled={disabled}
      options={options}
      isMulti
      isSearchable
      onChange={handleUserChange}
      placeholder={"Select Employees..."}
      value={userId}
    />
  );
};

EmployeeField.propTypes = {
  employees: PropTypes.array,
  userId: PropTypes.array,
  handleUserChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default EmployeeField;
