import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";
import styled from "styled-components";
import dayjs from "dayjs";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const UploadTools = () => {
  const [tools, setTools] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [error, setError] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/tools`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTools(json));
    fetch(`${API_URL}/employees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(
          json.map((employee) => ({
            id: employee.id,
            name: `${employee.firstName} ${employee.lastName}`,
          }))
        )
      );
    fetch(`${API_URL}/categories`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCategories(json));
    fetch(`${API_URL}/manufacturers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setManufacturers(json));
    fetch(`${API_URL}/locations`, requestOptions)
      .then((res) => res.json())
      .then((json) => setLocations(json));
  }, []);

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(d);
        });

        const data = valuesArray.map((value) => ({
          ...value,
          scanCode: value.scanCode,
          name: value.name,
          manufacturerId: value.manufacturerId
            ? manufacturers[
                manufacturers.findIndex(
                  (manufacturer) =>
                    value.manufacturerId.toLowerCase() ===
                    manufacturer.name.toLowerCase()
                )
              ].id
            : "",
          model: value.model,
          serialNumber: value.serialNumber,
          description: value.description,
          purchaseDate: value.purchaseDate
            ? new Date(value.purchaseDate).toISOString()
            : null,
          operational: value.operational === "Operational" ? true : false,
          ownershipType: value.ownershipType,
          defaultLocationId: value.defaultLocationId
            ? locations[
                locations.findIndex(
                  (location) =>
                    value.defaultLocationId.toLowerCase() ===
                    location.name.toLowerCase()
                )
              ].id
            : "",
          currentLocationId: value.currentLocationId
            ? locations[
                locations.findIndex(
                  (location) =>
                    value.currentLocationId.toLowerCase() ===
                    location.name.toLowerCase()
                )
              ].id
            : "",
          responsibleEmployeeId: value.responsibleEmployeeId
            ? employees[
                employees.findIndex(
                  (employee) =>
                    value.responsibleEmployeeId.toLowerCase() ===
                    employee.name.toLowerCase()
                )
              ].id
            : "",
          toolCategoryId: value.toolCategoryId
            ? categories[
                categories.findIndex(
                  (category) =>
                    value.toolCategoryId.toLowerCase() ===
                    category.name.toLowerCase()
                )
              ].id
            : "",
        }));
        const filterTools = (arr1, arr2) => {
          let resNew = [];
          let resUpdate = [];
          resNew = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.scanCode === el.scanCode;
            });
          });
          resUpdate = arr1.filter((el) => {
            const foundTool = arr2.find(
              (element) => element.scanCode === el.scanCode
            );
            if (foundTool) {
              const criteria = [
                foundTool.name === el.name,
                foundTool.model === undefined || foundTool.model === null
                  ? el.model === ""
                  : foundTool.model === el.model,
                foundTool.description === undefined ||
                foundTool.description === null
                  ? el.description === ""
                  : foundTool.description === el.description,
                foundTool.serialNumber === undefined ||
                foundTool.serialNumber === null
                  ? el.serialNumber === ""
                  : foundTool.serialNumber === el.serialNumber,
                foundTool.purchaseDate === undefined ||
                foundTool.purchaseDate === null
                  ? el.purchaseDate === ""
                  : foundTool.purchaseDate === el.purchaseDate,
                foundTool.operational === el.operational,
                foundTool.manufacturerId === el.manufacturerId,
                foundTool.defaultLocationId === el.defaultLocationId,
                foundTool.currentLocationId === el.currentLocationId,
                foundTool.responsibleEmployeeId === el.responsibleEmployeeId,
                foundTool.toolCategoryId === el.toolCategoryId,
              ];
              return !criteria.every((e) => e === true);
            } else return false;
          });
          return [resNew, resUpdate];
        };

        const newTools = filterTools(data, tools);
        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(newTools[0]);
        const toolUpdates = newTools[1];
        const toolUpdatesScanCode =
          toolUpdates.length > 0
            ? toolUpdates.map((tool) => ({
                ...tool,
                scanCode:
                  tools[
                    tools.findIndex((element) => {
                      return element.scanCode === tool.scanCode;
                    })
                  ].scanCode,
              }))
            : [];
        setUpdates(toolUpdatesScanCode);
      },
    });
  };
  const handleSubmit = () => {
    try {
      if (values.length > 0) {
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        };
        fetch(`${API_URL}/upload/tools`, requestOptions).then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          return response.json();
        });
      }
      if (updates.length > 0) {
        const requestOptions2 = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(),
        };
        fetch(`${API_URL}/update/tools`, requestOptions2).then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          return response.json();
        });
      }
    } catch (error) {
      setError(error.message);
    }
    setDoneUploading(true);
  };

  useEffect(() => {
    if (doneUploading) {
      navigate("/tools");
    }
  }, [doneUploading]);

  return (
    <div>
      <h1>Upload Tools</h1>
      <ButtonDiv>
        <div className="button-wrap">
          <label className="button" htmlFor="upload">
            Choose File
          </label>
          <input
            type={"file"}
            name="file"
            accept=".csv"
            onChange={(e) => handleFile(e)}
            className="upload"
            id="upload"
          />
        </div>
      </ButtonDiv>
      <div style={{ height: "25px", margin: "20px" }}>
        {error && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <div>
        {values.length > 0 && (
          <Button onClick={handleSubmit}>
            {values.length > 0 && "Add"}
            {values.length > 0 && updates.length > 0 && " and "}
            {updates.length > 0 && "Update"} Tool
            {values.length > 1 && "s"}
          </Button>
        )}
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {values && (
          <table>
            <thead>
              <tr>
                {(values.length > 1 || updates.length > 1) && <th>Type</th>}
                {tableRows.map((rows, index) => {
                  return <th key={index}>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {updates.length > 0 &&
                updates.map((update) => (
                  <tr key={update.scanCode}>
                    <td>Update</td>
                    <td>{update.scanCode}</td>
                    <td>{update.name}</td>
                    <td>
                      {update.manufacturerId &&
                        manufacturers[
                          manufacturers.findIndex(
                            (manufacturer) =>
                              manufacturer.id === update.manufacturerId
                          )
                        ].name}
                    </td>
                    <td>{update.model}</td>
                    <td>{update.serialNumber}</td>
                    <td>{update.description}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {update.operational.toString()}
                    </td>
                    <td>{dayjs(update.purchaseDate).format("MM/DD/YYYY")}</td>
                    <td>{update.ownershipType}</td>
                    <td>
                      {update.defaultLocationId &&
                        locations[
                          locations.findIndex(
                            (location) =>
                              location.id === update.defaultLocationId
                          )
                        ].name}
                    </td>
                    <td>
                      {update.currentLocationId &&
                        locations[
                          locations.findIndex(
                            (location) =>
                              location.id === update.currentLocationId
                          )
                        ].name}
                    </td>
                    <td>
                      {update.responsibleEmployeeId &&
                        employees[
                          employees.findIndex(
                            (employee) =>
                              employee.id === update.responsibleEmployeeId
                          )
                        ].firstName}
                    </td>
                    <td>
                      {update.toolCategoryId &&
                        categories[
                          categories.findIndex(
                            (category) => category.id === update.toolCategoryId
                          )
                        ].firstName}
                    </td>
                  </tr>
                ))}
              {values.length > 0 &&
                values.map((value) => (
                  <tr key={value.scanCode}>
                    <td>New</td>
                    <td>{value.scanCode}</td>
                    <td>{value.name}</td>
                    <td>
                      {value.manufacturerId &&
                        manufacturers[
                          manufacturers.findIndex(
                            (manufacturer) =>
                              manufacturer.id === value.manufacturerId
                          )
                        ].name}
                    </td>
                    <td>{value.model}</td>
                    <td>{value.serialNumber}</td>
                    <td>{value.description}</td>
                    <td>{dayjs(value.purchaseDate).format("MM/DD/YYYY")}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {value.operational.toString()}
                    </td>
                    <td>{value.ownershipType}</td>
                    <td>
                      {value.defaultLocationId &&
                        locations[
                          locations.findIndex(
                            (location) =>
                              location.id === value.defaultLocationId
                          )
                        ].name}
                    </td>
                    <td>
                      {value.currentLocationId &&
                        locations[
                          locations.findIndex(
                            (location) =>
                              location.id === value.currentLocationId
                          )
                        ].name}
                    </td>
                    <td>
                      {value.responsibleEmployeeId &&
                        employees[
                          employees.findIndex(
                            (employee) =>
                              employee.id === value.responsibleEmployeeId
                          )
                        ].name}
                    </td>
                    <td>
                      {value.toolCategoryId &&
                        categories[
                          categories.findIndex(
                            (category) => category.id === value.toolCategoryId
                          )
                        ].name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadTools;
