import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../styles/Style";

const MyFilterComponent = ({ userId, filters, setFilters, itemName }) => {
  const handleMyFilter = (value) => {
    value
      ? setFilters((prevState) => {
          return { ...prevState, userId: userId };
        })
      : setFilters((prevState) => {
          delete prevState.userId;
          return { ...prevState };
        });
  };
  return (
    <div>
      <Button
        style={{
          background: filters.userId === userId ? "blue" : "lightgray",
          color: filters.userId === userId ? "white" : "black",
        }}
        onClick={() => handleMyFilter(true)}
      >
        My {itemName}
      </Button>
      <Button
        style={{
          background: !(filters.userId === userId) ? "blue" : "lightgray",
          color: !(filters.userId === userId) ? "white" : "black",
        }}
        onClick={() => handleMyFilter(false)}
      >
        All {itemName}
      </Button>
    </div>
  );
};

MyFilterComponent.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  userId: PropTypes.string,
  itemName: PropTypes.string,
};

export default MyFilterComponent;
