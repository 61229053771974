import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import Pagination from "../../Search/Pagination";
import ConfirmExport from "./ConfirmExport";
import { DataTable } from "../../../styles/DataTable";
import { API_URL } from "../../../env";

const ExportPO = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [onToggleExport, setOnToggleExport] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [csvItem, setCsvItem] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => setVendors(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/export/purchaseorders/status/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setPurchaseOrders(json.purchaseOrders);
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/export/purchaseorders?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Job",
      field: "jobId",
      options: jobs.map((job) => ({
        label: `${job.jobNumber}-${job.jobName}`,
        value: job.id,
      })),
    },
    {
      label: "Vendor",
      field: "vendorId",
      options: vendors.map((vendor) => ({
        label: vendor.vendorName,
        value: vendor.id,
      })),
    },
    {
      label: "Status",
      field: "orderState",
      options: [
        { label: "Draft", value: "DRAFT" },
        { label: "Active", value: "ACTIVE" },
        { label: "Complete", value: "COMPLETE" },
        { label: "Canceled", value: "CANCELLED" },
        { label: "Bid Active", value: "BID_ACTIVE" },
        { label: "Bid Cancelled", value: "BID_CANCELLED" },
        { label: "Finalizing", value: "FINALIZING" },
      ],
    },
    // Add more filter categories as needed
  ];

  // useEffect(() => {
  //   const requestOptions = {
  //     method: "GET",
  //     withCredentials: true,
  //     credentials: "include",
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   fetch(`${API_URL}/export/purchaseorders/status`, requestOptions)
  //     .then((res) => res.json())
  //     .then((json) => setPurchaseOrders(json));
  // }, []);

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const onCheckAll = (e) => {
    if (e.target.checked) {
      setSelected(purchaseOrders.map((purchaseOrder) => purchaseOrder.id));
      setSelectAll(true);
    } else {
      setSelected([]);
      setSelectAll(false);
    }
  };

  const onCheckBoxChange = (e, id) => {
    if (e.target.checked) {
      setSelected((prevState) => {
        prevState.push(id);
        return [...prevState];
      });
    } else {
      const selectedIndex = selected.findIndex((po) => id === po);
      setSelected((prevState) => {
        prevState.splice(selectedIndex, 1);
        return [...prevState];
      });
    }
  };

  const onExportPOHeader = () => {
    let updatePO = [];
    const exportDate = new Date().toISOString();
    const header = purchaseOrders
      .filter((purchaseOrder) => selected.includes(purchaseOrder.id))
      .map((purchaseOrder) => {
        const amount = purchaseOrder.items
          .reduce((prev, cur) => prev + cur.extPrice, 0)
          .toFixed(2);
        !purchaseOrder.exported && updatePO.push(purchaseOrder.id);
        return [
          purchaseOrder.purchaseOrderNumber,
          "P",
          purchaseOrder.vendor.vendorNumber,
          dayjs(purchaseOrder.createdAt).format("M/D/YYYY"),
          dayjs(purchaseOrder.needByDate).format("M/D/YYYY"),
          purchaseOrder.job.jobNumber,
          purchaseOrder.taxCode,
          amount,
          amount,
          purchaseOrder.generalLedgerCode,
          purchaseOrder.job.jobAddress,
          purchaseOrder.job.jobAddress2,
          purchaseOrder.job.jobCity,
          purchaseOrder.job.jobState,
          purchaseOrder.job.jobZip,
          purchaseOrder.fieldContactName,
          "O",
          "J",
          purchaseOrder.description,
        ];
      });
    setCsvHeader(header);
    setPurchaseOrders((prevState) => {
      const updatedState = prevState.map((purchaseOrder) => {
        if (updatePO.includes(purchaseOrder.id)) {
          purchaseOrder.exportDate = exportDate;
          return purchaseOrder;
        } else {
          return purchaseOrder;
        }
      });
      return [...updatedState];
    });
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ updatePO, exportDate }),
    };
    fetch(
      `${API_URL}/export/purchaseorders/status/update`,
      requestOptions
    ).then((res) => res.json());
  };
  const onExportPOItem = () => {
    const items = purchaseOrders
      .filter((purchaseOrder) => selected.includes(purchaseOrder.id))
      .map((purchaseOrder) => {
        const amount = purchaseOrder.items
          .reduce((prev, cur) => prev + cur.extPrice, 0)
          .toFixed(2);
        return [
          purchaseOrder.purchaseOrderNumber,
          purchaseOrder.job.jobNumber,
          purchaseOrder?.Task?.taskCode ? purchaseOrder.Task.taskCode : "",
          amount,
          amount,
        ];
      });
    setCsvItem(items);
  };

  return (
    <div>
      {onToggleExport && (
        <ConfirmExport
          setOnToggleExport={setOnToggleExport}
          csvHeader={csvHeader}
          csvItem={csvItem}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Export POs"}
        buttonPath={""}
        pageTitle={"Export Purchase Orders"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
        alternativeButton={true}
        alternativeButtonFunction={() => {
          setOnToggleExport(true);
          onExportPOHeader();
          onExportPOItem();
        }}
      />
      <DataTable>
        <tbody>
          <tr>
            <td>
              <table className="tableTimeHead">
                <thead>
                  <tr className="cell">
                    <th>
                      <input
                        type="checkbox"
                        style={{ transform: "scale(1.33)" }}
                        checked={selectAll}
                        onChange={(e) => {
                          onCheckAll(e);
                        }}
                      />
                    </th>
                    <th style={{ width: "150px" }}>PO#</th>
                    <th
                      onClick={() => {
                        handleSort("job.jobNumber");
                      }}
                    >
                      Job#
                    </th>
                    <th
                      style={{ width: "150px" }}
                      onClick={() => {
                        handleSort("orderState");
                      }}
                    >
                      Status
                    </th>
                    <th
                      onClick={() => {
                        handleSort("vendor.vendorName");
                      }}
                    >
                      Vendor
                    </th>
                    <th
                      onClick={() => {
                        handleSort("exportDate");
                      }}
                    >
                      Exported
                    </th>
                    <th
                      onClick={() => {
                        handleSort("user.firstName");
                      }}
                    >
                      Ordered By
                    </th>
                    <th
                      style={{ width: "150px" }}
                      onClick={() => {
                        handleSort("createdAt");
                      }}
                    >
                      Ordered On
                    </th>
                  </tr>
                </thead>
              </table>
            </td>
          </tr>
          {!loading && purchaseOrders?.length > 0 && (
            <tr>
              <td>
                <div className="tableBodyContainer">
                  <table className="tableBody">
                    <tbody>
                      {purchaseOrders.map((purchaseOrder) => (
                        <tr
                          key={purchaseOrder.id}
                          id={purchaseOrder.id}
                          className={
                            selected.includes(purchaseOrder.id)
                              ? "highlighted"
                              : undefined
                          }
                        >
                          <td>
                            <input
                              type="checkbox"
                              className="check-box"
                              checked={selected.includes(purchaseOrder.id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                onCheckBoxChange(e, purchaseOrder.id);
                              }}
                            />
                          </td>
                          <td style={{ width: "150px" }}>
                            {purchaseOrder.purchaseOrderNumber}
                          </td>
                          <td>{purchaseOrder.job.jobNumber}</td>
                          <td style={{ width: "150px" }}>
                            {purchaseOrder.orderState}
                          </td>
                          <td>{purchaseOrder.vendor.vendorName}</td>
                          <td>
                            {purchaseOrder.exportDate
                              ? dayjs(purchaseOrder.exportDate).format(
                                  "M/D/YY h:mm A"
                                )
                              : ""}
                          </td>
                          <td>{`${purchaseOrder.user.firstName} ${purchaseOrder.user.lastName}`}</td>
                          <td style={{ width: "150px" }}>
                            {dayjs(purchaseOrder.createdAt).format(
                              "M/D/YY h:mm A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </DataTable>
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

export default ExportPO;
