import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import QuotePricingReview from "../Functions/QuotePricingReview";
import UploadQuote from "../Functions/UploadQuote";
import AddVendorModal from "../Functions/AddVendorModal";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const RFQContainer = styled.div`
  text-align: left;
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
    }
    .submit-button {
      display: flex;
      justify-content: right;
      height: 50px;
    }
    .order-requisitions {
      button {
        margin-right: 5px;
        margin-bottom: 10px;
      }
    }
  }
  .reqDetails {
    display: grid;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
        h3 {
          margin: 3px 0px;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    table {
      width: 100%;
    }
  }
`;

const SingleRFQ = () => {
  const params = useParams();
  const { id } = params;
  const [requestForQuote, setRequestForQuote] = useState(null);
  const [onToggleUploadQuote, setOnToggleUploadQuote] = useState(false);
  const [pricing, setPricing] = useState(null);
  const [allocatedItems, setAllocatedItems] = useState(null);
  const [quoteIndex, setQuoteIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [pricingReview, setPricingReview] = useState(false);
  const [uploadQuote, setUploadQuote] = useState("");
  const [showAddVendor, setShowAddVendor] = useState(false);
  const [lowestOrder, setLowestOrder] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    let items = [];
    fetch(`${API_URL}/rfq/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        items = json.items;
        setRequestForQuote({ ...json, quotes: [] });
      })
      .then(() => {
        fetch(`${API_URL}/rfq/${id}/quotes`, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            const parsedJSON = json.map((quote) => ({
              ...quote,
              pricing: quote.pricing ? JSON.parse(quote.pricing) : null,
            }));
            setRequestForQuote((prevState) => ({
              ...prevState,
              quotes: parsedJSON,
            }));
            if (parsedJSON.length > 0) {
              const calculatedLowestOrder = findLowestOrderTotal(
                parsedJSON,
                items.length
              );
              setLowestOrder(calculatedLowestOrder);
            }
            if (parsedJSON.length > 0 && items.length > 0) {
              setAllocatedItems(() => {
                let blankArray = [];
                for (let v = 0; v < parsedJSON.length; v++) {
                  if (parsedJSON[v].pricing !== null) {
                    blankArray.push({
                      vendorId: parsedJSON[v].vendor.id,
                      items: [],
                    });
                    for (let i = 0; i < items.length; i++) {
                      const unit =
                        parsedJSON[v].pricing[i]["Unit"].toLowerCase();
                      blankArray[v].items.push({
                        ...items[i],
                        allocated: 0,
                        unitPrice: Number(
                          parsedJSON[v].pricing[i]["UnitPrice"]
                        ),
                        unit: unit === "e" ? 1 : unit === "c" ? 100 : 1000,
                        extPrice: Number(parsedJSON[v].pricing[i]["ExtPrice"]),
                      });
                    }
                  } else {
                    blankArray.push({
                      vendorId: parsedJSON[v].vendor.id,
                      items: [],
                    });
                  }
                }
                return blankArray;
              });
            }
            setLoading(false);
          });
      });
  }, []);

  const zeroAllocation = () => {
    let blankArray = [];
    const quotes = requestForQuote.quotes;
    const items = requestForQuote.items;
    for (let v = 0; v < quotes.length; v++) {
      if (quotes[v].pricing !== null) {
        blankArray.push({
          vendorId: quotes[v].vendor.id,
          items: [],
        });
        for (let i = 0; i < items.length; i++) {
          const unit = quotes[v].pricing[i]["Unit"].toLowerCase();
          blankArray[v].items.push({
            ...items[i],
            allocated: 0,
            unitPrice: Number(quotes[v].pricing[i]["UnitPrice"]),
            unit: unit === "e" ? 1 : unit === "c" ? 100 : 1000,
            extPrice: Number(quotes[v].pricing[i]["ExtPrice"]),
          });
        }
      } else {
        blankArray.push({
          vendorId: quotes[v].vendor.id,
          items: [],
        });
      }
    }
    setAllocatedItems(blankArray);
  };

  const handleAddQuote = (id, total, pdf) => {
    if (pdf) {
      let file = pdf;
      let fileName = pdf.name;
      // Split the filename to get the name and type
      let fileParts = fileName.split(".");
      let contentType = fileParts[1];
      const reader = new FileReader();
      reader.onloadend = () => {
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fileName,
            contentType,
            total,
          }),
        };
        fetch(`${API_URL}/rfq/upload/quote/${id}`, requestOptions)
          .then((res) => res.json())
          .then((res) => {
            setRequestForQuote((prevState) => {
              const index = prevState.quotes.findIndex(
                (quote) => quote.id === id
              );
              prevState.quotes[index] = {
                ...prevState.quotes[index],
                attachments: {
                  url: res.url,
                },
              };
              return prevState;
            });
            const requestOptions = {
              method: "PUT",
              headers: { "Content-Type": "application/pdf" },
              body: file,
            };
            fetch(res.url, requestOptions).catch((error) =>
              alert("ERROR" + JSON.stringify(error))
            );
          })
          .catch((error) => alert(JSON.stringify(error)));
      };
      reader.readAsDataURL(pdf);
    }
  };

  const handleAddVendor = async (vendorId) => {
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        rfqId: id,
        vendorId,
      }),
    };
    try {
      fetch(`${API_URL}/rfq/add-vendor-to-rfq`, requestOptions)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(res.status);
          }
          return res.json();
        })
        .then((json) =>
          setRequestForQuote((prevState) => ({
            ...prevState,
            quotes: [...prevState.quotes, { ...json, pricing: null }],
          }))
        );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPricing = async (index, url) => {
    try {
      setOcrLoading(true);
      const form_data = {
        urls: url,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form_data),
      };
      await fetch(`${API_URL}/getpricing`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setPricing(json);
          setQuoteIndex(index);
          setOcrLoading(false);
          setPricingReview(true);
        });
    } catch (error) {
      console.error("Error getting pricing from the PDF:", error);
    }
  };

  const handleConfirmPricing = async (confirmedPricing) => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ pricing: confirmedPricing }),
    };
    setRequestForQuote((prevState) => {
      prevState.quotes[quoteIndex].pricing = confirmedPricing;
      return [...prevState];
    });
    await fetch(
      `${API_URL}/update/quote/${requestForQuote.quotes[quoteIndex].id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(() => {
        setPricing(null);
        setQuoteIndex(null);
        setOcrLoading(false);
        setPricingReview(false);
      });
  };

  const handleSendRFQ = () => {
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
      }),
    };
    fetch(`${API_URL}/rfq/send`, requestOptions)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((error) => console.log(JSON.stringify(error)));
  };

  const handleSendPO = () => {
    const filteredItems = allocatedItems
      .map((vendor) => ({
        vendorId: vendor.vendorId,
        items: vendor.items
          .map((item) => ({
            itemId: item.item.id,
            quantityOrdered: item.allocated,
            unitPrice: item.unitPrice,
            unit: item.unit,
            extPrice: item.extPrice,
          }))
          .filter((item) => item.quantityOrdered > 0),
      }))
      .filter((vendor) => vendor.items.length > 0);
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        rfqId: requestForQuote.id,
        jobId: requestForQuote.jobId,
        details: {
          RequestForQuote: { connect: { id: requestForQuote.id } },
          user: { connect: { id: requestForQuote.userId } },
          job: { connect: { id: requestForQuote.jobId } },
          deliveryAddress: requestForQuote.deliveryAddress,
          fieldContactName: "Brian Taylor",
          fieldContactNumber: "5614597016",
          generalLedger: {
            connect: { generalLedgerCode: requestForQuote.generalLedgerCode },
          },
          Task: { connect: { id: requestForQuote.taskId } },
          tax: { connect: { taxCode: requestForQuote.taxCode } },
          orderMode: "REQUEST_FOR_QUOTE",
          orderState: "ACTIVE",
          needByDate: requestForQuote.needByDate,
          needByWindow: requestForQuote.needByWindow,
          customWindow: requestForQuote.customWindow,
          deliveryInstructions: requestForQuote.deliveryInstructions,
          additionalRecipientEmails: requestForQuote.additionalRecipientEmails,
          billingDetails: requestForQuote.billingDetails,
        },
        items: filteredItems,
      }),
    };
    fetch(`${API_URL}/create/order-from-rfq`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(() =>
        setRequestForQuote((prevState) => ({
          ...prevState,
          orderState: "COMPLETE",
        }))
      )
      .catch((error) => console.log(JSON.stringify(error)));
  };

  const findLowestOrderTotal = (quotes, itemsLength) => {
    // Initialize variables to hold the lowest order total and the items contributing to it
    const quotePricing = quotes.map((quote) => quote.pricing);

    let total = 0;
    let lowestVendorIndex = [];

    // Iterate over each item
    for (let i = 0; i < itemsLength; i++) {
      let prices = [];
      // Iterate over each vendor quote
      for (let q = 0; q < quotePricing.length; q++) {
        // Extract price for each item from each vendor
        if (quotePricing[q] !== null) {
          prices.push(Number(quotePricing[q][i]["ExtPrice"]));
        } else {
          prices.push(null);
        }
      }
      // find min value and index ignoring null values
      const [minVal, minIndex] = prices.reduce(
        (acc, val, index) => {
          if (val !== null && (acc[0] === null || val < acc[0])) {
            return [val, index];
          } else {
            return acc;
          }
        },
        [null, -1]
      );
      total += minVal;
      lowestVendorIndex.push(minIndex);
    }
    return { total, lowestVendorIndex };
  };

  // console.log(requestForQuote);

  const handleAlloctionChange = (vendorId, itemId, e) => {
    const { value } = e.target;
    setAllocatedItems((prevState) => {
      const newState = [...prevState];
      newState[vendorId].items[itemId].allocated = value;
      return newState;
    });
  };

  const allocateLowestPriceOrder = () => {
    const { lowestVendorIndex } = lowestOrder;
    for (let i = 0; i < lowestVendorIndex.length; i++) {
      setAllocatedItems((prevState) => {
        const newState = [...prevState];
        newState[lowestVendorIndex[i]].items[i].allocated = parseInt(
          Object.values(requestForQuote.quotes[lowestVendorIndex[i]].pricing)[
            i
          ]["Qty"]
        );
        return newState;
      });
    }
  };
  return (
    requestForQuote !== null && (
      <RFQContainer>
        {ocrLoading && (
          <div>
            <h4>Loading...</h4>
          </div>
        )}
        {showAddVendor && (
          <AddVendorModal
            type={"RFQ"}
            prevVendors={requestForQuote.quotes.map((quote) => quote.vendor.id)}
            setShowAddVendor={setShowAddVendor}
            handleAddVendor={handleAddVendor}
          />
        )}
        {pricingReview && (
          <QuotePricingReview
            pricing={pricing}
            quoteIndex={quoteIndex}
            items={requestForQuote.items}
            setPricingReview={setPricingReview}
            handleConfirmPricing={handleConfirmPricing}
          />
        )}
        {onToggleUploadQuote && (
          <UploadQuote
            setOnToggleUploadQuote={setOnToggleUploadQuote}
            handleAddQuote={handleAddQuote}
            uploadQuote={uploadQuote}
          />
        )}
        <div className="order-header">
          <div>
            <h1 className="po-title">
              RFQ# {requestForQuote.requestForQuoteNumber}
            </h1>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              <span>Order Status: </span>
              <span
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  color: requestForQuote?.orderState.includes("ACTIVE")
                    ? "green"
                    : "black",
                }}
              >
                {`${requestForQuote.orderState
                  .replace("_", " ")
                  .toLowerCase()} ${
                  requestForQuote?.orderState === "BID_ACTIVE"
                    ? Math.round(
                        dayjs(requestForQuote.bidByDate).diff(dayjs()) /
                          86400000
                      ) < 0
                      ? `- Deadline ${Math.abs(
                          Math.round(
                            dayjs(requestForQuote.bidByDate).diff(dayjs()) /
                              86400000
                          )
                        )} day${
                          Math.abs(
                            Math.round(
                              dayjs(requestForQuote.bidByDate).diff(dayjs()) /
                                86400000
                            )
                          ) > 1
                            ? "s"
                            : ""
                        } ago`
                      : `- Deadline ${dayjs(requestForQuote.bidByDate).diff(
                          dayjs("4/29/24"),
                          "day"
                        )} day${
                          dayjs(requestForQuote.bidByDate).diff(
                            dayjs("4/29/24"),
                            "day"
                          ) > 1
                            ? "s"
                            : ""
                        } from now`
                    : ""
                }`}
              </span>
            </p>
            {requestForQuote.order.length > 0 && (
              <div className="order-requisitions">
                {requestForQuote.order.map((order) => (
                  <Link to={`/purchaseorder/${order.id}`} key={order.id}>
                    <Button>{`${order.vendor.vendorName} - PO# ${order.purchaseOrderNumber}`}</Button>
                  </Link>
                ))}
              </div>
            )}
            <div className="order-requisitions">
              {requestForQuote.Requisition.map((req) => (
                <Link to={`/material/requisition/${req.id}`} key={req.id}>
                  <Button>REQ#{req.requisitionNumber}</Button>
                </Link>
              ))}
            </div>
          </div>
          <div className="submit-button">
            <span>
              <Button
                onClick={() => {
                  window.open(
                    `${API_URL}/rfq/pdf/${requestForQuote.requestForQuoteNumber}`,
                    "_blank"
                  );
                }}
              >
                View RFQ PDF
              </Button>
            </span>
            {requestForQuote.orderState === "DRAFT" ? (
              <span style={{ marginLeft: "10px" }}>
                <Button onClick={() => handleSendRFQ()}>
                  {`Send RFQ${requestForQuote.vendor.length > 1 ? "s" : ""}`}
                </Button>
              </span>
            ) : (
              <span style={{ marginLeft: "10px" }}>
                <Button
                  disabled={
                    allocatedItems?.length > 0 &&
                    allocatedItems
                      .map((vendor) => ({
                        vendorId: vendor.vendorId,
                        items: vendor.items.filter(
                          (item) => item.allocated > 0
                        ),
                      }))
                      .filter((vendor) => vendor.items.length > 0).length < 1
                  }
                  onClick={() => handleSendPO()}
                >{`Create PO`}</Button>
              </span>
            )}
          </div>
        </div>
        <div
          className="reqDetails"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Order Details</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Job</th>
                <td>
                  {`${requestForQuote.job.jobNumber} ${requestForQuote.job.jobName}`}
                </td>
              </tr>
              <tr>
                <th>Requested by</th>
                <td>
                  {`${requestForQuote.user.firstName} ${requestForQuote.user.lastName}`}
                </td>
              </tr>
              <tr>
                <th>Bid by</th>
                <td>{dayjs(requestForQuote.bidByDate).format("M/D/YYYY")}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Accounting Details</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>GL Code</th>
                <td>
                  <span>
                    {`${requestForQuote.generalLedger.generalLedgerCode} - ${requestForQuote.generalLedger.generalLedgerName}`}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Cost Code</th>
                <td>
                  <span>
                    {`${requestForQuote.Task.taskCode} - ${requestForQuote.Task.taskName}`}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Tax Code</th>
                <td>
                  <span>{requestForQuote.tax.taxCode}</span>
                </td>
              </tr>

              <tr>
                <th>Billing Details</th>
                <td>
                  <input
                    type="text"
                    value={requestForQuote.billingDetails}
                    readOnly
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Additional Information</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Order Description</th>
                <td>
                  <input
                    type="text"
                    value={
                      requestForQuote.description
                        ? requestForQuote.description
                        : ""
                    }
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>Delivery Instructions</th>
                <td>
                  <textarea
                    value={
                      requestForQuote.deliveryInstruction
                        ? requestForQuote.deliveryInstruction
                        : ""
                    }
                    readOnly
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div
            style={{ backgroundColor: "black", height: "1px", width: "100%" }}
          />
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>
                  <h3>Quotes</h3>
                </th>
                <th colSpan={2}>
                  <Button onClick={() => setShowAddVendor(true)}>
                    Add Vendor
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Vendor</th>
                <th>Subtotal</th>
                <th>Shipping</th>
                <th>Est. Tax</th>
                <th>Total</th>
                <th>
                  {requestForQuote.orderState === "COMPLETE"
                    ? "Ordered"
                    : "Allocated"}
                </th>
              </tr>
              {requestForQuote?.quotes.length > 0 &&
                requestForQuote.quotes.map((quote, index) => {
                  if (quote.pricing !== null) {
                    const subtotal = Object.values(quote.pricing).reduce(
                      (prev, cur) => prev + Number(cur["ExtPrice"]),
                      0
                    );
                    const tax =
                      subtotal <= requestForQuote.tax.countyTaxLimit
                        ? subtotal *
                          (requestForQuote.tax.countyTaxRate +
                            requestForQuote.tax.stateTaxRate)
                        : requestForQuote.tax.countyTaxRate *
                            requestForQuote.tax.countyTaxLimit +
                          subtotal * requestForQuote.tax.stateTaxRate;
                    return (
                      <tr key={quote.id}>
                        <td>{quote.vendor.vendorName}</td>
                        <td>
                          {quote?.pricing ? `${subtotal.toFixed(2)}` : "-"}
                        </td>
                        <td>{"-"}</td>
                        <td>{tax.toFixed(2)}</td>
                        <td>{(subtotal + tax).toFixed(2)}</td>
                        <td>
                          {allocatedItems[index].items.reduce(
                            (prev, cur) =>
                              prev +
                              Math.round(
                                cur.allocated * (cur.unitPrice / cur.unit) * 100
                              ) /
                                100,
                            0
                          )}
                        </td>
                        {quote.attachments.length > 0 ? (
                          <>
                            <td style={{ width: "150px" }}>
                              <a
                                href={quote.attachments[0].url}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Button style={{ width: "150px" }}>
                                  View Quote
                                </Button>
                              </a>
                            </td>
                            {requestForQuote.orderState !== "COMPLETE" && (
                              <>
                                <td style={{ padding: "0px", width: "150px" }}>
                                  {!quote.pricing ? (
                                    <Button
                                      style={{
                                        verticalAlign: "middle",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() =>
                                        handleGetPricing(
                                          index,
                                          quote.attachments[0].url
                                        )
                                      }
                                    >
                                      Get pricing
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        width: "150px",
                                        verticalAlign: "middle",
                                        marginLeft: "10px",
                                        padding: "8px 4px",
                                      }}
                                      onClick={() => setPricingReview(true)}
                                    >
                                      Review pricing
                                    </Button>
                                  )}
                                </td>
                                <td style={{ width: "150px" }}>
                                  <Button
                                    style={{
                                      width: "150px",
                                      verticalAlign: "middle",
                                      marginLeft: "10px",
                                      padding: "8px 4px",
                                    }}
                                    onClick={() => {
                                      zeroAllocation();
                                      for (
                                        let i = 0;
                                        i < requestForQuote.items.length;
                                        i++
                                      ) {
                                        setAllocatedItems((prevState) => {
                                          const newState = [...prevState];
                                          newState[index].items[i].allocated =
                                            parseInt(
                                              Object.values(
                                                requestForQuote.quotes[index]
                                                  .pricing
                                              )[i]["Qty"]
                                            );
                                          return newState;
                                        });
                                      }
                                    }}
                                  >
                                    Allocate All
                                  </Button>
                                </td>
                              </>
                            )}
                          </>
                        ) : (
                          <td>
                            <Button
                              style={{
                                width: "150px",
                                verticalAlign: "middle",
                              }}
                              onClick={() => {
                                setOnToggleUploadQuote(true);
                                setUploadQuote(quote);
                              }}
                            >
                              Add Quote
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={quote.id}>
                        <td>{quote.vendor.vendorName}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td></td>
                      </tr>
                    );
                  }
                })}
              {requestForQuote.quotes.length > 1 && (
                <>
                  <tr style={{ height: "15px" }}></tr>
                  <tr>
                    <td>Lowest Price Order</td>
                    <td>{`${lowestOrder?.total}`}</td>
                    <td>{"-"}</td>
                    <td>
                      {lowestOrder?.total <= requestForQuote.tax.countyTaxLimit
                        ? (
                            lowestOrder?.total *
                            (requestForQuote.tax.countyTaxRate +
                              requestForQuote.tax.stateTaxRate)
                          ).toFixed(2)
                        : (
                            requestForQuote.tax.countyTaxRate *
                              requestForQuote.tax.countyTaxLimit +
                            lowestOrder?.total *
                              requestForQuote.tax.stateTaxRate
                          ).toFixed(2)}
                    </td>
                    <td>
                      {(
                        lowestOrder?.total +
                        (lowestOrder?.total <=
                        requestForQuote.tax.countyTaxLimit
                          ? lowestOrder?.total *
                            (requestForQuote.tax.countyTaxRate +
                              requestForQuote.tax.stateTaxRate)
                          : requestForQuote.tax.countyTaxRate *
                              requestForQuote.tax.countyTaxLimit +
                            lowestOrder?.total *
                              requestForQuote.tax.stateTaxRate)
                      ).toFixed(2)}
                    </td>
                    <td>
                      {allocatedItems
                        .reduce(
                          (prev, cur) =>
                            prev +
                            cur.items.reduce(
                              (prev1, cur1) =>
                                prev1 +
                                +(
                                  cur1.allocated *
                                  (cur1.unitPrice / cur1.unit)
                                ).toFixed(2),
                              0
                            ),
                          0
                        )
                        .toFixed(2)}
                    </td>
                    {requestForQuote.orderState !== "COMPLETE" && (
                      <>
                        <td>
                          <Button
                            style={{
                              width: "150px",
                              verticalAlign: "middle",
                              padding: "8px 4px",
                            }}
                            onClick={() => {
                              zeroAllocation();
                              allocateLowestPriceOrder();
                            }}
                          >
                            Allocate All
                          </Button>
                        </td>
                        <td>
                          <Button
                            style={{
                              width: "150px",
                              verticalAlign: "middle",
                              marginLeft: "10px",
                              padding: "8px 4px",
                            }}
                            onClick={() => {
                              zeroAllocation();
                            }}
                          >
                            Zero All
                          </Button>
                        </td>
                      </>
                    )}
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <AttachmentBanner
          addAttachment={true}
          attachments={
            requestForQuote.attachments.length > 0
              ? requestForQuote.attachments
              : []
          }
        />
        {!loading && (
          <div style={{ marginBottom: "50px" }}>
            <table className="item-table">
              <thead>
                <tr>
                  <th
                    style={{ background: "black", color: "white" }}
                    colSpan={"100%"}
                  >
                    <h3>Items</h3>
                  </th>
                </tr>
                <tr>
                  <th>Order Qty.</th>
                  <th>Unit</th>
                  <th>Description</th>
                  <th>Vendor Quotes</th>
                </tr>
              </thead>
              <tbody>
                {requestForQuote.items.length > 0 &&
                  requestForQuote.items.map((item, itemIndex) => (
                    <tr key={item.itemId}>
                      <td>{item.item.quantity}</td>
                      <td>{item.item?.unitOfMeasure?.code}</td>
                      <td>{item.item.description}</td>
                      <td style={{ padding: "0px", margin: "-1px" }}>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            margin: "-1px",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Vendor</th>
                              <th>Qty</th>
                              <th>Unit</th>
                              <th>Unit Price</th>
                              <th>Ext Price</th>
                              <th>
                                {requestForQuote.orderState === "COMPLETE"
                                  ? "Ordered"
                                  : "Allocated"}
                              </th>
                            </tr>
                          </thead>
                          {requestForQuote.quotes.map(
                            ({ id, vendor, pricing }, quoteIndex) => (
                              <tbody key={id}>
                                <tr>
                                  <td
                                    style={{
                                      textTransform: "capitalize",
                                      width: "200px",
                                    }}
                                  >
                                    {vendor.vendorName}
                                  </td>
                                  <td>
                                    {pricing &&
                                      pricing[item.item.lineNumber - 1]["Qty"]}
                                  </td>
                                  <td style={{ textTransform: "uppercase" }}>
                                    {pricing &&
                                      pricing[item.item.lineNumber - 1]["Unit"]}
                                  </td>
                                  <td>
                                    {pricing &&
                                      pricing[item.item.lineNumber - 1][
                                        "UnitPrice"
                                      ]}
                                  </td>
                                  <td>
                                    {pricing &&
                                      pricing[item.item.lineNumber - 1][
                                        "ExtPrice"
                                      ]}
                                  </td>
                                  <td
                                    style={{
                                      padding: "0px",
                                    }}
                                  >
                                    {requestForQuote.orderState ===
                                    "COMPLETE" ? (
                                      <span>
                                        {
                                          requestForQuote.order
                                            .filter(
                                              (vendorOrder) =>
                                                vendorOrder.vendorId ===
                                                vendor.id
                                            )[0]
                                            .items.filter(
                                              (itemOrder) =>
                                                itemOrder.itemId ===
                                                item.item.id
                                            )[0]?.quantityOrdered
                                        }
                                      </span>
                                    ) : (
                                      <input
                                        type="text"
                                        style={{
                                          marginLeft: "5px",
                                          padding: "0px",
                                          border: "none",
                                          outline: "none",
                                        }}
                                        disabled={
                                          allocatedItems[quoteIndex].items
                                            .length < 1
                                        }
                                        value={
                                          allocatedItems[quoteIndex].items
                                            .length > 0
                                            ? allocatedItems[quoteIndex].items[
                                                itemIndex
                                              ].allocated
                                            : 0
                                        }
                                        onChange={(e) =>
                                          handleAlloctionChange(
                                            quoteIndex,
                                            itemIndex,
                                            e
                                          )
                                        }
                                      />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </RFQContainer>
    )
  );
};

SingleRFQ.propTypes = {
  userId: PropTypes.string,
};

export default SingleRFQ;
