import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const JobForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  .map-div {
    height: 200px;
    width: 200px;
  }
  .leaflet-container {
    height: 100%;
  }
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      grid-row: 4;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddJobForm = () => {
  const [state, setState] = useState({
    jobNumber: "",
    jobName: "",
    jobAddress: "",
    jobAddress2: "",
    jobCity: "",
    jobState: "",
    jobZip: "",
    jobLat: "",
    jobLng: "",
  });

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    const formData = state;
    formData.jobLat === "" && delete formData.jobLat;
    formData.jobLng === "" && delete formData.jobLng;
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...formData }),
    };
    fetch(`${API_URL}/create/job`, requestOptions).then((response) =>
      response.json()
    );
  };

  const {
    jobNumber,
    jobName,
    jobAddress,
    jobAddress2,
    jobCity,
    jobState,
    jobZip,
    jobLat,
    jobLng,
  } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Add Job
      </h1>
      <div>
        <JobForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/jobs");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="jobNumber" className="job-number">
                Job Number
                <br />
                <input
                  type="text"
                  name="jobNumber"
                  id="jobNumber"
                  value={jobNumber}
                  onChange={handleChange}
                  placeholder="Job Number*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="jobName" className="job-name">
                Job Name
                <br />
                <input
                  type="text"
                  name="jobName"
                  id="jobName"
                  value={jobName}
                  onChange={handleChange}
                  placeholder="Job Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="jobAddress" className="job-address">
                Job Address
                <br />
                <input
                  type="text"
                  name="jobAddress"
                  id="jobAddress"
                  value={jobAddress}
                  onChange={handleChange}
                  placeholder="Job Address"
                  autoComplete="none"
                />
                <input
                  type="text"
                  name="jobAddress2"
                  id="jobAddress2"
                  value={jobAddress2}
                  onChange={handleChange}
                  placeholder="Job Address 2"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="jobCity" className="job-city">
                Job City
                <br />
                <input
                  type="text"
                  name="jobCity"
                  id="jobCity"
                  value={jobCity}
                  onChange={handleChange}
                  placeholder="Job City"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="jobState" className="job-state">
                Job State
                <br />
                <input
                  type="text"
                  name="jobState"
                  id="jobState"
                  value={jobState}
                  onChange={handleChange}
                  placeholder="Job State"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="jobZip" className="job-zip">
                Job Zip
                <br />
                <input
                  type="text"
                  name="jobZip"
                  id="jobZip"
                  value={jobZip}
                  onChange={handleChange}
                  placeholder="Job Zip"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="jobZip" className="job-zip">
                Job Lattitude
                <br />
                <input
                  value={jobLat}
                  onChange={handleChange}
                  type="text"
                  name="jobLat"
                  id="jobLat"
                  placeholder="Job Lattitude"
                />
                <br />
                Job Longitude
                <input
                  value={jobLng}
                  onChange={handleChange}
                  type="text"
                  name="jobLng"
                  id="jobLng"
                  placeholder="Job Longitude"
                />
              </label>
              {jobLat && jobLng && (
                <div className="map-div">
                  <MapContainer
                    className="map-container"
                    center={[jobLat, jobLng]}
                    zoom={13}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {jobLat && jobLng && (
                      <Marker
                        position={[jobLat, jobLng]}
                        icon={
                          new Icon({
                            iconUrl: markerIconPng,
                            iconSize: [23, 36],
                            iconAnchor: [12, 41],
                          })
                        }
                      ></Marker>
                    )}
                  </MapContainer>
                </div>
              )}
              <Button type="submit">Create Job</Button>
            </div>
          </fieldset>
        </JobForm>
      </div>
    </div>
  );
};

export default AddJobForm;
