import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import _ from "lodash";
import dayjs from "dayjs";
import CustomStyles from "../../styles/SelectCustomStyle";
import { API_URL } from "../../env";

const JobWOTable = styled.table`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid black;
    width: 200px;
  }
`;

const JobWorkOrderReport = () => {
  const [jobId, setJobId] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [workOrders, setWorkOrders] = useState([]);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/fulljoblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
  }, []);
  useEffect(() => {
    if (jobId) {
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(`${API_URL}/workorders/${jobId}`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.clockActions.length > 0 || json.FieldWorkOrder.length > 0) {
            let extraDays = [];
            const fieldWOHours = [];
            const fieldWODates = [];
            const clockActions = [];
            const timeByDate = [];
            if (json.FieldWorkOrder.length > 0) {
              fieldWODates.push(
                ...json.FieldWorkOrder.map((workOrder) =>
                  workOrder.date.slice(0, 10)
                )
              );
              fieldWODates.sort((a, b) => {
                const date1 = new Date(a);
                const date2 = new Date(b);
                return date1 - date2;
              });
              fieldWOHours.push(
                ...json.FieldWorkOrder.map((workOrder) => ({
                  date: workOrder.date.slice(0, 10),
                  id: workOrder.id,
                  hours: workOrder.labor.reduce(
                    (prev, cur) =>
                      prev +
                      (cur.clockIn && cur.clockOut
                        ? dayjs(cur.clockOut).diff(dayjs(cur.clockIn)) / 3600000
                        : 0),
                    0
                  ),
                }))
              );
            }
            if (json.clockActions.length > 0) {
              timeByDate.push(
                ...json.clockActions.map((clockAction) => ({
                  date: clockAction.clockIn.slice(0, 10),
                  hours:
                    clockAction.clockOut && clockAction.clockIn
                      ? dayjs(clockAction.clockOut).diff(
                          dayjs(clockAction.clockIn)
                        ) / 3600000
                      : 0,
                }))
              );
              clockActions.push(
                ...json.clockActions.map((clock) => {
                  const clockInDate = clock?.clockIn?.slice(0, 10);
                  const clockOutDate = clock?.clockOut?.slice(0, 10);
                  if (clockInDate === clockOutDate) {
                    return clockInDate;
                  } else {
                    extraDays.push(clockOutDate);
                    return clockInDate;
                  }
                })
              );
              extraDays.length > 0 && clockActions.push(...extraDays);
              clockActions.sort((a, b) => {
                const date1 = new Date(a);
                const date2 = new Date(b);
                return date1 - date2;
              });
            }
            const allDates = _.union(fieldWODates, clockActions);
            allDates.sort((a, b) => {
              const date1 = new Date(a);
              const date2 = new Date(b);
              return date1 - date2;
            });
            const hoursByDate = _.chain(timeByDate)
              .groupBy("date")
              .map((value, key) => ({ date: key, hours: value }))
              .value();
            const totalHoursByDate = hoursByDate.map((day) => ({
              date: day.date,
              hours: day.hours.reduce(
                (prev, cur) => prev + parseFloat(cur.hours),
                0
              ),
            }));
            const checkCompletedDate = json.FieldWorkOrder.filter(
              (workOrder) => workOrder.complete === true
            );
            const completedDate =
              checkCompletedDate.length > 0
                ? checkCompletedDate[0].date.slice(0, 10)
                : null;

            setWorkOrders({
              allDates,
              fieldWODates,
              totalHoursByDate,
              fieldWOHours,
              completedDate,
            });
          } else {
            setWorkOrders(json);
          }
        });
    }
  }, [jobId]);
  useEffect(() => {
    jobs.length > 0 > 0 && setLoading(false);
  }, [jobs]);
  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));
  return (
    <div>
      <h1>Job Work Order Report</h1>
      <div style={{ width: "500px", margin: "0px auto 0px auto" }}>
        <Select
          styles={CustomStyles}
          options={jobList}
          isLoading={loading}
          name="jobId"
          onChange={(e) => setJobId(e.value)}
          defaultValue={jobId}
          placeholder="Select Job"
        />
      </div>
      {workOrders?.allDates?.length > 0 ? (
        <div>
          <JobWOTable>
            <thead>
              <tr>
                <th>Date</th>
                <th colSpan="2">Time</th>
                <th colSpan="2">Work Orders</th>
              </tr>
            </thead>
            <tbody>
              {workOrders.allDates.map((date, index) => (
                <tr
                  key={`${date}${index}`}
                  style={{
                    background:
                      date === workOrders.completedDate ? "blue" : "none",
                    color:
                      date === workOrders.completedDate ? "white" : "black",
                  }}
                >
                  <td>{dayjs(date).format("MM/DD/YYYY")}</td>
                  {workOrders.totalHoursByDate.some(
                    (timeDate) => date === timeDate.date
                  ) ? (
                    <>
                      <td>&#9745;</td>
                      <td>
                        {workOrders.totalHoursByDate
                          .filter((day) => day.date === date)[0]
                          .hours.toFixed(2)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>&#9744;</td>
                      <td>-</td>
                    </>
                  )}
                  {workOrders.fieldWODates.some(
                    (ticketDate) => date === ticketDate
                  ) ? (
                    <>
                      <td>&#9745;</td>
                      <td>
                        <Link
                          target={"_blank"}
                          to={`/forms/workorders/${
                            workOrders.fieldWOHours.filter(
                              (day) => day.date === date
                            )[0].id
                          }`}
                          style={{
                            background:
                              date === workOrders.completedDate
                                ? "blue"
                                : "none",
                            color:
                              date === workOrders.completedDate
                                ? "white"
                                : "black",
                          }}
                        >
                          {workOrders.fieldWOHours
                            .filter((day) => day.date === date)[0]
                            .hours.toFixed(2)}
                        </Link>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>&#9744;</td>
                      <td>-</td>
                    </>
                  )}
                </tr>
              ))}
              <tr style={{ fontWeight: "bold" }}>
                <td>Totals</td>
                <td></td>
                <td>
                  {workOrders.totalHoursByDate
                    .reduce((prev, cur) => prev + cur.hours, 0)
                    .toFixed(2)}
                </td>
                <td></td>
                <td>
                  {workOrders.fieldWOHours
                    .reduce((prev, cur) => prev + cur.hours, 0)
                    .toFixed(2)}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan="5"
                  style={{
                    textAlign: "left",
                    border: "none",
                    paddingTop: "10px",
                  }}
                >
                  <span
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "blue",
                      display: "inline-block",
                      marginRight: "10px",
                    }}
                  />
                  <span>Work order marked as job completed</span>
                </td>
              </tr>
            </tfoot>
          </JobWOTable>
        </div>
      ) : (
        <div>
          <h3>Select a Job</h3>
        </div>
      )}
    </div>
  );
};

export default JobWorkOrderReport;
