import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const TaskForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

class SingleTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: { ...this.props.editTask[0] },
      updatedTask: "",
      error: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      updatedTask: { ...this.state.updatedTask, [name]: value },
    });
  }

  handleActiveChange(e) {
    const { checked, name } = e.target;
    this.setState({
      updatedTask: { ...this.state.updatedTask, [name]: checked },
    });
  }

  render() {
    const { task, updatedTask } = this.state;
    return (
      <div>
        <div>
          <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Edit Task
          </h3>
          <TaskForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...updatedTask }),
              };
              await fetch(
                `${API_URL}/update/task/${task.id}`,
                requestOptions
              ).then((res) => res.json());
              this.props.onEditTask(task.id, updatedTask);
              this.props.onDeselectTask();
            }}
          >
            <fieldset>
              <div className="fields">
                <div>
                  <label className="task-code">
                    Task Code
                    <br />
                    <input
                      type="text"
                      name="taskCode"
                      id="taskCode"
                      defaultValue={task.taskCode}
                      onChange={this.handleChange}
                      placeholder="Task Code*"
                      required
                      autoComplete="none"
                    />
                  </label>
                </div>
                <label className="task-name">
                  Task Name
                  <br />
                  <input
                    type="text"
                    name="taskName"
                    id="taskName"
                    defaultValue={task.taskName}
                    onChange={this.handleChange}
                    placeholder="Task Name*"
                    required
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="active">
                  Public
                  <input
                    type="checkbox"
                    name="isPublic"
                    id="isPublic"
                    defaultChecked={task.isPublic}
                    onChange={this.handleActiveChange}
                  />
                </label>
                {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                <Button type="submit">Save Changes</Button>
              </div>
            </fieldset>
          </TaskForm>
        </div>
      </div>
    );
  }
}

SingleTask.propTypes = {
  id: PropTypes.string,
  editTask: PropTypes.array,
  onEditTask: PropTypes.func,
  onDeselectTask: PropTypes.func,
};

export default SingleTask;
