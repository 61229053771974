import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditWorkOrder from "./EditWorkOrder";
import Table from "./WorkOrderTable";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import Pagination from "../../Search/Pagination";
import { API_URL } from "../../../env";

const columns = [
  ["WO #", "id"],
  ["First Name", "user.firstName"],
  ["Last Name", "user.lastName"],
  ["Job", "job.jobNumber"],
  ["Date", "date"],
  ["Complete", "complete"],
  ["Submitted", "submitted"],
  ["Submitted At", "submittedAt"],
  ["PDF", "id"],
];

const WorkOrders = () => {
  const [state, setState] = useState({
    workOrders: [],
    editWorkOrder: "",
  });
  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/workorders/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                workOrders: json.workOrders,
              }));
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/forms/workorders?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Job",
      field: "jobId",
      options: jobs.map((job) => ({
        label: `${job.jobNumber}-${job.jobName}`,
        value: job.id,
      })),
    },
    {
      label: "Employee",
      field: "userId",
      options: employees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
    },
    {
      label: "Submitted",
      field: "submitted",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Completed",
      field: "complete",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const workOrder = state.workOrders.filter(
      (workOrder) => workOrder.id === id
    );
    setState((prevState) => ({ ...prevState, editWorkOrder: workOrder }));
  };

  const onEditWorkOrder = (id, workOrder) => {
    const index = state.workOrders.findIndex(
      (workOrder) => workOrder.id === id
    );
    let newState = state.workOrders;
    newState[index] = {
      ...state.workOrders[index],
      ...workOrder,
    };
    setState((prevState) => ({ ...prevState, workOrders: newState }));
  };

  const onDeselectWorkOrder = () => {
    setState((prevState) => ({ ...prevState, editWorkOrder: "" }));
  };

  const onDeleteWorkOrder = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(`${API_URL}/delete/form/workorder/${id}`, requestOptions)
        .then((res) => {
          {
            if (res.status !== 200) {
              throw new Error(res.statusText);
            }
            return res.json();
          }
        })
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            editWorkOrder: "",
            workOrders: prevState.workOrders.filter(
              (workOrder) => workOrder.id !== id
            ),
          }));
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { workOrders, editWorkOrder } = state;
  return editWorkOrder ? (
    <EditWorkOrder
      editWorkOrder={editWorkOrder}
      onDeselectWorkOrder={onDeselectWorkOrder}
      onDeleteWorkOrder={onDeleteWorkOrder}
      onEditWorkOrder={onEditWorkOrder}
    />
  ) : (
    <div>
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Work Order"}
        buttonPath={"/create/forms/workorder"}
        pageTitle={"Field Work Orders"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={workOrders}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

export default WorkOrders;
