import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const ToolForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      grid-row: 4;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddToolForm = () => {
  const [state, setState] = useState({
    name: "",
    locationAddress: "",
    locationCity: "",
    locationState: "",
    locationZip: "",
    locationType: "",
    locationManagerId: "",
  });
  const [employees, setEmployees] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/employees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    const formData = state;
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...formData }),
    };
    fetch(`${API_URL}/location`, requestOptions).then((response) =>
      response.json()
    );
  };
  const {
    name,
    locationAddress,
    locationCity,
    locationState,
    locationZip,
    locationType,
    locationManagerId,
  } = state;

  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Location
      </h1>
      <div>
        <ToolForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/tool/locations");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="name" className="name">
                Location Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Location Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationAddress" className="description">
                Location Address
                <br />
                <input
                  type="text"
                  name="locationAddress"
                  id="locationAddress"
                  value={locationAddress}
                  onChange={handleChange}
                  placeholder="Location Address*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationCity" className="locationCity">
                Location City
                <br />
                <input
                  type="text"
                  name="locationCity"
                  id="locationCity"
                  value={locationCity}
                  onChange={handleChange}
                  placeholder="Location City*"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationState" className="locationState">
                Location State
                <br />
                <input
                  type="text"
                  name="locationState"
                  id="locationState"
                  value={locationState}
                  onChange={handleChange}
                  placeholder="Location State*"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationZip" className="locationZip">
                Location Zip
                <br />
                <input
                  type="text"
                  name="locationZip"
                  id="locationZip"
                  value={locationZip}
                  onChange={handleChange}
                  placeholder="Location Zip*"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationType" className="locationType">
                Location Type
                <br />
                <select
                  required
                  name="locationType"
                  id="locationType"
                  value={locationType}
                  onChange={handleChange}
                >
                  <option defaultValue>Select A Type</option>
                  <option value="Jobsite">Jobsite</option>
                  <option value="Warehouse">Warehouse</option>
                  <option value="Vehicle">Vehicle</option>
                </select>
              </label>
              <label htmlFor="locationManagerId" className="locationManagerId">
                Location Manager
                <br />
                <select
                  required
                  name="locationManagerId"
                  id="locationManagerId"
                  value={locationManagerId}
                  onChange={handleChange}
                >
                  <option defaultValue>Select An Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </label>
              <Button type="submit">Add Location</Button>
            </div>
          </fieldset>
        </ToolForm>
      </div>
    </div>
  );
};

export default AddToolForm;
