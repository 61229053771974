import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { API_URL } from "../../env";
import { Button } from "../../styles/Style";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const DateButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: center;
    display: inline-flex;
    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const ExportHourly = () => {
  const [loading, setLoading] = useState(false);
  const [csv, setCSV] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(start.toISOString()));
  const [endDate, setEndDate] = useState(new Date(end.toISOString()));

  const calculateDailyPay = (timecards, expenses) => {
    const result = [];

    // Iterate through timecards
    timecards.forEach((timecard) => {
      const { timeCardPayRate, timeCardItems, user } = timecard;

      const { employeeNumber } = user;

      // Iterate through timeCardItems
      timeCardItems.forEach((timeCardItem) => {
        const { hours, job, task } = timeCardItem;
        const { jobNumber } = job;
        const { taskCode } = task;

        // Parse the start date
        const timeCardDate = new Date(startDate);

        // Calculate pay for each day
        hours.forEach((dayHours, index) => {
          // Calculate the daily pay based on the given pay rate
          const dailyPay = (dayHours / 40) * timeCardPayRate;

          // Skip if dailyPay is zero
          if (dailyPay === 0) {
            return;
          }

          // Calculate the date for the current day
          const currentDate = new Date(timeCardDate);
          currentDate.setDate(timeCardDate.getDate() + index);

          // Add the daily pay to the result object
          result.push([
            employeeNumber,
            jobNumber,
            taskCode,
            dailyPay,
            dayjs(currentDate).format("MM/DD/YYYY"),
            taskCode === "985" ? "PTO" : "SAL",
          ]);
        });
      });
    });

    if (expenses.length > 0) {
      expenses.forEach((expense) => {
        const { user, job, task, purchaseDate, amount } = expense;
        const { employeeNumber } = user;
        const { jobNumber } = job;
        const { taskCode } = task;
        result.push([
          employeeNumber,
          jobNumber,
          taskCode,
          amount,
          dayjs(purchaseDate).isBefore(dayjs(startDate))
            ? dayjs(startDate).format("MM/DD/YYYY")
            : dayjs(purchaseDate).isAfter(dayjs(endDate))
            ? dayjs(endDate).subtract(2, "days").format("MM/DD/YYYY")
            : dayjs(purchaseDate).format("MM/DD/YYYY"),
          taskCode === "963"
            ? "FUEL"
            : parseInt(taskCode) <= 800
            ? "MATER"
            : "REIM",
        ]);
      });
    }
    return setCSV(result);
  };

  useEffect(() => {
    if (data?.salaryExport?.length > 0 || data?.expenseExport?.length > 0) {
      calculateDailyPay(data.salaryExport, data.expenseExport);
    }
  }, [data]);

  const getTime = () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/salaryexport/${startDate.toISOString()}/${endDate.toISOString()}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setData(json));
    setLoading(false);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setStartDate(start);
    setEndDate(newEnd);
    setCSV([]);
  };
  return (
    <div>
      <h2>Export Time</h2>
      <DateButton>
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(startDate, 1), subWeeks(endDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(startDate, 1), subDays(endDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(startDate, 1), addDays(endDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(startDate, 1), addWeeks(endDate, 1)])
            }
          >
            {">>"}
          </Button>
        </div>
      </DateButton>
      <br />
      {csv.length > 0 && !loading ? (
        <CSVLink
          data={csv}
          style={{
            background: "blue",
            color: "white",
            padding: "5px",
          }}
          filename={`ADD_BY_EMPLOYEE_WE_${dayjs(endDate).format(
            "MM-DD-YY"
          )}.csv`}
        >
          Download
        </CSVLink>
      ) : loading ? (
        <Button>Loading</Button>
      ) : (
        <Button
          onClick={() => {
            setLoading(true);
            getTime();
          }}
        >
          Get Time
        </Button>
      )}
    </div>
  );
};

export default ExportHourly;
