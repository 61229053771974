import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import TimeFooter from "./TimeFooter";
import dayjs from "dayjs";
import EditTimePanel from "./EditTimePanel";
import { DataTable } from "../../styles/DataTable";
import TimeSearch from "./TimeSearch";
import { endOfDay, roundToNearestMinutes } from "date-fns";
import { API_URL } from "../../env";
import ConfirmDelete from "./ConfirmDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStickyNote,
  // faCaretDown,
  // faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

// const columns = [
//   ["✔"],
//   ["Dept", "user.department.name"],
//   ["First", "firstName"],
//   ["Middle", "middleName"],
//   ["Last", "lastName"],
//   ["Job", "jobId"],
//   ["Cost Code", "taskId"],
//   ["In", "clockTime"],
//   ["Out", "clockTime"],
//   ["Total"],
// ];

// const searchFields = ["jobNumber", "jobName"];

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const Time = () => {
  const [state, setState] = useState({
    clockActions: [],
    timeRecords: [],
    editTime: "",
    selected: [],
    selectedTotal: [],
    processedSelected: [],
    processedSelectedTotal: [],
    processedTimeUserShow: [],
    allocateBatch: [],
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy] = useState(undefined);
  const [orderBy] = useState(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  !localStorage.getItem("startDate") &&
    localStorage.setItem("startDate", start.toISOString());
  !localStorage.getItem("endDate") &&
    localStorage.setItem("endDate", end.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(localStorage.getItem("startDate"))
  );
  const [endDate, setEndDate] = useState(
    new Date(localStorage.getItem("endDate"))
  );
  const [filters, setFilters] = useState({
    startDate: new Date(localStorage.getItem("startDate")).toISOString(),
    endDate: new Date(localStorage.getItem("endDate")).toISOString(),
  });
  const [showTimeType, setShowTimeType] = useState("open");
  const [openSelectAll, setOpenSelectAll] = useState(false);
  const [closedSelectAll, setClosedSelectAll] = useState(false);
  const [editBatch, setEditBatch] = useState([]);
  const [onToggleConfirm, setOnToggleConfirm] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [hourlyRates, setHourlyRates] = useState([]);
  const [devices, setDevices] = useState([]);
  const [ctrlKey, setCtrlKey] = useState(false);
  const [shiftKey, setShiftKey] = useState(false);
  const [lastSelected, setLastSelected] = useState(null);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
    fetch(`${API_URL}/departments`, requestOptions)
      .then((res) => res.json())
      .then((json) => setDepartments(json));
    fetch(`${API_URL}/devices`, requestOptions)
      .then((res) => res.json())
      .then((json) => setDevices(json));
    fetch(`${API_URL}/electricians`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
    fetch(`${API_URL}/projectManagers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setProjectManagers(json));
    fetch(`${API_URL}/hourly-rates`, requestOptions)
      .then((res) => res.json())
      .then((json) => setHourlyRates(json));
  }, []);
  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/clockactions/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${encodeURIComponent(
              JSON.stringify(filters)
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) =>
              setState((prevState) => ({
                ...prevState,
                clockActions: _.orderBy(
                  json,
                  [
                    "user.lastName",
                    "user.firstName",
                    (item) => item.clockIn || item.clockOut,
                  ],
                  ["asc", "asc", "asc"]
                ),
              }))
            );
          fetch(
            `${API_URL}/timerecords/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${encodeURIComponent(
              JSON.stringify(filters)
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) =>
              setState((prevState) => {
                const timeRecords = _.chain(json)
                  .groupBy("name")
                  .map((value, key) => ({ name: key, timeRecords: value }))
                  .value();
                return {
                  ...prevState,
                  timeRecords: timeRecords.sort((a, b) => {
                    if (
                      a.timeRecords[0].user.lastName.toLowerCase() <
                      b.timeRecords[0].user.lastName.toLowerCase()
                    ) {
                      return -1;
                    }
                    if (
                      a.timeRecords[0].user.lastName.toLowerCase() >
                      b.timeRecords[0].user.lastName.toLowerCase()
                    ) {
                      return 1;
                    }
                    return 0;
                  }),
                  processedTimeUserShow: timeRecords.map(() => false),
                };
              })
            );

          // Update the URL with the current page and other parameters
          setLoading(false);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/time?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Department",
      field: "user.departmentId",
      options: departments.map((department) => ({
        label: department.name,
        value: department.id,
      })),
      isMulti: true,
    },
    {
      label: "Job",
      field: "jobId",
      options: jobs.map((job) => ({
        label: `${job.jobNumber} ${job.jobName}`,
        value: job.id,
      })),
      isMulti: true,
    },
    {
      label: "Job PM",
      field: "job.projectManagerId",
      options: projectManagers.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
    },
    {
      label: "Employee",
      field: "userId",
      options: employees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
      isMulti: true,
    },
    {
      label: "Cost Code",
      field: "taskId",
      options: tasks.map((task) => ({
        label: `${task.taskCode} ${task.taskName}`,
        value: task.id,
      })),
      isMulti: true,
    },
    {
      label: "Device",
      field: "deviceCode",
      options: [
        ...devices.map((device) => ({
          label: device.name,
          value: device.code,
        })),
        { label: "Website", value: null },
      ],
      isMulti: true,
    },
    // Add more filter categories as needed
  ];

  const onShiftSelect = (endIndex) => {
    const selected = state.selected;
    const selectedTotal = state.selectedTotal;
    if (showTimeType === "open") {
      const filteredClockActions = state.clockActions.filter(
        (clockAction, index) => index <= endIndex && index > lastSelected
      );
      const newSelected = filteredClockActions.map(
        (clockAction) => clockAction.id
      );
      selected.push(...newSelected);
      const newSelectedTotal = filteredClockActions.map(
        (clockAction) =>
          clockAction.clockOut &&
          clockAction.clockIn &&
          dayjs(clockAction.clockOut).diff(dayjs(clockAction.clockIn)) / 3600000
      );
      selectedTotal.push(...newSelectedTotal);
      setState((prevState) => ({
        ...prevState,
        selected,
        selectedTotal,
      }));
      setLastSelected(null);
    }
  };

  const onCtrlSelect = (id, duration, index) => {
    if (showTimeType === "open") {
      const selected = state.selected;
      const selectedTotal = state.selectedTotal;
      if (!selected.includes(id)) {
        selected.push(id);
        duration !== "-" ? selectedTotal.push(duration) : selectedTotal.push(0);
        setState((prevState) => ({
          ...prevState,
          selected,
          selectedTotal,
        }));
        setLastSelected(index);
      } else {
        const newSelected = selected.filter((item) => item !== id);
        const selectedTotalIndex = selectedTotal.findIndex(
          (total) => total === duration
        );
        delete selectedTotal[selectedTotalIndex];
        setState((prevState) => ({
          ...prevState,
          selected: newSelected,
          selectedTotal,
        }));
        setLastSelected(null);
      }
    } else {
      const processedSelected = state.processedSelected;
      const processedSelectedTotal = state.processedSelectedTotal;
      if (!processedSelected.includes(id)) {
        processedSelected.push(id);
        processedSelectedTotal.push(duration);
        setState((prevState) => ({
          ...prevState,
          processedSelected,
          processedSelectedTotal,
        }));
        setLastSelected(index);
      } else {
        const newProcessedSelected = processedSelected.filter(
          (item) => item !== id
        );
        const processedSelectedTotalIndex = processedSelectedTotal.findIndex(
          (total) => total === duration
        );
        delete processedSelectedTotal[processedSelectedTotalIndex];
        setState((prevState) => ({
          ...prevState,
          processedSelected: newProcessedSelected,
          processedSelectedTotal,
        }));
        setLastSelected(null);
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const { type, keyCode } = event;
      if (type === "keydown" && keyCode === 16) {
        setShiftKey(true);
      }
      if (type === "keyup" && keyCode === 16) {
        setShiftKey(false);
      }
      if (type === "keydown" && keyCode === 17) {
        setCtrlKey(true);
      }
      if (type === "keyup" && keyCode === 17) {
        setCtrlKey(false);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("keyup", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [ctrlKey, shiftKey]);

  const editMultiple = () => {
    const getBatch = state.clockActions.filter((clockAction) =>
      state.selected.includes(clockAction.id)
    );
    setEditBatch(getBatch);
  };

  const onEditBatch = (change) => {
    setState((prevState) => {
      const updateClockActions = [...prevState.clockActions];
      for (let i = 0; i < change.length; i++) {
        const index = updateClockActions.findIndex(
          (item) => item.id === change[i].id
        );
        updateClockActions[index] = {
          ...updateClockActions[index],
          ...change[i],
        };
      }
      return {
        ...prevState,
        selectedTotal: [],
        selected: [],
        clockActions: updateClockActions,
      };
    });
  };

  const onAllocateBatch = (allocate) => {
    setState((prevState) => ({ ...prevState, allocateBatch: allocate }));
  };

  const onSubmitAllocate = async () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    await fetch(
      `${API_URL}/clockactions/${startDate.toISOString()}/${endDate.toISOString()}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) =>
        setState((prevState) => ({
          ...prevState,
          clockActions: _.orderBy(
            json,
            [
              "user.lastName",
              "user.firstName",
              (item) => item.clockIn || item.clockOut,
            ],
            ["asc", "asc", "asc"]
          ),
        }))
      );
  };

  // const handleSort = (column) => {
  //   if (column === sortBy) {
  //     orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
  //   } else {
  //     setSortBy(column);
  //     setOrderBy("asc");
  //   }
  // };

  // const processTime = async () => {
  //   const roundBatch = state.selected.map((item) => {
  //     // TODO - remove clockAction if PTO SICK or HOL and add to new array
  //     const action = state.clockActions.filter((clock) => item === clock.id);
  //     const roundedIn = roundToNearestMinutes(new Date(action[0].clockIn), {
  //       nearestTo: 15,
  //     }).toISOString();
  //     const roundedOut = roundToNearestMinutes(new Date(action[0].clockOut), {
  //       nearestTo: 15,
  //     }).toISOString();
  //     const duration = parseFloat(
  //       dayjs(roundedOut).diff(dayjs(roundedIn)) / 3600000
  //     ).toFixed(2);
  //     return {
  //       userId: action[0].userId,
  //       jobId: action[0].jobId,
  //       day: `${new Date(roundedIn).getMonth() + 1}/${new Date(
  //         roundedIn
  //       ).getDate()}/${new Date(roundedIn).getFullYear()}`,
  //       clockActionId: action[0].id,
  //       clockIn: roundedIn,
  //       clockOut: roundedOut,
  //       duration,
  //     };
  //   });

  //   let overNightClocks = [];
  //   for (let i = 0; i < roundBatch.length; i++) {
  //     if (!dayjs(roundBatch[i].clockIn).isSame(roundBatch[i].clockOut, "day")) {
  //       const endClockInDay = new Date(
  //         new Date(roundBatch[i].clockIn).setHours(23, 59, 59, 59)
  //       ).toISOString();
  //       const startClockOutDay = new Date(
  //         new Date(roundBatch[i].clockOut).setHours(0, 0, 0)
  //       ).toISOString();
  //       const clockOut = roundBatch[i].clockOut;
  //       roundBatch[i].clockOut = endClockInDay;
  //       roundBatch[i].duration = parseFloat(
  //         dayjs(endClockInDay).diff(dayjs(roundBatch[i].clockIn)) / 3600000
  //       ).toFixed(2);
  //       overNightClocks.push({
  //         ...roundBatch[i],
  //         clockIn: startClockOutDay,
  //         clockOut,
  //         day: `${new Date(startClockOutDay).getMonth() + 1}/${new Date(
  //           startClockOutDay
  //         ).getDate()}/${new Date(startClockOutDay).getFullYear()}`,
  //         duration: parseFloat(
  //           dayjs(clockOut).diff(dayjs(startClockOutDay)) / 3600000
  //         ).toFixed(2),
  //       });
  //     }
  //   }
  //   overNightClocks.length > 0 && roundBatch.push(...overNightClocks);
  //   const sortedEmployeeByDay = _.chain(roundBatch)
  //     .groupBy("userId")
  //     .map((value, key) => ({
  //       user: key,
  //       week: _.chain(value)
  //         .groupBy("day")
  //         .map((value, key) => ({ date: key, day: value }))
  //         .value(),
  //     }))
  //     .value();
  //   const processBatch = sortedEmployeeByDay.map(({ week }) => {
  //     const weekTotal = week.reduce((prev, cur, weekIndex) => {
  //       return (
  //         prev +
  //         cur.day.reduce(
  //           (last, now, dayIndex) =>
  //             last +
  //             (last + parseFloat(now.duration) >= 6 &&
  //             week[weekIndex].day.length === dayIndex + 1
  //               ? parseFloat(now.duration) - 0.5
  //               : parseFloat(now.duration)),
  //           0
  //         )
  //       );
  //     }, 0);
  //     const totalOT = weekTotal - 40;
  //     let runningHourlyTotal = 0;
  //     let runningOTTotal = 0;
  //     return week.map(({ day }, clockInDayIndex) => {
  //       day.sort((a, b) => new Date(a.clockIn) - new Date(b.clockIn));
  //       const dayTotal = day.reduce((prev, cur, index) => {
  //         return (
  //           prev +
  //           (prev >= 6 && day.length === index + 1
  //             ? parseFloat(cur.duration) - 0.5
  //             : parseFloat(cur.duration))
  //         );
  //       }, 0);
  //       if (weekTotal > 40) {
  //         const payTypeClockActions = [];
  //         for (let clock = 0; clock < day.length; clock++) {
  //           if (
  //             (day.length === clock + 1 && dayTotal >= 6
  //               ? parseFloat(day[clock].duration) - 0.5
  //               : parseFloat(day[clock].duration)) +
  //               runningHourlyTotal -
  //               runningOTTotal >
  //             8 * (clockInDayIndex + 1)
  //           ) {
  //             if (
  //               runningHourlyTotal - runningOTTotal >=
  //               8 * (clockInDayIndex + 1)
  //             ) {
  //               payTypeClockActions.push({
  //                 userId: day[clock].userId,
  //                 jobId: day[clock].jobId,
  //                 clockActionId: day[clock].clockActionId,
  //                 clockIn: day[clock].clockIn,
  //                 clockOut:
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? dayjs(day[clock].clockOut)
  //                         .subtract(30, "minutes")
  //                         .toISOString()
  //                     : day[clock].clockOut,
  //                 duration:
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? parseFloat(day[clock].duration) - 0.5
  //                     : parseFloat(day[clock].duration),
  //                 lunchRemoved:
  //                   day.length === clock + 1 && dayTotal >= 6 ? true : false,
  //                 payClass: "OT",
  //               });
  //               runningHourlyTotal =
  //                 day.length === clock + 1 && dayTotal >= 6
  //                   ? parseFloat(day[clock].duration) - 0.5 + runningHourlyTotal
  //                   : parseFloat(day[clock].duration) + runningHourlyTotal;
  //               runningOTTotal =
  //                 day.length === clock + 1 && dayTotal >= 6
  //                   ? parseFloat(day[clock].duration) - 0.5 + runningOTTotal
  //                   : parseFloat(day[clock].duration) + runningOTTotal;
  //             } else {
  //               if (runningOTTotal >= totalOT) {
  //                 payTypeClockActions.push({
  //                   userId: day[clock].userId,
  //                   jobId: day[clock].jobId,
  //                   clockActionId: day[clock].clockActionId,
  //                   clockIn: day[clock].clockIn,
  //                   clockOut:
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? dayjs(day[clock].clockOut)
  //                           .subtract(30, "minutes")
  //                           .toISOString()
  //                       : day[clock].clockOut,
  //                   duration:
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(day[clock].duration) - 0.5
  //                       : parseFloat(day[clock].duration),
  //                   lunchRemoved:
  //                     day.length === clock + 1 && dayTotal >= 6 ? true : false,
  //                   payClass: "ST",
  //                 });
  //                 runningHourlyTotal =
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? parseFloat(day[clock].duration) -
  //                       0.5 +
  //                       runningHourlyTotal
  //                     : parseFloat(day[clock].duration) + runningHourlyTotal;
  //               } else if (
  //                 day.length === clock + 1 &&
  //                 week.length === clockInDayIndex + 2 &&
  //                 weekTotal -
  //                   (day.length === clock + 1 && dayTotal >= 6
  //                     ? parseFloat(day[clock].duration) -
  //                       0.5 +
  //                       runningHourlyTotal
  //                     : parseFloat(day[clock].duration) + runningHourlyTotal) <
  //                   8
  //               ) {
  //                 const timeST = parseFloat(
  //                   8 * (clockInDayIndex + 1) +
  //                     runningOTTotal -
  //                     runningHourlyTotal
  //                 ).toFixed(2);
  //                 const hours = timeST.split(".");
  //                 const timeOT =
  //                   runningHourlyTotal +
  //                   parseFloat(day[clock].duration) -
  //                   runningOTTotal -
  //                   8 * (clockInDayIndex + 1);
  //                 const newInOut = dayjs(day[clock].clockIn)
  //                   .add(parseInt(hours[0]), "hours")
  //                   .add((parseInt(hours[1]) / 100) * 60, "minutes")
  //                   .toISOString();
  //                 payTypeClockActions.push({
  //                   userId: day[clock].userId,
  //                   jobId: day[clock].jobId,
  //                   clockActionId: day[clock].clockActionId,
  //                   clockIn: day[clock].clockIn,
  //                   clockOut: newInOut,
  //                   duration: parseFloat(timeST),
  //                   lunchRemoved: false,
  //                 });
  //                 payTypeClockActions.push({
  //                   userId: day[clock].userId,
  //                   jobId: day[clock].jobId,
  //                   clockActionId: day[clock].clockActionId,
  //                   clockIn: newInOut,
  //                   clockOut:
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? dayjs(day[clock].clockOut)
  //                           .subtract(30, "minutes")
  //                           .toISOString()
  //                       : day[clock].clockOut,
  //                   duration:
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(timeOT) - 0.5
  //                       : parseFloat(timeOT),
  //                   lunchRemoved:
  //                     day.length === clock + 1 && dayTotal >= 6 ? true : false,
  //                   payClass: "OT",
  //                 });
  //                 runningHourlyTotal =
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? parseFloat(day[clock].duration) -
  //                       0.5 +
  //                       runningHourlyTotal
  //                     : parseFloat(day[clock].duration) + runningHourlyTotal;
  //                 runningOTTotal =
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? parseFloat(timeOT) - 0.5 + runningOTTotal
  //                     : parseFloat(timeOT) + runningOTTotal;
  //               } else {
  //                 const timeST = parseFloat(
  //                   8 * (clockInDayIndex + 1) +
  //                     runningOTTotal -
  //                     runningHourlyTotal
  //                 ).toFixed(2);
  //                 const hours = timeST.split(".");
  //                 const timeOT =
  //                   runningHourlyTotal +
  //                   parseFloat(day[clock].duration) -
  //                   runningOTTotal -
  //                   8 * (clockInDayIndex + 1);
  //                 let newInOut = dayjs(day[clock].clockIn)
  //                   .add(parseInt(hours[0]), "hours")
  //                   .add((parseInt(hours[1]) / 100) * 60, "minutes")
  //                   .toISOString();
  //                 if (
  //                   runningOTTotal +
  //                     (day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(timeOT) - 0.5
  //                       : parseFloat(timeOT)) >=
  //                   totalOT
  //                 ) {
  //                   const extraOT = Math.abs(
  //                     totalOT -
  //                       runningOTTotal -
  //                       (day.length === clock + 1 && dayTotal >= 6
  //                         ? parseFloat(timeOT) - 0.5
  //                         : parseFloat(timeOT))
  //                   );
  //                   newInOut = dayjs(newInOut)
  //                     .add(extraOT, "hours")
  //                     .toISOString();
  //                   payTypeClockActions.push({
  //                     userId: day[clock].userId,
  //                     jobId: day[clock].jobId,
  //                     clockActionId: day[clock].clockActionId,
  //                     clockIn: day[clock].clockIn,
  //                     clockOut: newInOut,
  //                     duration: parseFloat(timeST) + extraOT,
  //                     lunchRemoved: false,
  //                     payClass: "ST",
  //                   });
  //                   payTypeClockActions.push({
  //                     userId: day[clock].userId,
  //                     jobId: day[clock].jobId,
  //                     clockActionId: day[clock].clockActionId,
  //                     clockIn: newInOut,
  //                     clockOut:
  //                       day.length === clock + 1 && dayTotal >= 6
  //                         ? dayjs(day[clock].clockOut)
  //                             .subtract(30, "minutes")
  //                             .toISOString()
  //                         : day[clock].clockOut,
  //                     duration:
  //                       day.length === clock + 1 && dayTotal >= 6
  //                         ? parseFloat(timeOT - extraOT) - 0.5
  //                         : parseFloat(timeOT - extraOT),
  //                     lunchRemoved:
  //                       day.length === clock + 1 && dayTotal >= 6
  //                         ? true
  //                         : false,
  //                     payClass: "OT",
  //                   });
  //                   runningHourlyTotal =
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(day[clock].duration) -
  //                         0.5 +
  //                         runningHourlyTotal
  //                       : parseFloat(day[clock].duration) + runningHourlyTotal;
  //                   runningOTTotal =
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(timeOT) - 0.5 + runningOTTotal
  //                       : parseFloat(timeOT) + runningOTTotal;
  //                 } else {
  //                   payTypeClockActions.push({
  //                     userId: day[clock].userId,
  //                     jobId: day[clock].jobId,
  //                     clockActionId: day[clock].clockActionId,
  //                     clockIn: day[clock].clockIn,
  //                     clockOut: newInOut,
  //                     duration: parseFloat(timeST),
  //                     lunchRemoved: false,
  //                   });
  //                   payTypeClockActions.push({
  //                     userId: day[clock].userId,
  //                     jobId: day[clock].jobId,
  //                     clockActionId: day[clock].clockActionId,
  //                     clockIn: newInOut,
  //                     clockOut:
  //                       day.length === clock + 1 && dayTotal >= 6
  //                         ? dayjs(day[clock].clockOut)
  //                             .subtract(30, "minutes")
  //                             .toISOString()
  //                         : day[clock].clockOut,
  //                     duration:
  //                       day.length === clock + 1 && dayTotal >= 6
  //                         ? parseFloat(timeOT) - 0.5
  //                         : parseFloat(timeOT),
  //                     lunchRemoved:
  //                       day.length === clock + 1 && dayTotal >= 6
  //                         ? true
  //                         : false,
  //                     payClass: "OT",
  //                   });
  //                   runningHourlyTotal =
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(day[clock].duration) -
  //                         0.5 +
  //                         runningHourlyTotal
  //                       : parseFloat(day[clock].duration) + runningHourlyTotal;
  //                   runningOTTotal =
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(timeOT) - 0.5 + runningOTTotal
  //                       : parseFloat(timeOT) + runningOTTotal;
  //                 }
  //               }
  //             }
  //           } else {
  //             if (
  //               day.length === clock + 1 &&
  //               week.length === clockInDayIndex + 1 &&
  //               clockInDayIndex * 8 - (runningHourlyTotal - runningOTTotal) <
  //                 (day.length === clock + 1 && dayTotal >= 6
  //                   ? parseFloat(day[clock].duration) - 0.5 + runningHourlyTotal
  //                   : parseFloat(day[clock].duration) + runningHourlyTotal)
  //             ) {
  //               const timeST = parseFloat(
  //                 8 * clockInDayIndex + runningOTTotal - runningHourlyTotal
  //               ).toFixed(2);
  //               const hours = timeST.split(".");
  //               const timeOT = parseFloat(day[clock].duration) - timeST;
  //               const newInOut = dayjs(day[clock].clockIn)
  //                 .add(parseInt(hours[0]), "hours")
  //                 .add((parseInt(hours[1]) / 100) * 60, "minutes")
  //                 .toISOString();
  //               timeST > 0 &&
  //                 payTypeClockActions.push({
  //                   userId: day[clock].userId,
  //                   jobId: day[clock].jobId,
  //                   clockActionId: day[clock].clockActionId,
  //                   clockIn: day[clock].clockIn,
  //                   clockOut: newInOut,
  //                   duration: parseFloat(timeST),
  //                   payClass: "ST",
  //                   lunchRemoved: false,
  //                 });
  //               timeOT > 0 &&
  //                 payTypeClockActions.push({
  //                   userId: day[clock].userId,
  //                   jobId: day[clock].jobId,
  //                   clockActionId: day[clock].clockActionId,
  //                   clockIn: newInOut,
  //                   clockOut:
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? dayjs(day[clock].clockOut)
  //                           .subtract(30, "minutes")
  //                           .toISOString()
  //                       : day[clock].clockOut,
  //                   duration:
  //                     day.length === clock + 1 && dayTotal >= 6
  //                       ? parseFloat(timeOT) - 0.5
  //                       : parseFloat(timeOT),
  //                   lunchRemoved:
  //                     day.length === clock + 1 && dayTotal >= 6 ? true : false,
  //                   payClass: "OT",
  //                 });
  //               runningHourlyTotal =
  //                 day.length === clock + 1 && dayTotal >= 6
  //                   ? parseFloat(day[clock].duration) - 0.5 + runningHourlyTotal
  //                   : parseFloat(day[clock].duration) + runningHourlyTotal;
  //               runningOTTotal =
  //                 day.length === clock + 1 && dayTotal >= 6
  //                   ? parseFloat(timeOT) - 0.5 + runningOTTotal
  //                   : parseFloat(timeOT) + runningOTTotal;
  //             } else {
  //               payTypeClockActions.push({
  //                 userId: day[clock].userId,
  //                 jobId: day[clock].jobId,
  //                 clockActionId: day[clock].clockActionId,
  //                 clockIn: day[clock].clockIn,
  //                 clockOut:
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? dayjs(day[clock].clockOut)
  //                         .subtract(30, "minutes")
  //                         .toISOString()
  //                     : day[clock].clockOut,
  //                 duration:
  //                   day.length === clock + 1 && dayTotal >= 6
  //                     ? parseFloat(day[clock].duration) - 0.5
  //                     : parseFloat(day[clock].duration),
  //                 lunchRemoved:
  //                   day.length === clock + 1 && dayTotal >= 6 ? true : false,
  //                 payClass: runningHourlyTotal >= 40 ? "OT" : "ST",
  //               });
  //               runningHourlyTotal =
  //                 day.length === clock + 1 && dayTotal >= 6
  //                   ? parseFloat(day[clock].duration) - 0.5 + runningHourlyTotal
  //                   : parseFloat(day[clock].duration) + runningHourlyTotal;
  //               // runningOTTotal =
  //               //   runningHourlyTotal >= 40
  //               //     ? day.length === clock + 1 && dayTotal >= 6
  //               //       ? parseFloat(day[clock].duration) - 0.5 + runningOTTotal
  //               //       : parseFloat(day[clock].duration) + runningOTTotal
  //               //     : runningOTTotal;
  //             }
  //           }
  //         }
  //         return payTypeClockActions;
  //       } else {
  //         return day.map(
  //           (
  //             { clockActionId, clockIn, clockOut, duration, userId, jobId },
  //             i,
  //             row
  //           ) => {
  //             return {
  //               userId,
  //               jobId,
  //               clockActionId,
  //               clockIn,
  //               clockOut:
  //                 dayTotal >= 6 && row.length === i + 1
  //                   ? dayjs(clockOut).subtract(30, "minutes").toISOString()
  //                   : clockOut,
  //               duration:
  //                 dayTotal >= 6 && row.length === i + 1
  //                   ? parseFloat(duration - 0.5)
  //                   : parseFloat(duration),
  //               lunchRemoved:
  //                 dayTotal >= 6 && row.length === i + 1 ? true : false,
  //               payClass: "ST",
  //             };
  //           }
  //         );
  //       }
  //     });
  //   });
  //   const finishedBatch = _.flattenDeep(processBatch);
  //   finishedBatch.sort((a, b) => new Date(a.clockIn) - new Date(b.clockIn));
  //   const requestOptions = {
  //     method: "POST",
  //     withCredentials: true,
  //     credentials: "include",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(finishedBatch),
  //   };
  //   await fetch(`${API_URL}/process/timerecords`, requestOptions)
  //     .then((response) => response.json())
  //     .then(() => {
  //       const updatedClockActions = state.clockActions.filter((item) =>
  //         state.selected.every((day) => day !== item.id)
  //       );
  //       setState((prevState) => ({
  //         ...prevState,
  //         clockActions: updatedClockActions,
  //         selected: [],
  //         selectedTotal: [],
  //       }));
  //       setOpenSelectAll(false);
  //     })
  //     .then(async () => {
  //       const getRequestOptions = {
  //         method: "GET",
  //         withCredentials: true,
  //         credentials: "include",
  //         headers: { "Content-Type": "application/json" },
  //       };
  //       await fetch(
  //         `${API_URL}/timerecords/${startDate.toISOString()}/${endDate.toISOString()}`,
  //         getRequestOptions
  //       )
  //         .then((res) => res.json())
  //         .then((json) =>
  //           setState((prevState) => {
  //             const timeRecords = _.chain(json)
  //               .groupBy("name")
  //               .map((value, key) => ({ name: key, timeRecords: value }))
  //               .value();
  //             return {
  //               ...prevState,
  //               timeRecords: timeRecords.sort((a, b) => {
  //                 if (
  //                   a.timeRecords[0].user.lastName.toLowerCase() <
  //                   b.timeRecords[0].user.lastName.toLowerCase()
  //                 ) {
  //                   return -1;
  //                 }
  //                 if (
  //                   a.timeRecords[0].user.lastName.toLowerCase() >
  //                   b.timeRecords[0].user.lastName.toLowerCase()
  //                 ) {
  //                   return 1;
  //                 }
  //                 return 0;
  //               }),
  //               processedTimeUserShow: timeRecords.map(() => false),
  //             };
  //           })
  //         );
  //     });
  // };

  const processTime = async () => {
    const roundBatch = state.selected.map((item) => {
      // TODO - remove clockAction if PTO SICK or HOL and add to new array
      const action = state.clockActions.filter((clock) => item === clock.id);
      const roundedIn = roundToNearestMinutes(new Date(action[0].clockIn), {
        nearestTo: 15,
      }).toISOString();
      const roundedOut = roundToNearestMinutes(new Date(action[0].clockOut), {
        nearestTo: 15,
      }).toISOString();
      const duration = parseFloat(
        dayjs(roundedOut).diff(dayjs(roundedIn)) / 3600000
      ).toFixed(2);
      return {
        userId: action[0].userId,
        jobId: action[0].jobId,
        day: `${new Date(roundedIn).getMonth() + 1}/${new Date(
          roundedIn
        ).getDate()}/${new Date(roundedIn).getFullYear()}`,
        clockActionId: action[0].id,
        clockIn: roundedIn,
        clockOut: roundedOut,
        duration,
      };
    });

    let overNightClocks = [];
    for (let i = 0; i < roundBatch.length; i++) {
      if (!dayjs(roundBatch[i].clockIn).isSame(roundBatch[i].clockOut, "day")) {
        const endClockInDay = new Date(
          new Date(roundBatch[i].clockIn).setHours(23, 59, 59, 59)
        ).toISOString();
        const startClockOutDay = new Date(
          new Date(roundBatch[i].clockOut).setHours(0, 0, 0)
        ).toISOString();
        const clockOut = roundBatch[i].clockOut;
        roundBatch[i].clockOut = endClockInDay;
        roundBatch[i].duration = parseFloat(
          dayjs(endClockInDay).diff(dayjs(roundBatch[i].clockIn)) / 3600000
        ).toFixed(2);
        overNightClocks.push({
          ...roundBatch[i],
          clockIn: startClockOutDay,
          clockOut,
          day: `${new Date(startClockOutDay).getMonth() + 1}/${new Date(
            startClockOutDay
          ).getDate()}/${new Date(startClockOutDay).getFullYear()}`,
          duration: parseFloat(
            dayjs(clockOut).diff(dayjs(startClockOutDay)) / 3600000
          ).toFixed(2),
        });
      }
    }
    overNightClocks.length > 0 && roundBatch.push(...overNightClocks);
    const sortedEmployeeByDay = _.chain(roundBatch)
      .groupBy("userId")
      .map((value, key) => ({
        user: key,
        week: _.chain(value)
          .groupBy("day")
          .map((value, key) => ({ date: key, day: value }))
          .value(),
      }))
      .value();
    const removedLunch = sortedEmployeeByDay.map(({ week, user }) => {
      return {
        user,
        week: week.map(({ day, date }) => {
          day.sort((a, b) => new Date(a.clockIn) - new Date(b.clockIn));
          const dayTotal = day.reduce((prev, cur, index) => {
            return (
              prev +
              (prev >= 6 && day.length === index + 1
                ? parseFloat(cur.duration) - 0.5
                : parseFloat(cur.duration))
            );
          }, 0);
          if (dayTotal >= 6) {
            const test = day.map((dayClock, dayClockIndex) => ({
              ...dayClock,
              clockOut:
                day.length === dayClockIndex + 1
                  ? dayjs(dayClock.clockOut)
                      .subtract(30, "minutes")
                      .toISOString()
                  : dayClock.clockOut,
              duration:
                day.length === dayClockIndex + 1
                  ? parseFloat(dayClock.duration) - 0.5
                  : parseFloat(dayClock.duration),
              lunchRemoved: true,
            }));
            return { date, day: test };
          } else {
            const test = day.map((dayClock) => ({
              ...dayClock,
              duration: parseFloat(dayClock.duration),
              lunchRemoved: false,
            }));
            return { date, day: test };
          }
        }),
      };
    });
    const processBatch = removedLunch.map(({ week }) => {
      const weekTotal = week.reduce((prev, cur) => {
        return prev + cur.day.reduce((last, now) => last + now.duration, 0);
      }, 0);
      const totalOT = weekTotal - 40;
      let runningSTTotal = 0;
      let runningOTTotal = 0;
      return week.map(({ day }, clockInDayIndex) => {
        day.sort((a, b) => new Date(a.clockIn) - new Date(b.clockIn));
        const dayTotal = day.reduce((prev, cur) => {
          return prev + cur.duration;
        }, 0);
        if (weekTotal > 40) {
          const payTypeClockActions = [];
          let runningDayTotal = 0;
          for (let clock = 0; clock < day.length; clock++) {
            if (runningSTTotal === 40) {
              payTypeClockActions.push({
                userId: day[clock].userId,
                jobId: day[clock].jobId,
                clockActionId: day[clock].clockActionId,
                clockIn: day[clock].clockIn,
                clockOut: day[clock].clockOut,
                duration: day[clock].duration,
                lunchRemoved: day[clock].lunchRemoved,
                payClass: "OT",
              });
              runningOTTotal = day[clock].duration + runningOTTotal;
              runningDayTotal = day[clock].duration + runningDayTotal;
            } else if (totalOT === runningOTTotal) {
              payTypeClockActions.push({
                userId: day[clock].userId,
                jobId: day[clock].jobId,
                clockActionId: day[clock].clockActionId,
                clockIn: day[clock].clockIn,
                clockOut: day[clock].clockOut,
                duration: day[clock].duration,
                lunchRemoved: day[clock].lunchRemoved,
                payClass: "ST",
              });
              runningSTTotal = day[clock].duration + runningSTTotal;
              runningDayTotal = day[clock].duration + runningDayTotal;
            } else if (
              day[clock].duration + runningSTTotal > 40 &&
              clockInDayIndex + 1 > 5 &&
              runningOTTotal < totalOT
            ) {
              let timeST = 40 - runningSTTotal;
              let timeOT = day[clock].duration - timeST;
              if (timeOT + runningOTTotal > totalOT) {
                const diff = timeOT + runningOTTotal - totalOT;
                timeST = timeST + diff;
                timeOT = timeOT - diff;
              }
              const newClockTime = dayjs(day[clock].clockIn)
                .add(timeST, "hours")
                .toISOString();
              payTypeClockActions.push({
                userId: day[clock].userId,
                jobId: day[clock].jobId,
                clockActionId: day[clock].clockActionId,
                clockIn: day[clock].clockIn,
                clockOut: newClockTime,
                duration: timeST,
                lunchRemoved: day[clock].lunchRemoved,
                payClass: "ST",
              });
              payTypeClockActions.push({
                userId: day[clock].userId,
                jobId: day[clock].jobId,
                clockActionId: day[clock].clockActionId,
                clockIn: newClockTime,
                clockOut: day[clock].clockOut,
                duration: timeOT,
                lunchRemoved: day[clock].lunchRemoved,
                payClass: "OT",
              });
              runningSTTotal = timeST + runningSTTotal;
              runningOTTotal = timeOT + runningOTTotal;
              runningDayTotal = day[clock].duration + runningDayTotal;
            } else {
              if (
                dayTotal > 8 &&
                day[clock].duration + runningDayTotal > 8 &&
                clockInDayIndex + 1 <= 5 &&
                runningOTTotal < totalOT
              ) {
                if (runningDayTotal > 8 && weekTotal > 40) {
                  payTypeClockActions.push({
                    userId: day[clock].userId,
                    jobId: day[clock].jobId,
                    clockActionId: day[clock].clockActionId,
                    clockIn: day[clock].clockIn,
                    clockOut: day[clock].clockOut,
                    duration: day[clock].duration,
                    lunchRemoved: day[clock].lunchRemoved,
                    payClass: "OT",
                  });
                  runningOTTotal = day[clock].duration + runningOTTotal;
                  runningDayTotal = day[clock].duration + runningDayTotal;
                } else {
                  let timeST = 8 - runningDayTotal;
                  let timeOT = runningDayTotal + day[clock].duration - 8;
                  if (timeOT + runningOTTotal > totalOT) {
                    const diff = timeOT + runningOTTotal - totalOT;
                    timeST = timeST + diff;
                    timeOT = timeOT - diff;
                  }
                  const newClockTime = dayjs(day[clock].clockIn)
                    .add(timeST, "hours")
                    .toISOString();
                  payTypeClockActions.push({
                    userId: day[clock].userId,
                    jobId: day[clock].jobId,
                    clockActionId: day[clock].clockActionId,
                    clockIn: day[clock].clockIn,
                    clockOut: newClockTime,
                    duration: timeST,
                    lunchRemoved: day[clock].lunchRemoved,
                    payClass: "ST",
                  });
                  payTypeClockActions.push({
                    userId: day[clock].userId,
                    jobId: day[clock].jobId,
                    clockActionId: day[clock].clockActionId,
                    clockIn: newClockTime,
                    clockOut: day[clock].clockOut,
                    duration: timeOT,
                    lunchRemoved: day[clock].lunchRemoved,
                    payClass: "OT",
                  });
                  runningSTTotal = timeST + runningSTTotal;
                  runningOTTotal = timeOT + runningOTTotal;
                  runningDayTotal = day[clock].duration + runningDayTotal;
                }
              } else {
                payTypeClockActions.push({
                  userId: day[clock].userId,
                  jobId: day[clock].jobId,
                  clockActionId: day[clock].clockActionId,
                  clockIn: day[clock].clockIn,
                  clockOut: day[clock].clockOut,
                  duration: day[clock].duration,
                  lunchRemoved: day[clock].lunchRemoved,
                  payClass: "ST",
                });
                runningSTTotal = day[clock].duration + runningSTTotal;
                runningDayTotal = day[clock].duration + runningDayTotal;
              }
            }
          }
          return payTypeClockActions;
        } else {
          return day.map(
            ({
              clockActionId,
              clockIn,
              clockOut,
              duration,
              userId,
              jobId,
              lunchRemoved,
            }) => {
              return {
                userId,
                jobId,
                clockActionId,
                clockIn,
                clockOut,
                duration: parseFloat(duration),
                lunchRemoved,
                payClass: "ST",
              };
            }
          );
        }
      });
    });
    const finishedBatch = _.flattenDeep(processBatch);
    finishedBatch.sort((a, b) => new Date(a.clockIn) - new Date(b.clockIn));
    const ratedBatch = finishedBatch.map((record) => ({
      ...record,
      rateX100:
        hourlyRates.find(
          (employeePayRate) => employeePayRate.id === record.userId
        ).payRate * 100,
    }));

    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ratedBatch),
    };
    await fetch(`${API_URL}/process/timerecords`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        const updatedClockActions = state.clockActions.filter((item) =>
          state.selected.every((day) => day !== item.id)
        );
        setState((prevState) => ({
          ...prevState,
          clockActions: updatedClockActions,
          selected: [],
          selectedTotal: [],
        }));
        setOpenSelectAll(false);
      })
      .then(async () => {
        const getRequestOptions = {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(
          `${API_URL}/timerecords/${startDate.toISOString()}/${endDate.toISOString()}`,
          getRequestOptions
        )
          .then((res) => res.json())
          .then((json) =>
            setState((prevState) => {
              const timeRecords = _.chain(json)
                .groupBy("name")
                .map((value, key) => ({ name: key, timeRecords: value }))
                .value();
              return {
                ...prevState,
                timeRecords: timeRecords.sort((a, b) => {
                  if (
                    a.timeRecords[0].user.lastName.toLowerCase() <
                    b.timeRecords[0].user.lastName.toLowerCase()
                  ) {
                    return -1;
                  }
                  if (
                    a.timeRecords[0].user.lastName.toLowerCase() >
                    b.timeRecords[0].user.lastName.toLowerCase()
                  ) {
                    return 1;
                  }
                  return 0;
                }),
                processedTimeUserShow: timeRecords.map(() => false),
              };
            })
          );
      });
  };

  const unProcessTime = async () => {
    const allTimeRecords = state.timeRecords.map(
      ({ timeRecords }) => timeRecords
    );
    const flattenedTimeRecords = allTimeRecords.flat();
    const updatedClockActionIds = flattenedTimeRecords.flatMap((item) =>
      state.processedSelected.includes(item.id) ? item.clockActionId : []
    );
    const updatedTimeRecords = state.timeRecords.map((employee) => {
      return {
        ...employee,
        timeRecords: employee.timeRecords.filter((item) =>
          state.processedSelected.every((id) => id !== item.id)
        ),
      };
    });
    const filteredUpdatedTimeRecords = updatedTimeRecords.filter(
      (employee) => employee.timeRecords.length > 0
    );
    const requestOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        updateIds: updatedClockActionIds,
        deleteIds: state.processedSelected,
      }),
    };
    await fetch(`${API_URL}/delete/timerecords`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          timeRecords: filteredUpdatedTimeRecords,
          processedSelected: [],
          processedSelectedTotal: [],
        }));
        setClosedSelectAll(false);
      })
      .then(async () => {
        const getRequestOptions = {
          method: "GET",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(
          `${API_URL}/clockactions/${startDate.toISOString()}/${endDate.toISOString()}`,
          getRequestOptions
        )
          .then((res) => res.json())
          .then((json) =>
            setState((prevState) => ({
              ...prevState,
              clockActions: _.orderBy(
                json,
                [
                  "user.lastName",
                  "user.firstName",
                  (item) => item.clockIn || item.clockOut,
                ],
                ["asc", "asc", "asc"]
              ),
            }))
          );
      });
  };

  const onSelect = (id) => {
    const clockAction = state.clockActions.filter(
      (clockAction) => clockAction.id === id
    );
    setState((prevState) => ({
      ...prevState,
      editTime: clockAction,
    }));
  };

  const onEditTime = (id, time) => {
    const index = state.clockActions.findIndex((time) => time.id === id);
    let newState = [...state.clockActions];
    newState[index] = {
      ...state.clockActions[index],
      ...time,
    };
    setState((prevState) => ({
      ...prevState,
      clockActions: newState,
    }));
  };

  const onDeleteTime = (id) => {
    setState((prevState) => ({
      ...prevState,
      clockActions: state.clockActions.filter((time) => time.id !== id),
    }));
  };

  const onDeleteBatch = async () => {
    const requestOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state.selected),
    };
    await fetch(`${API_URL}/delete/clockactions`, requestOptions)
      .then((res) => res.json())
      .then(() =>
        setState((prevState) => ({
          ...prevState,
          selected: [],
          clockActions: state.clockActions.filter(
            (time) => !prevState.selected.includes(time.id)
          ),
        }))
      );
  };

  const onDeselectTime = () => {
    setState((prevState) => ({
      ...prevState,
      editTime: "",
    }));
  };

  const onDeselectBatch = () => {
    setEditBatch([]);
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setStartDate(start);
    localStorage.setItem("startDate", start.toISOString());
    setEndDate(newEnd);
    localStorage.setItem("endDate", newEnd.toISOString());
    setFilters((prevState) => ({
      ...prevState,
      startDate: start.toISOString(),
      endDate: newEnd.toISOString(),
    }));
  };

  const onCheckBoxChange = (e, id, duration) => {
    if (showTimeType === "open") {
      const selected = state.selected;
      const selectedTotal = state.selectedTotal;
      if (e.target.checked) {
        selected.push(id);
        duration !== "-" ? selectedTotal.push(duration) : selectedTotal.push(0);
        setState((prevState) => ({
          ...prevState,
          selected,
          selectedTotal,
        }));
      } else {
        const newSelected = selected.filter((item) => item !== id);
        const selectedTotalIndex = selectedTotal.findIndex(
          (total) => total === duration
        );
        delete selectedTotal[selectedTotalIndex];
        setState((prevState) => ({
          ...prevState,
          selected: newSelected,
          selectedTotal,
        }));
      }
    } else {
      const processedSelected = state.processedSelected;
      const processedSelectedTotal = state.processedSelectedTotal;
      if (e.target.checked) {
        processedSelected.push(id);
        processedSelectedTotal.push(duration);
        setState((prevState) => ({
          ...prevState,
          processedSelected,
          processedSelectedTotal,
        }));
      } else {
        const newProcessedSelected = processedSelected.filter(
          (item) => item !== id
        );
        const processedSelectedTotalIndex = processedSelectedTotal.findIndex(
          (total) => total === duration
        );
        delete processedSelectedTotal[processedSelectedTotalIndex];
        setState((prevState) => ({
          ...prevState,
          processedSelected: newProcessedSelected,
          processedSelectedTotal,
        }));
      }
    }
  };

  const onCheckAll = (e) => {
    if (showTimeType === "open") {
      if (e.target.checked) {
        setState((prevState) => ({
          ...prevState,
          selected: state.clockActions.map((clockAction) => clockAction.id),
          selectedTotal: state.clockActions.map((clockAction) =>
            clockAction.clockOut && clockAction.clockIn
              ? dayjs(clockAction.clockOut).diff(dayjs(clockAction.clockIn)) /
                3600000
              : 0
          ),
        }));
        setOpenSelectAll(true);
      } else {
        setState((prevState) => ({
          ...prevState,
          selected: [],
          selectedTotal: [],
        }));
        setOpenSelectAll(false);
      }
    } else {
      if (e.target.checked) {
        setState((prevState) => {
          const allTimeRecords = _.flatten(
            state.timeRecords.map((item) => item.timeRecords)
          );
          return {
            ...prevState,
            processedSelected: allTimeRecords.map(
              (timeRecord) => timeRecord.id
            ),
            processedSelectedTotal: allTimeRecords.map(
              (timeRecord) => timeRecord.duration
            ),
          };
        });
        setClosedSelectAll(true);
      } else {
        setState((prevState) => ({
          ...prevState,
          processedSelected: [],
          processedSelectedTotal: [],
        }));
        setClosedSelectAll(false);
      }
    }
  };

  const handleShowEmployee = (index) => {
    setState((prevState) => {
      const show = [...prevState.processedTimeUserShow];
      show[index] = !show[index];
      return {
        ...prevState,
        processedTimeUserShow: [...show],
      };
    });
  };

  const onEmployeeCheckBoxChange = (e, name) => {
    const filterEmployee = state.timeRecords.filter(
      (record) => record.name === name
    );
    const selectId = filterEmployee[0].timeRecords.map(
      (timeRecord) => timeRecord.id
    );
    const selectDuration = filterEmployee[0].timeRecords.map(
      (timeRecord) => timeRecord.duration
    );
    if (e.target.checked) {
      setState((prevState) => ({
        ...prevState,
        processedSelected: [...prevState.processedSelected, ...selectId],
        processedSelectedTotal: [
          ...prevState.processedSelectedTotal,
          ...selectDuration,
        ],
      }));
    } else {
      let newTotal = state.processedSelectedTotal;
      for (let i = 0; i < selectDuration.length; i++) {
        newTotal.splice(
          newTotal.findIndex((item) => item === selectDuration[i]),
          1
        );
      }
      setState((prevState) => ({
        ...prevState,
        processedSelected: prevState.processedSelected.filter(
          (timeRecordId) => !selectId.includes(timeRecordId)
        ),

        processedSelectedTotal: [...newTotal],
      }));
    }
  };

  return (
    <div>
      {onToggleConfirm && (
        <ConfirmDelete
          setOnToggleConfirm={setOnToggleConfirm}
          onDeleteBatch={onDeleteBatch}
          selectedLength={state.selected.length}
        />
      )}
      {(state.editTime || editBatch.length > 0) && (
        <EditTimePanel
          editBatch={editBatch}
          editTime={state.editTime}
          onDeselectTime={onDeselectTime}
          onDeselectBatch={onDeselectBatch}
          onEditTime={onEditTime}
          onEditBatch={onEditBatch}
          onDeleteTime={onDeleteTime}
          setOnToggleConfirm={setOnToggleConfirm}
          allocateBatch={state.allocateBatch}
          onAllocateBatch={onAllocateBatch}
          onSubmitAllocate={onSubmitAllocate}
          jobs={jobs}
          tasks={tasks}
        />
      )}
      <TimeSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onDateChange={onDateChange}
        processTime={processTime}
        unProcessTime={unProcessTime}
        selected={state.selected}
        processedSelected={state.processedSelected}
        showTimeType={showTimeType}
        editMultiple={editMultiple}
        setOnToggleConfirm={setOnToggleConfirm}
      />
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <button
            onClick={() => setShowTimeType("open")}
            style={{
              width: "100%",
              backgroundColor:
                showTimeType === "open" ? "#032bdf" : "gainsboro",
              border: "none",
              padding: "5px",
              color: showTimeType === "open" ? "white" : "black",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Open
          </button>
        </div>
        <div style={{ width: "50%" }}>
          <button
            onClick={() => setShowTimeType("closed")}
            style={{
              width: "100%",
              backgroundColor:
                showTimeType === "closed" ? "#032bdf" : "gainsboro",
              border: "none",
              padding: "5px",
              color: showTimeType === "closed" ? "white" : "black",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Processed
          </button>
        </div>
      </div>
      {!loading &&
        (showTimeType === "open" ? (
          <DataTable>
            <tbody>
              <tr>
                <td>
                  <table className="tableTimeHead">
                    <thead>
                      <tr className="cell">
                        <th>
                          <input
                            type="checkbox"
                            style={{ transform: "scale(1.33)" }}
                            checked={openSelectAll}
                            onChange={(e) => {
                              onCheckAll(e);
                            }}
                          />
                        </th>
                        <th>Note</th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Dept
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          First
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Last
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Device
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Job
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Cost Code
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          In
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Out
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="tableBodyContainerTime">
                    <table className="tableBody">
                      <tbody>
                        {state.clockActions.map((rowData, index) => {
                          const duration =
                            rowData.clockOut && rowData.clockIn
                              ? dayjs(rowData.clockOut).diff(
                                  dayjs(rowData.clockIn)
                                ) / 3600000
                              : "-";
                          return (
                            <tr
                              key={rowData.id}
                              id={rowData.id}
                              className={
                                state.selected.includes(rowData.id)
                                  ? "highlighted"
                                  : undefined
                              }
                              style={{
                                color: `${
                                  rowData?.crewClock ? "blue" : "black"
                                }`,
                              }}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  className="check-box"
                                  checked={state.selected.includes(rowData.id)}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    onCheckBoxChange(e, rowData.id, duration);
                                  }}
                                />
                              </td>
                              <td>
                                {rowData.note ? (
                                  <FontAwesomeIcon icon={faStickyNote} />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData.user.department.name}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData.user.firstName}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData.user.lastName}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData?.device?.name
                                  ? rowData.device.name
                                  : "Website"}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                                style={{ textAlign: "left" }}
                              >
                                {rowData.job.jobNumber} - {rowData.job.jobName}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData.task.taskCode}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData.clockIn
                                  ? dayjs(rowData.clockIn).format(
                                      "M/D/YY h:mm A"
                                    )
                                  : "-"}
                              </td>
                              <td
                                onClick={() => {
                                  ctrlKey
                                    ? onCtrlSelect(rowData.id, duration, index)
                                    : shiftKey
                                    ? onShiftSelect(index)
                                    : onSelect(rowData.id);
                                }}
                              >
                                {rowData.clockOut
                                  ? dayjs(rowData.clockOut).format(
                                      "M/D/YY h:mm A"
                                    )
                                  : "-"}
                              </td>
                              {duration === "-" ? (
                                <td
                                  onClick={() => {
                                    ctrlKey
                                      ? onCtrlSelect(
                                          rowData.id,
                                          duration,
                                          index
                                        )
                                      : shiftKey
                                      ? onShiftSelect(index)
                                      : onSelect(rowData.id);
                                  }}
                                >
                                  -
                                </td>
                              ) : parseFloat(duration) < 12 ? (
                                <td
                                  onClick={() => {
                                    ctrlKey
                                      ? onCtrlSelect(
                                          rowData.id,
                                          duration,
                                          index
                                        )
                                      : shiftKey
                                      ? onShiftSelect(index)
                                      : onSelect(rowData.id);
                                  }}
                                >
                                  {parseFloat(duration).toFixed(2)}
                                </td>
                              ) : (
                                <td
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    ctrlKey
                                      ? onCtrlSelect(
                                          rowData.id,
                                          duration,
                                          index
                                        )
                                      : shiftKey
                                      ? onShiftSelect(index)
                                      : onSelect(rowData.id);
                                  }}
                                >
                                  {parseFloat(duration).toFixed(2)}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </DataTable>
        ) : (
          <DataTable>
            <tbody>
              <tr>
                <td>
                  <table className="tableProcessedTimeHead">
                    <thead>
                      <tr className="cell">
                        <th>
                          <input
                            type="checkbox"
                            style={{ transform: "scale(1.33)" }}
                            checked={closedSelectAll}
                            onChange={(e) => {
                              onCheckAll(e);
                            }}
                          />
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Dept
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          First
                        </th>
                        <th
                        // onClick={() => {onSort(column[1]);}}
                        >
                          Last
                        </th>

                        <th
                          // onClick={() => {onSort(column[1]);}}
                          style={{ width: "auto" }}
                        >
                          ST
                        </th>
                        <th
                          // onClick={() => {onSort(column[1]);}}
                          style={{ width: "auto" }}
                        >
                          OT
                        </th>
                        <th
                          style={{ width: "auto" }}
                          // onClick={() => {onSort(column[1]);}}
                        >
                          PTO
                        </th>
                        <th
                          style={{ width: "auto" }}
                          // onClick={() => {onSort(column[1]);}}
                        >
                          Hol
                        </th>
                        <th
                          style={{ width: "auto" }}
                          // onClick={() => {onSort(column[1]);}}
                        >
                          Sick
                        </th>
                        <th
                          style={{ width: "auto" }}
                          // onClick={() => {onSort(column[1]);}}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="tableBodyContainerProcessedTime">
                    <table className="tableBody">
                      {state.timeRecords.map((employee, index) => (
                        <tbody key={employee.name}>
                          <tr
                            style={{
                              background: "gainsboro",
                              border: "1px solid white",
                              height: "50px",
                              fontWeight: "bold",
                            }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                style={{ transform: "scale(1.33)" }}
                                checked={employee.timeRecords.every((record) =>
                                  state.processedSelected.includes(record.id)
                                )}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  onEmployeeCheckBoxChange(e, employee.name);
                                }}
                              />
                            </td>
                            <td onClick={() => handleShowEmployee(index)}>
                              {employee.timeRecords[0].user.department.name}
                            </td>
                            <td onClick={() => handleShowEmployee(index)}>
                              {employee.timeRecords[0].user.firstName}
                            </td>
                            <td onClick={() => handleShowEmployee(index)}>
                              {employee.timeRecords[0].user.lastName}
                            </td>
                            <td
                              onClick={() => handleShowEmployee(index)}
                              style={{ width: "auto" }}
                            >
                              {employee.timeRecords
                                .reduce(
                                  (prev, cur) =>
                                    prev +
                                    (cur.payClass === "ST"
                                      ? parseFloat(cur.duration)
                                      : 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                            <td
                              onClick={() => handleShowEmployee(index)}
                              style={{ width: "auto" }}
                            >
                              {employee.timeRecords
                                .reduce(
                                  (prev, cur) =>
                                    prev +
                                    (cur.payClass === "OT"
                                      ? parseFloat(cur.duration)
                                      : 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                            <td
                              onClick={() => handleShowEmployee(index)}
                              style={{ width: "auto" }}
                            >
                              {employee.timeRecords
                                .reduce(
                                  (prev, cur) =>
                                    prev +
                                    (cur.payClass === "PTO"
                                      ? parseFloat(cur.duration)
                                      : 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                            <td
                              onClick={() => handleShowEmployee(index)}
                              style={{ width: "auto" }}
                            >
                              {employee.timeRecords
                                .reduce(
                                  (prev, cur) =>
                                    prev +
                                    (cur.payClass === "HOL"
                                      ? parseFloat(cur.duration)
                                      : 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                            <td
                              onClick={() => handleShowEmployee(index)}
                              style={{ width: "auto" }}
                            >
                              {employee.timeRecords
                                .reduce(
                                  (prev, cur) =>
                                    prev +
                                    (cur.payClass === "SICK"
                                      ? parseFloat(cur.duration)
                                      : 0),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                            <td
                              onClick={() => handleShowEmployee(index)}
                              style={{ width: "auto" }}
                            >
                              {employee.timeRecords
                                .reduce(
                                  (prev, cur) =>
                                    prev + parseFloat(cur.duration),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10}>
                              {state.processedTimeUserShow[index] && (
                                <DataTable>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <table className="tableProcessedTimeHead">
                                          <thead>
                                            <tr className="cell">
                                              <th></th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                Dept
                                              </th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                First
                                              </th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                Last
                                              </th>

                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                Job
                                              </th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                In
                                              </th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                Out
                                              </th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                Pay Type
                                              </th>
                                              <th
                                              // onClick={() => {onSort(column[1]);}}
                                              >
                                                Total
                                              </th>
                                            </tr>
                                          </thead>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="tableBodyContainerProcessedTime">
                                          <table className="tableBody">
                                            <tbody
                                              style={{
                                                cursor: ctrlKey
                                                  ? "pointer"
                                                  : "default",
                                              }}
                                            >
                                              {employee.timeRecords.map(
                                                (rowData) => (
                                                  <tr
                                                    key={rowData.id}
                                                    id={rowData.id}
                                                    className={
                                                      state.processedSelected.includes(
                                                        rowData.id
                                                      )
                                                        ? "highlighted"
                                                        : undefined
                                                    }
                                                  >
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        className="check-box"
                                                        checked={state.processedSelected.includes(
                                                          rowData.id
                                                        )}
                                                        onChange={(e) => {
                                                          e.stopPropagation();
                                                          onCheckBoxChange(
                                                            e,
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {
                                                        rowData.user.department
                                                          .name
                                                      }
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.user.firstName}
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.user.lastName}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.job.jobNumber} -{" "}
                                                      {rowData.job.jobName}
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.clockIn
                                                        ? dayjs(
                                                            rowData.clockIn
                                                          ).format(
                                                            "M/D/YY h:mm A"
                                                          )
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.clockOut
                                                        ? dayjs(
                                                            rowData.clockOut
                                                          ).format(
                                                            "M/D/YY h:mm A"
                                                          )
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.payClass}
                                                    </td>
                                                    <td
                                                      onClick={() => {
                                                        ctrlKey &&
                                                          onCtrlSelect(
                                                            rowData.id,
                                                            rowData.duration
                                                          );
                                                      }}
                                                    >
                                                      {rowData.duration.toFixed(
                                                        2
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </DataTable>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </DataTable>
        ))}
      <TimeFooter
        count={
          showTimeType === "open"
            ? state.selected.length > 0
              ? state.selected.length
              : state.clockActions.length
            : state.processedSelected.length > 0
            ? state.processedSelected.length
            : _.flatten(state.timeRecords.map((item) => item.timeRecords))
                .length
        }
        countText={
          showTimeType === "open"
            ? state.selected.length === 0
              ? "Records"
              : "Selected"
            : state.processedSelected.length === 0
            ? "Records"
            : "Selected"
        }
        total={
          showTimeType === "open"
            ? state.selectedTotal.length > 0
              ? state.selectedTotal.reduce((prev, cur) => prev + cur, 0)
              : state.clockActions
                  .map((clockAction) =>
                    clockAction.clockOut && clockAction.clockIn
                      ? dayjs(clockAction.clockOut).diff(
                          dayjs(clockAction.clockIn)
                        ) / 3600000
                      : 0
                  )
                  .reduce((prev, cur) => prev + cur, 0)
            : state.processedSelectedTotal.length > 0
            ? state.processedSelectedTotal.reduce((prev, cur) => prev + cur, 0)
            : state.timeRecords.reduce(
                (prev, cur) =>
                  prev +
                  cur.timeRecords.reduce(
                    (last, now) => last + parseFloat(now.duration),
                    0
                  ),
                0
              )
        }
      />
    </div>
  );
};

export default Time;
