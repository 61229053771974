import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { startOfDay, endOfDay } from "date-fns";
import dayjs from "dayjs";
import styled from "styled-components";
import { API_URL } from "../../../env";

const WorkOrderTable = styled.table`
  width: 800px;
  border-spacing: 0px;
  border-collapse: collapse;
  border: 1px solid black;
  input,
  select {
    margin-left: 5px;
  }
  th,
  td {
    border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid black;
  }
  .job {
    width: 70%;
  }
  .date {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 3fr;
    div {
      input {
        width: 80px;
      }
    }
  }
  .complete {
  }
  .description {
    textarea {
      width: 98%;
      border: none;
      resize: none;
    }
  }
`;

const WorkOrder = () => {
  const [state, setState] = useState({
    user: "",
    jobId: "",
    date: new Date(),
    customerId: "",
    complete: false,
    workDescription: "",
    labor: [],
    foremanLabor: "",
    material: "",
    images: [],
  });

  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetLabor = (date) => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/clockactions/${startOfDay(date).toISOString()}/${endOfDay(
        date
      ).toISOString()}/${jobId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setState((prevState) => ({ ...prevState, labor: json })));
  };
  const handleDateChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      date: value,
    }));
    handleGetLabor(value);
  };
  const { jobId, date, customerId, complete, workDescription, labor } = state;
  return (
    <div>
      <WorkOrderTable>
        <tbody>
          <tr>
            <td className="title" colSpan={2}>
              <h1>Field Work Order</h1>
            </td>
          </tr>
          <tr>
            <td className="job">
              Project:
              <select
                value={jobId}
                name="jobId"
                id="jobId"
                required
                onChange={handleChange}
              >
                <option defaultValue>Select A Job</option>
                {jobs &&
                  jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.jobNumber} - {job.jobName}
                    </option>
                  ))}
              </select>
            </td>
            <td className="date">
              Date:
              <DatePicker selected={date} onChange={handleDateChange} />
            </td>
          </tr>
          <tr>
            <td className="customer">
              Customer:
              <select
                value={customerId}
                name="customerId"
                id="customerId"
                onChange={handleChange}
              >
                <option defaultValue>Select a Customer</option>
                {customers &&
                  customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
              </select>
            </td>
            <td className="complete">
              Complete:
              <input
                type="checkbox"
                checked={complete === true ? true : false}
                onChange={() => {
                  setState((prevState) => ({ ...prevState, complete: true }));
                }}
              />
              Y
              <input
                type="checkbox"
                checked={complete === false ? true : false}
                onChange={() => {
                  setState((prevState) => ({ ...prevState, complete: false }));
                }}
              />
              N
            </td>
          </tr>
          <tr>
            <th colSpan={2}>Description of Work</th>
          </tr>
          <tr>
            <td className="description" colSpan={2}>
              <textarea
                type="text"
                value={workDescription}
                name="workDescription"
                rows={10}
                onChange={(e) => handleChange(e)}
              />
            </td>
          </tr>
          <tr>
            <th>Labor</th>
            <th>Hours</th>
          </tr>
          {labor.length !== 0 ? (
            labor.map((row) => (
              <tr key={row.id}>
                <td>
                  {row.user.firstName} {row.user.lastName}
                </td>
                <td>
                  {(
                    dayjs(row.clockOut).diff(dayjs(row.clockIn)) / 3600000
                  ).toFixed(2)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td style={{ height: "25px" }}></td>
              <td></td>
            </tr>
          )}
          <tr>
            <th>Material</th>
            <th>Quantity</th>
          </tr>
          <tr>
            <td style={{ height: "25px" }}>
              <input
                name="material"
                type="text"
                style={{
                  width: "95%",
                  height: "95%",
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  border: "none",
                }}
                onChange={handleChange}
              />
            </td>
            <td>
              <button>-</button>
              <input
                name="material"
                type="number"
                style={{
                  height: "95%",
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  border: "none",
                }}
              />
              <button>+</button>
            </td>
          </tr>
          <tr>
            <th colSpan={2}>Images</th>
          </tr>
          <tr>
            <td style={{ height: "25px" }}></td>
            <td>Add Image</td>
          </tr>
        </tbody>
      </WorkOrderTable>
    </div>
  );
};

export default WorkOrder;
