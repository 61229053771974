import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, RedButton } from "../../styles/Style";
import { API_URL } from "../../env";

const ToolForm = styled.form`
  fieldset {
    display: grid;
    grid-template-columns: 2fr 3fr;
    border: none;
    label {
      font-weight: bold;
      text-align: center;
    }
  }
  .image {
    grid-column-start: 1;
  }
  .tool-image {
    width: auto;
    max-height: 350px;
  }
  .fields {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
  }
  .buttons {
    margin: 20px auto;
    button {
      margin: 10px 10px;
      text-transform: uppercase;
    }
    .delete-button {
      background: "red";
    }
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 65px;
    font-size: 1px;
    color: white;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .image-button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
`;

const SingleTool = ({
  editTool,
  onEditTool,
  onDeselectTool,
  employees,
  categories,
  manufacturers,
  locations,
}) => {
  const [state, setState] = useState({
    tool: { ...editTool[0] },
    updatedTool: "",
    error: "",
  });

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/tool/image/${editTool[0].id}`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setState((prevState) => ({
          ...prevState,
          tool: { ...prevState.tool, image: json.image },
        }))
      );
  }, [editTool[0].id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedTool: { ...prevState.updatedTool, [name]: value },
    }));
  };

  const handleOperationalChange = (e) => {
    const { checked, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedTool: { ...prevState.updatedTool, [name]: checked },
    }));
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setState((prevState) => ({
          ...prevState,
          updatedTool: { ...prevState.updatedTool, image: reader.result },
        }));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setPurchaseDate = (value) => {
    setState((prevState) => ({
      ...prevState,
      updatedTool: {
        ...prevState.updatedTool,
        purchaseDate: value,
      },
    }));
  };

  const { tool, updatedTool } = state;
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Edit Tool
        </h3>
        <ToolForm
          onSubmit={async (e) => {
            e.preventDefault();
            const requestOptions = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...updatedTool }),
            };
            await fetch(`${API_URL}/update/tool/${tool.id}`, requestOptions)
              .then((res) => res.json())
              .then(() => {
                onEditTool(tool.id, updatedTool);
                onDeselectTool();
              });
          }}
        >
          <fieldset>
            <div className="image">
              <label htmlFor="image">
                Image
                <br />
                {tool.image ? (
                  <div>
                    <img
                      src={updatedTool.image ? updatedTool.image : tool.image}
                      className="tool-image"
                    />
                    <ButtonDiv>
                      <div className="button-wrap">
                        <label className="image-button" htmlFor="file">
                          Update Picture
                        </label>
                        <input
                          id="file"
                          type="file"
                          name="myImage"
                          onChange={onChangePicture}
                        />
                      </div>
                    </ButtonDiv>
                  </div>
                ) : (
                  <div>
                    <img src={updatedTool.image} className="tool-image" />
                    <ButtonDiv>
                      <div className="button-wrap">
                        <label className="image-button" htmlFor="file">
                          Upload Picture
                        </label>
                        <input
                          id="file"
                          type="file"
                          name="myImage"
                          onChange={onChangePicture}
                        />
                      </div>
                    </ButtonDiv>
                  </div>
                )}
              </label>
            </div>
            <div className="fields">
              <label htmlFor="operational" className="operational">
                Operational
                <br />
                <input
                  type="checkbox"
                  name="operational"
                  id="operational"
                  checked={
                    state.updatedTool.operational === true ||
                    state.updatedTool.operational === false
                      ? state.updatedTool.operational
                      : tool.operational
                  }
                  onChange={(e) => handleOperationalChange(e)}
                />
              </label>
              <label htmlFor="name" className="name">
                Tool Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={tool.name}
                  onChange={(e) => handleChange(e)}
                  placeholder="Tool Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="description" className="description">
                Tool Description
                <br />
                <input
                  type="text"
                  name="description"
                  id="description"
                  defaultValue={tool.description}
                  onChange={(e) => handleChange(e)}
                  placeholder="Tool Description"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="model" className="model">
                Model
                <br />
                <input
                  type="text"
                  name="model"
                  id="model"
                  defaultValue={tool.model}
                  onChange={(e) => handleChange(e)}
                  placeholder="Model"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="serialNumber" className="serialNumber">
                Serial Number
                <br />
                <input
                  type="text"
                  name="serialNumber"
                  id="serialNumber"
                  defaultValue={tool.serialNumber}
                  onChange={(e) => handleChange(e)}
                  placeholder="Serial Number"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="scanCode" className="scanCode">
                Scan Code
                <br />
                <input
                  type="text"
                  name="scanCode"
                  id="scanCode"
                  defaultValue={tool.scanCode}
                  onChange={(e) => handleChange(e)}
                  placeholder="Scan Code*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="toolCategoryId" className="category">
                Category
                <br />
                <select
                  required
                  name="toolCategoryId"
                  id="toolCategoryId"
                  defaultValue={tool.toolCategoryId}
                  onChange={(e) => handleChange(e)}
                >
                  <option defaultValue>Select A Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="manufacturerId" className="manufacturer">
                Manufacturer
                <br />
                <select
                  required
                  name="manufacturerId"
                  id="manufacturerId"
                  defaultValue={tool.manufacturerId}
                  onChange={(e) => handleChange(e)}
                >
                  <option defaultValue>Select A Manufacturer</option>
                  {manufacturers.map((manufacturer) => (
                    <option key={manufacturer.id} value={manufacturer.id}>
                      {manufacturer.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="defaultLocationId" className="defaultLocation">
                Default Location
                <br />
                <select
                  required
                  name="defaultLocationId"
                  id="defaultLocationId"
                  defaultValue={tool.defaultLocationId}
                  onChange={(e) => handleChange(e)}
                >
                  <option defaultValue>Select A Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="currentLocationId" className="currentLocation">
                Current Location
                <br />
                <select
                  required
                  name="currentLocationId"
                  id="currentLocationId"
                  defaultValue={tool.currentLocationId}
                  onChange={(e) => handleChange(e)}
                >
                  <option defaultValue>Select A Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </label>
              <label
                htmlFor="responsibleEmployeeId"
                className="currentLocation"
              >
                Responsible Employee
                <br />
                <select
                  required
                  name="responsibleEmployeeId"
                  id="responsibleEmployeeId"
                  defaultValue={tool.responsibleEmployeeId}
                  onChange={(e) => handleChange(e)}
                >
                  <option defaultValue>Select An Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Purchase Date
                <DatePicker
                  selected={
                    updatedTool?.purchaseDate
                      ? new Date(updatedTool.purchaseDate)
                      : new Date(tool.purchaseDate)
                  }
                  onChange={(date) => setPurchaseDate(date)}
                />
              </label>
              {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
            </div>
          </fieldset>
          <div className="buttons">
            <RedButton>Delete Tool</RedButton>
            <Button type="submit">Save Tool</Button>
          </div>
        </ToolForm>
      </div>
    </div>
  );
};

SingleTool.propTypes = {
  id: PropTypes.string,
  editTool: PropTypes.array,
  onEditTool: PropTypes.func,
  onDeselectTool: PropTypes.func,
  manufacturers: PropTypes.array,
  employees: PropTypes.array,
  locations: PropTypes.array,
  categories: PropTypes.array,
};

export default SingleTool;
