import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Button } from "../../styles/Style";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../env";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const UploadTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [updates, setUpdates] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
  }, []);

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(d);
        });

        const data = valuesArray.map((value) => ({
          ...value,
          isPublic: value.isPublic === "TRUE" ? true : false,
        }));
        const filterTasks = (arr1, arr2) => {
          let resNew = [];
          let resUpdate = [];
          resNew = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.taskCode === el.taskCode;
            });
          });
          resUpdate = arr1.filter((el) => {
            return arr2.find((element) => {
              const criteria = [
                element.taskCode === el.taskCode,
                element.taskName === el.taskName,
              ];
              // console.log(criteria);
              // console.log(criteria.some((e) => e === true));
              return criteria.some((e) => e === true);
            });
          });
          return [resNew, resUpdate];
        };

        const newTasks = filterTasks(data, tasks);
        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(newTasks[0]);
        setUpdates(newTasks[1]);
      },
    });
  };
  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(`${API_URL}/upload/tasks`, requestOptions).then((res) => res.json());
    if (updates.length > 0) {
      const requestOptions2 = {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updates),
      };
      fetch(`${API_URL}/update/tasks`, requestOptions2).then((res) =>
        res.json()
      );
    }
    navigate("/costcodes");
  };

  return (
    <div>
      <h1>Upload Cost Codes</h1>
      <ButtonDiv>
        <div className="button-wrap">
          <label className="button" htmlFor="upload">
            Choose File
          </label>
          <input
            type={"file"}
            name="file"
            accept=".csv"
            onChange={(e) => handleFile(e)}
            className="upload"
            id="upload"
          />
        </div>
      </ButtonDiv>
      <div>
        {(values.length > 0 || updates.length > 0) && (
          <Button onClick={handleSubmit}>
            Add{updates.length > 1 && " and Update"} New Cost Code
            {values.length > 1 && "s"}
          </Button>
        )}
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {values && (
          <table>
            <thead>
              <tr>
                {(values.length > 1 || updates.length > 1) && <th>Type</th>}
                {tableRows.map((rows, index) => {
                  return <th key={index}>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {updates.map((update) => (
                <tr key={update.taskCode}>
                  <td>Update</td>
                  <td>{update.taskCode}</td>
                  <td>{update.taskName}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {update.isPublic.toString()}
                  </td>
                </tr>
              ))}
              {values.map((value) => (
                <tr key={value.taskCode}>
                  <td>New</td>
                  <td>{value.taskCode}</td>
                  <td>{value.taskName}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {value.isPublic.toString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadTasks;
