import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import Select from "react-select";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../../env";

const InfoTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  tr {
    height: 30px;
    text-align: left;
  }
  tr:nth-child(even) {
    background: #f5f5f5;
    input {
      background: #f5f5f5;
    }
    input:disabled {
      background: #f5f5f5;
    }
  }
  .task-info-cell {
    border: 1px solid #e8e8e8;
    height: 29px;
    padding: 0px;
    margin: 0px;
    input {
      height: 29px;
      padding: 0px 0px 0px 5px;
      margin: 0px;
      border: 0px;
    }
    .date-picker {
      width: 100px;
    }
    input:disabled {
      background: white;
      color: black;
    }
  }
`;

const ganttColumns = [
  { type: "string", label: "Task ID" },
  { type: "string", label: "Task Name" },
  { type: "string", label: "Phase" },
  { type: "date", label: "Start Date" },
  { type: "date", label: "End Date" },
  { type: "number", label: "Duration" },
  { type: "number", label: "Percent Complete" },
  { type: "string", label: "Dependencies" },
];

const ProjectSchedule = ({ jobId, estimateId }) => {
  const [columns] = useState(ganttColumns);
  const [rows, setRows] = useState([]);
  const [phases, setPhases] = useState([]);
  const [modify, setModify] = useState(false);
  const [mapBudget, setMapBudget] = useState(false);
  const [mappedScheduleTasks, setMappedScheduleTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phaseLoading, setPhaseLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/job/${jobId}/schedule`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.projectSchedule.length > 1) {
          setRows(
            json.projectSchedule
              .map((task) => [
                task.id,
                task.name,
                task.resource,
                new Date(task.startDate),
                new Date(task.endDate),
                null,
                0,
                null,
              ])
              .sort((a, b) => a[3] - b[3])
          );
          setMappedScheduleTasks(
            json.projectSchedule.map((task) => ({
              id: task.id,
              phases: [],
            }))
          );
        } else {
          setRows([["", "", "", new Date(), new Date(), null, 0, null]]);
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (mapBudget && phases.length < 1) {
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(`${API_URL}/estimate/${estimateId}/phases`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setPhases(json);
          setPhaseLoading(false);
        });
    }
  }, [mapBudget]);

  const handleCreateSchedule = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        rows.map((row) => ({
          name: row[1],
          resource: row[2],
          startDate: row[3].toISOString(),
          endDate: row[4].toISOString(),
          jobId,
          estimateId,
        }))
      ),
    };
    fetch(`${API_URL}/create/job-schedule`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => console.log(json));
  };

  const handleCreateMap = () => {
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        mappedScheduleTasks.map((task) => ({
          id: task.id,
          estimatedMaterial: phases
            .filter((phase) =>
              task.phases.map((taskPhase) => taskPhase.value).includes(phase.id)
            )
            .map((phase) => phase.materials.map((mtl) => mtl.id))
            .flat(),
        }))
      ),
    };
    fetch(`${API_URL}/update/schedule-budget-map`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => console.log(json));
  };

  const removeTaskName = (input) => {
    return input.map((inputRow) => {
      return [inputRow[0], "", ...inputRow.slice(2)];
    });
  };

  const onDateChange = (date, index, dateIndex) => {
    setRows((prevState) => {
      const newState = [...prevState];
      newState[index][dateIndex] = new Date(date);
      return newState;
    });
  };

  const phaseList =
    phases.length > 0
      ? phases.map((phase) => ({
          value: phase.id,
          label: phase.name,
        }))
      : [];

  const options = {
    height: rows.length * 30 + 50,
    gantt: {
      trackHeight: 30,
    },
  };

  return (
    <div>
      <div>
        <div>
          {mapBudget ? (
            <button onClick={() => handleCreateMap()}>Save Map</button>
          ) : (
            <button onClick={() => handleCreateSchedule()}>
              Save Schedule
            </button>
          )}

          <button onClick={() => setModify((prevState) => !prevState)}>
            Edit Schedule
          </button>
          <button onClick={() => setMapBudget((prevState) => !prevState)}>
            {mapBudget ? "Back To Schedule" : "Map Budget To Schedule"}
          </button>
        </div>
        {!mapBudget ? (
          !loading &&
          rows.length > 0 && (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
              <div>
                <InfoTable>
                  <thead>
                    <tr>
                      <th>Task</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="task-info-cell">
                          <input
                            disabled={modify}
                            value={row[1]}
                            type="text"
                            onChange={(e) =>
                              setRows((prevState) => {
                                const newState = [...prevState];
                                newState[index][0] = e.target.value;
                                newState[index][1] = e.target.value;
                                newState[index][2] = e.target.value;
                                return newState;
                              })
                            }
                          />
                        </td>
                        <td className="task-info-cell">
                          <DatePicker
                            disabled={modify}
                            selected={row[3]}
                            onChange={(date) => onDateChange(date, index, 3)}
                            className="date-picker"
                            preventOpenOnFocus={true}
                          />
                        </td>
                        <td className="task-info-cell">
                          <DatePicker
                            disabled={modify}
                            selected={row[4]}
                            onChange={(date) => onDateChange(date, index, 4)}
                            className="date-picker"
                            preventOpenOnFocus={true}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </InfoTable>
              </div>
              <div style={{ marginTop: "30px" }}>
                <Chart
                  chartType="Gantt"
                  width="100%"
                  height="50%"
                  data={[columns, ...removeTaskName(rows)]}
                  options={options}
                />
              </div>
              <button
                onClick={() =>
                  setRows((prevState) => {
                    const newState = [...prevState];
                    newState.push([
                      "",
                      "",
                      "",
                      new Date(),
                      new Date(),
                      null,
                      0,
                      null,
                    ]);
                    return newState;
                  })
                }
              >
                Add Task
              </button>
            </div>
          )
        ) : (
          <div>
            <h3>Map Budget To Schedule</h3>
            <InfoTable>
              <thead>
                <tr>
                  <th>Task</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Phases</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td className="task-info-cell">
                      <input
                        disabled={true}
                        value={row[1]}
                        type="text"
                        onChange={(e) =>
                          setRows((prevState) => {
                            const newState = [...prevState];
                            newState[index][0] = e.target.value;
                            newState[index][1] = e.target.value;
                            newState[index][2] = e.target.value;
                            return newState;
                          })
                        }
                      />
                    </td>
                    <td className="task-info-cell">
                      <DatePicker
                        disabled={true}
                        selected={row[3]}
                        onChange={(date) => onDateChange(date, index, 3)}
                        className="date-picker"
                        preventOpenOnFocus={true}
                      />
                    </td>
                    <td className="task-info-cell">
                      <DatePicker
                        disabled={true}
                        selected={row[4]}
                        onChange={(date) => onDateChange(date, index, 4)}
                        className="date-picker"
                        preventOpenOnFocus={true}
                      />
                    </td>
                    <td>
                      <Select
                        isLoading={phaseLoading}
                        options={phaseList}
                        isSearchable
                        isMulti
                        onChange={(e) =>
                          setMappedScheduleTasks((prevState) => {
                            const newState = [...prevState];
                            newState[index] = {
                              ...prevState[index],
                              phases: e,
                            };
                            return newState;
                          })
                        }
                        placeholder={"Select Related Phases"}
                        defaultValue={mappedScheduleTasks[index].phases}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </InfoTable>
          </div>
        )}
      </div>
    </div>
  );
};

ProjectSchedule.propTypes = {
  jobId: PropTypes.string,
  estimateId: PropTypes.string,
};

export default ProjectSchedule;
