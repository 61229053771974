import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Footer = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 50px;
  background: lightgray;
  font-weight: bold;
  span {
    margin: 10px;
  }
`;

const TimeFooter = ({ count, countText, total }) => {
  return (
    count !== 0 && (
      <Footer>
        <span>{`${count} ${countText}`}</span>
        <span>Total: {total.toFixed(2)}</span>
      </Footer>
    )
  );
};

TimeFooter.propTypes = {
  count: PropTypes.number,
  countText: PropTypes.string,
  total: PropTypes.number,
};

export default TimeFooter;
