import styled from "styled-components";

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1920px;
  margin-bottom: 10px;
`;

const Column = styled.div`
  flex: 1;
  min-width: 250px;
  padding: 15px;
  background-color: #ededed;
  border-radius: 5px;

  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }

  .custom-select {
    width: 100%;

    .custom-dropdown {
      max-height: 150px;
      overflow-y: auto;
    }
  }

  .custom-input {
    width: 95%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
  }

  .form-group-double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .custom-input-double {
    width: 90%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
  }
`;

export { FormContainer, Column };
