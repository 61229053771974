import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const UploadEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [error, setError] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/employees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
    fetch(`${API_URL}/departments`, requestOptions)
      .then((res) => res.json())
      .then((json) => setDepartments(json));
  }, []);

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(d);
        });

        const data = valuesArray.map((value) => ({
          ...value,
          pinNumber: String(value.pinNumber).padStart(4, "0"),
          employeeNumber: parseInt(value.employeeNumber),
          active: value.active === "" ? true : false,
          departmentId: value.departmentId
            ? departments[
                departments.findIndex(
                  (dept) =>
                    value.departmentId.toLowerCase() === dept.name.toLowerCase()
                )
              ].id
            : "",
        }));
        const filterEmployees = (arr1, arr2) => {
          let resNew = [];
          let resUpdate = [];
          resNew = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.employeeNumber === el.employeeNumber;
            });
          });
          resUpdate = arr1.filter((el) => {
            const foundEmployee = arr2.find(
              (element) => element.employeeNumber === el.employeeNumber
            );
            if (foundEmployee) {
              const criteria = [
                foundEmployee.firstName === el.firstName.trim(),
                foundEmployee.middleName === undefined ||
                foundEmployee.middleName === null
                  ? el.middleName === ""
                  : foundEmployee.middleName === el.middleName,
                foundEmployee.lastName === el.lastName,
                foundEmployee.active === el.active,
                foundEmployee.departmentId === el.departmentId,
                foundEmployee.pinNumber === el.pinNumber.toString(),
              ];
              return !criteria.every((e) => e === true);
            } else return false;
          });
          return [resNew, resUpdate];
        };

        const newEmployees = filterEmployees(data, employees);
        // Filtered Column Names
        setTableRows(rowsArray[0]);
        // Filtered Values
        setValues(newEmployees[0]);
        const employeeUpdates = newEmployees[1];
        const employeeUpdatesId = employeeUpdates.map((employee) => {
          return {
            ...employee,
            id: employees[
              employees.findIndex((element) => {
                return element.pinNumber === employee.pinNumber;
              })
            ].id,
          };
        });
        setUpdates(employeeUpdatesId);
      },
    });
  };
  const handleSubmit = () => {
    try {
      if (values) {
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        };
        fetch(`${API_URL}/create/employees`, requestOptions).then(
          (response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            return response.json();
          }
        );
      }
      if (updates) {
        const requestOptions2 = {
          method: "PUT",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updates),
        };
        fetch(`${API_URL}/update/employees`, requestOptions2).then(
          (response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            return response.json();
          }
        );
      }
    } catch (error) {
      setError(error.message);
    }
    setDoneUploading(true);
  };

  useEffect(() => {
    if (doneUploading) {
      navigate("/employees");
    }
  }, [doneUploading]);

  return (
    <div>
      <h1>Upload Employees</h1>
      <ButtonDiv>
        <div className="button-wrap">
          <label className="button" htmlFor="upload">
            Choose File
          </label>
          <input
            type={"file"}
            name="file"
            accept=".csv"
            onChange={(e) => handleFile(e)}
            className="upload"
            id="upload"
          />
        </div>
      </ButtonDiv>
      <div style={{ height: "25px", margin: "20px" }}>
        {error && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <div>
        {(values.length > 0 || updates.length > 0) && (
          <Button onClick={handleSubmit}>
            {values.length > 0 && "Add"}
            {values.length > 0 && updates.length > 0 && " and "}
            {updates.length > 0 && "Update"} Employee
            {values.length > 1 && "s"}
          </Button>
        )}
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {values && (
          <table>
            <thead>
              <tr>
                {(values.length > 1 || updates.length > 1) && <th>Type</th>}
                {tableRows.map((rows, index) => {
                  return <th key={index}>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {updates.map((update) => (
                <tr key={update.employeeNumber}>
                  <td>Update</td>
                  <td>{update.employeeNumber}</td>
                  <td>{update.firstName}</td>
                  <td>{update.middleName}</td>
                  <td>{update.lastName}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {update.active.toString()}
                  </td>
                  <td>
                    {update.departmentId &&
                      departments[
                        departments.findIndex(
                          (department) => department.id === update.departmentId
                        )
                      ].name}
                  </td>
                  <td>{update.pinNumber}</td>
                </tr>
              ))}
              {values.map((value) => (
                <tr key={value.employeeNumber}>
                  <td>New</td>
                  <td>{value.employeeNumber}</td>
                  <td>{value.firstName}</td>
                  <td>{value.middleName}</td>
                  <td>{value.lastName}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {value.active.toString()}
                  </td>
                  <td>
                    {value.departmentId &&
                      departments[
                        departments.findIndex(
                          (department) => department.id === value.departmentId
                        )
                      ].name}
                  </td>
                  <td>{value.pinNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadEmployees;
