import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditExpensePanel from "./EditExpensePanel";
import Table from "../../Table/Table";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import Pagination from "../../Search/Pagination";
import { API_URL } from "../../../env";

const columns = [
  ["First Name", "user.firstName"],
  ["Last Name", "user.lastName"],
  ["Job", "job.jobNumber"],
  ["Cost Code", "task.taskCode"],
  ["Date", "purchaseDate"],
  ["Amount", "amount"],
];

const Expenses = () => {
  const [state, setState] = useState({
    expenses: [],
    editExpense: "",
  });

  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [costCodes, setCostCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCostCodes(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/expenses/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                expenses: json.expenseReports,
              }));
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/forms/expenses?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Job",
      field: "jobId",
      options: jobs.map((job) => ({
        label: `${job.jobNumber}-${job.jobName}`,
        value: job.id,
      })),
    },
    {
      label: "Employee",
      field: "userId",
      options: employees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
    },
    {
      label: "Cost Code",
      field: "taskId",
      options: costCodes.map((task) => ({
        label: `${task.taskCode} - ${task.taskName}`,
        value: task.id,
      })),
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const expense = state.expenses.filter((expense) => expense.id === id);
    setState((prevState) => ({
      ...prevState,
      editExpense: expense,
    }));
  };

  const onEditExpense = (id, expense) => {
    const index = state.expenses.findIndex((expense) => expense.id === id);
    let newState = state.expenses;
    newState[index] = {
      ...state.expenses[index],
      ...expense,
    };
    setState((prevState) => ({
      ...prevState,
      expenses: newState,
    }));
  };

  const onDeselectExpense = () => {
    setState((prevState) => ({
      ...prevState,
      editExpense: "",
    }));
  };

  const onDeleteExpense = async (id) => {
    const requestOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    await fetch(`${API_URL}/delete/form/expense/${id}`, requestOptions)
      .then((res) => res.json())
      .then(() =>
        setState((prevState) => {
          const newExpenses = prevState.expenses.filter(
            (expense) => expense.id !== id
          );
          return { ...prevState, editExpense: "", expenses: newExpenses };
        })
      );
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { expenses, editExpense } = state;

  return (
    <div>
      {editExpense && (
        <EditExpensePanel
          editExpense={editExpense}
          onDeselectExpense={onDeselectExpense}
          onEditExpense={onEditExpense}
          onDeleteExpense={onDeleteExpense}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Create Expense"}
        buttonPath={"/create/forms/expense"}
        pageTitle={"Expense Reports"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={expenses}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

export default Expenses;
