import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../../../env";
import getTimeWindowText from "../../../Material/getTimeWindowText";

const POTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background: black;
    color: white;
  }
  tbody {
    tr:hover {
      background-color: gainsboro;
    }
    tr:nth-child(even) {
      background-color: #f0f0f0;
    }
    tr:nth-child(even):hover {
      background-color: gainsboro;
    }
  }
  .flag {
    opacity: 20%;
  }
  .flagged {
    opacity: 100%;
  }
  .flag:hover {
    opacity: 100%;
  }
`;

const PODashboard = ({ purchaseOrders, setPurchaseOrders }) => {
  const handleFlagChange = (purchaseOrderId, prevFlagState, index) => {
    setPurchaseOrders((prevState) => {
      const newState = [...prevState];
      newState[index].flagged = !prevFlagState;
      return newState;
    });
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ flagged: !prevFlagState }),
    };
    fetch(
      `${API_URL}/purchaseorder/${purchaseOrderId}/flag`,
      requestOptions
    ).then((res) => {
      {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
      }
    });
  };
  return (
    <div>
      <POTable>
        <thead>
          <tr>
            <th>PO#</th>
            <th>Vendor</th>
            <th>Type</th>
            <th>Status</th>
            <th>Sent</th>
            <th>Need By</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrders.map((purchaseOrder, index) => (
            <tr key={purchaseOrder.id} style={{ cursor: "pointer" }}>
              <td
                style={{ width: "10%" }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                {purchaseOrder.purchaseOrderNumber}
              </td>
              <td
                style={{ width: "30%" }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                {purchaseOrder.vendor.vendorName}
              </td>
              <td
                style={{ width: "10%", textTransform: "capitalize" }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                {purchaseOrder.orderType.toLowerCase()}
              </td>
              <td
                style={{ width: "10%", textTransform: "capitalize" }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                {purchaseOrder.orderState.toLowerCase()}
              </td>
              <td
                style={{ width: "10%" }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                {dayjs(purchaseOrder.createdAt).format("MM/DD/YYYY")}
              </td>
              <td
                style={{ width: "15%" }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                {`${dayjs(purchaseOrder.needByDate).format(
                  "MM/DD/YYYY"
                )} (${getTimeWindowText(
                  purchaseOrder.needByWindow,
                  purchaseOrder.customWindow
                )})`}
              </td>
              <td
                style={{
                  width: "10%",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
                onClick={() =>
                  window.open(`/purchaseorder/${purchaseOrder.id}`, "_blank")
                }
              >
                $
                {purchaseOrder.items
                  .reduce((prev, cur) => prev + cur.extPrice, 0)
                  .toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </td>
              <td
                style={{
                  width: "5%",
                }}
                className={`${purchaseOrder.flagged ? "flagged" : "flag"}`}
                onClick={() =>
                  handleFlagChange(
                    purchaseOrder.id,
                    purchaseOrder.flagged,
                    index
                  )
                }
              >
                <FontAwesomeIcon icon={faFlag} />
              </td>
            </tr>
          ))}
        </tbody>
      </POTable>
    </div>
  );
};

PODashboard.propTypes = {
  purchaseOrders: PropTypes.array,
  setPurchaseOrders: PropTypes.func,
};

export default PODashboard;
