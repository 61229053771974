import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditToolCategoryPanel from "./EditToolCategoryPanel";
import Table from "../../Table/Table";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import { API_URL } from "../../../env";

const columns = [["Category Name", "name"]];

const ToolCategories = () => {
  const [state, setState] = useState({
    toolCategories: [],
    editToolCategory: "",
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/categories/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                toolCategories: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);

          navigate(`/tool/categories?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const onSelect = (id) => {
    const toolCategory = state.toolCategories.filter(
      (toolCategory) => toolCategory.id === id
    );
    setState((prevState) => ({
      ...prevState,
      editToolCategory: toolCategory,
    }));
  };

  const onEditToolCategory = (id, toolCategory) => {
    const index = state.toolCategories.findIndex(
      (toolCategory) => toolCategory.id === id
    );
    let newState = state.toolCategories;
    newState[index] = {
      ...state.toolCategories[index],
      ...toolCategory,
    };
    setState((prevState) => ({
      ...prevState,
      toolCategories: newState,
    }));
  };

  const onDeselectToolCategory = () => {
    setState((prevState) => ({
      ...prevState,
      editToolCategory: "",
    }));
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { toolCategories, editToolCategory } = state;
  return (
    <div>
      {editToolCategory && (
        <EditToolCategoryPanel
          editToolCategory={editToolCategory}
          onDeselectToolCategory={onDeselectToolCategory}
          onEditToolCategory={onEditToolCategory}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        buttonText={"Add Tool Category"}
        buttonPath={"/tool/create/category"}
        pageTitle={"Category"}
        fetchData={fetchData}
        filterBool={false}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={toolCategories}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default ToolCategories;
