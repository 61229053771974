import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { API_URL } from "../../env";
import ProjectBudget from "./ProjectDashboardComponents/ProjectBudget";
import ProjectMetrics from "./ProjectDashboardComponents/ProjectMetrics";
import ProjectSchedule from "./ProjectDashboardComponents/ProjectSchedule";
import ProjectMaterial from "./ProjectDashboardComponents/ProjectMaterial";

const DashboardContainer = styled.div`
  .dashboard-menu {
    width: 100%;
    ul {
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: row;
      list-style: none;
      justify-content: space-evenly;
      padding: 0px;
      li {
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        padding: 0.3rem;
      }
      li:hover {
        background: blue;
        color: white;
      }
      .active {
        background: blue;
        color: white;
      }
    }
  }
`;

const ProjectDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [estimate, setEstimate] = useState(null);
  const [section, setSection] = useState("BUDGET");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs/estimates`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setJobs(json);
        setLoading(false);
      });
    if (queryParams) {
      setJobId(queryParams.get("job"));
    }
  }, []);

  useEffect(() => {
    if (jobId && jobs.length > 0) {
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      if (jobs[jobs.findIndex((job) => job.id === jobId)]?.estimate[0]?.id) {
        fetch(
          `${API_URL}/estimate/${
            jobs[jobs.findIndex((job) => job.id === jobId)].estimate[0].id
          }`,
          requestOptions
        )
          .then((res) => res.json())
          .then((json) => {
            setEstimate(json);
          });
      }
      queryParams.set("job", jobId);
      navigate(`/project-dashboard?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [jobId]);

  const options = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber}-${job.jobName}`,
  }));

  const materialBudget =
    estimate?.materials.reduce(
      (prev, cur) =>
        prev +
        cur.quantityEstimated * (cur.estimatedMaterialUnitPricex1M / 1000000),
      0
    ) +
    (estimate?.quotes
      ? JSON.parse(estimate.quotes).reduce(
          (prev, cur) => prev + Number(cur.materialCost),
          0
        )
      : 0) +
    (estimate?.allowances
      ? JSON.parse(estimate?.allowances).reduce(
          (prev, cur) => prev + Number(cur.materialCost),
          0
        )
      : 0);

  const laborBudget =
    estimate?.materials.reduce(
      (prev, cur) =>
        prev +
        (cur.estimatedLaborUnitsx1M / 1000000) *
          cur.quantityEstimated *
          Number(estimate.estimatedLaborRate),
      0
    ) +
    (estimate?.allowances
      ? JSON.parse(estimate?.allowances).reduce(
          (prev, cur) => prev + Number(cur.laborCost),
          0
        )
      : 0);

  return (
    !loading && (
      <DashboardContainer>
        <div>
          <h1>Project Dashboard</h1>
          <div style={{ margin: "10px auto", width: "30%" }}>
            <Select
              isLoading={loading}
              options={options}
              isSearchable
              onChange={(e) => setJobId(e.value)}
              placeholder={"Select Job"}
              defaultValue={jobId}
            />
          </div>
        </div>
        {jobId && (
          <div className="dashboard-menu">
            <ul>
              <li
                onClick={() => setSection("BUDGET")}
                className={`${section === "BUDGET" ? "active" : ""}`}
              >
                Budget
              </li>
              <li
                onClick={() => setSection("MATERIAL")}
                className={`${section === "MATERIAL" ? "active" : ""}`}
              >
                Material
              </li>
              <li
                onClick={() => setSection("LABOR")}
                className={`${section === "LABOR" ? "active" : ""}`}
              >
                Labor
              </li>
              <li
                onClick={() => setSection("SCHEDULE")}
                className={`${section === "SCHEDULE" ? "active" : ""}`}
              >
                Schedule
              </li>
              <li
                onClick={() => setSection("METRICS")}
                className={`${section === "METRICS" ? "active" : ""}`}
              >
                Metrics
              </li>
            </ul>
          </div>
        )}
        {section === "BUDGET" && jobId && (
          <ProjectBudget
            estimateId={
              jobs[jobs.findIndex((job) => job.id === jobId)]?.estimate[0]?.id
            }
          />
        )}
        {section === "MATERIAL" && <ProjectMaterial jobId={jobId} />}
        {section === "LABOR" && <p>LABOR</p>}
        {section === "SCHEDULE" && (
          <ProjectSchedule
            jobId={jobId}
            estimateId={
              jobs[jobs.findIndex((job) => job.id === jobId)].estimate[0].id
            }
          />
        )}
        {section === "METRICS" && (
          <ProjectMetrics
            jobId={jobId}
            budget={{ materialBudget, laborBudget }}
          />
        )}
      </DashboardContainer>
    )
  );
};

export default ProjectDashboard;
