/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import { Button } from "../../styles/Style";
import EmployeeField from "./EmployeeField";
import { API_URL } from "../../env";

const MultipleTimeRecordForm = styled.div`
  table {
    width: 100%;
  }
  tbody {
    width: auto;
  }
  tr {
    border-bottom: 1px solid black;
  }
  td,
  th {
    padding: 0px 30px 10px 0px;
    border-bottom: 1px solid black;
  }
  .deleteButton {
    position: relative;
    top: 0px;
    left: calc(50% + 13px);
    border: none;
    background-color: white;
  }
`;

const AddMultipleTimeRecordForm = () => {
  const [endDate] = useState(new Date(localStorage.getItem("endDate")));
  const [jobs, setJobs] = useState([]);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [timeRow, setTimeRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  let navigate = useNavigate();

  const handleClockInChange = (value, rowIndex, dayIndex) => {
    setTimeRow((prevState) => {
      prevState[rowIndex].timeDetails[dayIndex].clockIn = value;
      prevState[rowIndex].timeDetails[dayIndex].timeDiff =
        dayjs(prevState[rowIndex].timeDetails[dayIndex].clockOut).diff(
          dayjs(value)
        ) > 86400000;
      return [...prevState];
    });
  };

  const handleClockOutChange = (value, rowIndex, dayIndex) => {
    setTimeRow((prevState) => {
      prevState[rowIndex].timeDetails[dayIndex].clockOut = value;
      prevState[rowIndex].timeDetails[dayIndex].timeDiff =
        dayjs(value).diff(
          dayjs(prevState[rowIndex].timeDetails[dayIndex].clockIn)
        ) > 86400000;
      return [...prevState];
    });
  };

  const handleJobChange = (e, rowIndex, dayIndex) => {
    setTimeRow((prevState) => {
      prevState[rowIndex].timeDetails[dayIndex].jobId = e;
      return [...prevState];
    });
  };

  const handleTaskChange = (e, rowIndex, dayIndex) => {
    setTimeRow((prevState) => {
      prevState[rowIndex].timeDetails[dayIndex].taskId = e.target.value;
      return [...prevState];
    });
  };

  const handleUserChange = (e, rowIndex, dayIndex) => {
    const userList = e.map((e) => ({ value: e.value, label: e.label }));
    setTimeRow((prevState) => {
      prevState[rowIndex].timeDetails[dayIndex].userId = userList;
      return [...prevState];
    });
  };

  const handleAddRow = (dayIndex) => {
    setTimeRow((prevState) => {
      const newState = [...prevState];
      newState[dayIndex].timeDetails.push({
        userId: [],
        jobId: "",
        taskId: "",
        clockIn: new Date(
          dayjs(endDate)
            .subtract(6 - dayIndex, "days")
            .set("hour", 7)
            .set("minutes", 0)
            .toISOString()
        ),
        clockOut: new Date(
          dayjs(endDate)
            .subtract(6 - dayIndex, "days")
            .set("hour", 15)
            .set("minutes", 30)
            .toISOString()
        ),
        timeDiff: false,
      });
      return [...newState];
    });
  };

  const handleDeleteRow = (dayIndex, jobIndex) => {
    setTimeRow((prevState) => {
      prevState[dayIndex].timeDetails.splice(jobIndex, 1);
      return [...prevState];
    });
  };

  const copyPreviousRow = (dayIndex) => {
    setTimeRow((prevState) => {
      prevState[dayIndex].timeDetails = prevState[dayIndex - 1].timeDetails.map(
        (job) => ({
          ...job,
          clockIn: new Date(dayjs(job.clockIn).add(1, "day").toISOString()),
          clockOut: new Date(dayjs(job.clockOut).add(1, "day").toISOString()),
        })
      );
      return [...prevState];
    });
  };

  useEffect(() => {
    setTimeRow(() => {
      let days = [];
      for (let i = 0; i < 7; i++) {
        days.push({
          date: dayjs(endDate).subtract(i, "days").toISOString(),
          timeDetails: [
            {
              userId: [],
              jobId: "",
              taskId: "",
              clockIn: new Date(
                dayjs(endDate)
                  .subtract(i, "days")
                  .set("hour", 7)
                  .set("minutes", 0)
                  .toISOString()
              ),
              clockOut: new Date(
                dayjs(endDate)
                  .subtract(i, "days")
                  .set("hour", 15)
                  .set("minutes", 30)
                  .toISOString()
              ),
              timeDiff: false,
            },
          ],
        });
      }
      return days.sort((a, b) => new Date(a.date) - new Date(b.date));
    });
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activejobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUsers(json));
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
    setLoading(false);
  }, []);

  const handleSubmit = () => {
    if (
      timeRow.every((timeDetails) =>
        timeDetails.timeDetails.every(
          (taskCheck) =>
            taskCheck.taskId || (!taskCheck.jobId && !taskCheck.taskId)
        )
      )
    ) {
      const userActions = [];
      timeRow.forEach((time) =>
        time.timeDetails.forEach((test) =>
          test.userId.forEach((user) => {
            userActions.push({
              userId: user.value,
              jobId: test.jobId.value,
              taskId: test.taskId,
              clockIn: test.clockIn,
              clockOut: test.clockOut,
            });
          })
        )
      );
      const requestOptions = {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userActions),
      };
      fetch(`${API_URL}/create/clockactions`, requestOptions)
        .then((response) => response.json())
        .then(() => navigate("/time"));
    } else {
      setError("At least one time record is missing a cost code");
    }
  };

  const jobOptions = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const TimeRowDiv = (index) => (
    <tr>
      <th>{dayjs(timeRow[index].date).format("dddd, MMM DD")}</th>
      <td>
        <Button type="button" onClick={() => handleAddRow(index)}>
          Add Job
        </Button>
        {index !== 0 && (
          <div>
            <br />
            <Button type="button" onClick={() => copyPreviousRow(index)}>
              Copy Prev
            </Button>
          </div>
        )}
      </td>
      {timeRow[index]?.timeDetails?.length > 0 &&
        timeRow[index].timeDetails.map(
          ({ userId, jobId, taskId, clockIn, clockOut }, jobIndex) => (
            <td key={jobIndex}>
              <button
                type="button"
                className="deleteButton"
                disabled={timeRow[index].timeDetails.length === 1}
                onClick={() => handleDeleteRow(index, jobIndex)}
              >
                <FontAwesomeIcon icon={faX} />
              </button>
              <EmployeeField
                employees={users}
                userId={userId}
                handleUserChange={(e) => handleUserChange(e, index, jobIndex)}
              />
              <br />
              <Select
                options={jobOptions}
                isSearchable
                placeholder={"Select Job..."}
                value={jobId}
                key={`select-${index}-${jobIndex}`}
                onChange={(e) => handleJobChange(e, index, jobIndex)}
              />
              <br />
              <select
                value={taskId}
                name="task"
                id={`task_${index}_${jobIndex}`}
                required
                onChange={(e) => handleTaskChange(e, index, jobIndex)}
                className="input-field"
              >
                <option defaultValue>Select A Cost Code</option>
                {tasks.map((task) => (
                  <option key={task.id} value={task.id}>
                    {task.taskCode} - {task.taskName}
                  </option>
                ))}
              </select>
              <br />
              <label className="start">
                Start Date
                <br />
                <DateTimePicker
                  key={`clockIn_${index}_${jobIndex}`}
                  onChange={(value) =>
                    handleClockInChange(value, index, jobIndex)
                  }
                  value={clockIn}
                  calendarType={"US"}
                  disableClock
                />
              </label>
              <br />
              <label className="end">
                End Date
                <br />
                <DateTimePicker
                  key={`clockOut_${index}_${jobIndex}`}
                  onChange={(value) =>
                    handleClockOutChange(value, index, jobIndex)
                  }
                  value={clockOut}
                  calendarType={"US"}
                  disableClock
                />
              </label>
            </td>
          )
        )}
    </tr>
  );

  return (
    !loading && (
      <div>
        <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Create Time Records
        </h1>
        {error.length > 0 && (
          <p style={{ fontWeight: "bold", color: "red" }}>{error}</p>
        )}
        <Link to="/create/timerecord">
          <Button>Create Single</Button>
        </Link>
        {timeRow.some((day) =>
          day.timeDetails.some((time) => time.timeDiff === true)
        ) && (
          <h3
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              color: "red",
              fontSize: "12px",
            }}
          >
            Difference between start time and end time cannot be more than 24
            hours
          </h3>
        )}
        <div>
          <MultipleTimeRecordForm>
            <fieldset>
              <div className="fields">
                <table>
                  {timeRow.length > 0 && (
                    <tbody>
                      {TimeRowDiv(0)}
                      {TimeRowDiv(1)}
                      {TimeRowDiv(2)}
                      {TimeRowDiv(3)}
                      {TimeRowDiv(4)}
                      {TimeRowDiv(5)}
                      {TimeRowDiv(6)}
                    </tbody>
                  )}
                </table>
                {error.length > 0 && (
                  <p style={{ fontWeight: "bold", color: "red" }}>{error}</p>
                )}
                <Button
                  className="submit-button"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Create Time Records
                </Button>
              </div>
            </fieldset>
          </MultipleTimeRecordForm>
        </div>
      </div>
    )
  );
};

export default AddMultipleTimeRecordForm;
