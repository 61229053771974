import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditDailyReport from "./EditDailyReport";
import Table from "./DailyReportTable";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import Pagination from "../../Search/Pagination";
import { API_URL } from "../../../env";

const columns = [
  ["Date", "date"],
  ["Job #", "job.jobNumber"],
  ["Job Name", "job.jobName"],
  ["Schedule", "schedule"],
  ["Lost Time", "lostTime"],
  ["Electricians", "labor.length"],
  ["Submitted", "submitted"],
  ["PDF", "id"],
];

const DailyReport = () => {
  const [state, setState] = useState({
    dailyReports: [],
    editDailyReport: "",
  });
  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/dailyreports/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                dailyReports: json.dailyReports,
              }));
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/forms/dailies?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Job",
      field: "jobId",
      options: jobs.map((job) => ({
        label: `${job.jobNumber}-${job.jobName}`,
        value: job.id,
      })),
    },
    {
      label: "Employee",
      field: "userId",
      options: employees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
    },
    {
      label: "Submitted",
      field: "submitted",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const dailyReport = state.dailyReports.filter(
      (dailyReport) => dailyReport.id === id
    );
    setState((prevState) => ({
      ...prevState,
      editDailyReport: dailyReport,
    }));
  };

  const onEditDailyReport = (id, dailyReport) => {
    const index = state.dailyReports.findIndex(
      (dailyReport) => dailyReport.id === id
    );
    let newState = state.dailyReports;
    newState[index] = {
      ...state.dailyReports[index],
      ...dailyReport,
    };
    setState((prevState) => ({
      ...prevState,
      dailyReports: newState,
    }));
  };

  const onDeselectDailyReport = () => {
    setState((prevState) => ({
      ...prevState,
      editDailyReport: "",
    }));
  };

  const onDeleteDailyReport = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(`${API_URL}/delete/form/dailyreport/${id}`, requestOptions)
        .then((res) => {
          {
            if (res.status !== 200) {
              throw new Error(res.statusText);
            }
            return res.json();
          }
        })
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            editDailyReport: "",
            dailyReports: prevState.dailyReports.filter(
              (dailyReport) => dailyReport.id !== id
            ),
          }));
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { dailyReports, editDailyReport } = state;

  return editDailyReport ? (
    <EditDailyReport
      editDailyReport={editDailyReport}
      onDeselectDailyReport={onDeselectDailyReport}
      onEditDailyReport={onEditDailyReport}
      onDeleteDailyReport={onDeleteDailyReport}
    />
  ) : (
    <div>
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Daily Report"}
        buttonPath={"/create/forms/dailyreport"}
        pageTitle={"Daily Reports"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={dailyReports}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

export default DailyReport;
