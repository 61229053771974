import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const LocationForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const SingleLocation = ({
  editLocation,
  onEditLocation,
  onDeselectLocation,
}) => {
  const [state, setState] = useState({
    location: { ...editLocation[0] },
    updatedLocation: "",
    error: "",
  });
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedLocation: { ...prevState.updatedLocation, [name]: value },
    }));
  };

  const handleActiveChange = (e) => {
    const { checked, name } = e.target;
    setState({
      updatedLocation: { ...state.updatedLocation, [name]: checked },
    });
  };

  const { location, updatedLocation } = state;
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Edit Location
        </h3>
        <LocationForm
          onSubmit={async (e) => {
            e.preventDefault();
            const requestOptions = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...updatedLocation }),
            };
            await fetch(
              `${API_URL}/update/location/${location.id}`,
              requestOptions
            ).then((res) => res.json());
            onEditLocation(location.id, updatedLocation);
            onDeselectLocation();
          }}
        >
          <fieldset>
            <div className="fields">
              <div>
                <label className="location-name">
                  Location Name
                  <br />
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue={location.name}
                    onChange={handleChange}
                    placeholder="Location Name*"
                    required
                    autoComplete="none"
                  />
                </label>
              </div>
              <label htmlFor="active">
                Active
                <input
                  type="checkbox"
                  name="active"
                  id="active"
                  defaultChecked={location.active}
                  onChange={handleActiveChange}
                />
              </label>

              <label htmlFor="locationAddress" className="description">
                Location Address
                <br />
                <input
                  type="text"
                  name="locationAddress"
                  id="locationAddress"
                  defaultValue={location.locationAddress}
                  onChange={handleChange}
                  placeholder="Location Address*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationCity" className="locationCity">
                Location City
                <br />
                <input
                  type="text"
                  name="locationCity"
                  id="locationCity"
                  defaultValue={location.locationCity}
                  onChange={handleChange}
                  placeholder="Location City*"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationState" className="locationState">
                Location State
                <br />
                <input
                  type="text"
                  name="locationState"
                  id="locationState"
                  defaultValue={location.locationState}
                  onChange={handleChange}
                  placeholder="Location State*"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationZip" className="locationZip">
                Location Zip
                <br />
                <input
                  type="text"
                  name="locationZip"
                  id="locationZip"
                  defaultValue={location.locationZip}
                  onChange={handleChange}
                  placeholder="Location Zip*"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="locationType" className="locationType">
                Location Type
                <br />
                <select
                  required
                  name="locationType"
                  id="locationType"
                  defaultValue={location.locationType}
                  onChange={handleChange}
                >
                  <option defaultValue>Select A Type</option>
                  <option value="Jobsite">Jobsite</option>
                  <option value="Warehouse">Warehouse</option>
                  <option value="Vehicle">Vehicle</option>
                </select>
              </label>
              <label htmlFor="locationManagerId" className="locationManagerId">
                Location Manager
                <br />
                <select
                  required
                  name="locationManagerId"
                  id="locationManagerId"
                  value={
                    updatedLocation.locationManagerId
                      ? updatedLocation.locationManagerId
                      : location.locationManagerId
                  }
                  onChange={handleChange}
                >
                  <option defaultValue>Select An Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </label>
              {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
              <Button type="submit">Save Changes</Button>
            </div>
          </fieldset>
        </LocationForm>
      </div>
    </div>
  );
};

SingleLocation.propTypes = {
  id: PropTypes.string,
  editLocation: PropTypes.array,
  onEditLocation: PropTypes.func,
  onDeselectLocation: PropTypes.func,
};

export default SingleLocation;
