import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FavoritesDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  text-transform: capitalize;
  a {
    text-transform: capitalize;
    div {
      background: lightgray;
      padding: 100px;
      h2 {
        margin: 0px;
      }
    }
  }
`;

const Home = ({ name, favorites }) => {
  const favoritesList = JSON.parse(favorites);
  const favoriteLinks = [
    ["time", "/time"],
    ["salary timecards", "/reports/salarytimecards"],
    ["hourly timecards", "/reports/hourlytimecards"],
    ["create timecard", "/create/mytimecard"],
    ["time clock dashboard", "/time-clock-dashboard"],
    ["daily reports", "/forms/dailies"],
    ["field work orders", "/forms/workorders"],
    ["expense reports", "/forms/expenses"],
    ["tools", "/tools"],
    ["new tool", "/create/tool"],
    ["export time", "/export/time"],
    ["upload jobs", "/upload/jobs"],
    ["upload employees", "/upload/employees"],
  ];
  return (
    <div>
      <h1 style={{ textTransform: "uppercase" }}>Welcome, {name}</h1>
      {!favoritesList.every((fav) => fav === "") && (
        <FavoritesDiv>
          {favoritesList.map((favorite) => {
            const link = favoriteLinks.filter((link) => link[0] === favorite);
            return link.length > 0 ? (
              <Link to={`${link[0][1]}`} key={favorite}>
                <div>
                  <h2>{favorite}</h2>
                </div>
              </Link>
            ) : (
              <Link to={`/settings`} key={favorite}>
                <div>
                  <h2>Pick A Favorite</h2>
                </div>
              </Link>
            );
          })}
        </FavoritesDiv>
      )}
    </div>
  );
};

Home.propTypes = {
  name: PropTypes.string,
  favorites: PropTypes.string,
};

export default Home;
