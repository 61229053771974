import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { API_URL } from "../../../env";
import { Button } from "../../../styles/Style";
import Select from "react-select";

const QuotePricingReviewContainer = styled.div`
  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }
  .confirm-panel {
    padding: 50px;
    background-color: white;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: block;
    position: fixed;
    z-index: 25;
  }
  .opaque-panel {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: gray;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
`;

const AddVendorModal = ({
  setShowAddVendor,
  handleAddVendor,
  type,
  prevVendors,
}) => {
  const [vendorId, setVendorId] = useState("");
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json.filter((vendor) => !prevVendors.includes(vendor.id)));
        setLoading(false);
      });
  }, []);

  const options = vendors.map((vendor) => ({
    value: vendor.id,
    label: vendor.vendorName,
  }));

  return (
    <QuotePricingReviewContainer>
      <div className="confirm-panel">
        <h2 style={{ verticalAlign: "middle" }}>Add Vendor</h2>
        <div>
          <Select
            isLoading={loading}
            options={options}
            isSearchable
            onChange={(e) => setVendorId(e.value)}
            placeholder={"Select Vendor"}
            defaultValue={vendorId}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20%",
            marginTop: "50px",
          }}
        >
          <Button onClick={() => setShowAddVendor(false)}>Cancel</Button>
          <Button
            disabled={vendorId === ""}
            onClick={() => {
              handleAddVendor(vendorId);
              setShowAddVendor(false);
            }}
          >
            {`Add Vendor & Send ${type}`}
          </Button>
        </div>
      </div>
      <div className="opaque-panel" onClick={() => setShowAddVendor(false)} />
    </QuotePricingReviewContainer>
  );
};

AddVendorModal.propTypes = {
  type: PropTypes.string,
  prevVendors: PropTypes.array,
  setShowAddVendor: PropTypes.func,
  handleAddVendor: PropTypes.func,
};

export default AddVendorModal;
