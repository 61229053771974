import React from "react";
import PropTypes from "prop-types";

const DELDashboard = ({ deliveries }) => {
  console.log(deliveries);
  return (
    <div>
      <p>Deliveries</p>
    </div>
  );
};

DELDashboard.propTypes = {
  deliveries: PropTypes.array,
};

export default DELDashboard;
