import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { isSunday } from "date-fns";
import TimeCardRow from "./TimeCardRow";
import TimeCardTable from "./styles/TimeCardStyles";
import { API_URL } from "../../env";

const TimeCard = () => {
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const [jobs, setJobs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [weekEnding, setWeekEnding] = useState(
    new Date(
      dayjs().subtract(3, "day").endOf("week").add(1, "day").toISOString()
    )
  );
  const [timeCardItems, setTimeCardItems] = useState([
    {
      jobId: "",
      taskId: "",
      hours: { set: [0, 0, 0, 0, 0, 0, 0] },
    },
  ]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/salaryemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
  }, []);

  useEffect(() => {
    jobs.length > 0 && tasks.length > 0 && setLoading(false);
  }, [jobs, tasks]);

  const handleEmployeeChange = (e) => {
    const { value } = e.target;
    setEmployee(value);
  };

  const handleChange = (e, rowIndex, cellIndex) => {
    const { value } = e.target;
    setTimeCardItems((prevState) => {
      prevState[rowIndex].hours.set[cellIndex] = parseInt(value);
      return [...prevState];
    });
  };

  const handleJobChange = (e, rowIndex) => {
    const { value } = e;
    setTimeCardItems((prevState) => {
      prevState[rowIndex].jobId = value;
      return [...prevState];
    });
  };

  const handleTaskChange = (e, rowIndex) => {
    const { value } = e;
    setTimeCardItems((prevState) => {
      prevState[rowIndex].taskId = value;
      return [...prevState];
    });
  };

  const handleAddRow = () => {
    setTimeCardItems((prevState) => {
      const items = [
        ...prevState,
        {
          jobId: "",
          taskId: "",
          hours: { set: [0, 0, 0, 0, 0, 0, 0] },
        },
      ];
      return [...items];
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setTimeCardItems((prevState) => {
      prevState.splice(rowIndex, 1);
      return [...prevState];
    });
  };

  const handleDateChange = (value) => {
    setWeekEnding(value);
  };

  const isDate = (date) => {
    return isSunday(date);
  };

  const handleSubmit = () => {
    const timeCard = {
      userId: employee,
      weekEnding,
      timeCardItems: {
        create: [...timeCardItems],
      },
    };
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...timeCard }),
    };
    fetch(`${API_URL}/create/timecard`, requestOptions).then((response) =>
      response.json()
    );
  };

  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const taskList = tasks.map((task) => ({
    value: task.id,
    label: `${task.taskCode} - ${task.taskName}`,
  }));

  const mondayTotal = timeCardItems
    .map((day) => day.hours.set[0])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const tuesdayTotal = timeCardItems
    .map((day) => day.hours.set[1])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const wednesdayTotal = timeCardItems
    .map((day) => day.hours.set[2])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const thursdayTotal = timeCardItems
    .map((day) => day.hours.set[3])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const fridayTotal = timeCardItems
    .map((day) => day.hours.set[4])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const saturdayTotal = timeCardItems
    .map((day) => day.hours.set[5])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const sundayTotal = timeCardItems
    .map((day) => day.hours.set[6])
    .reduce((prev, cur) => prev + Number(cur), 0);
  const grandTotal =
    mondayTotal +
    tuesdayTotal +
    wednesdayTotal +
    thursdayTotal +
    fridayTotal +
    saturdayTotal +
    sundayTotal;
  return (
    !loading && (
      <div>
        <h2>Enter Timecard</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr 3fr",
          }}
        >
          <span style={{ gridColumnStart: "2" }}>Employee: </span>
          <select
            onChange={handleEmployeeChange}
            style={{ gridColumnStart: "3" }}
          >
            <option>Select An Employee</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))}
          </select>
          <span style={{ gridColumnStart: "4" }}>Week Ending: </span>
          <DatePicker
            style={{ gridColumnStart: "5" }}
            selected={weekEnding}
            onChange={handleDateChange}
            filterDate={isDate}
          />
        </div>
        <TimeCardTable
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/timecards");
          }}
        >
          <table>
            <thead>
              <tr
                style={{
                  gridTemplateColumns: "250px 175px repeat(8, 100px) 34px",
                }}
              >
                <th />
                <th />
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
                <th />
              </tr>
              <tr
                style={{
                  gridTemplateColumns: "250px 175px repeat(8, 100px) 34px",
                }}
              >
                <th>Job</th>
                <th>Cost Code</th>
                <th>
                  {dayjs(weekEnding).subtract(6, "day").format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(weekEnding).subtract(5, "day").format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(weekEnding).subtract(4, "day").format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(weekEnding).subtract(3, "day").format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(weekEnding).subtract(2, "day").format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(weekEnding).subtract(1, "day").format("MM/DD/YY")}
                </th>
                <th>{dayjs(weekEnding).format("MM/DD/YY")}</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {timeCardItems.map((row, rowIndex) => (
                <TimeCardRow
                  jobs={jobList}
                  tasks={taskList}
                  loading={loading}
                  key={rowIndex}
                  job={row.jobId}
                  task={row.taskId}
                  hours={row.hours.set}
                  handleChange={handleChange}
                  handleJobChange={handleJobChange}
                  handleTaskChange={handleTaskChange}
                  handleDeleteRow={handleDeleteRow}
                  rowIndex={rowIndex}
                  numRows={timeCardItems.length}
                />
              ))}
            </tbody>
            <tfoot>
              <tr
                style={{
                  gridTemplateColumns: "250px 175px repeat(8, 100px) 34px",
                }}
              >
                <td>
                  <input
                    type="text"
                    onClick={() => handleAddRow()}
                    value="Add Row"
                    className="add-row-button"
                    readOnly
                  />
                </td>
                <td>
                  <input type="text" value="Total" readOnly />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(mondayTotal) ? "-" : mondayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(tuesdayTotal) ? "-" : tuesdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(wednesdayTotal) ? "-" : wednesdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(thursdayTotal) ? "-" : thursdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(fridayTotal) ? "-" : fridayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(saturdayTotal) ? "-" : saturdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(sundayTotal) ? "-" : sundayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(grandTotal) ? "-" : grandTotal}
                    readOnly
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          <br />
          <div className="bottom-buttons">
            <button
              type="submit"
              className="submit-button"
              disabled={timeCardItems.some(
                (row) =>
                  row.job === "" ||
                  row.task === "" ||
                  row.hours.set.reduce((prev, cur) => prev + cur) === 0
              )}
            >
              Submit
            </button>
          </div>
        </TimeCardTable>
      </div>
    )
  );
};

export default TimeCard;
