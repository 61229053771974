import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SingleToolCategory from "./SingleToolCategory";

const Panel = styled.div`
  .visible-panel {
    @keyframes moveup {
      from {
        bottom: -100%;
      }
      to {
        bottom: -175px;
      }
    }
    @keyframes moveupinvisible {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-bottom: none;
    background-color: white;
    bottom: -100%;
    margin: 0 auto;
    display: block;
    position: fixed;
    animation-name: moveup;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 10;
    .close-button {
      display: block;
      position: fixed;
      right: 272px;
      background-color: gray;
      border: 1px gray solid;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      color: white;
      font-weight: bold;
    }
  }
  .invisible-panel {
    display: block;
    position: fixed;
    left: 0;
    color: transparent;
    width: 100%;
    height: 100%;
    animation-name: moveupinvisible;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 5;
    cursor: pointer;
  }
`;

const EditToolCategoryPanel = (props) => {
  const { editToolCategory, onDeselectToolCategory, onEditToolCategory } =
    props;
  return (
    <Panel>
      <div className="visible-panel">
        <button onClick={onDeselectToolCategory} className="close-button">
          X
        </button>
        <SingleToolCategory
          editToolCategory={editToolCategory}
          onDeselectToolCategory={onDeselectToolCategory}
          onEditToolCategory={onEditToolCategory}
        />
      </div>
      <div onClick={onDeselectToolCategory} className="invisible-panel" />
    </Panel>
  );
};

EditToolCategoryPanel.propTypes = {
  editToolCategory: PropTypes.array,
  onDeselectToolCategory: PropTypes.func,
  onEditToolCategory: PropTypes.func,
};

export default EditToolCategoryPanel;
