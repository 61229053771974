import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faCloud,
  faCloudRain,
  faCloudSun,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../styles/Style";
import logo from "../../../static/champion_logo_small.png";
import { API_URL } from "../../../env";

const DailyReport = styled.div`
  @media print {
    .page-title {
      display: none;
    }
    button {
      display: none !important;
    }
    .no-border-print,
    select,
    textarea {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      appearance: none !important;
    }
  }
  .champion-logo {
    width: 300px;
  }
`;

const DailyReportForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  table {
    border-collapse: collapse;
    white-space: nowrap;
    td {
      border: 1px solid black;
    }
  }
  textarea {
    resize: none;
    width: 99%;
  }
  @media print {
    th {
      background: white !important;
      color: black !important;
    }
  }
  th {
    border: 1px solid black;
    background: black;
    color: white;
  }
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    justify-items: center;
    label {
      font-weight: bold;
      text-align: center;
    }
    .text {
      width: 100%;
      tr,
      td,
      label {
        width: 100%;
      }
    }
    .weather {
      width: 100%;
      .title {
        text-align: center;
      }
      th,
      td {
        padding-left: 10px;
      }
      text-align: left;
    }
    .labor {
      width: 100%;
      .electricians {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5px;
      }
    }
    .subs {
      width: 100%;
      td {
        text-align: left;
      }
    }
    .reason {
      width: 100%;
      text-align: left;
      textarea {
        width: 98%;
      }
    }
    button {
      grid-column: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const EditDailyReport = ({
  onEditDailyReport,
  onDeselectDailyReport,
  onDeleteDailyReport,
}) => {
  const [state, setState] = useState({
    dailyReport: "",
    updatedDailyReport: "",
    error: "",
  });

  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  // const [employees, setEmployees] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState(new Date());

  const { id } = useParams();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/dailyreport/id/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setState((prevState) => ({ ...prevState, dailyReport: json }))
      );
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    // fetch(`${API_URL}/activeemployees`, requestOptions)
    //   .then((res) => res.json())
    //   .then((json) => setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))));
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => {
      const updatedState = prevState;
      updatedState.updatedDailyReport = {
        ...updatedState.updatedDailyReport,
        [name]: value,
      };
      return {
        ...updatedState,
      };
    });
  };

  const handleDateChange = (value) => {
    setPurchaseDate(value);
  };

  const { dailyReport, updatedDailyReport } = state;

  return (
    dailyReport && (
      <DailyReport>
        <DailyReportForm>
          <div
            style={{
              gridColumnStart: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                style={{
                  background: "none",
                  cursor: "pointer",
                  gridColumnStart: 2,
                  width: "100px",
                  padding: "10px 5px",
                  backgroundColor: "#032bdf",
                  color: "white",
                  fontWeight: "bold",
                  border: "none",
                }}
                type="submit"
              >
                SAVE
              </button>
            </div>
            <div>
              <Link to={`/forms/dailyreport/pdf/${id}`}>
                <button
                  style={{
                    background: "none",
                    cursor: "pointer",
                    gridColumnStart: 2,
                    width: "100px",
                    padding: "10px 5px",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    border: "none",
                  }}
                >
                  PDF
                </button>
              </Link>
            </div>
            <div>
              <button
                style={{
                  background: "none",
                  cursor: "pointer",
                  gridColumnStart: 2,
                  width: "100px",
                  padding: "10px 5px",
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "bold",
                  border: "none",
                }}
                onClick={() => onDeleteDailyReport(dailyReport.id)}
              >
                DELETE
              </button>
            </div>
          </div>
        </DailyReportForm>
        <div>
          <h3
            className="page-title"
            style={{ textAlign: "center", textTransform: "uppercase" }}
          >
            Edit Daily Report
          </h3>
          <DailyReportForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...updatedDailyReport }),
              };
              await fetch(
                `${API_URL}/update/dailyreport/${dailyReport.id}`,
                requestOptions
              ).then((res) => res.json());
              onEditDailyReport(dailyReport.id, updatedDailyReport);
              onDeselectDailyReport();
            }}
          >
            <fieldset>
              <div className="fields">
                <div>
                  <table className="text">
                    <tbody>
                      <tr>
                        <td className="title" style={{ textAlign: "left" }}>
                          <img
                            src={logo}
                            alt="champion-logo"
                            className="champion-logo"
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <p
                              style={{
                                fontSize: "12px",
                                margin: "0px",
                                marginTop: "-15px",
                              }}
                            >
                              State Electrical License # EC 13005014
                            </p>
                            <h2
                              style={{
                                textTransform: "uppercase",
                                margin: "0px",
                                marginTop: "10px",
                              }}
                            >
                              Daily Report
                            </h2>
                          </div>
                        </td>
                        <td style={{ fontSize: "16px", fontWeight: "bold" }}>
                          <span>2875 Jupiter Park Dr. Ste 500</span>
                          <br />
                          <span>Jupiter, Florida 33458</span>
                          <br />
                          <span>{"TEL: (561)296-4144"}</span>
                          <br />
                          <span>{"FAX: (561)296-4166"}</span>
                          <br />
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text">
                    <tbody>
                      <tr>
                        {/* <td>
                          <label htmlFor="employees" className="employees">
                            Foreman
                            <br />
                            <select
                              value={
                                updatedDailyReport.userId
                                  ? updatedDailyReport.userId
                                  : dailyReport.userId
                              }
                              name="userId"
                              id="userId"
                              required
                              onChange={handleChange}
                              className="input-field no-border-print"
                            >
                              {employees.map((employee) => (
                                <option key={employee.id} value={employee.id}>
                                  {employee.firstName +
                                    (employee.middleName
                                      ? " " +
                                        employee.middleName +
                                        " " +
                                        employee.lastName
                                      : " " + employee.lastName)}
                                </option>
                              ))}
                            </select>
                          </label>
                        </td> */}
                        <td>
                          <label htmlFor="jobs" className="job">
                            Job
                            <br />
                            <select
                              value={
                                updatedDailyReport.jobId
                                  ? updatedDailyReport.jobId
                                  : state.dailyReport.jobId
                              }
                              name="jobId"
                              id="jobId"
                              required
                              onChange={handleChange}
                              className="input-field no-border-print"
                            >
                              {jobs.map((job) => (
                                <option key={job.id} value={job.id}>
                                  {job.jobNumber} - {job.jobName}
                                </option>
                              ))}
                            </select>
                          </label>
                        </td>
                        <td>
                          <label>
                            Date
                            <br />
                            <DatePicker
                              className="no-border-print"
                              selected={purchaseDate}
                              onChange={handleDateChange}
                            />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="customer" className="customer">
                            Contractor
                            <br />
                            <select
                              value={
                                updatedDailyReport.customerId
                                  ? updatedDailyReport.customerId
                                  : state.dailyReport.customerId
                              }
                              name="customerId"
                              id="customerId"
                              required
                              onChange={handleChange}
                              className="input-field no-border-print"
                            >
                              {customers.map((customer) => (
                                <option key={customer.id} value={customer.id}>
                                  {customer.name}
                                </option>
                              ))}
                            </select>
                          </label>
                        </td>
                        <td>
                          <label>
                            Super
                            <br />
                            <input
                              className="no-border-print"
                              type="text"
                              value={
                                updatedDailyReport.super
                                  ? updatedDailyReport.super
                                  : state.dailyReport.super
                              }
                              onChange={handleChange}
                              id="super"
                              name="super"
                            />
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            Construction Schedule
                            <br />
                            <input
                              type="checkbox"
                              checked={
                                updatedDailyReport.schedule
                                  ? updatedDailyReport.schedule === "AHEAD"
                                  : dailyReport.schedule === "AHEAD"
                              }
                              onChange={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  updatedDailyReport: {
                                    ...prevState.updatedDailyReport,
                                    schedule: "AHEAD",
                                  },
                                }));
                              }}
                            />
                            AHEAD
                            <input
                              type="checkbox"
                              checked={
                                updatedDailyReport.schedule
                                  ? updatedDailyReport.schedule === "ON"
                                  : dailyReport.schedule === "ON"
                              }
                              onChange={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  updatedDailyReport: {
                                    ...prevState.updatedDailyReport,
                                    schedule: "ON",
                                  },
                                }));
                              }}
                            />
                            ON
                            <input
                              type="checkbox"
                              checked={
                                updatedDailyReport.schedule
                                  ? updatedDailyReport.schedule === "BEHIND"
                                  : dailyReport.schedule === "BEHIND"
                              }
                              onChange={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  updatedDailyReport: {
                                    ...prevState.updatedDailyReport,
                                    schedule: "BEHIND",
                                  },
                                }));
                              }}
                            />
                            BEHIND
                          </label>
                        </td>
                        <td className="reason">
                          <label className="reason">
                            Behind Schedule Reason
                            <br />
                            <textarea
                              disabled={
                                updatedDailyReport.schedule
                                  ? updatedDailyReport.schedule === "ON"
                                  : dailyReport.schedule === "ON"
                              }
                              onChange={handleChange}
                              className="reason"
                              name="scheduleReason"
                              id="scheduleReason"
                            />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>
                            Lost Time
                            <br />
                            <input
                              className="no-border-print"
                              type="number"
                              value={
                                updatedDailyReport.lostTime
                                  ? updatedDailyReport.lostTime
                                  : dailyReport.lostTime
                              }
                              onChange={handleChange}
                              name="lostTime"
                              id="lostTime"
                              style={{ width: "40px", textAlign: "right" }}
                            />
                            Hours
                          </label>
                        </td>
                        <td className="reason">
                          <label className="reason">
                            Lost Time Reason
                            <br />
                            <textarea
                              disabled={
                                updatedDailyReport.lostTime
                                  ? updatedDailyReport.lostTime === 0
                                  : dailyReport.lostTime === 0
                              }
                              onChange={handleChange}
                              className="reason"
                              name="lostTimeReason"
                              id="lostTimeReason"
                            />
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="labor">
                    <thead>
                      <tr>
                        <th>Electricians</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{`Manpower Count: ${dailyReport.labor.length}`}</td>
                      </tr>
                      <tr>
                        <td className="electricians">
                          {dailyReport.labor.map((clockAction) => (
                            <span key={clockAction.id}>
                              {`${clockAction.user.firstName} ${clockAction.user.lastName}`}
                            </span>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="labor">
                    <thead>
                      <tr>
                        <th>Laborers</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label>
                            <textarea value={dailyReport.laborers} />
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="subs">
                    <thead>
                      <tr>
                        <th colSpan={2}>Subcontractors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {JSON.parse(dailyReport.subcontractors).map((sub) => (
                        <tr key={sub.subName}>
                          <td>
                            <label>
                              Name
                              <br />
                              <input
                                className="no-border-print"
                                type="text"
                                value={sub.subName}
                              />
                            </label>
                          </td>
                          <td className="reason">
                            <label>
                              Work Performed
                              <br />
                              <textarea value={sub.subWorkPerformed} />
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="text">
                    <thead>
                      <tr>
                        <th>Work Performed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea
                            value={
                              updatedDailyReport.workPerformed
                                ? updatedDailyReport.workPerformed
                                : state.dailyReport.workPerformed
                            }
                            onChange={handleChange}
                            name="workPerformed"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text">
                    <thead>
                      <tr>
                        <th>Inspections</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea value={dailyReport.inspections} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text">
                    <thead>
                      <tr>
                        <th>Hindrances</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea value={dailyReport.hindrance} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text">
                    <thead>
                      <tr>
                        <th>Deliveries</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea value={dailyReport.deliveries} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text">
                    <thead>
                      <tr>
                        <th>Visitors</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea value={dailyReport.visitors} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="text">
                    <thead>
                      <tr>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <textarea value={dailyReport.notes} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {JSON.parse(dailyReport.weather) && (
                    <table className="weather">
                      <thead>
                        <tr>
                          <th colSpan={4} className="title">
                            Weather
                          </th>
                        </tr>
                        <tr>
                          <th>Time</th>
                          <th>Conditions</th>
                          <th>Temp</th>
                          <th>Precip</th>
                        </tr>
                      </thead>
                      <tbody>
                        {JSON.parse(dailyReport.weather).map((hour) => (
                          <tr key={hour.datetime}>
                            <td>{dayjs(hour.datetimeStr).format("hh:mm a")}</td>
                            {hour.conditions === "Overcast" ? (
                              <td>
                                <FontAwesomeIcon icon={faCloud} />{" "}
                                {hour.conditions}
                              </td>
                            ) : hour.conditions === "Partially cloudy" ? (
                              <td>
                                <FontAwesomeIcon icon={faCloudSun} />{" "}
                                {hour.conditions}
                              </td>
                            ) : hour.conditions === "Rain" ? (
                              <td>
                                <FontAwesomeIcon icon={faCloudRain} />{" "}
                                {hour.conditions}
                              </td>
                            ) : (
                              <td>
                                <FontAwesomeIcon icon={faSun} />{" "}
                                {hour.conditions}
                              </td>
                            )}
                            <td>{`${hour.temp.toFixed(0)} F`}</td>
                            <td>{hour.precip.toFixed(1)} in</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                  <Button type="submit">Save Changes</Button>
                </div>
              </div>
            </fieldset>
          </DailyReportForm>
        </div>
      </DailyReport>
    )
  );
};

EditDailyReport.propTypes = {
  id: PropTypes.string,
  editDailyReport: PropTypes.array,
  onEditDailyReport: PropTypes.func,
  onDeselectDailyReport: PropTypes.func,
  onDeleteDailyReport: PropTypes.func,
};

export default EditDailyReport;
