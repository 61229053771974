import styled from "styled-components";

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid #ccc;
  max-height: 300px; /* Set a maximum height for the table to enable scrolling */
  padding-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed; /* Use fixed table layout for precise column widths */
`;

const TableRow = styled.tr`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(
    auto-fit,
    minmax(30px, 1fr)
  ); /* Define the column widths */
`;

const PlaceholderRow = styled.tr`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(
    auto-fit,
    minmax(30px, 1fr)
  ); /* Define the column widths */
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ccc;
  height: 30px;
  outline: ${(props) => (props.active ? "2px solid blue" : "none")};
  grid-column: span 4;
`;

const InputCell = styled.td`
  border: 1px solid #ccc;
  outline: 2px solid blue;
  position: relative;
  height: 30px;
  grid-column: span 4;
  input {
    border: none;
    width: 95%;
    height: 100%;
    outline: none;
  }
`;

const DummyInputCell = styled.td`
  border: 1px solid #ccc;
  height: 30px;
  position: relative;
  grid-column: span 4;
  input {
    border: none;
    padding: 5px;
    width: 95%;
    outline: none;
  }
`;

const SelectCell = styled.td`
  border: 1px solid #ccc;
  height: 30px;
  grid-column: span 4;
  outline: ${(props) => (props.active ? "2px solid blue" : "none")};
  position: relative;
`;

const RowNumberCell = styled.td`
  border: 1px solid #ccc;
  height: 30px;
  background-color: #f0f0f0;
  font-weight: bold;
  transition: background-color 0.2s; /* Add a transition for a smooth color change on hover */

  &:hover {
    background-color: lightgray; /* Change the background color on hover */
  }
`;

const TableHead = styled.thead`
  background-color: #f0f0f0;
`;

const TableRowNumberHeaderCell = styled.th`
  padding: 7px;
  text-align: center;
  border-bottom: 2px solid #ccc;
`;
const TableHeaderCell = styled.th`
  padding: 7px;
  text-align: center;
  border-bottom: 2px solid #ccc;
  grid-column: span 4;
`;

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: "none", // Remove the outer border
    padding: "0", // Remove padding
    minHeight: "30px",
    height: "30px",
    boxShadow: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: "0", // Remove margin
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "12px", // Adjust the font size as needed
    maxHeight: "150px",
  }),
};

export {
  TableContainer,
  Table,
  TableRow,
  PlaceholderRow,
  TableCell,
  InputCell,
  DummyInputCell,
  SelectCell,
  RowNumberCell,
  TableHead,
  TableRowNumberHeaderCell,
  TableHeaderCell,
  selectStyles,
};
