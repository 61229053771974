import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../env";
import ForgotPassword from "./ForgotPassword";
import Form from "./styles/Form";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetValid, setResetValid] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };
    fetch(`${API_URL}/verifyreset/${params.token}`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setEmail(json.email);
        setResetValid(json.message);
      });
  }, []);
  return resetValid === "valid" ? (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
          credentials: "include",
        };
        await fetch(`${API_URL}/reset`, requestOptions)
          .then((response) => response.json())
          .then(() => navigate("/login"));
      }}
    >
      <fieldset>
        <h2>Reset Password</h2>
        <div style={{ height: "50px" }}>
          {password !== confirmPassword && (
            <span style={{ color: "red" }}>Passwords Do Not Match</span>
          )}
        </div>
        <label htmlFor="email">
          Password
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          Confirm Password
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </label>

        <button
          type="submit"
          disabled={password !== confirmPassword && password === ""}
        >
          Reset Password
        </button>
      </fieldset>
    </Form>
  ) : (
    <div>
      <h3 style={{ color: "red" }}>
        Password reset link is invalid or has expired. Try requesting a reset
        password email again below.
      </h3>
      <ForgotPassword />
    </div>
  );
};

export default ResetPassword;
