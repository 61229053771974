import React, { useEffect, useState, Fragment } from "react";
import _ from "lodash";
import Select from "react-select";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import CustomStyles from "../../styles/SelectCustomStyle";
import { API_URL } from "../../env";

const JobHoursDetailReport = styled.div`
  #footer {
    width: 50%;
  }
`;

const Header = styled.div`
  @media print {
    .header {
      display: none !important;
    }
    .print-header {
      justify-items: left;
      width: 100%;
      margin: 0px auto 0px auto;
      display: grid !important;
      grid-template-columns: 3fr 1fr 1fr;
      margin-bottom: 10px;
    }
  }
  .date-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
  }
  .print-header {
    display: none;
  }
  .header {
    width: 500px;
    margin: 0px auto 0px auto;
  }
  margin: 0px auto 0px auto;
`;

const HoursDetailTable = styled.table`
  border-collapse: collapse;
  margin: 10px auto 0px auto;
  th,
  td {
    border: 1px solid black;
    padding: 0px 10px;
    text-align: left;
  }
  td {
    text-align: left;
  }
`;

const JobHoursDetail = () => {
  const [hours, setHours] = useState([]);
  const [jobId, setJobId] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
  }, []);

  useEffect(() => {
    if (jobId) {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `${API_URL}/jobhours/detail/${jobId}/${
          startDate === ""
            ? "null"
            : dayjs(startDate).startOf("day").toISOString()
        }/${
          endDate === "" ? "null" : dayjs(endDate).endOf("day").toISOString()
        }`,
        requestOptions
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.clockActions.length > 0 || json.timeRecords.length > 0) {
            // setHours(json);
            setHours([
              ...json.clockActions.map((clock) => ({
                clockAction: { task: clock.task },
                clockIn: clock.clockIn,
                clockOut: clock.clockOut,
                id: clock.id,
                job: clock.job,
                payClass: "Unprocessed",
                user: clock.user,
                duration:
                  clock.clockOut && clock.clockIn
                    ? parseFloat(
                        dayjs(clock.clockOut).diff(dayjs(clock.clockIn)) /
                          3600000
                      )
                    : 0,
              })),
              ...json.timeRecords,
            ]);
            setLoading(false);
          } else {
            setHours(json);
            setLoading(false);
          }
        });
    }
  }, [jobId, startDate, endDate]);
  useEffect(() => {
    jobs.length > 0 > 0 && setLoading(false);
  }, [jobs]);
  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const sortedHours =
    hours?.length > 0
      ? _.chain(hours.sort((a, b) => new Date(a.clockIn) - new Date(b.clockIn)))
          .groupBy((obj) => obj.clockIn.substr(0, 10))
          .map((value, key) => ({ name: key, timeRecords: value }))
          .value()
      : [];

  const selectedJobIndex = jobId
    ? jobs.findIndex((job) => job.id === jobId)
    : null;
  return (
    <JobHoursDetailReport>
      <h1>Job Hours Detail</h1>
      <Header>
        {jobId && (
          <div className="print-header">
            <span
              style={{ fontWeight: "bold" }}
            >{`Job: ${jobs[selectedJobIndex].jobNumber} - ${jobs[selectedJobIndex].jobName}`}</span>
            {startDate && (
              <span style={{ fontWeight: "bold" }}>{`From: ${dayjs(
                startDate
              ).format("M/D/YYYY")}`}</span>
            )}
            {endDate && (
              <span style={{ fontWeight: "bold" }}>{`To: ${dayjs(
                endDate
              ).format("M/D/YYYY")}`}</span>
            )}
          </div>
        )}
        <div className="header">
          <div className="date-header">
            <div>
              <span style={{ fontWeight: "bold" }}>From:</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>To:</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
          <Select
            styles={CustomStyles}
            options={jobList}
            isLoading={loading}
            name="jobId"
            onChange={(e) => setJobId(e.value)}
            defaultValue={jobId}
            placeholder="Select Job"
          />
        </div>
      </Header>
      <div>
        {sortedHours?.length > 0 ? (
          <div>
            <HoursDetailTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Job</th>
                  <th>Cost Code</th>
                  <th>Sign In</th>
                  <th>Sign Out</th>
                  <th>Pay Class</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {sortedHours.map((obj) =>
                  obj.timeRecords.map((timeRecord, index) =>
                    index === obj.timeRecords.length - 1 ? (
                      <Fragment key={timeRecord.id}>
                        <tr>
                          <td>{`${timeRecord.user.firstName} ${timeRecord.user.lastName}`}</td>
                          <td>{`${timeRecord.job.jobNumber} - ${timeRecord.job.jobName}`}</td>
                          <td>{`${timeRecord.clockAction.task.taskCode} - ${timeRecord.clockAction.task.taskName}`}</td>
                          <td>
                            {dayjs(timeRecord.clockIn).format(
                              "ddd MMM DD, YYYY h:mm a"
                            )}
                          </td>
                          <td>
                            {dayjs(timeRecord.clockOut).format(
                              "ddd MMM DD, YYYY h:mm a"
                            )}
                          </td>
                          <td>{timeRecord.payClass}</td>
                          <td>{timeRecord.duration.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td
                            colSpan="6"
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {`${dayjs(obj.name).format("M/D/YY")} Total: `}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {obj.timeRecords
                              .reduce(
                                (prev, cur) =>
                                  prev +
                                  (cur.clockIn && cur.clockOut
                                    ? dayjs(cur.clockOut).diff(
                                        dayjs(cur.clockIn)
                                      ) / 3600000
                                    : 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                        </tr>
                      </Fragment>
                    ) : (
                      <tr key={timeRecord.id}>
                        <td>{`${timeRecord.user.firstName} ${timeRecord.user.lastName}`}</td>
                        <td>{`${timeRecord.job.jobNumber} - ${timeRecord.job.jobName}`}</td>
                        <td>{`${timeRecord.clockAction.task.taskCode} - ${timeRecord.clockAction.task.taskName}`}</td>
                        <td>
                          {dayjs(timeRecord.clockIn).format(
                            "ddd MMM DD, YYYY h:mm a"
                          )}
                        </td>
                        <td>
                          {dayjs(timeRecord.clockOut).format(
                            "ddd MMM DD, YYYY h:mm a"
                          )}
                        </td>
                        <td>{timeRecord.payClass}</td>
                        <td>{timeRecord.duration.toFixed(2)}</td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </HoursDetailTable>
            <HoursDetailTable id="footer">
              <tr>
                <td
                  colSpan="6"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  ST Total:{" "}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {hours
                    .reduce(
                      (prev, cur) =>
                        prev +
                        (cur.clockIn && cur.clockOut && cur.payClass === "ST"
                          ? dayjs(cur.clockOut).diff(dayjs(cur.clockIn)) /
                            3600000
                          : 0),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="6"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  OT Total:{" "}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {hours
                    .reduce(
                      (prev, cur) =>
                        prev +
                        (cur.clockIn && cur.clockOut && cur.payClass === "OT"
                          ? dayjs(cur.clockOut).diff(dayjs(cur.clockIn)) /
                            3600000
                          : 0),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
              {hours.some((clock) => clock.payClass === "Unprocessed") && (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Unprocessed Total:{" "}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {hours
                      .reduce(
                        (prev, cur) =>
                          prev +
                          (cur.clockIn &&
                          cur.clockOut &&
                          cur.payClass === "Unprocessed"
                            ? dayjs(cur.clockOut).diff(dayjs(cur.clockIn)) /
                              3600000
                            : 0),
                        0
                      )
                      .toFixed(2)}
                  </td>
                </tr>
              )}
              <tr>
                <td
                  colSpan="6"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Grand Total:{" "}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {hours
                    .reduce(
                      (prev, cur) =>
                        prev +
                        (cur.clockIn && cur.clockOut
                          ? dayjs(cur.clockOut).diff(dayjs(cur.clockIn)) /
                            3600000
                          : 0),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
            </HoursDetailTable>
          </div>
        ) : (
          !loading &&
          jobId && (
            <div>
              <p>No time found for this criteria</p>
            </div>
          )
        )}
      </div>
    </JobHoursDetailReport>
  );
};

export default JobHoursDetail;
