import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import { Button } from "../../styles/Style";
import "react-datepicker/dist/react-datepicker.css";
import SingleTimeCardRow from "../TimeCards/SingleTimeCardRow";
import TimeCardTable from "../TimeCards/styles/TimeCardStyles";
import EmployeeField from "../Time/EmployeeField";
import styled from "styled-components";
import { API_URL } from "../../env";

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
    margin-top: 100px;
    th {
      color: black;
    }
  }
`;

const DateContainer = styled.div`
  @media print {
    display: none;
  }
  h2 {
    text-transform: uppercase;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: right;
    display: inline-flex;
    margin-bottom: 25px;

    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const SalaryTimeCardReport = () => {
  !localStorage.getItem("startDate") &&
    localStorage.setItem("startDate", start.toISOString());
  !localStorage.getItem("endDate") &&
    localStorage.setItem("endDate", end.toISOString());
  const [timeCards, setTimeCards] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(localStorage.getItem("startDate"))
  );
  const [endDate, setEndDate] = useState(
    new Date(localStorage.getItem("endDate"))
  );
  const [employees, setEmployees] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/salaryemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);
  useEffect(() => {
    document.title = `Salary Timecards WE ${dayjs(endDate).format("MM-DD-YY")}`;
    return () => {
      document.title = `Tool Time`;
    };
  }, [endDate]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/timecards/${startDate.toISOString()}/${endDate.toISOString()}${
        employeeFilter.length > 0
          ? `/${encodeURIComponent(
              JSON.stringify(employeeFilter.map((employee) => employee.value))
            )}`
          : ""
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setTimeCards(json));
  }, []);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setStartDate(start);
    localStorage.setItem("startDate", start.toISOString());
    setEndDate(newEnd);
    localStorage.setItem("endDate", newEnd.toISOString());
  };

  const onDateFilter = () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/timecards/${startDate.toISOString()}/${endDate.toISOString()}${
        employeeFilter.length > 0
          ? `/${encodeURIComponent(
              JSON.stringify(employeeFilter.map((employee) => employee.value))
            )}`
          : ""
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setTimeCards(json));
  };

  const handleUserChange = (e) => {
    const userList = e.map((e) => ({ value: e.value, label: e.label }));
    setEmployeeFilter(userList);
  };

  return (
    <div>
      <DateContainer>
        <h2>Timecard Report</h2>
        <div style={{ width: "500px", margin: "auto" }}>
          <EmployeeField
            employees={employees}
            userId={employeeFilter}
            handleUserChange={handleUserChange}
          />
        </div>
        <br />
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(startDate, 1), subWeeks(endDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(startDate, 1), subDays(endDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(startDate, 1), addDays(endDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(startDate, 1), addWeeks(endDate, 1)])
            }
          >
            {">>"}
          </Button>
          <Button className="button-margin-1" onClick={onDateFilter}>
            GO
          </Button>
        </div>
        <div style={{ width: "95%" }}>
          <Button
            style={{ display: "block", marginLeft: "auto" }}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </div>
      </DateContainer>
      <div>
        {timeCards.map((timeCard) => {
          const mondayTotal = timeCard.timeCardItems
            .map((day) => day.hours[0])
            .reduce((prev, cur) => prev + Number(cur), 0);

          const tuesdayTotal = timeCard.timeCardItems
            .map((day) => day.hours[1])
            .reduce((prev, cur) => prev + Number(cur), 0);
          const wednesdayTotal = timeCard.timeCardItems
            .map((day) => day.hours[2])
            .reduce((prev, cur) => prev + Number(cur), 0);
          const thursdayTotal = timeCard.timeCardItems
            .map((day) => day.hours[3])
            .reduce((prev, cur) => prev + Number(cur), 0);
          const fridayTotal = timeCard.timeCardItems
            .map((day) => day.hours[4])
            .reduce((prev, cur) => prev + Number(cur), 0);
          const saturdayTotal = timeCard.timeCardItems
            .map((day) => day.hours[5])
            .reduce((prev, cur) => prev + Number(cur), 0);
          const sundayTotal = timeCard.timeCardItems
            .map((day) => day.hours[6])
            .reduce((prev, cur) => prev + Number(cur), 0);
          const grandTotal =
            mondayTotal +
            tuesdayTotal +
            wednesdayTotal +
            thursdayTotal +
            fridayTotal +
            saturdayTotal +
            sundayTotal;
          return (
            <PageBreak key={timeCard.id}>
              <h2>
                {timeCard.user.firstName} {timeCard.user.lastName}
              </h2>
              <h3>
                Week Ending: {dayjs(timeCard.weekEnding).format("MM/DD/YY")}
              </h3>
              <TimeCardTable>
                <table>
                  <thead>
                    <tr
                      style={{
                        gridTemplateColumns: "200px 150px repeat(8, 100px)",
                        backgroundColor: "black",
                        color: "white",
                      }}
                    >
                      <th></th>
                      <th></th>
                      <th>Monday</th>
                      <th>Tuesday</th>
                      <th>Wednesday</th>
                      <th>Thursday</th>
                      <th>Friday</th>
                      <th>Saturday</th>
                      <th>Sunday</th>
                      <th></th>
                    </tr>
                    <tr
                      style={{
                        gridTemplateColumns: "200px 150px repeat(8, 100px)",
                        backgroundColor: "black",
                        color: "white",
                      }}
                    >
                      <th>Job</th>
                      <th>Cost Code</th>
                      <th>
                        {dayjs(timeCard.weekEnding)
                          .subtract(6, "day")
                          .format("MM/DD/YY")}
                      </th>
                      <th>
                        {dayjs(timeCard.weekEnding)
                          .subtract(5, "day")
                          .format("MM/DD/YY")}
                      </th>
                      <th>
                        {dayjs(timeCard.weekEnding)
                          .subtract(4, "day")
                          .format("MM/DD/YY")}
                      </th>
                      <th>
                        {dayjs(timeCard.weekEnding)
                          .subtract(3, "day")
                          .format("MM/DD/YY")}
                      </th>
                      <th>
                        {dayjs(timeCard.weekEnding)
                          .subtract(2, "day")
                          .format("MM/DD/YY")}
                      </th>
                      <th>
                        {dayjs(timeCard.weekEnding)
                          .subtract(1, "day")
                          .format("MM/DD/YY")}
                      </th>
                      <th>{dayjs(timeCard.weekEnding).format("MM/DD/YY")}</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeCard.timeCardItems &&
                      timeCard.timeCardItems.map((row, rowIndex) => (
                        <SingleTimeCardRow
                          key={rowIndex}
                          job={`${row.job.jobNumber} - ${row.job.jobName}`}
                          task={`${row.task.taskCode} - ${row.task.taskName}`}
                          hours={row.hours}
                        />
                      ))}
                  </tbody>
                  <tfoot>
                    <tr
                      style={{
                        gridTemplateColumns: "200px 150px repeat(8, 100px)",
                      }}
                    >
                      <td>
                        <input type="text" value="" readOnly />
                      </td>
                      <td>
                        <input type="text" value="Total" readOnly />
                      </td>
                      <td>
                        <input type="text" value={mondayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={tuesdayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={wednesdayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={thursdayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={fridayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={saturdayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={sundayTotal} readOnly />
                      </td>
                      <td>
                        <input type="text" value={grandTotal} readOnly />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </TimeCardTable>
            </PageBreak>
          );
        })}
      </div>
    </div>
  );
};

export default SalaryTimeCardReport;
