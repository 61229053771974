import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../styles/Style";

const ReviewPricingContainer = styled.div`
  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }
  .confirm-panel {
    padding: 50px;
    background-color: white;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: block;
    position: fixed;
    z-index: 25;
  }
  .opaque-panel {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: gray;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
`;

const ReviewPricing = ({
  parsedPricing,
  setPriceReview,
  setPricingReview,
  handleConfirmPricing,
}) => {
  const [confirmedPricing, setConfirmedPricing] = useState(parsedPricing.table);
  const handleTableChange = (e, index) => {
    setConfirmedPricing((prevState) => {
      const { value, name } = e.target;
      prevState[index][name] = value;
      return [...prevState];
    });
  };
  return (
    <ReviewPricingContainer>
      <div className="confirm-panel">
        <h2 style={{ verticalAlign: "middle" }}>Review Pricing</h2>
        <table>
          <tbody>
            <tr>
              <th>Vendor</th>
              <td>{parsedPricing.vendor}</td>
            </tr>
            <tr>
              <th>Quote Number</th>
              <td>{parsedPricing.quoteNumber}</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{parsedPricing.date}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Qty</th>
              <th>UOM</th>
              <th>Description</th>
              <th>Unit Price</th>
              <th>Ext Price</th>
            </tr>
          </thead>
          <tbody>
            {confirmedPricing.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <input
                    style={{ width: "100px" }}
                    name="quantity"
                    onChange={(e) => handleTableChange(e, index)}
                    value={item.quantity}
                  />
                </td>
                <td style={{ textTransform: "uppercase" }}>
                  {item.UOM.toLowerCase()}
                </td>
                <td>
                  <input
                    style={{ width: "350px" }}
                    name="description"
                    onChange={(e) => handleTableChange(e, index)}
                    value={item.description}
                  />
                </td>
                <td>
                  <input
                    style={{ width: "100px" }}
                    name="unitPrice"
                    onChange={(e) => handleTableChange(e, index)}
                    value={item.unitPrice}
                  />
                </td>
                <td>
                  <input
                    style={{ width: "100px" }}
                    name="extPrice"
                    onChange={(e) => handleTableChange(e, index)}
                    value={item.extPrice}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>Subtotal</th>
              <td>{parsedPricing.subtotal}</td>
              <td>
                {parsedPricing.table
                  .reduce((prev, cur) => prev + parseFloat(cur.extPrice), 0)
                  .toFixed(2) === parsedPricing.subtotal ? (
                  <FontAwesomeIcon icon={faCheck} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faX} color="red" />
                )}
              </td>
            </tr>
            <tr>
              <th>Total</th>
              <td>{parsedPricing.total}</td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20%",
            marginTop: "50px",
          }}
        >
          <Button onClick={() => setPriceReview(false)}>Back</Button>
          <Button
            onClick={() => {
              handleConfirmPricing({
                ...parsedPricing,
                table: confirmedPricing,
              });
              setPricingReview(false);
            }}
          >
            Confirm Pricing
          </Button>
        </div>
      </div>
      <div className="opaque-panel" />
    </ReviewPricingContainer>
  );
};

ReviewPricing.propTypes = {
  parsedPricing: PropTypes.object,
  setPriceReview: PropTypes.func,
  setPricingReview: PropTypes.func,
  handleConfirmPricing: PropTypes.func,
};

export default ReviewPricing;
