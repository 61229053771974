import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const JobForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  .map-div {
    height: 200px;
    width: 200px;
  }
  .leaflet-container {
    height: 100%;
  }
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    .job-buttons {
      grid-column: 2/5;
      grid-row: 4;
    }
    button {
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const SingleJob = ({
  editJob,
  onEditJob,
  onDeselectJob,
  setOnToggleConfirm,
}) => {
  const [job, setJob] = useState({});
  const [foremen, setForemen] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [updatedJob, setUpdatedJob] = useState({});
  const [coords, setCoords] = useState(null);

  useEffect(
    () =>
      setJob({
        ...editJob[0],
        projectManagerId:
          editJob[0].projectManagerId === null
            ? ""
            : editJob[0].projectManagerId,
        foremanId: editJob[0].foremanId === null ? "" : editJob[0].foremanId,
      }),
    []
  );

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setForemen(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
    fetch(`${API_URL}/projectmanagers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setProjectManagers(json));
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
  }, []);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const val = type === "number" ? parseInt(value) : value;
    // console.log(type);
    setUpdatedJob((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleCustomerChange = (e) => {
    const { value } = e.target;
    setUpdatedJob((prevState) => ({
      ...prevState,
      customerNumber: parseInt(value),
    }));
  };

  const handleCoordChange = (e) => {
    const { name, value } = e.target;
    setUpdatedJob((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : parseFloat(value),
    }));
    setCoords((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : parseFloat(value),
    }));
  };

  const handleActiveChange = (e) => {
    const { checked, name } = e.target;
    // console.log(name, checked);
    setUpdatedJob((prevState) => ({ ...prevState, [name]: checked }));
  };
  // console.log(updatedJob);
  const getCoords = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jobAddress: editJob.jobAddress ? editJob.jobAddress : job.jobAddress,
        jobAddress2: editJob.jobAddress2
          ? editJob.jobAddress2
          : job.jobAddress2,
        jobCity: editJob.jobCity ? editJob.jobCity : job.jobCity,
        jobState: editJob.jobState ? editJob.jobState : job.jobState,
        jobZip: editJob.jobZip ? editJob.jobZip : job.jobZip,
      }),
    };
    await fetch(`${API_URL}/jobcoord`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setCoords({
          jobLat: json.jobLat,
          jobLng: json.jobLng,
        });
      });
  };

  return (
    job && (
      <div>
        <div>
          <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Edit Job
          </h3>
          <JobForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: coords
                  ? JSON.stringify({ ...updatedJob, ...coords })
                  : JSON.stringify({ ...updatedJob }),
              };
              await fetch(
                `${API_URL}/update/job/${job.id}`,
                requestOptions
              ).then((res) => res.json());
              onEditJob(job.id, { ...updatedJob, ...coords });
              onDeselectJob();
            }}
          >
            <fieldset>
              <div className="fields">
                <div>
                  <label className="job-number">
                    Job Number
                    <br />
                    <input
                      type="text"
                      name="jobNumber"
                      id="jobNumber"
                      defaultValue={job.jobNumber}
                      onChange={handleChange}
                      placeholder="Job Number*"
                      required
                      autoComplete="none"
                    />
                  </label>
                </div>
                <label className="job-name">
                  Job Name
                  <br />
                  <input
                    type="text"
                    name="jobName"
                    id="jobName"
                    defaultValue={job.jobName}
                    onChange={handleChange}
                    placeholder="Job Name*"
                    required
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="active">
                  Active
                  <input
                    type="checkbox"
                    name="active"
                    id="active"
                    // defaultChecked={job.active}
                    checked={
                      updatedJob.active === true || updatedJob.active === false
                        ? updatedJob.active
                        : job.active
                    }
                    onChange={(e) => handleActiveChange(e)}
                  />
                </label>
                <label>
                  PM
                  <br />
                  <select
                    name="projectManagerId"
                    id="projectManagerId"
                    value={
                      updatedJob.projectManagerId ||
                      updatedJob.projectManagerId === ""
                        ? updatedJob.projectManagerId
                        : job.projectManagerId
                    }
                    onChange={handleChange}
                  >
                    {<option value={""}>Select A PM</option>}
                    {projectManagers.map((pm) => (
                      <option key={pm.id} value={pm.id}>
                        {`${pm.firstName} ${pm.lastName}`}
                      </option>
                    ))}
                  </select>
                  <select
                    name="assistantProjectManagerId"
                    id="assistantProjectManagerId"
                    value={
                      updatedJob.assistantProjectManagerId ||
                      updatedJob.assistantProjectManagerId === ""
                        ? updatedJob.assistantProjectManagerId
                        : job.assistantProjectManagerId
                    }
                    onChange={handleChange}
                  >
                    {<option value={""}>Select A PM</option>}
                    {projectManagers
                      .filter((pm) =>
                        updatedJob.projectManagerId
                          ? updatedJob.projectManagerId !== pm.id
                          : job.projectManagerId !== pm.id
                      )
                      .map((pm) => (
                        <option key={pm.id} value={pm.id}>
                          {`${pm.firstName} ${pm.lastName}`}
                        </option>
                      ))}
                  </select>
                </label>
                <label>
                  Foreman
                  <br />
                  <select
                    name="foremanId"
                    id="foremanId"
                    value={
                      updatedJob.foremanId
                        ? updatedJob.foremanId
                        : job.foremanId
                    }
                    onChange={handleChange}
                  >
                    {<option value={""}>Select A Foreman</option>}
                    {foremen.map((foreman) => (
                      <option key={foreman.id} value={foreman.id}>
                        {`${foreman.firstName} ${foreman.lastName}`}
                      </option>
                    ))}
                  </select>
                  <select
                    name="secondForemanId"
                    id="secondForemanId"
                    value={
                      updatedJob.secondForemanId
                        ? updatedJob.secondForemanId
                        : job.secondForemanId
                    }
                    onChange={handleChange}
                  >
                    {<option value={""}>Select A Foreman</option>}
                    {foremen
                      .filter((fm) =>
                        updatedJob.foremanId
                          ? updatedJob.foremanId !== fm.id
                          : job.foremanId !== fm.id
                      )
                      .map((foreman) => (
                        <option key={foreman.id} value={foreman.id}>
                          {`${foreman.firstName} ${foreman.lastName}`}
                        </option>
                      ))}
                  </select>
                </label>
                <label>
                  Customer
                  <select
                    name="customerNumber"
                    id="customerNumber"
                    value={
                      updatedJob.customerNumber
                        ? updatedJob.customerNumber
                        : job.customerNumber
                    }
                    onChange={handleCustomerChange}
                  >
                    {job.customerNumber === null && (
                      <option value={""}>Select A Customer</option>
                    )}
                    {customers.map((customer) => (
                      <option
                        key={customer.customerNumber}
                        value={customer.customerNumber}
                      >
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label htmlFor="jobAddress" className="job-address">
                  Job Address
                  <br />
                  <input
                    type="text"
                    name="jobAddress"
                    id="jobAddress"
                    defaultValue={job.jobAddress}
                    onChange={handleChange}
                    placeholder="Job Address"
                    autoComplete="none"
                  />
                  <input
                    type="text"
                    name="jobAddress2"
                    id="jobAddress2"
                    defaultValue={job.jobAddress2}
                    onChange={handleChange}
                    placeholder="Job Address 2"
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="jobCity" className="job-city">
                  Job City
                  <br />
                  <input
                    type="text"
                    name="jobCity"
                    id="jobCity"
                    defaultValue={job.jobCity}
                    onChange={handleChange}
                    placeholder="Job City"
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="jobState" className="job-state">
                  Job State
                  <br />
                  <input
                    type="text"
                    name="jobState"
                    id="jobState"
                    defaultValue={job.jobState}
                    onChange={handleChange}
                    placeholder="Job State"
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="jobZip" className="job-zip">
                  Job Zip
                  <br />
                  <input
                    type="text"
                    name="jobZip"
                    id="jobZip"
                    defaultValue={job.jobZip}
                    onChange={handleChange}
                    placeholder="Job Zip"
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="jobCoords" className="job-coords">
                  Job Lattitude
                  <br />
                  <input
                    defaultValue={coords ? coords.jobLat : job.jobLat}
                    onChange={handleCoordChange}
                    type="number"
                    name="jobLat"
                    id="jobLat"
                    placeholder="Job Lattitude"
                    step="any"
                  />
                  <br />
                  Job Longitude
                  <input
                    defaultValue={coords ? coords.jobLng : job.jobLng}
                    onChange={handleCoordChange}
                    type="number"
                    name="jobLng"
                    id="jobLng"
                    placeholder="Job Longitude"
                    step="any"
                  />
                  <Button onClick={getCoords}>Get GPS Coord</Button>
                </label>
                {job.jobLat && job.jobLng && (
                  <div className="map-div">
                    <MapContainer
                      className="map-container"
                      center={[
                        updatedJob.jobLat ? updatedJob.jobLat : job.jobLat,
                        updatedJob.jobLng ? updatedJob.jobLng : job.jobLng,
                      ]}
                      zoom={13}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {job.jobLat &&
                        job.jobLng &&
                        !updatedJob.jobLat &&
                        !updatedJob.jobLng && (
                          <Marker
                            position={[job.jobLat, job.jobLng]}
                            icon={
                              new Icon({
                                iconUrl: markerIconPng,
                                iconSize: [23, 36],
                                iconAnchor: [12, 41],
                              })
                            }
                          ></Marker>
                        )}
                      {updatedJob.jobLat && updatedJob.jobLng && (
                        <Marker
                          position={[updatedJob.jobLat, updatedJob.jobLng]}
                          icon={
                            new Icon({
                              iconUrl: markerIconPng,
                              iconSize: [23, 36],
                              iconAnchor: [12, 41],
                            })
                          }
                        ></Marker>
                      )}
                    </MapContainer>
                  </div>
                )}
                <label
                  htmlFor="nextPurchaseOrderNumber"
                  className="nextPurchaseOrderNumber"
                >
                  Next PO#
                  <br />
                  <input
                    type="number"
                    name="nextPurchaseOrderNumber"
                    id="nextPurchaseOrderNumber"
                    value={
                      updatedJob?.nextPurchaseOrderNumber
                        ? updatedJob.nextPurchaseOrderNumber
                        : job.nextPurchaseOrderNumber
                    }
                    onChange={handleChange}
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="jobFieldWorkOrderRCO">
                  <input
                    type="checkbox"
                    name="jobFieldWorkOrderRCO"
                    id="jobFieldWorkOrderRCO"
                    checked={
                      updatedJob.jobFieldWorkOrderRCO === true ||
                      updatedJob.jobFieldWorkOrderRCO === false
                        ? updatedJob.jobFieldWorkOrderRCO
                        : job.jobFieldWorkOrderRCO
                    }
                    onChange={(e) => handleActiveChange(e)}
                  />
                  Enable Field Work Order RCO
                </label>
                <label>
                  Job Type
                  <select
                    name="jobType"
                    id="jobType"
                    value={
                      updatedJob.jobType ? updatedJob.jobType : job.jobType
                    }
                    onChange={handleChange}
                  >
                    <option value={""}>Select A Job Type</option>
                    <option value={"QUOTE"}>QUOTE</option>
                    <option value={"TM"}>T&M</option>
                    <option value={"CONTRACT"}>CONTRACT</option>
                  </select>
                </label>
                {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                <div className="job-buttons">
                  <Button
                    type="button"
                    style={{ background: "red" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOnToggleConfirm(true);
                    }}
                  >
                    Delete Job
                  </Button>
                  <Button type="submit">Save Changes</Button>
                </div>
              </div>
            </fieldset>
          </JobForm>
        </div>
      </div>
    )
  );
};

SingleJob.propTypes = {
  id: PropTypes.string,
  editJob: PropTypes.array,
  onEditJob: PropTypes.func,
  onDeselectJob: PropTypes.func,
  setOnToggleConfirm: PropTypes.func,
};

export default SingleJob;
