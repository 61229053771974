import "./styles/App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import Home from "./components/Home/Home";
import Employees from "./components/Employees/Employees";
import TimeCards from "./components/TimeCards/TimeCards";
import SingleTimeCard from "./components/TimeCards/SingleTimeCard";
import CreateUser from "./components/Employees/CreateEmployee";
import CreateDepartment from "./components/Departments/CreateDepartment";
import Departments from "./components/Departments/Departments";
import SingleDepartment from "./components/Departments/SingleDepartment";
import Tasks from "./components/Tasks/Tasks";
import Login from "./components/Auth/Login";
import ResetPassword from "./components/Auth/ResetPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Nav from "./components/Nav/Nav";
import CreateTask from "./components/Tasks/CreateTask";
import Jobs from "./components/Jobs/Jobs";
import CreateJob from "./components/Jobs/CreateJob";
import Time from "./components/Time/Time";
import MyTimeCard from "./components/TimeCards/MyTimeCard";
import TimeCard from "./components/TimeCards/TimeCard";
import CreateTimeRecords from "./components/Time/CreateTimeRecords";
import CreateMultipleTimeRecords from "./components/Time/CreateMultipleTimeRecords";
import Settings from "./components/Settings/Settings";
import AdminSettings from "./components/Settings/AdminSettings";
import DailyReport from "./components/Forms/DailyReport/DailyReport";
import CreateDailyReport from "./components/Forms/DailyReport/CreateDailyReport";
import CreateWorkOrder from "./components/Forms/WorkOrder/CreateWorkOrder";
import CreateExpense from "./components/Forms/ExpenseReport/CreateExpense";
import Expenses from "./components/Forms/ExpenseReport/Expenses";
import ExportHourly from "./components/Exports/ExportHourly";
import ExportSalary from "./components/Exports/ExportSalary";
import ExportPO from "./components/Material/ExportPO/ExportPO";
import Customers from "./components/Customers/Customers";
import CreateCustomer from "./components/Customers/CreateCustomer";
import CreateTool from "./components/ToolTracker/CreateTool";
import Tools from "./components/ToolTracker/Tools";
import CreateLocation from "./components/ToolTracker/Locations/CreateLocation";
import Locations from "./components/ToolTracker/Locations/Locations";
import Manufacturers from "./components/ToolTracker/Manufacturers/Manufacturers";
import CreateManufacturer from "./components/ToolTracker/Manufacturers/CreateManufacturer";
import ToolCategories from "./components/ToolTracker/ToolCategories/ToolCategories";
import CreateToolCategory from "./components/ToolTracker/ToolCategories/CreateToolCategory";
import TimeClockDashboard from "./components/Dashboard/TimeClockDashboard";
import CreateDevice from "./components/Devices/CreateDevice";
import UploadJobs from "./components/Jobs/UploadJobs";
import UploadTasks from "./components/Tasks/UploadTasks";
import UploadEmployees from "./components/Employees/UploadEmployees";
import UploadCustomers from "./components/Customers/UploadCustomers";
import Devices from "./components/Devices/Devices";
import WorkOrders from "./components/Forms/WorkOrder/WorkOrders";
import SingleWorkOrder from "./components/Forms/WorkOrder/SingleWorkOrder";
import SingleDailyReport from "./components/Forms/DailyReport/SingleDailyReport";
import TopMenu from "./components/Nav/TopMenu";
import MyTimeCards from "./components/TimeCards/MyTimeCards";
import EditTimeCard from "./components/TimeCards/EditTimeCard";
import ActivateAccount from "./components/Auth/ActivateAccount";
import SalaryTimeCardReport from "./components/Reports/SalaryTimeCardReport";
import HourlyTimeCardReport from "./components/Reports/HourlyTimeCardReport";
import AllTimeCardReport from "./components/Reports/AllTimeCardReport";
import GangSheetReport from "./components/Reports/GangSheetReport";
import EmployeeExpenseReport from "./components/Reports/EmployeeExpenseReport";
import AppDownload from "./components/AppDownload/AppDownload";
import JobWorkOrderReport from "./components/Reports/JobWorkOrderReport";
import JobHoursDetail from "./components/Reports/JobHoursDetail";
import JobHoursSummary from "./components/Reports/JobHoursSummary";
import AbsenteeReport from "./components/Reports/AbsenteeReport";
import ManpowerReport from "./components/Reports/ManpowerReport";
import UploadCategories from "./components/ToolTracker/Upload/UploadCategories";
import UploadManufacturers from "./components/ToolTracker/Upload/UploadManufacturers";
import UploadTools from "./components/ToolTracker/Upload/UploadTools";
import CreatePurchaseOrder from "./components/Material/PurchaseOrders/CreatePurchaseOrder";
import BlankPurchaseOrder from "./components/Material/PurchaseOrders/BlankPurchaseOrderRevised";
import PurchaseOrders from "./components/Material/PurchaseOrders/PurchaseOrders";
import SinglePurchaseOrder from "./components/Material/PurchaseOrders/SinglePurchaseOrder";
import Requisitions from "./components/Material/Requisitions/Requisitions";
import SingleRequisition from "./components/Material/Requisitions/SingleRequisition";
import MaterialSettings from "./components/Material/Settings/MaterialSettings";
import Vendors from "./components/Vendors/Vendors";
import UploadVendors from "./components/Vendors/UploadVendors";
import CreateRFQ from "./components/Material/RFQs/CreateRFQ";
import BlankRFQ from "./components/Material/RFQs/BlankRFQ";
import SingleRFQ from "./components/Material/RFQs/SingleRFQ";
import RFQs from "./components/Material/RFQs/RFQs";
import Deliveries from "./components/Material/Deliveries/Deliveries";
import SingleDelivery from "./components/Material/Deliveries/SingleDelivery";
import CreateDelivery from "./components/Material/Deliveries/CreateDelivery";
import Estimates from "./components/Material/Estimates/Estimates";
import SingleEstimate from "./components/Material/Estimates/SingleEstimate";
import UploadEstimate from "./components/Material/Estimates/UploadEstimate";
import ProjectDashboard from "./components/Dashboard/ProjectDashboard";
import BenefitPlans from "./components/Benefits/BenefitPlans";
import BenefitAdmin from "./components/Benefits/BenefitAdmin";
import SelectBenefitPlan from "./components/Benefits/SelectBenefitPlan";
import { API_URL } from "./env";

const AppDiv = styled.div`
  .side-menu {
    background-color: #444445;
    /* background-color: #032bdf; */
    height: 100%;
  }
  .invisible-div {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
  }
  .app-header {
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-gap: 10px;
    margin-right: 10px;
    height: 100%;
  }
`;

const App = () => {
  const [state, setState] = useState({
    loading: true,
    isLoggedIn: false,
    permission: "",
    name: "",
    id: "",
    time: {
      created: "",
      expires: "",
    },
    expired: "",
  });

  const [userSettings, setUserSettings] = useState({
    favorites: "",
    notifications: "",
  });

  const [cart, setCart] = useState([]);
  const [currentCartIndex, setCurrentCartIndex] = useState(0);

  const handleExpired = () => {
    const expired = state.time.created < state.time.expires;
    setState((prevState) => ({
      ...prevState,
      expired,
    }));
  };

  const loggedInState = (
    isLoggedIn,
    permission,
    name,
    id,
    favorites,
    notifications
  ) => {
    setState((prevState) => ({
      ...prevState,
      isLoggedIn,
      permission,
      name,
      id,
    }));
    setUserSettings({
      favorites,
      notifications,
    });
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    try {
      fetch(`${API_URL}/auth`, requestOptions)
        .then((res) => {
          if (res.status !== 200) {
            setState({
              loading: false,
              isLoggedIn: false,
              permission: "",
              name: "",
              id: "",
              benefitEnrollmentEnabled: false,
              time: {
                created: "",
                expires: "",
              },
              expired: "",
            });
            throw new Error(res.status);
          }
          return res.json();
        })
        .then((json) => {
          setState({
            loading: false,
            isLoggedIn: json.isLoggedIn,
            permission: json.permission,
            benefitEnrollmentEnabled: json.benefitEnrollmentEnabled,
            time: json.time,
            name: json.name,
            id: json.id,
          });
          setUserSettings({
            favorites: json.favorites,
            notifications: json.notifications,
          });
        })
        .then(handleExpired());
    } catch (error) {
      console.log(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }, []);

  const updateAuth = () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    try {
      fetch(`${API_URL}/update/auth`, requestOptions)
        .then((res) => res.json())
        .then((json) => setUserSettings(json));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    () => updateAuth();
  }, [updateAuth]);

  return (
    <AppDiv className="App">
      {!state.loading &&
        (state.isLoggedIn && !state.expired ? (
          <header className="app-header">
            <div className="side-menu">
              <Nav
                isLoggedIn={state.isLoggedIn}
                loggedInState={loggedInState}
                permission={state.permission}
                id={state.id}
                benefitEnrollmentEnabled={state.benefitEnrollmentEnabled}
              />
            </div>
            <div>
              <TopMenu
                name={state.name}
                loggedInState={loggedInState}
                cart={cart}
                setCart={setCart}
                currentCartIndex={currentCartIndex}
                setCurrentCartIndex={setCurrentCartIndex}
              />
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                    <Home
                      name={state.name}
                      favorites={userSettings.favorites}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    state.isLoggedIn ? (
                      <Navigate to="/" />
                    ) : (
                      <Login loggedInState={loggedInState} />
                    )
                  }
                />
                <Route
                  path="/forgotpassword"
                  exact
                  element={
                    state.isLoggedIn ? <Navigate to="/" /> : <ForgotPassword />
                  }
                />
                <Route
                  path="/resetpassword/:token"
                  exact
                  element={
                    state.isLoggedIn ? <Navigate to="/" /> : <ResetPassword />
                  }
                />
                <Route
                  path="/settings"
                  exact
                  element={
                    <Settings
                      id={state.id}
                      name={state.name}
                      favorites={userSettings.favorites}
                      notifications={userSettings.notifications}
                      permission={state.permission}
                      updateAuth={updateAuth}
                    />
                  }
                />
                <Route
                  path="/settings/admin"
                  exact
                  element={
                    <AdminSettings
                      id={state.id}
                      name={state.name}
                      permission={state.permission}
                      updateAuth={updateAuth}
                    />
                  }
                />
                <Route path="/employees" exact element={<Employees />} />
                <Route exact path="/create/employee" element={<CreateUser />} />
                <Route
                  exact
                  path="/employees/upload"
                  element={<UploadEmployees />}
                />
                <Route path="/forms/dailies" exact element={<DailyReport />} />
                <Route
                  path="/forms/dailyreport/:id"
                  exact
                  element={<SingleDailyReport />}
                />
                <Route
                  path="/create/forms/dailyreport"
                  exact
                  element={<CreateDailyReport />}
                />
                <Route
                  path="/forms/workorders"
                  exact
                  element={<WorkOrders />}
                />
                <Route
                  path="/forms/workorders/:id"
                  exact
                  element={<SingleWorkOrder />}
                />
                <Route
                  path="/create/forms/workorder"
                  exact
                  element={<CreateWorkOrder />}
                />
                <Route
                  path="/create/forms/expense"
                  exact
                  element={<CreateExpense />}
                />
                <Route path="/forms/expenses" exact element={<Expenses />} />
                <Route path="/tools" exact element={<Tools />} />
                <Route path="/create/tool" exact element={<CreateTool />} />
                <Route path="/tool/locations" exact element={<Locations />} />
                <Route
                  path="/tool/create/location"
                  exact
                  element={<CreateLocation />}
                />
                <Route
                  path="/tool/manufacturers"
                  exact
                  element={<Manufacturers />}
                />
                <Route
                  path="/tool/create/manufacturer"
                  exact
                  element={<CreateManufacturer />}
                />
                <Route
                  path="/tool/categories"
                  exact
                  element={<ToolCategories />}
                />
                <Route
                  path="/tool/create/category"
                  exact
                  element={<CreateToolCategory />}
                />
                <Route path="/upload/tools" exact element={<UploadTools />} />
                <Route
                  path="/upload/categories"
                  exact
                  element={<UploadCategories />}
                />
                <Route
                  path="/upload/manufacturers"
                  exact
                  element={<UploadManufacturers />}
                />
                <Route path="/export/hourly" exact element={<ExportHourly />} />
                <Route path="/export/salary" exact element={<ExportSalary />} />
                <Route path="/departments" exact element={<Departments />} />
                <Route
                  path="/jobs"
                  exact
                  element={<Jobs />}
                  // props={handleExpired}
                />
                <Route exact path="/create/job" element={<CreateJob />} />
                <Route path="/jobs/upload" exact element={<UploadJobs />} />
                <Route
                  path="/department/:id"
                  exact
                  element={<SingleDepartment />}
                />
                <Route
                  exact
                  path="/create/department"
                  element={<CreateDepartment />}
                />
                <Route exact path="/devices" element={<Devices />} />
                <Route
                  exact
                  path="/register/device"
                  element={<CreateDevice />}
                />
                <Route path="/costcodes" exact element={<Tasks />} />
                <Route exact path="/create/costcode" element={<CreateTask />} />
                <Route
                  path="/costcodes/upload"
                  exact
                  element={<UploadTasks />}
                />
                <Route path="/customers" exact element={<Customers />} />
                <Route
                  path="/customers/upload"
                  exact
                  element={<UploadCustomers />}
                />
                <Route
                  exact
                  path="/create/customer"
                  element={<CreateCustomer />}
                />
                <Route path="/timecards" exact element={<TimeCards />} />
                <Route
                  path="/timecard/:id"
                  exact
                  element={<SingleTimeCard />}
                />
                <Route
                  path="/edit/timecard/:id"
                  exact
                  element={<EditTimeCard userId={state.id} />}
                />
                <Route
                  path="/timecards/user/:id"
                  exact
                  element={<MyTimeCards id={state.id} />}
                />
                <Route exact path="/create/timecard" element={<TimeCard />} />
                <Route
                  exact
                  path="/create/mytimecard"
                  element={<MyTimeCard id={state.id} />}
                />
                <Route path="/time" exact element={<Time />} />
                <Route
                  path="/create/timerecord"
                  exact
                  element={<CreateTimeRecords />}
                />
                <Route
                  path="/create/multipletimerecords"
                  exact
                  element={<CreateMultipleTimeRecords />}
                />
                <Route
                  path="/time-clock-dashboard"
                  exact
                  element={
                    <TimeClockDashboard
                      id={state.id}
                      permission={state.permission}
                    />
                  }
                />
                <Route
                  path="/project-dashboard"
                  exact
                  element={
                    <ProjectDashboard
                      id={state.id}
                      permission={state.permission}
                    />
                  }
                />
                <Route
                  path="/reports/gangsheets"
                  exact
                  element={<GangSheetReport />}
                />
                <Route
                  path="/reports/salarytimecards"
                  exact
                  element={<SalaryTimeCardReport />}
                />
                <Route
                  path="/reports/hourlytimecards"
                  exact
                  element={<HourlyTimeCardReport />}
                />
                <Route
                  path="/reports/alltimecards"
                  exact
                  element={<AllTimeCardReport />}
                />
                <Route
                  path="/reports/expenses"
                  exact
                  element={<EmployeeExpenseReport />}
                />
                <Route
                  path="/reports/jobworkorders"
                  exact
                  element={<JobWorkOrderReport />}
                />
                <Route
                  path="/reports/jobhoursdetail"
                  exact
                  element={<JobHoursDetail />}
                />
                <Route
                  path="/reports/jobhourssummary"
                  exact
                  element={<JobHoursSummary />}
                />
                <Route
                  path="/reports/absentee"
                  exact
                  element={<AbsenteeReport />}
                />
                <Route
                  path="/reports/manpower"
                  exact
                  element={<ManpowerReport />}
                />
                <Route path="/vendors" exact element={<Vendors />} />
                <Route
                  path="/upload/vendors"
                  exact
                  element={<UploadVendors />}
                />
                <Route
                  path="/material/settings"
                  exact
                  element={<MaterialSettings />}
                />
                <Route
                  path="/material/requisitions"
                  exact
                  element={<Requisitions userId={state.id} />}
                />
                <Route
                  path="/material/requisition/:id"
                  exact
                  element={<SingleRequisition userId={state.id} />}
                />
                <Route
                  path="/create/purchaseorder"
                  exact
                  element={<CreatePurchaseOrder userId={state.id} />}
                />
                <Route
                  path="/create/blankpurchaseorder"
                  exact
                  element={
                    <BlankPurchaseOrder
                      user={state}
                      cart={cart}
                      setCart={setCart}
                      currentCartIndex={currentCartIndex}
                    />
                  }
                />
                <Route
                  path="/purchaseorders"
                  exact
                  element={<PurchaseOrders userId={state.id} />}
                />
                <Route
                  path="/purchaseorder/:id"
                  exact
                  element={<SinglePurchaseOrder />}
                />
                <Route
                  path="/export/purchaseorders"
                  exact
                  element={<ExportPO />}
                />
                <Route
                  path="/create/rfq"
                  exact
                  element={<CreateRFQ userId={state.id} />}
                />
                <Route
                  path="/create/blankrfq"
                  exact
                  element={
                    <BlankRFQ
                      user={state}
                      cart={cart}
                      setCart={setCart}
                      currentCartIndex={currentCartIndex}
                      setCurrentCartIndex={setCurrentCartIndex}
                    />
                  }
                />
                <Route
                  path="/rfqs"
                  exact
                  element={<RFQs userId={state.id} />}
                />
                <Route
                  path="/deliveries"
                  exact
                  element={<Deliveries userId={state.id} />}
                />
                <Route
                  path="/receive/delivery"
                  exact
                  element={<CreateDelivery userId={state.id} />}
                />
                <Route
                  path="/delivery/:id"
                  exact
                  element={<SingleDelivery />}
                />
                <Route path="/rfq/:id" exact element={<SingleRFQ />} />
                <Route path="/estimates" exact element={<Estimates />} />
                <Route
                  path="/upload/estimate"
                  exact
                  element={<UploadEstimate />}
                />
                <Route
                  path="/estimate/:id"
                  exact
                  element={<SingleEstimate cart={cart} setCart={setCart} />}
                />
                <Route path="/benefit-plans" exact element={<BenefitPlans />} />
                <Route
                  path="/benefit-enrollment"
                  exact
                  element={<BenefitAdmin />}
                />
                <Route
                  path="/benefits/select-plan"
                  exact
                  element={
                    <SelectBenefitPlan
                      userId={state.id}
                      userName={state.name}
                    />
                  }
                />
                <Route path="/download/app" exact element={<AppDownload />} />
              </Routes>
            </div>
          </header>
        ) : (
          <header className="App-header">
            <Nav />
            <Routes>
              <Route
                path="*"
                element={
                  state.isLoggedIn ? (
                    <Navigate to="/" />
                  ) : (
                    <Login loggedInState={loggedInState} />
                  )
                }
              />
              <Route
                path="/forgotpassword"
                exact
                element={<ForgotPassword />}
              />
              <Route
                path="/resetpassword/:token"
                exact
                element={<ResetPassword />}
              />
              <Route
                path="/activate/:token"
                exact
                element={<ActivateAccount />}
              />
            </Routes>
          </header>
        ))}
    </AppDiv>
  );
};

export default App;
