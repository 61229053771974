import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "./styles/Form";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [resetting, setResetting] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [error, setError] = useState("");
  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        setResetting(true);
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
          }),
        };
        try {
          await fetch(`${API_URL}/forgotpassword`, requestOptions)
            .then((res) => {
              if (res.status !== 202) {
                throw new Error(res.statusText);
              }
              return res.json();
            })
            .then(() => {
              setResetting(false);
              setResetSuccess(true);
            });
        } catch (error) {
          setError((prevState) => ({ ...prevState, error: error.message }));
        }
      }}
    >
      <fieldset>
        <h2>Password Reset</h2>
        <p style={{ color: "red" }}>{error}</p>
        {resetSuccess ? (
          <>
            <h3 style={{ color: "green" }}>
              &#9745; Reset Password Email Sent
            </h3>
            <Link to="/login">
              <Button>Back To Login</Button>
            </Link>
          </>
        ) : (
          <>
            <label htmlFor="email">
              Email
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <button type="submit" disabled={resetting}>
              {resetting ? "Sending Reset Email" : "Send Reset Email"}
            </button>
          </>
        )}
      </fieldset>
    </Form>
  );
};

export default ForgotPassword;
