import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Button } from "../../styles/Style";
import SingleTimeCardRow from "./SingleTimeCardRow";
import TimeCardTable from "./styles/TimeCardStyles";
import { API_URL } from "../../env";

const SingleTimeCard = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [timeCard, setTimeCard] = useState([]);

  const { id } = useParams();

  const [mondayTotal, setMondayTotal] = useState([]);
  const [tuesdayTotal, setTuesdayTotal] = useState([]);
  const [wednesdayTotal, setWednesdayTotal] = useState([]);
  const [thursdayTotal, setThursdayTotal] = useState([]);
  const [fridayTotal, setFridayTotal] = useState([]);
  const [saturdayTotal, setSaturdayTotal] = useState([]);
  const [sundayTotal, setSundayTotal] = useState([]);
  const [grandTotal, setGrandTotal] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/timecard/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTimeCard(json))
      .then(setLoading(false));
  }, []);

  useEffect(() => {
    if (timeCard.timeCardItems) {
      setMondayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[0])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setTuesdayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[1])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setWednesdayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[2])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setThursdayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[3])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setFridayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[4])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setSaturdayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[5])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setSundayTotal(
        timeCard.timeCardItems
          .map((day) => day.hours[6])
          .reduce((prev, cur) => prev + Number(cur), 0)
      );
      setGrandTotal(
        mondayTotal +
          tuesdayTotal +
          wednesdayTotal +
          thursdayTotal +
          fridayTotal +
          saturdayTotal +
          sundayTotal
      );
    }
  }, [timeCard]);

  const handleDeleteTimeCard = async () => {
    const requestOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    await fetch(`${API_URL}/delete/timecard/${id}`, requestOptions)
      .then((res) => res.json())
      .then(() => navigate(`/timecards/user/${userId}`));
  };

  return (
    !loading && (
      <div>
        {timeCard.user && (
          <div>
            <h2>
              {`${timeCard.user.firstName} ${timeCard.user.lastName}'${
                timeCard.user.lastName.slice(-1) != "s" ? `s` : ``
              } `}
              Timecard
            </h2>
            <h3>
              Week Ending: {dayjs(timeCard.weekEnding).format("MM/DD/YY")}
            </h3>
          </div>
        )}
        <TimeCardTable>
          <table>
            <thead>
              <tr
                style={{
                  gridTemplateColumns: "200px 150px repeat(8, 100px)",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <th></th>
                <th></th>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
                <th></th>
              </tr>
              <tr
                style={{
                  gridTemplateColumns: "200px 150px repeat(8, 100px)",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <th>Job</th>
                <th>Cost Code</th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(6, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(5, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(4, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(3, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(2, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(1, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>{dayjs(timeCard.weekEnding).format("MM/DD/YY")}</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {timeCard.timeCardItems &&
                timeCard.timeCardItems.map((row, rowIndex) => (
                  <SingleTimeCardRow
                    key={rowIndex}
                    job={`${row.job.jobNumber} - ${row.job.jobName}`}
                    task={`${row.task.taskCode} - ${row.task.taskName}`}
                    hours={row.hours}
                  />
                ))}
            </tbody>
            <tfoot>
              <tr
                style={{
                  gridTemplateColumns: "200px 150px repeat(8, 100px)",
                }}
              >
                <td>
                  <input type="text" value="" readOnly />
                </td>
                <td>
                  <input type="text" value="Total" readOnly />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(mondayTotal) ? "-" : mondayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(tuesdayTotal) ? "-" : tuesdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(wednesdayTotal) ? "-" : wednesdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(thursdayTotal) ? "-" : thursdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(fridayTotal) ? "-" : fridayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(saturdayTotal) ? "-" : saturdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(sundayTotal) ? "-" : sundayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(grandTotal) ? "-" : grandTotal}
                    readOnly
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </TimeCardTable>
        {dayjs().diff(dayjs(timeCard.weekEnding)) < 259200000 && (
          <div style={{ marginTop: "50px" }}>
            <Link to={`/edit/timecard/${id}`}>
              <Button>Edit Timecard</Button>
            </Link>
          </div>
        )}
        <div style={{ marginTop: "50px" }}>
          <Button
            onClick={handleDeleteTimeCard}
            style={{ backgroundColor: "red" }}
          >
            Delete Timecard
          </Button>
        </div>
      </div>
    )
  );
};

SingleTimeCard.propTypes = {
  userId: PropTypes.string,
};

export default SingleTimeCard;
