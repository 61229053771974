import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const TaskForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddTaskForm = () => {
  const [state, setState] = useState({
    taskCode: "",
    taskName: "",
    isPublic: true,
  });

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleActiveChange = (e) => {
    const { name, checked } = e.target;
    setState({
      [name]: checked,
    });
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...state }),
    };
    fetch(`${API_URL}/create/task`, requestOptions).then((response) =>
      response.json()
    );
  };
  const { taskCode, taskName, isPublic } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Add Cost Code
      </h1>
      <div>
        <TaskForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/costcodes");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="taskCode" className="task-code">
                Task Code
                <br />
                <input
                  type="text"
                  name="taskCode"
                  id="taskCode"
                  value={taskCode}
                  onChange={handleChange}
                  placeholder="Task Code*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="taskName" className="task-name">
                Task Name
                <br />
                <input
                  type="text"
                  name="taskName"
                  id="taskName"
                  value={taskName}
                  onChange={handleChange}
                  placeholder="Task Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="isPublic">
                Public
                <input
                  type="checkbox"
                  name="isPublic"
                  id="isPublic"
                  checked={isPublic}
                  onChange={handleActiveChange}
                />
              </label>
              <Button type="submit">Create Cost Code</Button>
            </div>
          </fieldset>
        </TaskForm>
      </div>
    </div>
  );
};

export default AddTaskForm;
