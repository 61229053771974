import React from "react";
import PropTypes from "prop-types";

const TimeCardRow = ({ job, task, hours }) => {
  const total = hours.reduce((prev, cur) => prev + Number(cur), 0);
  return (
    <tr
      style={{
        gridTemplateColumns: "200px 150px repeat(8, 100px)",
      }}
    >
      <td className="job">
        <input type="text" value={job} readOnly style={{ fontSize: "11px" }} />
      </td>
      <td className="task">
        <input type="text" value={task} readOnly style={{ fontSize: "11px" }} />
      </td>
      {hours.map((day, index) => (
        <td key={index}>
          <input type="text" value={day} readOnly />
        </td>
      ))}
      <td>
        <input type="text" value={isNaN(total) ? "-" : total} readOnly />
      </td>
    </tr>
  );
};

TimeCardRow.propTypes = {
  job: PropTypes.string,
  task: PropTypes.string,
  hours: PropTypes.array,
};

export default TimeCardRow;
