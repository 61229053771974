import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import styled from "styled-components";
import dayjs from "dayjs";
import { API_URL } from "../../env";
import { Button } from "../../styles/Style";

const BenefitSelectContainer = styled.div`
  .center-title {
    text-align: center;
  }
  .benefit-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    text-align: left;
    margin-bottom: 2rem;
    justify-content: center;
  }
  .benefit-footer {
    display: grid;
    border-top: 1px solid gray;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    position: sticky;
    bottom: 0;
    background: white;
    padding: 1rem 0px;
    div {
      display: grid;
      margin: auto 0px;
      justify-content: center;
    }
  }
  .description {
    white-space: pre-line;
    margin: 1rem 0px;
  }
  .line {
    height: 1px;
    background: gray;
    width: 100%;
    margin: 1rem 0px;
  }
  .selected {
    border: 3px solid blue !important;
  }
  .waived {
    opacity: 0.5;
  }
  .benefit-card {
    width: 25rem;
    border-radius: 5px;
    border: 1px solid black;
    padding: 2rem 1rem;
  }
  .title .footer-plan-names {
    font-weight: bold;
  }
  .price {
    font-weight: bold;
    font-size: 2rem;
  }
  .buttons {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
  }
`;

const SelectBenefitPlan = ({ userId, userName }) => {
  const [employeeBenefitElections, setEmployeeBenefitElections] =
    useState(null);
  const [benefitPlans, setBenefitPlans] = useState([]);
  const [successPage, setSuccessPage] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/benefit-plans/open-enrollment`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        setBenefitPlans(json);
        setEmployeeBenefitElections(
          json.map((plan) => ({
            employeeId: userId,
            benefitPlanId: plan.id,
            waived: null,
            coverage: null,
          }))
        );
      });
  }, []);

  const errorCheck = () => {
    if (
      !employeeBenefitElections.every(
        (plan) => plan.waived === true || plan.waived === false
      )
    ) {
      setError("Each plan must either be joined or waived.");
      return false;
    }
    let medical = 0;
    let dental = 0;
    let vision = 0;
    for (let i = 0; i < employeeBenefitElections.length; i++) {
      if (employeeBenefitElections[i].waived === false) {
        if (employeeBenefitElections[i].planType === "Medical") {
          medical++;
        }
        if (employeeBenefitElections[i].planType === "Dental") {
          dental++;
        }
        if (employeeBenefitElections[i].planType === "Vision") {
          vision++;
        }
      }
    }
    if (medical > 1 || dental >> 1 || vision > 1) {
      setError(
        `Only 1 ${
          medical > 1 ? "Medical" : dental > 1 ? "Dental" : "Vision"
        } plan can be chosen.`
      );
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const errors = errorCheck();
    if (errors) {
      const requestOptions = {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          employeeBenefitElections.map((benElect) => ({
            ...benElect,
            coverage: benElect.coverage.value,
          }))
        ),
      };
      fetch(`${API_URL}/employee-benefits/submit`, requestOptions)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(() => setSuccessPage(true));
    }
  };

  return !successPage ? (
    employeeBenefitElections?.length > 0 && (
      <BenefitSelectContainer>
        <h1>Employee Benefit Selection</h1>
        <h3>{userName}</h3>
        <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
        <div className="benefit-card-container">
          {benefitPlans.length > 0 &&
            employeeBenefitElections !== null &&
            benefitPlans.map((plan, index) => (
              <div
                key={plan.id}
                className={`benefit-card${
                  employeeBenefitElections[index]?.waived === false
                    ? " selected"
                    : ""
                }${
                  employeeBenefitElections[index]?.waived === true
                    ? " waived"
                    : ""
                }`}
              >
                <h3>{plan.planName}</h3>
                <h3 className="center-title">
                  {employeeBenefitElections[index]?.waived === true
                    ? "-WAIVED-"
                    : employeeBenefitElections[index]?.waived !== null
                    ? "-SELECTED-"
                    : ""}
                </h3>
                <h4>{plan.planType} Plan</h4>
                <div className="line" />
                <p className="title">Coverage Effective</p>
                <p>
                  {`${dayjs(plan.planStartDate).format("M/D/YY")} - ${dayjs(
                    plan.planEndDate
                  ).format("M/D/YY")}`}
                </p>
                <p className="title">Plan Details</p>
                <p className="description">{plan.planDescription}</p>
                <Select
                  options={plan.coverageOptions.map((option) => ({
                    label:
                      option === "Employee"
                        ? "Employee Only"
                        : option === "EmployeeSpouse"
                        ? "Employee + Spouse"
                        : option === "EmployeeChildren"
                        ? "Employee + Children"
                        : "Employee + Family",
                    value: option,
                  }))}
                  onChange={(e) =>
                    setEmployeeBenefitElections((prevState) => {
                      const newState = [...prevState];
                      newState[index].coverage = e;
                      return newState;
                    })
                  }
                  placeholder="Select Who Is Covered..."
                />
                {employeeBenefitElections[index]?.coverage && (
                  <div>
                    <p>Your weekly cost:</p>
                    <span className="price">
                      $
                      {(
                        plan.totalCostPerWeekx100[
                          plan.coverageOptions.indexOf(
                            employeeBenefitElections[index].coverage.value
                          )
                        ] /
                          100 -
                        plan.companyPaysPerWeekx100[0] / 100
                      ).toFixed(2)}
                    </span>
                  </div>
                )}
                <div className="line" />
                <div className="buttons">
                  <Button
                    disabled={employeeBenefitElections[index].waived === true}
                    onClick={() =>
                      setEmployeeBenefitElections((prevState) => {
                        const newState = [...prevState];
                        newState[index].waived = true;
                        newState[index].coverage = null;
                        return newState;
                      })
                    }
                  >
                    Waive Coverage
                  </Button>
                  <Button
                    disabled={employeeBenefitElections[index].waived === false}
                    onClick={() =>
                      setEmployeeBenefitElections((prevState) => {
                        const newState = [...prevState];
                        newState[index].waived = false;
                        newState.forEach(
                          (planIsSameType, planIsSameTypeIndex) => {
                            if (
                              newState[index].benefitPlanId !==
                                planIsSameType.benefitPlanId &&
                              benefitPlans[index].planType ===
                                benefitPlans[planIsSameTypeIndex].planType
                            ) {
                              planIsSameType.waived = true;
                            }
                          }
                        );
                        return newState;
                      })
                    }
                  >
                    Join This Plan
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className="benefit-footer">
          <div style={{ gridTemplateColumns: "1fr 1fr" }}>
            <div>
              <span>Your total weekly cost:</span>

              <span className="price">
                $
                {benefitPlans
                  .reduce(
                    (prev, cur, index) =>
                      prev +
                      (employeeBenefitElections[index]?.coverage &&
                      employeeBenefitElections[index]?.waived === false
                        ? cur.totalCostPerWeekx100[
                            cur.coverageOptions.indexOf(
                              employeeBenefitElections[index].coverage.value
                            )
                          ] /
                            100 -
                          cur.companyPaysPerWeekx100[0] / 100
                        : 0),
                    0
                  )
                  .toFixed(2)}
              </span>
            </div>
            <span className="footer-plan-names">
              {employeeBenefitElections?.length > 0 &&
                employeeBenefitElections
                  .filter((ben) => ben.waived === false)
                  .map((ben) => {
                    const benefitPlanIndex = benefitPlans.findIndex(
                      (benIndex) => ben.benefitPlanId === benIndex.id
                    );
                    return (
                      <>
                        {benefitPlans[benefitPlanIndex].planName} -{" "}
                        {ben.coverage?.label}
                        <br />
                      </>
                    );
                  })}
            </span>
          </div>
          <div>
            <Button
              onClick={() => handleSubmit()}
              disabled={
                employeeBenefitElections.filter((plan) => plan.waived !== null)
                  .length !== benefitPlans.length
              }
            >
              Submit Benefit Selections
            </Button>
          </div>
        </div>
      </BenefitSelectContainer>
    )
  ) : (
    <div>
      <h1>Thank you!</h1>
      {employeeBenefitElections.every((plan) => plan.waived === true) ? (
        <p>You have successfully waived all benefits.</p>
      ) : (
        <>
          <p>You have successfully submitted your benefit elections.</p>
          <p>
            You will be receiving an email with additional paperwork to fill out
            for the plans you have elected.
          </p>
        </>
      )}
    </div>
  );
};

SelectBenefitPlan.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
};

export default SelectBenefitPlan;
