import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const CustomerForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddCustomerForm = () => {
  const [state, setState] = useState({
    customerNumber: "",
    name: "",
    emails: [""],
  });

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseInt(value) : value;
    setState((prevState) => ({ ...prevState, [name]: val }));
  };

  const handleEmailChange = (e, index) => {
    const { value } = e.target;
    let emails = state.emails;
    emails[index] = value;
    setState((prevState) => ({
      ...prevState,
      emails,
    }));
  };

  const addEmail = () => {
    let emails = state.emails;
    emails.push("");
    setState((prevState) => ({
      ...prevState,
      emails,
    }));
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...state }),
    };
    fetch(`${API_URL}/create/customer`, requestOptions).then((response) =>
      response.json()
    );
  };
  const { customerNumber, name, emails } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Customer
      </h1>
      <div>
        <CustomerForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/customers");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="customer-number" className="customer-number">
                Customer Number
                <br />
                <input
                  type="number"
                  name="customerNumber"
                  id="customerNumber"
                  value={customerNumber}
                  onChange={handleChange}
                  placeholder="Customer Number*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="customer-name" className="customer-name">
                Customer Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Customer Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <div>
                <label htmlFor="customer-email" className="customer-email">
                  Customer Email
                  <br />
                  {emails.map((email, i) => (
                    <input
                      type="text"
                      value={email}
                      key={i}
                      onChange={(e) => handleEmailChange(e, i)}
                      placeholder="Customer Email*"
                      autoComplete="none"
                    />
                  ))}
                </label>
                <br />
                <Button type="button" onClick={addEmail}>
                  Add Email
                </Button>
              </div>
              <Button type="submit">Create Customer</Button>
            </div>
          </fieldset>
        </CustomerForm>
      </div>
    </div>
  );
};

export default AddCustomerForm;
