import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import MaterialTableBody from "../Components/MaterialTableBody";
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableRowNumberHeaderCell,
  TableHeaderCell,
} from "../Styles/MaterialTableStyles";
import { Button } from "../../../styles/Style";
import { FormContainer, Column } from "../Styles/MaterialFormStyles";
import CustomStyles from "../../../styles/SelectCustomStyle";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../../env";

const PurchaseOrderContainer = styled.div`
  text-align: left;
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
    }
    .submit-button {
      display: flex;
      justify-content: right;
      height: 50px;
    }
    .order-requisitions {
      button {
        margin-right: 5px;
        margin-bottom: 10px;
      }
    }
  }
  .reqDetails {
    display: grid;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
        h3 {
          margin: 3px 0px;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    table {
      width: 100%;
    }
  }
`;

const SinglePurchaseOrder = () => {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [updatedPurchaseOrder, setUpdatedPurchaseOrder] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [items, setItems] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [generalLedgerCodes, setGeneralLedgerCodes] = useState([]);
  // let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/order/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setPurchaseOrder(json);
        setItems(
          json.items.map((item) => [
            item.item.quantity,
            item.item.unitOfMeasure.id,
            item.item.description,
          ])
        );
        setAttachments(json.attachments === null ? [] : json.attachments);
      })
      .then(setLoading(false));
    fetch(`${API_URL}/activejobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setJobs(json);
      });
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json);
      });
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
    fetch(`${API_URL}/fieldcontacts`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setEmployees(json);
      });
    fetch(`${API_URL}/material/glcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setGeneralLedgerCodes(json);
      });
    fetch(`${API_URL}/material/uoms`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUnitsOfMeasure(json));
    fetch(`${API_URL}/material/taxcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTaxCodes(json);
      });
  }, []);

  const handleChange = ({ target, name }) => {
    setUpdatedPurchaseOrder((prevstate) => ({
      ...prevstate,
      [name]: target.value,
    }));
  };
  const handleSelectChange = ({ value, name }) => {
    setUpdatedPurchaseOrder((prevstate) => ({ ...prevstate, [name]: value }));
  };
  const handleDateChange = (value) => {
    setUpdatedPurchaseOrder((prevstate) => ({
      ...prevstate,
      needByDate: value,
    }));
  };
  const handleAddAttachment = (e) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      // Split the filename to get the name and type
      let fileParts = file.name.split(".");
      let contentType = fileParts[1];
      const reader = new FileReader();
      reader.onloadend = () => {
        // const pdfString = reader.result;
        setAttachments((prevState) => [
          ...prevState,
          { file: e.target.files[0], fileName },
        ]);
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fileName,
            contentType,
          }),
        };
        fetch(`${API_URL}/order/upload/attachments/${id}`, requestOptions)
          .then((res) => res.json())
          .then((res) => {
            const requestOptions = {
              method: "PUT",
              headers: { "Content-Type": "application/pdf" },
              body: file,
            };
            fetch(res.url, requestOptions).catch((error) =>
              alert("ERROR" + JSON.stringify(error))
            );
          })
          .catch((error) => alert(JSON.stringify(error)));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const uomList = unitsOfMeasure.map((uom) => ({
    value: uom.id,
    label: uom.code,
  }));

  const employeeList = employees.map((employee) => ({
    value: employee.id,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const vendorList = vendors.map((vendor) => ({
    value: vendor.id,
    label: vendor.vendorName,
  }));
  const glCodeList = generalLedgerCodes.map((gl) => ({
    value: gl.id,
    label: `${gl.generalLedgerCode} - ${gl.generalLedgerName}`,
  }));
  const costCodeList = tasks.map((taskCode) => ({
    value: taskCode.id,
    label: `${taskCode.taskCode} - ${taskCode.taskName}`,
  }));
  const taxCodeList = taxCodes.map((tax) => ({
    value: tax.id,
    label: tax.taxCode,
  }));
  const windowList = [
    { value: "ASAP", label: "ASAP" },
    { value: "AMEarly", label: "7AM - 10AM" },
    { value: "AM", label: "10AM - 12PM" },
    { value: "PM", label: "12PM - 3PM" },
    { value: "PMLate", label: "3PM - 6PM" },
    { value: "CUSTOM", label: "Custom Time Window" },
  ];

  const updatePurchaseOrder = (value) => {
    setPurchaseOrder((prevState) => ({
      ...prevState,
      Task: { id: value },
    }));
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedPurchaseOrder),
    };
    fetch(`${API_URL}/update/purchaseorder/${id}`, requestOptions).then((res) =>
      res.json()
    );
  };

  const handleSendPurchaseOrder = () => {
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id,
      }),
    };
    fetch(`${API_URL}/order/send`, requestOptions)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((error) => console.log(JSON.stringify(error)));
  };

  return (
    purchaseOrder && (
      <PurchaseOrderContainer>
        <div className="order-header">
          <div>
            <h1 className="po-title">
              PO# {purchaseOrder.purchaseOrderNumber}
            </h1>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              <span>Order Status: </span>
              <span
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  color:
                    purchaseOrder.orderState === "ACTIVE" ? "green" : "black",
                }}
              >
                {purchaseOrder.orderState.toLowerCase()}
              </span>
            </p>
            <div className="order-requisitions">
              {purchaseOrder?.Requisition.map((req) => (
                <Link to={`/material/requisition/${req.id}`} key={req.id}>
                  <Button>REQ#{req.requisitionNumber}</Button>
                </Link>
              ))}
              {purchaseOrder?.RequestForQuote.map((rfq) => (
                <Link to={`/rfq/${rfq.id}`} key={rfq.id}>
                  <Button>RFQ#{rfq.requestForQuoteNumber}</Button>
                </Link>
              ))}
            </div>
          </div>
          <div className="submit-button">
            {!updatedPurchaseOrder && (
              <div style={{ marginRight: "10px" }}>
                <Button onClick={() => updatePurchaseOrder()}>Save</Button>
              </div>
            )}
            <span>
              <Button
                onClick={() => {
                  window.open(
                    `${API_URL}/purchaseorder/pdf/${purchaseOrder.purchaseOrderNumber}`,
                    "_blank"
                  );
                }}
              >
                View PDF
              </Button>
            </span>
            {purchaseOrder.orderState === "DRAFT" && (
              <span style={{ marginLeft: "10px" }}>
                <Button onClick={() => handleSendPurchaseOrder()}>
                  Send Purchase Order
                </Button>
              </span>
            )}
          </div>
        </div>
        <FormContainer>
          <Column>
            <div className="form-group">
              <label>Requested By</label>
              <Select
                styles={CustomStyles}
                options={employeeList}
                isLoading={loading}
                name="userId"
                onChange={(e) => handleSelectChange(e)}
                defaultValue={purchaseOrder.userId}
                value={employeeList.find(
                  (obj) => obj.value === purchaseOrder.userId
                )}
                placeholder="Select Job"
              />
            </div>
            <div className="form-group">
              <label>Job</label>
              <Select
                styles={CustomStyles}
                options={jobList}
                isLoading={loading}
                name="jobId"
                onChange={(e) => handleSelectChange(e)}
                defaultValue={purchaseOrder.job}
                value={jobList.find((obj) => obj.value === purchaseOrder.jobId)}
                placeholder="Select Job"
              />
            </div>
            <div className="form-group">
              <label>Vendor</label>
              <Select
                styles={CustomStyles}
                options={vendorList}
                isLoading={loading}
                name="vendorId"
                onChange={(e) => handleSelectChange(e)}
                defaultValue={purchaseOrder.vendorId}
                value={vendorList.find(
                  (obj) => obj.value === purchaseOrder.vendorId
                )}
                placeholder="Select Vendor"
              />
            </div>
            <div className="form-group-double">
              <div className="form-group">
                <label>Need By Date</label>
                <DatePicker
                  className="custom-input-double"
                  selected={new Date(purchaseOrder.needByDate)}
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
              <div className="form-group">
                <label>Need By Window</label>
                <Select
                  styles={CustomStyles}
                  options={windowList}
                  isLoading={loading}
                  name="needByWindow"
                  onChange={(e) => updatedPurchaseOrder(e)}
                  defaultValue={purchaseOrder.needByWindow}
                  value={windowList.find(
                    (obj) => obj.value === purchaseOrder.needByWindow
                  )}
                  placeholder="Select Window"
                />
              </div>
            </div>
          </Column>
          <Column>
            <div className="form-group-double">
              <div className="form-group">
                <label>Field Contact</label>
                <Select
                  styles={CustomStyles}
                  options={employeeList}
                  isLoading={loading}
                  name="fieldContactName"
                  onChange={(e) => handleSelectChange(e)}
                  defaultValue={purchaseOrder.fieldContactName}
                  value={employeeList.find(
                    (obj) => obj.label === purchaseOrder.fieldContactName
                  )}
                  placeholder="Select Field Contact"
                />
              </div>
              <div className="form-group">
                <label>Contact Phone #</label>
                <input
                  className="custom-input-double"
                  type="tel"
                  name="fieldContactNumber"
                  value={
                    purchaseOrder.fieldContactNumber &&
                    purchaseOrder.fieldContactNumber.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "$1-$2-$3"
                    )
                  }
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Delivery Address</label>
              <input
                type="text"
                className="custom-input"
                value={purchaseOrder.deliveryAddress}
              />
            </div>
            <div className="form-group">
              <label>Delivery Instructions</label>
              <textarea
                className="custom-input"
                value={purchaseOrder.deliveryInstructions}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label>Order Description</label>
              <input
                className="custom-input"
                type="text"
                value={purchaseOrder.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Column>
          <Column>
            <div className="form-group">
              <label>GL Code</label>
              <Select
                styles={CustomStyles}
                options={glCodeList}
                isLoading={loading}
                name="glCodeId"
                onChange={(e) => handleSelectChange(e)}
                defaultValue={purchaseOrder.generalLedger.id}
                value={glCodeList.find(
                  (obj) => obj.value === purchaseOrder.generalLedger.id
                )}
                placeholder="Select GL Code"
              />
            </div>
            <div className="form-group">
              <label>Cost Code</label>
              <Select
                styles={CustomStyles}
                options={costCodeList}
                isLoading={loading}
                name="costCodeId"
                onChange={(e) => handleSelectChange(e)}
                defaultValue={purchaseOrder?.Task?.id}
                value={costCodeList.find(
                  (obj) => obj.value === purchaseOrder?.Task?.id
                )}
                placeholder="Select Cost Code"
              />
            </div>
            <div className="form-group">
              <label>Tax Code</label>
              <Select
                styles={CustomStyles}
                options={taxCodeList}
                isLoading={loading}
                name="taxCodeId"
                onChange={(e) => handleSelectChange(e)}
                defaultValue={purchaseOrder.tax.id}
                value={taxCodeList.find(
                  (obj) => obj.value === purchaseOrder.tax.id
                )}
                placeholder="Select Tax"
              />
            </div>
            <div className="form-group">
              <label>Billing Details</label>
              <input
                className="custom-input"
                type="text"
                value={purchaseOrder.billingDetails}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Column>
        </FormContainer>
        <AttachmentBanner
          handleAddAttachment={handleAddAttachment}
          addAttachment={true}
          attachments={attachments}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableRowNumberHeaderCell>#</TableRowNumberHeaderCell>
                <TableHeaderCell>Quantity</TableHeaderCell>
                <TableHeaderCell>Unit of Measure</TableHeaderCell>
                <TableHeaderCell>Description</TableHeaderCell>
              </TableRow>
            </TableHead>
            {uomList.length > 0 && (
              <MaterialTableBody
                loading={loading}
                uomList={uomList}
                data={items}
                setData={setItems}
                columns={[null, null, null]}
              />
            )}
          </Table>
        </TableContainer>
      </PurchaseOrderContainer>
    )
  );
};

SinglePurchaseOrder.propTypes = {
  userId: PropTypes.string,
};

export default SinglePurchaseOrder;
