import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReviewPricing from "./ReviewPricing";
import { Button } from "../../../styles/Style";

const QuotePricingReviewContainer = styled.div`
  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }
  .confirm-panel {
    padding: 50px;
    background-color: white;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: block;
    position: fixed;
    z-index: 25;
  }
  .opaque-panel {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: gray;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
`;

const QuotePricingReview = ({
  pricing,
  items,
  setPricingReview,
  handleConfirmPricing,
}) => {
  const [parsedPricing, setParsedPricing] = useState(null);
  const [priceReview, setPriceReview] = useState(false);
  const [matchedItems, setMatchedItems] = useState([]);

  useEffect(() => {
    handleQuotePricing(pricing);
  }, [pricing]);

  useEffect(() => {
    setMatchedItems(items.map(() => ""));
  }, [items]);

  const handleQuotePricing = (quotePricing) => {
    const parsedString = JSON.parse(quotePricing);
    const initialParsing = {
      subtotal: parsedString[0].ocr_text,
      total: parsedString[1].ocr_text,
      vendor: parsedString[2].ocr_text,
      date: parsedString[3].ocr_text,
      quoteNumber: parsedString[4].ocr_text,
      table: parsedString[5].cells.map((cell) => {
        const { id, label, text } = cell;
        return { [label]: text, id: id };
      }),
    };

    const table = initialParsing.table;
    const transformedArray = [];
    for (let i = 0; i < table.length; i += 5) {
      const id = table[i].id;
      const orderQty =
        table[i].Order_Qty.slice(table[i].Order_Qty.length - 1) === "l"
          ? table[i].Order_Qty.slice(0, -1).split(/([0-9]+)/)[1]
          : table[i].Order_Qty.split(/([0-9]+)/)[1];
      const uom = table[i].Order_Qty.split(/([a-zA-Z]+)/)[1];
      const description = table[i + 1].Description;
      const unitPrice = table[i + 2].Unit_Price;
      const u = table[i + 3].U;
      const extPrice = table[i + 4].Ext_Price;

      transformedArray.push({
        id,
        quantity: orderQty,
        UOM: uom,
        description: description,
        unitPrice: unitPrice,
        U: u,
        extPrice: extPrice,
      });
    }
    return setParsedPricing({
      ...initialParsing,
      table: transformedArray,
    });
  };

  const handleLineMatch = (index, value) => {
    setMatchedItems((prevState) => {
      prevState[index] = value;
      return [...prevState];
    });
  };

  return priceReview ? (
    <ReviewPricing
      parsedPricing={parsedPricing}
      setPriceReview={setPriceReview}
      setPricingReview={setPricingReview}
      handleConfirmPricing={handleConfirmPricing}
    />
  ) : (
    <QuotePricingReviewContainer>
      <div className="confirm-panel">
        <h2 style={{ verticalAlign: "middle" }}>Review Pricing</h2>
        <table>
          <thead>
            <tr>
              <th>Qty</th>
              <th>Description</th>
              <th>Quote Line Items</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={item.id}>
                <td>{item.quantity}</td>
                <td>{item.description}</td>
                <td>
                  <select
                    onChange={(e) => handleLineMatch(index, e.target.value)}
                    value={matchedItems[index]}
                  >
                    <option value={""}>Select Matching Line Item</option>
                    {parsedPricing && matchedItems[index] === ""
                      ? parsedPricing?.table
                          .filter((line) => !matchedItems.includes(line.id))
                          .map((tableItem) => (
                            <option key={tableItem.id} value={tableItem.id}>
                              {`${tableItem.quantity} - ${tableItem.description}`}
                            </option>
                          ))
                      : parsedPricing?.table.map((tableItem) => (
                          <option key={tableItem.id} value={tableItem.id}>
                            {`${tableItem.quantity} - ${tableItem.description}`}
                          </option>
                        ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20%",
            marginTop: "50px",
          }}
        >
          <Button onClick={() => setPricingReview(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setParsedPricing((prevState) => {
                prevState.table.sort((a, b) => {
                  const indexA = matchedItems.indexOf(a.id);
                  const indexB = matchedItems.indexOf(b.id);
                  return indexA - indexB;
                });
                return { ...prevState };
              });
              setPriceReview(true);
            }}
          >
            Review Pricing
          </Button>
        </div>
      </div>
      <div className="opaque-panel" onClick={() => setPricingReview(false)} />
    </QuotePricingReviewContainer>
  );
};

QuotePricingReview.propTypes = {
  pricing: PropTypes.string,
  quoteIndex: PropTypes.number,
  items: PropTypes.array,
  setPricingReview: PropTypes.func,
  handleConfirmPricing: PropTypes.func,
};

export default QuotePricingReview;
