import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { Button } from "../../../styles/Style";
import CustomStyles from "../../../styles/SelectCustomStyle";
import { API_URL } from "../../../env";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import getTimeWindowText from "../Functions/getTimeWindowText";

const RequestForQuoteContainer = styled.div`
  text-align: left;
  .subtitle {
    margin: 3px 0px;
  }
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
      h1 {
        margin: 0px;
      }
      h3 {
        margin-top: 0px;
      }
    }
    .submit-button {
      display: flex;
      justify-content: right;
      button {
        margin: 20px;
      }
    }
  }
  .reqDetails {
    display: grid;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          .standard-input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    .reqButtons {
      justify-self: right;
      button {
        margin: 5px 50px 5px 5px;
        padding: 15px 25px;
        font-weight: bold;
        background: blue;
        border: none;
        color: white;
        cursor: pointer;
      }
    }
    table {
      width: 100%;
    }
  }
`;

const CreateRequestForQuote = ({ userId }) => {
  const location = useLocation();
  let navigate = useNavigate();
  const {
    requisitionId,
    requestForQuoteNumber,
    user,
    job,
    jobId,
    deliveryAddress,
    needByDate,
    needByWindow,
    items,
  } = location.state;
  const [selectedItems, setSelectedItems] = useState([]);
  const [fieldContactName, setFieldContactName] = useState(
    `${user.firstName} ${user.lastName}`
  );
  const [fieldContactNumber, setFieldContactNumber] = useState(
    user.phoneNumber
  );
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [billingDetails, setBillingDetails] = useState(
    "2875 Jupiter Park Dr. Ste 500, Jupiter, FL 33458"
  );
  const [generalLedgerCodes, setGeneralLedgerCodes] = useState([]);
  const [generalLedger, setGeneralLedger] = useState("");
  const [taxCodes, setTaxCodes] = useState([]);
  const [taxCode, setTaxCode] = useState("");
  const [tasks, setTasks] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [attachments] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json);
        setLoading(false);
      });
    fetch(`${API_URL}/publictasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTasks(json);
      });
    fetch(`${API_URL}/material/glcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setGeneralLedgerCodes(json);
      });
    fetch(`${API_URL}/material/taxcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTaxCodes(json);
      });
  }, []);
  const handleItemCheck = ({ checked }, id) => {
    checked
      ? setSelectedItems((prevState) => {
          prevState.push(id);
          return [...prevState];
        })
      : setSelectedItems((prevState) => {
          const newItems = prevState.filter((itemId) => itemId !== id);
          return newItems;
        });
  };
  const handleSelectAll = ({ checked }) => {
    checked
      ? setSelectedItems(items.map((item) => item.id))
      : setSelectedItems([]);
  };

  const onDateChange = (value) => {
    setDate(value);
  };

  const createRFQ = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        details: {
          requestForQuoteNumber,
          user: { connect: { id: userId } },
          job: {
            connect: { id: jobId },
          },
          vendor: { connect: vendor.map((vendor) => ({ id: vendor })) },
          billingDetails,
          deliveryAddress,
          needByDate,
          needByWindow,
          generalLedger: { connect: { id: generalLedger } },
          Task: { connect: { id: taskId } },
          tax: { connect: { id: taxCode } },
          Requisition: { connect: { id: requisitionId } },
        },
        items: selectedItems.map((item) => ({ id: item })),
        vendorQuotes: vendor.map((vend) => ({ vendorId: vend })),
      }),
    };
    fetch(`${API_URL}/create/rfq`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        navigate(`/rfq/${json}`);
      });
  };

  const handleVendorChange = (e) => {
    const selectedVendors = e.map((e) => e.value);
    setVendor(selectedVendors);
  };

  const vendorList = vendors.map((vendor) => ({
    value: vendor.id,
    label: `${vendor.vendorName}`,
  }));
  return (
    <RequestForQuoteContainer>
      <div className="order-header">
        <div className="po-title">
          <h1>RFQ# {requestForQuoteNumber}</h1>
          <h3>{`${job.jobNumber} - ${job.jobName}`}</h3>
        </div>
        <div className="submit-button">
          <Button onClick={() => createRFQ()}>Create RFQ</Button>
        </div>
      </div>
      <div
        className="reqDetails"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
      >
        <div>
          <h3 className="subtitle">Vendors</h3>
          <Select
            styles={CustomStyles}
            options={vendorList}
            isLoading={loading}
            name="jobId"
            onChange={handleVendorChange}
            defaultValue={vendor}
            placeholder="Select Vendor(s)"
            isMulti
          />
          <h3 className="subtitle">Bid By Date</h3>
          <DatePicker
            selected={date}
            onChange={onDateChange}
            className="date-picker"
          />
        </div>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>
                <h3 className="subtitle">Order Details</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Requested by</th>
              <td>{`${user.firstName} ${user.lastName}`}</td>
            </tr>
            <tr>
              <th>Need by</th>
              <td>
                {`${dayjs(needByDate).format("M/D/YYYY")} - ${getTimeWindowText(
                  needByWindow
                )}`}
              </td>
            </tr>
            <tr>
              <th>Delivery Address</th>
              <td style={{ textTransform: "capitalize" }}>{deliveryAddress}</td>
            </tr>
            <tr>
              <th>Field Contact</th>
              <td>
                <input
                  className="standard-input"
                  type="text"
                  value={fieldContactName}
                  onChange={(e) => setFieldContactName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>Contact Phone</th>
              <td>
                <input
                  className="standard-input"
                  type="tel"
                  value={fieldContactNumber.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3"
                  )}
                  onChange={(e) => setFieldContactNumber(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>
                <h3 className="subtitle">Accounting Details</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>GL Code</th>
              <td>
                <select
                  value={generalLedger}
                  onChange={(e) => setGeneralLedger(e.target.value)}
                >
                  <option value={""}>Select GL Code</option>
                  {generalLedgerCodes.map((glcode) => (
                    <option key={glcode.id} value={glcode.id}>
                      {`${glcode.generalLedgerCode} ${glcode.generalLedgerName}`}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>Cost Code</th>
              <td>
                <select
                  value={taskId}
                  onChange={(e) => setTaskId(e.target.value)}
                >
                  <option value={""}>Select Cost Code</option>
                  {tasks.map((task) => (
                    <option key={task.id} value={task.id}>
                      {`${task.taskCode} ${task.taskName}`}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>Tax Code</th>
              <td>
                <select
                  value={taxCode}
                  onChange={(e) => setTaxCode(e.target.value)}
                >
                  <option value={""}>Select Tax Code</option>
                  {taxCodes.map((tax) => (
                    <option key={tax.id} value={tax.id}>
                      {tax.taxCode}
                    </option>
                  ))}
                </select>
              </td>
            </tr>

            <tr>
              <th>Billing Details</th>
              <td>
                <input
                  className="standard-input"
                  type="text"
                  value={billingDetails}
                  onChange={(e) => setBillingDetails(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AttachmentBanner attachments={attachments} addAttachment={true} />
      <div>
        <table className="item-table">
          <thead>
            <tr>
              <th
                style={{ background: "black", color: "white" }}
                colSpan={"100%"}
              >
                <h3>Items</h3>
              </th>
            </tr>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={(e) => handleSelectAll(e.target)}
                  checked={items.every((item) =>
                    selectedItems.includes(item.id)
                  )}
                />
              </th>
              <th>Qty. Requested</th>
              <th>Order Qty.</th>
              <th>Unit</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 &&
              items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handleItemCheck(e.target, item.id)}
                      checked={selectedItems.includes(item.id)}
                    />
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    <input type="number" />
                  </td>
                  <td>{item?.unitOfMeasure?.code}</td>
                  <td>{item.description}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </RequestForQuoteContainer>
  );
};

CreateRequestForQuote.propTypes = {
  userId: PropTypes.string,
};

export default CreateRequestForQuote;
