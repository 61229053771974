import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { SearchDiv, FilterBG, FilterDiv } from "../../styles/SearchStyle";
import { Button } from "../../styles/Style";

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .search-input {
    padding-right: 25px; /* Make space for the clear button */
  }
  .clear-button {
    position: absolute;
    right: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #999; /* Adjust color as needed */
  }
  .clear-button:hover {
    color: #333; /* Adjust hover color as needed */
  }
`;

const SearchSortFilterComponent = ({
  fetchData, // Function to fetch data based on filters, sorting, etc.
  filterCategories, // Array of filter categories with their respective fields
  pageParam,
  buttonPath,
  buttonText,
  pageTitle,
  searchTerm,
  setSearchTerm,
  filters,
  setFilters,
  sortBy,
  orderBy,
  filterBool,
  searchBool,
  alternativeButton,
  alternativeButtonFunction,
}) => {
  const [show, setShow] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e, field, isMulti) => {
    isMulti === true
      ? e?.length > 0
        ? setFilters((prevState) => {
            const multiList = e.map((e) => ({
              value: e.value,
              label: e.label,
            }));
            return { ...prevState, [field]: multiList };
          })
        : setFilters((prevState) => {
            const newState = { ...prevState };
            delete newState[field];
            return newState;
          })
      : setFilters((prevState) => ({ ...prevState, [field]: e.value }));
  };

  useEffect(() => {
    // Fetch data when any of the parameters change
    fetchData({ page: pageParam, searchTerm, filters, sortBy, orderBy });
  }, [pageParam, searchTerm, filters, fetchData, orderBy]);

  return (
    <SearchDiv>
      <div className="search">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {(filterBool !== false || searchBool !== false) && (
              <>
                <SearchContainer>
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  {searchTerm.length > 0 && (
                    <button
                      className="clear-button"
                      onClick={() => setSearchTerm("")}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </SearchContainer>
                {filterBool !== false && (
                  <>
                    {Object.keys(filters).length > 0 ? (
                      <button
                        className="active-filter filter"
                        onClick={() => setShow(true)}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </button>
                    ) : (
                      <button
                        className="lens filter"
                        onClick={() => setShow(true)}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </button>
                    )}
                    {Object.keys(filters).length > 0 && (
                      <button
                        className="lens active-filter"
                        style={{ width: "42px" }}
                        onClick={() => {
                          setFilters({});
                          setSearchTerm("");
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {show && (
            <div>
              <FilterBG
                className="invisible-background"
                onClick={() => setShow(false)}
              />
              <FilterDiv>
                <h3>FILTER</h3>
                {filterCategories.map((category) => (
                  <div key={category.field} className="filter-item-div">
                    <label>
                      <p>{category.label}:</p>
                      <Select
                        options={category.options.map(({ label, value }) => ({
                          value: value,
                          label: label,
                        }))}
                        isMulti={category?.isMulti === true ? true : false}
                        name={category.field}
                        value={category.options.find(
                          (option) => option.value === filters[category.field]
                        )}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            category.field,
                            category.isMulti
                          )
                        }
                      />
                    </label>
                  </div>
                ))}
                <button className="clear-button" onClick={() => setFilters({})}>
                  Clear
                </button>
              </FilterDiv>
            </div>
          )}
        </div>
      </div>
      <div>
        <h3 style={{ margin: "0px", padding: "0px" }}>{pageTitle}</h3>
      </div>
      {!alternativeButton && buttonText !== null && (
        <Link to={buttonPath} className="add-button">
          <Button>{buttonText}</Button>
        </Link>
      )}
      {alternativeButton && alternativeButtonFunction !== undefined && (
        <div className="add-button">
          <Button onClick={alternativeButtonFunction}>{buttonText}</Button>
        </div>
      )}
    </SearchDiv>
  );
};

SearchSortFilterComponent.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  fetchData: PropTypes.func,
  filterCategories: PropTypes.array,
  pageParam: PropTypes.number,
  buttonText: PropTypes.string,
  buttonPath: PropTypes.string,
  pageTitle: PropTypes.string,
  sortBy: PropTypes.any,
  orderBy: PropTypes.any,
  searchBool: PropTypes.bool,
  filterBool: PropTypes.bool,
  alternativeButton: PropTypes.bool,
  alternativeButtonFunction: PropTypes.func,
};

export default SearchSortFilterComponent;
