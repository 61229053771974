import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { addDays, addWeeks, subDays, subWeeks } from "date-fns";
import _ from "lodash";
import { Icon } from "leaflet";
import dayjs from "dayjs";
import MarkerClusterGroup from "./MarkerClusterGroup";
import { Button } from "../../styles/Style";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "react-datepicker/dist/react-datepicker.css";
import { SearchDiv } from "../../styles/SearchStyle";
import { API_URL } from "../../env";

const StyledDiv = styled.div`
  .map-div {
    width: 100%;
    height: 700px;
    z-index: -1;
  }
  .leaflet-container {
    height: 100%;
    width: 100%;
  }
`;

const TimeClockDashboard = ({ id, permission }) => {
  const [jobClockActions, setJobClockActions] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/time-clock-dashboard/clockactions/${date.toISOString()}${
        permission === "PM" ? `/${id}` : ""
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedClockActionsByJob = _.chain(json)
          .groupBy("jobName")
          .map((value, key) => ({
            job: key,
            value,
          }))
          .value();
        setJobClockActions(sortedClockActionsByJob);
      });
  }, []);

  const onDateChange = (value) => {
    setDate(value);
  };

  const onDateFilter = async () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    await fetch(
      `${API_URL}/time-clock-dashboard/clockactions/${date.toISOString()}${
        permission === "PM" ? `/${id}` : ""
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedClockActionsByJob = _.chain(json)
          .groupBy("jobName")
          .map((value, key) => ({
            job: key,
            value,
          }))
          .value();
        setJobClockActions(sortedClockActionsByJob);
      });
  };
  return (
    <StyledDiv>
      <SearchDiv>
        <div className="search">
          <h2
            style={{
              margin: "0px",
              padding: "0px",
            }}
          >
            Dashboard
          </h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2",
            gridTemplateRows: "1",
          }}
        >
          <div className="date-buttons">
            <Button onClick={() => onDateChange(subWeeks(date, 1))}>
              {"<<"}
            </Button>
            <Button
              className="button-margin-1"
              onClick={() => onDateChange(subDays(date, 1))}
            >
              {"<"}
            </Button>
            <DatePicker
              selected={date}
              onChange={onDateChange}
              className="date-picker"
            />
            <Button onClick={() => onDateChange(addDays(date, 1))}>
              {">"}
            </Button>
            <Button
              className="button-margin-1"
              onClick={() => onDateChange(addWeeks(date, 1))}
            >
              {">>"}
            </Button>
            <Button className="button-margin-1" onClick={() => onDateFilter()}>
              GO
            </Button>
          </div>
        </div>
      </SearchDiv>
      <div className="map-div">
        <MapContainer
          center={[26.322438, -80.137314]}
          zoom={9}
          scrollWheelZoom={true}
          zoomAnimation={false}
        >
          <TileLayer
            attribution="&copy; <a href='https://osm.org/copyright'>OpenStreetMap</a> contributors"
            url={"http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
          />
          <MarkerClusterGroup>
            {jobClockActions.length > 0 &&
              jobClockActions.map((job) =>
                job.value[0].job.jobLat !== null &&
                job.value[0].job.jobLng !== null ? (
                  <Marker
                    key={job.value[0].id}
                    position={[
                      job.value[0].job.jobLat,
                      job.value[0].job.jobLng,
                    ]}
                    icon={
                      new Icon({
                        iconUrl: markerIconPng,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                      })
                    }
                  >
                    <Tooltip>{job.job}</Tooltip>
                    <Popup offset={[0, -25]}>
                      <div>
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          {job.value.map((clockAction) => (
                            <li key={clockAction.id}>{`${
                              clockAction.user.firstName
                            } ${clockAction.user.lastName}: ${
                              clockAction.clockIn
                                ? dayjs(clockAction.clockIn).format("h:mm a")
                                : "N/A"
                            } - ${
                              clockAction.clockOut
                                ? dayjs(clockAction.clockOut).format("h:mm a")
                                : "N/A"
                            }`}</li>
                          ))}
                        </ul>
                      </div>
                    </Popup>
                  </Marker>
                ) : (
                  <div
                    key={job.value[0].id}
                    style={{
                      position: "absolute",
                      width: "fit",
                      height: "fit",
                      top: 10,
                      right: 10,
                      zIndex: 10000,
                      backgroundColor: "white",
                      color: "red",
                      fontWeight: "bold",
                      padding: "10px",
                    }}
                  >
                    <span>{job.job}</span>
                    <br />
                    <span>Time on Job - No Location</span>
                  </div>
                )
              )}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    </StyledDiv>
  );
};

TimeClockDashboard.propTypes = {
  id: PropTypes.string,
  permission: PropTypes.string,
};

export default TimeClockDashboard;
