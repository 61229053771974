import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import dayjs from "dayjs";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import CustomStyle from "../../styles/SelectCustomStyle";
import { API_URL } from "../../env";

const NoPrint = styled.div`
  @media print {
    display: none;
  }
`;

const DateButtons = styled.div`
  width: 500px;
  margin: 10px auto 10px auto;
  justify-content: center;
  display: inline-flex;
  .button-margin-1 {
    margin-left: 1px;
  }
  .react-datepicker-wrapper {
    width: auto;

    .react-datepicker__input-container,
    input {
      height: 100%;
      padding: 0px;
      border: none;
      outline: 1px solid black;
      outline-offset: -1px;
      text-align: center;
    }
  }
`;

const HoursDetailTable = styled.table`
  border-collapse: collapse;
  margin: 10px auto 10px auto;
  th,
  td {
    border: 1px solid black;
    padding: 0px 10px;
    text-align: left;
  }
  td {
    text-align: left;
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const ManpowerReport = () => {
  const [employees, setEmployees] = useState([]);
  const [firstDate, setFirstDate] = useState(new Date(start.toISOString()));
  const [lastDate, setLastDate] = useState(new Date(end.toISOString()));
  const [jobId, setJobId] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
  }, []);
  useEffect(() => {
    if (jobId && firstDate && lastDate) {
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `${API_URL}/reports/manpower/${firstDate}/${lastDate}/${jobId}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((json) => setEmployees(json));
    }
  }, [jobId, firstDate, lastDate]);
  useEffect(() => {
    jobs.length > 0 > 0 && setLoading(false);
  }, [jobs]);
  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setFirstDate(new Date(start.toISOString()));
    setLastDate(new Date(newEnd.toISOString()));
  };

  return (
    <div>
      <h1>Manpower Report</h1>
      {jobId && (
        <h2>
          {jobList[jobList.findIndex((job) => job.value === jobId)].label}
        </h2>
      )}
      <NoPrint>
        <div style={{ width: "500px", margin: "0px auto 0px auto" }}>
          <Select
            styles={CustomStyle}
            options={jobList}
            isLoading={loading}
            name="jobId"
            onChange={(e) => setJobId(e.value)}
            defaultValue={jobId}
            placeholder="Select Job"
          />
        </div>
        <DateButtons>
          <Button
            onClick={() =>
              onDateChange([subWeeks(firstDate, 1), subWeeks(lastDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(firstDate, 1), subDays(lastDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={firstDate}
            endDate={lastDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(firstDate, 1), addDays(lastDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(firstDate, 1), addWeeks(lastDate, 1)])
            }
          >
            {">>"}
          </Button>
        </DateButtons>
      </NoPrint>
      {employees.length ? (
        <div>
          <HoursDetailTable>
            <thead>
              <tr>
                <th>#</th>
                <th>Company</th>
                <th>Name</th>
                <th>Last Worked</th>
              </tr>
            </thead>
            <tbody>
              {employees
                .sort((a, b) => a.department.name - b.department.name)
                .map((employee, index) => (
                  <tr key={employee.id}>
                    <td>{index + 1}</td>
                    <td>
                      {employee.department.name === "FIELD"
                        ? "Champion"
                        : employee.department.name}
                    </td>
                    <td>{`${employee.firstName} ${employee.lastName}`}</td>
                    <td>
                      {employee.timeRecords.length > 0 &&
                        dayjs(employee.timeRecords[0].clockIn).format(
                          "MM/DD/YYYY"
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </HoursDetailTable>
        </div>
      ) : jobId ? (
        <div>
          <h3>No time on job within this period</h3>
        </div>
      ) : (
        <div>
          <h3>Select a Job</h3>
        </div>
      )}
    </div>
  );
};

export default ManpowerReport;
