import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "../../styles/DataTable";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    };
  }
  componentDidMount() {
    this.setState({
      data: { ...this.props.data },
    });
  }
  render() {
    const { columns, onSelect, sortBy, orderBy, handleSort, data } = this.props;
    return (
      <DataTable>
        <tbody>
          <tr>
            <td>
              <table className="tableHead">
                <thead>
                  <tr className="cell">
                    {columns.map((column) => (
                      <th
                        key={column[0]}
                        onClick={() => {
                          handleSort(column[1]);
                        }}
                      >
                        {column[0]}{" "}
                        {sortBy === undefined ? (
                          ""
                        ) : column[1] === sortBy ? (
                          orderBy === "asc" ? (
                            <FontAwesomeIcon icon={faCaretDown} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretUp} />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </td>
          </tr>
          {data?.length > 0 && (
            <tr>
              <td>
                <div className="tableBodyContainer">
                  <table className="tableBody">
                    <tbody>
                      {data.map((rowData) => (
                        <tr
                          key={rowData.id}
                          id={rowData.id}
                          onClick={() => onSelect(rowData.id)}
                        >
                          {columns.map((column) => {
                            if (
                              column[1] === "active" ||
                              column[1] === "isPublic" ||
                              column[1] === "operational" ||
                              column[1] === "complete" ||
                              column[1] === "submitted"
                            ) {
                              if (
                                rowData.active ||
                                rowData.isPublic ||
                                rowData.operational ||
                                rowData.complete ||
                                rowData.submitted
                              ) {
                                return (
                                  <td
                                    key={`${rowData.id} ${column[1]}`}
                                    style={{ fontSize: "20px" }}
                                  >
                                    &#9745;
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={`${rowData.id} ${column[1]}`}
                                    style={{ fontSize: "20px" }}
                                  >
                                    &#9744;
                                  </td>
                                );
                              }
                            } else if (column[1] === "coords") {
                              if (rowData.jobLat && rowData.jobLng) {
                                return (
                                  <td
                                    key={`${rowData.id} ${column[1]}`}
                                    style={{ fontSize: "20px" }}
                                  >
                                    &#9745;
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={`${rowData.id} ${column[1]}`}
                                    style={{ fontSize: "20px" }}
                                  >
                                    &#9744;
                                  </td>
                                );
                              }
                            } else if (column[1] === "date") {
                              if (rowData.date) {
                                const formatDate = new Date(rowData.date);
                                return (
                                  <td key={`${rowData.id} ${column[1]}`}>
                                    {formatDate.toLocaleDateString()}
                                  </td>
                                );
                              }
                            } else if (column[1] === "createdAt") {
                              if (rowData.createdAt) {
                                const formatDate = new Date(rowData.createdAt);
                                return (
                                  <td key={`${rowData.id} ${column[1]}`}>
                                    {formatDate.toLocaleDateString()}
                                  </td>
                                );
                              }
                            } else if (column[1] === "purchaseDate") {
                              if (rowData.purchaseDate) {
                                const formatDate = new Date(
                                  rowData.purchaseDate
                                );
                                return (
                                  <td key={`${rowData.id} ${column[1]}`}>
                                    {formatDate.toLocaleDateString()}
                                  </td>
                                );
                              }
                            } else if (column[1] === "amount") {
                              if (rowData.amount) {
                                return (
                                  <td key={`${rowData.id} ${column[1]}`}>
                                    ${rowData.amount.toFixed(2)}
                                  </td>
                                );
                              }
                            } else if (column[1].includes(".")) {
                              const multiLevelData = column[1].split(".");
                              return (
                                <td key={`${rowData.id} ${column[1]}`}>
                                  {
                                    rowData[multiLevelData[0]][
                                      multiLevelData[1]
                                    ]
                                  }
                                </td>
                              );
                            } else if (column[1] === "orderState") {
                              if (rowData.orderState) {
                                return (
                                  <td
                                    key={`${rowData.id} ${column[1]}`}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {rowData.orderState
                                      .toLowerCase()
                                      .replace("_", " ")}
                                  </td>
                                );
                              }
                            } else {
                              return (
                                <td key={`${rowData.id} ${column[1]}`}>
                                  {rowData[column[1]]}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </DataTable>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.arrayOf(String),
  data: PropTypes.array,
  onSelect: PropTypes.func,
  handleSort: PropTypes.func,
  sortBy: PropTypes.any,
  orderBy: PropTypes.any,
};

export default Table;
