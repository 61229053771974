import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import EditUserPanel from "./EditUserPanel";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import { API_URL } from "../../env";

const columns = [
  ["Employee #", "employeeNumber"],
  ["Active", "active"],
  ["Department", "department.name"],
  ["First Name", "firstName"],
  ["Middle Name", "middleName"],
  ["Last Name", "lastName"],
  ["Permission", "permission"],
  ["Pin", "pinNumber"],
];

const Employees = () => {
  const [state, setState] = useState({
    employees: [],
    editEmployee: "",
  });
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ active: "true" });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/departments`, requestOptions)
      .then((res) => res.json())
      .then((json) => setDepartments(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/employees/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                employees: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/employees?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Active",
      field: "active",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Department",
      field: "departmentId",
      options: departments.map((department) => ({
        label: department.name,
        value: department.id,
      })),
      isMulti: true,
    },
    {
      label: "Salary",
      field: "isSalary",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Permission",
      field: "permission",
      options: [
        { label: "ADMIN", value: "ADMIN" },
        { label: "FOREMAN", value: "FOREMAN" },
        { label: "USER", value: "USER" },
        { label: "PM", value: "PM" },
      ],
      isMulti: true,
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const user = state.employees.filter((employee) => employee.id === id);
    setState((prevState) => ({
      ...prevState,
      editEmployee: user,
    }));
  };

  const onEditEmployee = (id, user) => {
    const index = state.employees.findIndex((employee) => employee.id === id);
    let newState = state.employees;
    newState[index] = {
      ...state.employees[index],
      ...user,
    };
    setState((prevState) => ({
      ...prevState,
      employees: newState,
    }));
  };

  const onDeselectEmployee = () => {
    setState((prevState) => ({
      ...prevState,
      editEmployee: "",
    }));
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { employees, editEmployee } = state;

  return (
    <div>
      {editEmployee && (
        <EditUserPanel
          editUser={editEmployee}
          onDeselectEmployee={onDeselectEmployee}
          onEditEmployee={onEditEmployee}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Employee"}
        buttonPath={"/create/employee"}
        pageTitle={"Employees"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <div style={{ position: "absolute", top: "0px" }}>
          <p style={{ fontWeight: "bold" }}>
            {employees?.filter((employee) => employee?.active === true)?.length}{" "}
            Active Employees
          </p>
        </div>
      )}
      {!loading && (
        <Table
          columns={columns}
          data={employees}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Employees;
