import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import TimeCardTable from "./TimeCardTable";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import Pagination from "../Search/Pagination";
import { API_URL } from "../../env";

const columns = [
  ["Week Ending", "weekEnding"],
  ["First Name", "user.firstName"],
  ["Last Name", "user.lastName"],
  ["Total Hours", "sum"],
];

const MyTimeCards = ({ id }) => {
  const [timeCards, setTimeCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ userId: id });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/mytimecards/user/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setTimeCards(json.timeCards);
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/timecards/user/${id}?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    // Add more filter categories as needed
  ];
  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  return (
    <div>
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        filterBool={false}
        setFilters={setFilters}
        buttonText={"New TimeCard"}
        buttonPath={"/create/mytimecard"}
        pageTitle={"My Timecards"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <TimeCardTable
          columns={columns}
          data={timeCards}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
          navigate={navigate}
        />
      )}
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

MyTimeCards.propTypes = {
  id: PropTypes.string,
};

export default MyTimeCards;
