import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import EditCustomerPanel from "./EditCustomerPanel";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import { API_URL } from "../../env";

const columns = [
  ["Customer Number", "customerNumber"],
  ["Name", "name"],
  ["Emails", "emails"],
];

const Customers = () => {
  const [state, setState] = useState({
    customers: [],
    editCustomer: "",
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/customers/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                customers: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);

          navigate(`/customers?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const onSelect = (id) => {
    const customer = this.state.customers.filter(
      (customer) => customer.id === id
    );
    setState((prevState) => ({
      ...prevState,
      editCustomer: customer,
    }));
  };

  const onEditCustomer = (id, customer) => {
    const index = state.customers.findIndex((customer) => customer.id === id);
    let newState = state.customers;
    newState[index] = {
      ...state.customers[index],
      ...customer,
    };
    setState((prevState) => ({
      ...prevState,
      customers: newState,
    }));
  };

  const onDeselectCustomer = () => {
    setState((prevState) => ({
      ...prevState,
      editCustomer: "",
    }));
  };

  const filterCategories = [
    // Add more filter categories as needed
  ];

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { customers, editCustomer } = state;
  return (
    <div>
      {editCustomer && (
        <EditCustomerPanel
          editCustomer={editCustomer}
          onDeselectCustomer={onDeselectCustomer}
          onEditCustomer={onEditCustomer}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchBool={true}
        filterBool={false}
        buttonText={"Add Customer"}
        buttonPath={"/create/customer"}
        pageTitle={"Customers"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={customers}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Customers;
