import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const DeviceForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    input,
    select {
      padding: 7px;
      border: 1px solid lightgray;
    }
    .name {
      grid-row-start: 1;
      grid-column-start: 1;
    }
    .code {
      grid-row-start: 1;
      grid-column-start: 2;
    }
    .employee {
      grid-row-start: 1;
      grid-column-start: 3;
    }
    button {
      grid-row-start: 2;
      grid-column-start: 2;
      text-transform: uppercase;
      margin: 10px;
      height: 50px;
    }
  }
`;

const AddDeviceForm = () => {
  const [state, setState] = useState({
    name: "",
    code: "",
    responsibleUserId: "",
  });

  const [employees, setEmployees] = useState([]);

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    setState((prevState) => ({ ...prevState, [name]: val }));
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
    fetch(`${API_URL}/devicecode`, requestOptions)
      .then((res) => res.json())
      .then((json) => setState({ code: json }));
  }, []);

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    };
    fetch(`${API_URL}/create/device`, requestOptions).then((response) =>
      response.json()
    );
  };
  const { name, code, responsibleUserId } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Register Device
      </h1>
      <div>
        <DeviceForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/devices");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="name" className="name">
                Device Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Device Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="responsibleUser" className="employee">
                Responsible Employee
                <br />
                <select
                  value={responsibleUserId}
                  name="responsibleUserId"
                  id="responsibleUserId"
                  required
                  onChange={handleChange}
                >
                  <option defaultValue>Select An Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.firstName} ${employee.lastName}`}
                    </option>
                  ))}
                </select>
              </label>
              <div
                className="code"
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 150,
                  width: "100%",
                }}
              >
                <label>{code}</label>
                <QRCode
                  size={350}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={code}
                  viewBox={`0 0 350 350`}
                />
              </div>
              <Button type="submit">Add Device</Button>
            </div>
          </fieldset>
        </DeviceForm>
      </div>
    </div>
  );
};

export default AddDeviceForm;
