import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams, Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { API_URL } from "../../../env";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import getTimeWindowText from "../Functions/getTimeWindowText";
import { Button } from "../../../styles/Style";

const RequisitionContainer = styled.div`
  text-align: left;
  .requisition-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .title {
      margin-top: 0px;
    }
    .reqButtons {
      justify-self: right;
      button {
        margin: 5px;
        padding: 5px 10px;
        font-weight: bold;
        background: blue;
        border: none;
        color: white;
        cursor: pointer;
      }
    }
  }
  .reqDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
        h3 {
          margin: 3px 0px;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    table {
      width: 100%;
    }
  }
`;

const SingleRequisition = ({ userId }) => {
  const [requisition, setRequisition] = useState(null);
  const [fieldContactName] = useState("");
  const [fieldContactNumber] = useState("");
  const [vendor] = useState({});
  const [description] = useState("");
  const [deliveryInstruction] = useState("");
  const [generalLedger] = useState("");
  const [taxCode] = useState("");
  const [task] = useState("");
  const [loading, setLoading] = useState(true);
  const [nextRFQ, setNextRFQ] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/requisition/${params.id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setRequisition(json);
        setLoading(false);
      });
    fetch(`${API_URL}/next-rfq`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setNextRFQ(json);
      });
  }, []);
  const createOrder = () => {
    navigate("/create/purchaseorder", {
      state: {
        purchaseOrderNumber: `${requisition.job.jobNumber}-${String(
          requisition.job.nextPurchaseOrderNumber
        ).padStart(3, "0")}`,
        userId,
        user: requisition.user,
        job: requisition.job,
        jobId: requisition.jobId,
        vendorId: vendor.id,
        description,
        deliveryAddress: requisition.deliveryAddress,
        fieldContactName,
        fieldContactNumber,
        needByDate: requisition.needByDate,
        needByWindow: requisition.needByWindow,
        deliveryInstruction,
        generalLedgerCode: generalLedger.id,
        taxCode: taxCode.id,
        taskId: task.id,
        items: requisition.items,
        requisitionId: requisition.id,
        requisitionNumber: requisition.requisitionNumber,
      },
    });
  };
  const createRFQ = () => {
    navigate("/create/rfq", {
      state: {
        userId,
        requestForQuoteNumber: nextRFQ,
        user: requisition.user,
        job: requisition.job,
        jobId: requisition.jobId,
        vendorId: vendor.id,
        description,
        deliveryAddress: requisition.deliveryAddress,
        fieldContactName,
        fieldContactNumber,
        needByDate: requisition.needByDate,
        needByWindow: requisition.needByWindow,
        deliveryInstruction,
        generalLedgerCode: generalLedger.id,
        taxCode: taxCode.id,
        items: requisition.items,
        requisitionId: requisition.id,
        requisitionNumber: requisition.requisitionNumber,
      },
    });
  };
  return (
    !loading && (
      <RequisitionContainer>
        <div className="requisition-header">
          <div>
            <h1 className="title">
              Requisition #{requisition.requisitionNumber}
            </h1>
            <div className="order">
              {requisition.orderId && (
                <Link to={`/purchaseorder/${requisition.orderId}`}>
                  <Button>PO# {requisition.order.purchaseOrderNumber}</Button>
                </Link>
              )}
            </div>
            <div className="rfq">
              {requisition.rfqId && (
                <Link to={`/rfq/${requisition.rfqId}`}>
                  <Button>RFQ# {requisition.rfq.requestForQuoteNumber}</Button>
                </Link>
              )}
            </div>
          </div>
          {requisition.orderId === null && requisition.rfqId === null && (
            <div className="reqButtons">
              <button>Reject</button>
              <button
                onClick={() => createRFQ()}
              >{`Create RFQ# ${nextRFQ}`}</button>
              <button onClick={() => createOrder()}>
                {`Create PO# ${requisition.job.jobNumber}-${String(
                  requisition.job.nextPurchaseOrderNumber
                ).padStart(3, "0")}
                `}
              </button>
            </div>
          )}
        </div>
        <div className="reqDetails">
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Requisition Details</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan={2}>Job</th>
                <td colSpan={2}>
                  {`${requisition.job.jobNumber} ${requisition.job.jobName}`}
                </td>
              </tr>
              <tr colSpan={2}>
                <th>Need by</th>
                <td>{dayjs(requisition.needByDate).format("M/D/YYYY")}</td>
                <th>Need window</th>
                <td>
                  {getTimeWindowText(
                    requisition.needByWindow,
                    requisition.customWindow
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Delivery Details</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Deliver To</th>
                <td style={{ textTransform: "capitalize" }}>
                  {requisition.deliveryAddress}
                </td>
              </tr>
              <tr>
                <th>Requested by</th>
                <td>
                  {`${requisition.user.firstName} ${requisition.user.lastName}`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <AttachmentBanner
          addAttachment={false}
          attachments={
            requisition?.attachments.length > 0 ? requisition.attachments : []
          }
        />
        <div>
          <table className="item-table">
            <thead>
              <tr>
                <th
                  style={{ background: "black", color: "white" }}
                  colSpan={"100%"}
                >
                  <h3>Items</h3>
                </th>
              </tr>
              <tr>
                <th>Qty. Requested</th>
                <th>Unit</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {requisition.items.length > 0 &&
                requisition.items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.quantity}</td>
                    <td>{item?.unitOfMeasure?.code}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </RequisitionContainer>
    )
  );
};

SingleRequisition.propTypes = {
  userId: PropTypes.string,
};

export default SingleRequisition;
