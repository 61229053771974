import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import TimeCardRow from "./TimeCardRow";
import TimeCardTable from "./styles/TimeCardStyles";
import { API_URL } from "../../env";

const EditTimeCard = () => {
  const [jobs, setJobs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeCard, setTimeCard] = useState([]);
  const [timeCardItems, setTimeCardItems] = useState([]);

  const { id } = useParams();
  let navigate = useNavigate();

  const [mondayTotal, setMondayTotal] = useState([]);
  const [tuesdayTotal, setTuesdayTotal] = useState([]);
  const [wednesdayTotal, setWednesdayTotal] = useState([]);
  const [thursdayTotal, setThursdayTotal] = useState([]);
  const [fridayTotal, setFridayTotal] = useState([]);
  const [saturdayTotal, setSaturdayTotal] = useState([]);
  const [sundayTotal, setSundayTotal] = useState([]);
  const [grandTotal, setGrandTotal] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/timecard/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTimeCard(json);
        setTimeCardItems(json.timeCardItems);
      });
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
  }, []);

  useEffect(() => {
    jobs.length > 0 && tasks.length > 0 && setLoading(false);
  }, [jobs, tasks]);

  const handleChange = (e, rowIndex, cellIndex) => {
    const { value } = e.target;
    setTimeCardItems((prevState) => {
      prevState[rowIndex].hours[cellIndex] = parseInt(value);
      return [...prevState];
    });
  };

  const handleJobChange = (e, rowIndex) => {
    const { value } = e;
    setTimeCardItems((prevState) => {
      prevState[rowIndex].jobId = value;
      return [...prevState];
    });
  };

  const handleTaskChange = (e, rowIndex) => {
    const { value } = e;
    setTimeCardItems((prevState) => {
      prevState[rowIndex].taskId = value;
      return [...prevState];
    });
  };

  const handleAddRow = () => {
    setTimeCardItems((prevState) => {
      const items = [
        ...prevState,
        {
          jobId: "",
          taskId: "",
          hours: [0, 0, 0, 0, 0, 0, 0],
        },
      ];
      return [...items];
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setTimeCardItems((prevState) => {
      prevState.splice(rowIndex, 1);
      return [...prevState];
    });
  };

  const handleSubmit = () => {
    const setTimeCard = timeCardItems.map((tc) => ({
      jobId: tc.jobId,
      taskId: tc.taskId,
      hours: { set: [...tc.hours] },
    }));
    const newTimeCard = {
      userId: timeCard.userId,
      weekEnding: timeCard.weekEnding,
      timeCardItems: { create: [...setTimeCard] },
    };
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newTimeCard),
    };
    fetch(`${API_URL}/create/timecard/`, requestOptions).then((response) =>
      response.json()
    );
    const deleteOptions = {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/delete/timecard/${id}`, deleteOptions).then((response) =>
      response.json()
    );
  };

  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  const taskList = tasks.map((task) => ({
    value: task.id,
    label: `${task.taskCode} - ${task.taskName}`,
  }));

  useEffect(() => {
    if (timeCardItems) {
      const mon = timeCardItems
        .map((day) => day.hours[0])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setMondayTotal(mon);
      const tue = timeCardItems
        .map((day) => day.hours[1])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setTuesdayTotal(tue);
      const wed = timeCardItems
        .map((day) => day.hours[2])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setWednesdayTotal(wed);
      const thu = timeCardItems
        .map((day) => day.hours[3])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setThursdayTotal(thu);
      const fri = timeCardItems
        .map((day) => day.hours[4])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setFridayTotal(fri);
      const sat = timeCardItems
        .map((day) => day.hours[5])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setSaturdayTotal(sat);
      const sun = timeCardItems
        .map((day) => day.hours[6])
        .reduce((prev, cur) => prev + Number(cur), 0);
      setSundayTotal(sun);
      setGrandTotal(mon + tue + wed + thu + fri + sat + sun);
    }
  }, [timeCardItems]);

  return (
    !loading && (
      <div>
        <h2>Edit Timecard</h2>
        <h3>Week Ending: {dayjs(timeCard.weekEnding).format("MM/DD/YY")}</h3>
        <TimeCardTable
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate(`/timecards`);
          }}
        >
          <table>
            <thead>
              <tr
                style={{
                  gridTemplateColumns: "250px 175px repeat(8, 100px) 34px",
                }}
              >
                <th />
                <th />
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
                <th />
              </tr>
              <tr
                style={{
                  gridTemplateColumns: "250px 175px repeat(8, 100px) 34px",
                }}
              >
                <th>Job</th>
                <th>Cost Code</th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(6, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(5, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(4, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(3, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(2, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>
                  {dayjs(timeCard.weekEnding)
                    .subtract(1, "day")
                    .format("MM/DD/YY")}
                </th>
                <th>{dayjs(timeCard.weekEnding).format("MM/DD/YY")}</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {timeCardItems.map((row, rowIndex) => (
                <TimeCardRow
                  jobs={jobList}
                  tasks={taskList}
                  loading={loading}
                  key={rowIndex}
                  job={
                    jobList[jobList.findIndex((job) => job.value === row.jobId)]
                  }
                  task={
                    taskList[
                      taskList.findIndex((task) => task.value === row.taskId)
                    ]
                  }
                  hours={row.hours}
                  handleChange={handleChange}
                  handleJobChange={handleJobChange}
                  handleTaskChange={handleTaskChange}
                  handleDeleteRow={handleDeleteRow}
                  rowIndex={rowIndex}
                  numRows={timeCardItems.length}
                />
              ))}
            </tbody>
            <tfoot>
              <tr
                style={{
                  gridTemplateColumns: "250px 175px repeat(8, 100px) 34px",
                }}
              >
                <td>
                  <input
                    type="text"
                    onClick={() => handleAddRow()}
                    value="Add Row"
                    className="add-row-button"
                    readOnly
                  />
                </td>
                <td>
                  <input type="text" value="Total" readOnly />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(mondayTotal) ? "-" : mondayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(tuesdayTotal) ? "-" : tuesdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(wednesdayTotal) ? "-" : wednesdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(thursdayTotal) ? "-" : thursdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(fridayTotal) ? "-" : fridayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(saturdayTotal) ? "-" : saturdayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(sundayTotal) ? "-" : sundayTotal}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={isNaN(grandTotal) ? "-" : grandTotal}
                    readOnly
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          <br />
          <div className="bottom-buttons">
            <button
              type="submit"
              className="submit-button"
              disabled={timeCardItems.some(
                (row) =>
                  row.job === "" ||
                  row.task === "" ||
                  row.hours.reduce((prev, cur) => prev + cur) === 0 ||
                  timeCard.timeCardItems === timeCardItems
              )}
            >
              Submit
            </button>
          </div>
        </TimeCardTable>
      </div>
    )
  );
};

EditTimeCard.propTypes = {
  userId: PropTypes.string,
};

export default EditTimeCard;
