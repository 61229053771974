import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 65px;
    font-size: 1px;
    color: white;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .image-button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
`;

const AttachmentBannerContainer = styled.div`
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 6fr 1fr;
  h3 {
    margin: 3px 0px;
  }
  .attachment-row {
    display: flex;
    flex-direction: row;
    .attachment {
      cursor: pointer;
      height: 100px;
      text-align: center;
      justify-items: center;
      align-items: center;
      img {
        margin-left: auto;
        margin-right: auto;
        width: 75px;
        height: 133px;
        background: gray;
      }
    }
  }
`;

const AttachmentBanner = ({
  attachments,
  addAttachment,
  handleAddAttachment,
}) => (
  <AttachmentBannerContainer>
    <div>
      <h3 style={{ verticalAlign: "middle" }}>Attachments</h3>
      <div className="attachment-row">
        {attachments.length > 0 &&
          attachments.map((attachment, index) => (
            <div
              className="attachment"
              key={index}
              style={{
                borderRadius: "5px",
                padding: "5px",
                marginRight: "5px",
                height: "50px",
                verticalAlign: "middle",
                background: "#032bdf",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {`File Type: ${
                  attachment.fileType ? attachment.fileType.toLowerCase() : ""
                }`}
              </span>
              <br />
              <a
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                }}
              >
                {attachment.fileName}
              </a>
            </div>
          ))}
      </div>
    </div>
    <div style={{ display: "flex", justifyContent: "right" }}>
      {addAttachment && (
        <ButtonDiv>
          <div className="button-wrap">
            <label className="image-button" htmlFor="file">
              Add Attachment
            </label>
            <input
              id="file"
              type="file"
              name="myImage"
              onChange={handleAddAttachment}
            />
          </div>
        </ButtonDiv>
      )}
    </div>
  </AttachmentBannerContainer>
);

AttachmentBanner.propTypes = {
  handleAddAttachment: PropTypes.func,
  attachments: PropTypes.array,
  addAttachment: PropTypes.bool,
};

export default AttachmentBanner;
