import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { addDays, addWeeks, subDays, subWeeks } from "date-fns";
import { TimeSearchDiv, FilterBG, FilterDiv } from "../../styles/SearchStyle";
import { Button } from "../../styles/Style";
import "react-datepicker/dist/react-datepicker.css";

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .search-input {
    padding-right: 25px; /* Make space for the clear button */
  }
  .clear-button {
    position: absolute;
    right: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #999; /* Adjust color as needed */
  }
  .clear-button:hover {
    color: #333; /* Adjust hover color as needed */
  }
`;

const TimeSearch = ({
  fetchData, // Function to fetch data based on filters, sorting, etc.
  filterCategories, //
  searchTerm,
  setSearchTerm,
  filters,
  setFilters,
  sortBy,
  orderBy,
  startDate,
  endDate,
  onDateChange,
  processTime,
  unProcessTime,
  selected,
  processedSelected,
  showTimeType,
  editMultiple,
  setOnToggleConfirm,
}) => {
  const [show, setShow] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e, field, isMulti) => {
    isMulti === true
      ? e?.length > 0
        ? setFilters((prevState) => {
            const multiList = e.map((e) => ({
              value: e.value,
              label: e.label,
            }));
            return { ...prevState, [field]: multiList };
          })
        : setFilters((prevState) => {
            const newState = { ...prevState };
            delete newState[field];
            return newState;
          })
      : setFilters((prevState) => ({ ...prevState, [field]: e.value }));
  };

  useEffect(() => {
    // Fetch data when any of the parameters change
    fetchData({ searchTerm, filters, sortBy, orderBy });
  }, [searchTerm, filters, fetchData, orderBy]);

  return (
    <TimeSearchDiv>
      <div className="search">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SearchContainer>
              <input
                className="search-input"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
              {searchTerm.length > 0 && (
                <button
                  className="clear-button"
                  onClick={() => setSearchTerm("")}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )}
            </SearchContainer>
            <>
              {Object.keys(filters).length > 2 ? (
                <button
                  className="active-filter filter"
                  onClick={() => setShow(true)}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </button>
              ) : (
                <button className="lens filter" onClick={() => setShow(true)}>
                  <FontAwesomeIcon icon={faFilter} />
                </button>
              )}
              {Object.keys(filters).length > 2 && (
                <button
                  className="lens active-filter"
                  style={{ width: "42px" }}
                  onClick={() => {
                    setFilters({
                      startDate: new Date(startDate).toISOString(),
                      endDate: new Date(endDate).toISOString(),
                    });
                    setSearchTerm("");
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )}
            </>
          </div>
          {show && (
            <div>
              <FilterBG
                className="invisible-background"
                onClick={() => setShow(false)}
              />
              <FilterDiv>
                <h3>FILTER</h3>
                {filterCategories.map((category) => (
                  <div key={category.field} className="filter-item-div">
                    <label>
                      <p>{category.label}:</p>
                      <Select
                        options={category.options.map(({ label, value }) => ({
                          value: value,
                          label: label,
                        }))}
                        isMulti={category?.isMulti === true ? true : false}
                        name={category.field}
                        value={category.options.find(
                          (option) => option.value === filters[category.field]
                        )}
                        onChange={(e) =>
                          handleFilterChange(
                            e,
                            category.field,
                            category.isMulti
                          )
                        }
                      />
                    </label>
                  </div>
                ))}
                <button
                  className="clear-button"
                  onClick={() =>
                    setFilters({
                      startDate: new Date(startDate).toISOString(),
                      endDate: new Date(endDate).toISOString(),
                    })
                  }
                >
                  Clear
                </button>
              </FilterDiv>
            </div>
          )}
        </div>
      </div>
      {showTimeType === "open" ? (
        <div>
          {selected.length === 0 ? (
            <Link
              to="/create/timerecord"
              style={{ marginLeft: "5px" }}
              className="action-buttons"
            >
              <Button>Add Time</Button>
            </Link>
          ) : (
            <Button
              style={{ background: "red" }}
              onClick={() => setOnToggleConfirm(true)}
            >
              Delete
            </Button>
          )}
          <Button
            disabled={selected.length === 0}
            className="action-buttons"
            onClick={() => editMultiple()}
          >
            Edit
          </Button>
          <Button
            disabled={selected.length === 0}
            onClick={() => processTime()}
          >
            Process
          </Button>
        </div>
      ) : (
        <div>
          <Link
            to="/create/timerecord"
            style={{ marginLeft: "5px" }}
            className="action-buttons"
          >
            <Button>Add Time</Button>
          </Link>
          <Button disabled className="action-buttons">
            Edit
          </Button>
          <Button
            disabled={processedSelected.length === 0}
            onClick={() => unProcessTime()}
          >
            Reverse
          </Button>
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2",
          gridTemplateRows: "1",
        }}
      >
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(startDate, 1), subWeeks(endDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(startDate, 1), subDays(endDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(startDate, 1), addDays(endDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(startDate, 1), addWeeks(endDate, 1)])
            }
          >
            {">>"}
          </Button>
        </div>
      </div>
    </TimeSearchDiv>
  );
};

TimeSearch.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  fetchData: PropTypes.func,
  filterCategories: PropTypes.array,
  sortBy: PropTypes.any,
  orderBy: PropTypes.any,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  processTime: PropTypes.func,
  unProcessTime: PropTypes.func,
  selected: PropTypes.array,
  processedSelected: PropTypes.array,
  showTimeType: PropTypes.string,
  editMultiple: PropTypes.func,
  setOnToggleConfirm: PropTypes.func,
};

export default TimeSearch;
