import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";
import { Button } from "../../../styles/Style";

const UploadQuotePanel = styled.div`
  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }
  .confirm-panel {
    padding: 50px;
    background-color: white;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: block;
    position: fixed;
    z-index: 25;
  }
  .opaque-panel {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: gray;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 65px;
    font-size: 1px;
    color: white;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .image-button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
`;

const UploadQuote = ({
  setOnToggleUploadQuote,
  handleAddQuote,
  uploadQuote,
}) => {
  const [total, setTotal] = useState(0);
  const [pdf, setPDF] = useState(null);
  return (
    <UploadQuotePanel>
      <div className="confirm-panel">
        <h3>{`Add Quote For ${uploadQuote.vendor.vendorName}`}</h3>
        <label style={{ fontWeight: "bold" }}>
          Quote Total
          <CurrencyInput
            id="input-example"
            name="input-name"
            placeholder="Enter Quote Total"
            value={total}
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: "en-US", currency: "USD" }}
            onValueChange={setTotal}
            style={{ marginLeft: "5px" }}
          />
        </label>
        <br />
        <div>
          <ButtonDiv>
            <div className="button-wrap">
              <label className="image-button" htmlFor="file">
                Add Quote PDF
              </label>
              <input
                id="file"
                type="file"
                name="myImage"
                onChange={(e) => {
                  setPDF(e.target.files[0]);
                }}
              />
            </div>
          </ButtonDiv>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <ButtonDiv>
            <div className="button-wrap">
              <Button onClick={() => setOnToggleUploadQuote(false)}>
                Cancel
              </Button>
            </div>
          </ButtonDiv>
          <ButtonDiv>
            <Button
              disabled={parseFloat(total) === parseFloat("0") || pdf === null}
              onClick={() => {
                handleAddQuote(uploadQuote.id, parseFloat(total), pdf);
                setOnToggleUploadQuote(false);
              }}
            >
              Save
            </Button>
          </ButtonDiv>
        </div>
      </div>
      <div className="opaque-panel" />
    </UploadQuotePanel>
  );
};

UploadQuote.propTypes = {
  setOnToggleUploadQuote: PropTypes.func,
  handleAddQuote: PropTypes.func,
  uploadQuote: PropTypes.object,
};

export default UploadQuote;
