import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const DataTable = styled.table`
  border: 0px;
  width: 100%;
  border-collapse: collapse;
  .tableHead {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    background-color: black;
    color: white;
    padding: 0;
    th {
      text-align: center;
      padding: 0;
      cursor: pointer;
    }
    .descending::after th {
      content: "&#8593";
    }
    .ascending::after th {
      content: "&#8596";
    }
  }
  .tableBodyContainer {
    width: 100%;
    height: 700px;
    overflow: auto;
    .tableBody {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      cursor: pointer;
      tr:hover {
        background-color: gainsboro;
      }
      tr:nth-child(even) {
        background-color: #f0f0f0;
      }
      tr:nth-child(even):hover {
        background-color: gainsboro;
      }
      td {
        text-align: center;
      }
    }
  }
`;

const TimeCardTable = ({
  data,
  columns,
  handleSort,
  sortBy,
  orderBy,
  navigate,
}) => {
  return (
    <DataTable>
      <tbody>
        <tr>
          <td>
            <table className="tableHead">
              <thead>
                <tr className="cell">
                  {columns.map((column) => (
                    <th
                      key={column[0]}
                      onClick={() => {
                        handleSort(column[1]);
                      }}
                    >
                      {column[0]}{" "}
                      {sortBy === undefined ? (
                        ""
                      ) : column[1] === sortBy ? (
                        orderBy === "asc" ? (
                          <FontAwesomeIcon icon={faCaretDown} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretUp} />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </td>
        </tr>
        {data?.length > 0 && (
          <tr>
            <td>
              <div className="tableBodyContainer">
                <table className="tableBody">
                  <tbody>
                    {data &&
                      data.map((rowData) => (
                        <tr
                          key={rowData.id}
                          id={rowData.id}
                          onClick={() => navigate(`/timecard/${rowData.id}`)}
                        >
                          {columns.map((column) => {
                            if (column[1] === "weekEnding") {
                              return (
                                <td key={`${rowData.id} ${column[1]}`}>
                                  {dayjs(rowData[column[1]]).format("MM/DD/YY")}
                                </td>
                              );
                            } else if (column[1] === "sum") {
                              return (
                                <td key={`${rowData.id} ${column[1]}`}>
                                  {rowData.timeCardItems
                                    .map((timeCardItem) =>
                                      timeCardItem.hours.reduce(
                                        (prev, cur) => prev + Number(cur),
                                        0
                                      )
                                    )
                                    .reduce(
                                      (prev, cur) => prev + Number(cur),
                                      0
                                    )}
                                </td>
                              );
                            } else if (column[1].includes(".")) {
                              const multiLevelData = column[1].split(".");
                              return (
                                <td key={`${rowData.id} ${column[1]}`}>
                                  {
                                    rowData[multiLevelData[0]][
                                      multiLevelData[1]
                                    ]
                                  }
                                </td>
                              );
                            } else {
                              return (
                                <td key={`${rowData.id} ${column[1]}`}>
                                  {rowData[column[1]]}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </DataTable>
  );
};

TimeCardTable.propTypes = {
  columns: PropTypes.arrayOf(String),
  data: PropTypes.array,
  handleSort: PropTypes.func,
  sortBy: PropTypes.any,
  orderBy: PropTypes.any,
  navigate: PropTypes.func,
};

export default TimeCardTable;
