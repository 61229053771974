import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import EditVendorPanel from "./EditVendorPanel";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import { API_URL } from "../../env";

const columns = [
  ["Vendor Number", "vendorNumber"],
  ["Vendor Name", "vendorName"],
];

const Vendors = () => {
  const [state, setState] = useState({
    vendors: [],
    editVendor: "",
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/vendors/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                vendors: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);

          navigate(`/vendors?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const vendor = state.vendors.filter((vendor) => vendor.id === id);
    setState((prevState) => ({
      ...prevState,
      editVendor: vendor,
    }));
  };

  const onEditVendor = (id, vendor) => {
    const index = state.vendors.findIndex((vendor) => vendor.id === id);
    setState((prevState) => {
      prevState.vendors[index] = { ...vendor };
      return {
        ...prevState,
      };
    });
  };

  const onDeselectVendor = () => {
    setState((prevState) => ({
      ...prevState,
      editVendor: "",
    }));
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { vendors, editVendor } = state;
  return (
    <div>
      {editVendor && (
        <EditVendorPanel
          editVendor={editVendor}
          onDeselectVendor={onDeselectVendor}
          onEditVendor={onEditVendor}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Upload Vendors"}
        buttonPath={"/upload/vendors"}
        pageTitle={"Vendors"}
        fetchData={fetchData}
        filterBool={false}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={vendors}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Vendors;
