import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import Select from "react-select";
import { Button } from "../../../styles/Style";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../env";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const EstimateDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  .labor {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .quotes-allowances {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
`;

const headers = [
  "phase",
  "subPhase",
  "level",
  "subLevel",
  "area",
  "taskCode",
  "quantityEstimated",
  "unitOfMeasure",
  "description",
  "laborHours",
  "laborCost",
  "materialCost",
  "totalCost",
];

const UploadEstimate = () => {
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [estimatedLaborRate, setEstimatedLaborRate] = useState("");
  const [estimatedIndirectLabor, setEstimatedIndirectLabor] = useState("");
  const [estimatedAllowances, setEstimatedAllowances] = useState([]);
  const [estimatedQuotes, setEstimatedQuotes] = useState([]);
  const [uoms, setUOMs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setJobs(json);
        setLoading(false);
      });
    fetch(`${API_URL}/material/uoms`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setUOMs(json);
      });
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTasks(json);
      });
  }, []);

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const filteredData = results.data.filter(
          (datem) =>
            datem[0] !== "" &&
            !datem[0].toLowerCase().includes("job id") &&
            !datem[0].toLowerCase().includes("item #") &&
            !datem[0].toLowerCase().includes("totals:") &&
            !datem[0].toLowerCase().includes("page") &&
            !datem[2].toLowerCase().includes("phone")
        );
        const valuesArray = [];
        let currentValue = {};
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i][0].includes(">")) {
            const phaseTree = filteredData[i][0].split(" > ");
            currentValue = {
              phase: phaseTree[0] ? phaseTree[0] : "",
              subPhase: phaseTree[1] ? phaseTree[1] : "",
              level: phaseTree[2] ? phaseTree[2] : "",
              subLevel: phaseTree[3] ? phaseTree[3] : "",
              area: phaseTree[4] ? phaseTree[4] : "",
            };
          } else if (filteredData[i][0].toLowerCase().includes("cost code")) {
            const costCodeLine = filteredData[i][0]
              .split("Cost Code ")[1]
              .split(",")[0];
            currentValue = {
              ...currentValue,
              taskCode: costCodeLine.includes("UNAS")
                ? "UNAS"
                : costCodeLine.includes("V")
                ? "600"
                : costCodeLine === "920"
                ? "905"
                : costCodeLine,
            };
          } else {
            if (currentValue.taskCode !== "UNAS") {
              valuesArray.push({
                ...currentValue,
                itemIdNumber: Number(filteredData[i][[0]]),
                taskCode:
                  currentValue.taskCode.substr(0, 1) === "Q"
                    ? currentValue.taskCode.substr(1, 2) + "0"
                    : currentValue.taskCode,
                quantityEstimated: parseInt(
                  filteredData[i][3].replace(",", ""),
                  10
                ),
                unitOfMeasure: filteredData[i][4],
                estimatedLaborUnitsx1M:
                  Number(filteredData[i][8]) *
                  1000 *
                  Number(filteredData[i][9]) *
                  1000,
                estimatedLaborUnitPricex1M:
                  Number(filteredData[i][8]) *
                  1000 *
                  Number(filteredData[i][9]) *
                  1000 *
                  Number(estimatedLaborRate),
                estimatedMaterialUnitPricex1M:
                  Number(filteredData[i][5]) *
                  1000 *
                  Number(filteredData[i][6]) *
                  1000,
                description:
                  currentValue.taskCode.substr(0, 1) === "Q"
                    ? "QUOTE - " +
                      `${filteredData[i][2]} ${filteredData[i][1]}`
                        .replace(/\s+/g, " ")
                        .trim()
                    : `${filteredData[i][2]} ${filteredData[i][1]}`
                        .replace(/\s+/g, " ")
                        .trim(),
              });
            }
          }
        }
        setItems(valuesArray);
      },
    });
  };

  function findUniqueCombinations(arr) {
    // Use a Set to store unique combinations based on a string representation
    const uniqueSubPhaseCombo = new Set();
    const uniqueLevelCombo = new Set();
    const uniqueSubLevelCombo = new Set();
    const uniqueAreaCombinations = new Set();
    let uniqueSubPhaseArray = [];
    let uniqueLevelArray = [];
    let uniqueSubLevelArray = [];
    let uniqueAreaArray = [];
    for (const obj of arr) {
      // Create a string representation of the sorted object
      const subPhaseKey = `${obj.phase}-${obj.subPhase}`;
      const levelKey = `${obj.subPhase}-${obj.level}`;
      const subLevelKey = `${obj.level}-${obj.subLevel}`;
      const areaKey = `${obj.subLevel}-${obj.area}`;

      // Add the string representation to the Set if it's unique
      if (obj.phase && obj.subPhase) {
        if (!uniqueSubPhaseCombo.has(subPhaseKey)) {
          uniqueSubPhaseCombo.add(subPhaseKey);
          uniqueSubPhaseArray.push({
            phase: obj.phase,
            name: obj.subPhase,
          });
        }
      }
      if (obj.subPhase && obj.level) {
        if (!uniqueLevelCombo.has(levelKey)) {
          uniqueLevelCombo.add(levelKey);
          uniqueLevelArray.push({ subPhase: obj.subPhase, name: obj.level });
        }
      }
      if (obj.level && obj.subLevel) {
        if (!uniqueSubLevelCombo.has(subLevelKey)) {
          uniqueSubLevelCombo.add(subLevelKey);
          uniqueSubLevelArray.push({
            level: obj.level,
            name: obj.subLevel,
          });
        }
      }
      if (obj.subLevel && obj.area) {
        if (!uniqueAreaCombinations.has(areaKey)) {
          uniqueAreaCombinations.add(areaKey);
          uniqueAreaArray.push({
            subLevel: obj.subLevel,
            name: obj.area,
          });
        }
      }
    }

    return {
      subPhases: uniqueSubPhaseArray,
      levels: uniqueLevelArray,
      subLevels: uniqueSubLevelArray,
      areas: uniqueAreaArray,
    };
  }

  const handleSubmit = () => {
    const { subPhases, levels, subLevels, areas } =
      findUniqueCombinations(items);

    try {
      const requestOptions = {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phases: Array.from(new Set(items?.map((item) => item.phase))).map(
            (phase) => ({ name: phase })
          ),
          subPhases,
          levels,
          subLevels,
          areas,
          materials: items.map((item) => {
            const uomIndex = uoms.findIndex(
              (uom) => uom.code === item.unitOfMeasure
            );
            const taskIndex = tasks.findIndex(
              (task) => task.taskCode === item.taskCode
            );
            return {
              ...item,
              itemIdNumber: parseInt(item.itemIdNumber, 10),
              quantityEstimated: parseInt(item.quantityEstimated, 10),
              estimatedLaborUnitsx1M: parseInt(item.estimatedLaborUnitsx1M, 10),
              estimatedMaterialUnitPricex1M: parseInt(
                item.estimatedMaterialUnitPricex1M,
                10
              ),
              unitOfMeasure: {
                connect: {
                  id: uoms[uomIndex]?.id,
                },
              },
              unitOfMeasureId: uoms[uomIndex]?.id,
              taskId: tasks[taskIndex]?.id,
              description: item.description,
            };
          }),
          quotes: JSON.stringify(estimatedQuotes),
          allowances: JSON.stringify(estimatedAllowances),
          estimatedLaborRate,
          estimatedIndirectLabor,
        }),
      };
      fetch(`${API_URL}/estimate/upload/${jobId}`, requestOptions).then(
        (response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          return response.json();
        }
      );

      setDoneUploading(true);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (doneUploading) {
      navigate("/estimates");
    }
  }, [doneUploading]);

  const options = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber}-${job.jobName}`,
  }));

  return (
    <div style={{ marginBottom: "20px" }}>
      <h1>Upload Estimate</h1>
      <div style={{ margin: "10px auto", width: "30%" }}>
        <Select
          isLoading={loading}
          options={options}
          isSearchable
          onChange={(e) => setJobId(e.value)}
          placeholder={"Select Job"}
          defaultValue={jobId}
        />
      </div>
      <EstimateDetails>
        <div className="labor">
          <label>
            Estimated Labor Rate
            <input
              value={estimatedLaborRate}
              onChange={(e) => setEstimatedLaborRate(e.target.value)}
            />
          </label>
          <label>
            Estimated Indirect Labor
            <input
              value={estimatedIndirectLabor}
              onChange={(e) => setEstimatedIndirectLabor(e.target.value)}
            />
          </label>
        </div>
        <div className="quotes-allowances">
          <div>
            <h3>Quotes</h3>
            {estimatedQuotes.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Material Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {estimatedQuotes.map((quote, index) => (
                    <tr key={`quote-${index}`} className="quote">
                      <td>
                        <input
                          value={quote.quoteDesc}
                          onChange={(e) =>
                            setEstimatedQuotes((prevState) => {
                              const newState = prevState;
                              newState[index].quoteDesc = e.target.value;
                              return [...newState];
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          value={quote.materialCost}
                          onChange={(e) =>
                            setEstimatedQuotes((prevState) => {
                              const newState = prevState;
                              newState[index].materialCost = e.target.value;
                              return [...newState];
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr style={{ fontWeight: "bold" }}>
                    <td>Total</td>
                    <td>
                      {estimatedQuotes
                        .reduce(
                          (prev, cur) => prev + Number(cur.materialCost),
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </td>
                  </tr>
                </tfoot>
              </table>
            )}
            <button
              onClick={() =>
                setEstimatedQuotes((prevState) => [
                  ...prevState,
                  { quoteDesc: "", materialCost: "" },
                ])
              }
            >
              New Quote
            </button>
          </div>
          <div>
            <h3>Allowances</h3>
            {estimatedAllowances.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Material Cost</th>
                    <th>Labor Cost</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {estimatedAllowances.map((allowance, index) => (
                    <tr key={`allowance-${index}`} className="allowance">
                      <td>
                        <input
                          value={allowance.allowanceDesc}
                          onChange={(e) =>
                            setEstimatedAllowances((prevState) => {
                              const newState = prevState;
                              newState[index].allowanceDesc = e.target.value;
                              return [...newState];
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          value={allowance.materialCost}
                          onChange={(e) =>
                            setEstimatedAllowances((prevState) => {
                              const newState = prevState;
                              newState[index].materialCost = e.target.value;
                              return [...newState];
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          value={allowance.laborCost}
                          onChange={(e) =>
                            setEstimatedAllowances((prevState) => {
                              const newState = prevState;
                              newState[index].laborCost = e.target.value;
                              return [...newState];
                            })
                          }
                        />
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {(
                          Number(allowance.laborCost) +
                          Number(allowance.materialCost)
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr style={{ fontWeight: "bold" }}>
                    <td>Totals</td>
                    <td>
                      {estimatedAllowances
                        .reduce(
                          (prev, cur) => prev + Number(cur.materialCost),
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </td>
                    <td>
                      {estimatedAllowances
                        .reduce((prev, cur) => prev + Number(cur.laborCost), 0)
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </td>
                    <td>
                      {estimatedAllowances
                        .reduce(
                          (prev, cur) =>
                            prev +
                            (Number(cur.materialCost) + Number(cur.laborCost)),
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </td>
                  </tr>
                </tfoot>
              </table>
            )}
            <button
              onClick={() =>
                setEstimatedAllowances((prevState) => [
                  ...prevState,
                  { allowanceDesc: "", materialCost: "", laborCost: "" },
                ])
              }
            >
              New Allowance
            </button>
          </div>
        </div>
      </EstimateDetails>
      {estimatedLaborRate && (
        <ButtonDiv>
          <div className="button-wrap">
            <label className="button" htmlFor="upload">
              Choose File
            </label>
            <input
              type={"file"}
              name="file"
              accept=".csv"
              onChange={(e) => handleFile(e)}
              className="upload"
              id="upload"
            />
          </div>
        </ButtonDiv>
      )}
      <div style={{ height: "25px", margin: "20px" }}>
        {error && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <div>
        <Button
          disabled={!jobId || items.length < 1}
          onClick={() => handleSubmit()}
        >
          Upload Estimate
        </Button>
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {items.length > 0 && (
          <table>
            <thead>
              <tr>
                {headers.map((rows, index) => {
                  return (
                    <th
                      style={{
                        textTransform: "capitalize",
                        position: "sticky",
                        top: "0",
                        padding: "10px 0px",
                        background: "white",
                      }}
                      key={index}
                    >
                      {rows}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                const laborHrs = item.estimatedLaborUnitsx1M / 1000000000;
                const laborCost =
                  item.quantityEstimated *
                  (item.estimatedLaborUnitPricex1M / 1000000000);
                const materialCost =
                  item.quantityEstimated *
                  (item.estimatedMaterialUnitPricex1M / 1000000000);
                return (
                  <tr key={`${index}-${item.phase}`}>
                    <td>{item.phase}</td>
                    <td>{item.subPhase}</td>
                    <td>{item.level}</td>
                    <td>{item.subLevel}</td>
                    <td>{item.area}</td>
                    <td>{item.taskCode}</td>
                    <td>{item.quantityEstimated}</td>
                    <td>{item.unitOfMeasure}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      {laborHrs.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {laborCost.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {materialCost.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {(laborCost + materialCost).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr style={{ fontWeight: "bold" }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "right" }}>
                  {items
                    .reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedLaborUnitsx1M / 1000000),
                      0
                    )
                    .toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {items
                    .reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedLaborUnitPricex1M / 1000000),
                      0
                    )
                    .toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {items
                    .reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedMaterialUnitPricex1M / 1000000),
                      0
                    )
                    .toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {items
                    .reduce(
                      (prev, cur) =>
                        prev +
                        (cur.quantityEstimated *
                          (cur.estimatedMaterialUnitPricex1M / 1000000) +
                          (cur.quantityEstimated *
                            cur.estimatedLaborUnitPricex1M) /
                            1000000),
                      0
                    )
                    .toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
              </tr>
              {estimatedQuotes.map((quote, index) => {
                const quoteCost = Number(quote.materialCost).toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }
                );
                return (
                  <tr key={`${index}-quote`}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{`${quote.quoteDesc} Quote`}</td>
                    <td style={{ textAlign: "right" }}></td>
                    <td style={{ textAlign: "right" }}></td>
                    <td style={{ textAlign: "right" }}>{quoteCost}</td>
                    <td style={{ textAlign: "right" }}>{quoteCost}</td>
                  </tr>
                );
              })}
              {estimatedAllowances.map((allowance, index) => {
                const allowanceMaterial = Number(allowance.materialCost);
                const allowanceLabor = Number(allowance.laborCost);
                return (
                  <tr key={`${index}-allowance`}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{`${allowance.allowanceDesc} Allowance`}</td>
                    <td style={{ textAlign: "right" }}>
                      {(allowanceLabor / estimatedLaborRate).toLocaleString(
                        "en-US",
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {allowanceLabor.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {allowanceMaterial.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {(allowanceMaterial + allowanceLabor).toLocaleString(
                        "en-US",
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Tax</td>
                <td style={{ textAlign: "right" }}></td>
                <td style={{ textAlign: "right" }}></td>
                <td style={{ textAlign: "right" }}>
                  {(
                    (items.reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedMaterialUnitPricex1M / 1000000),
                      0
                    ) +
                      estimatedQuotes.reduce(
                        (prev, cur) => prev + Number(cur.materialCost),
                        0
                      ) +
                      estimatedAllowances.reduce(
                        (prev, cur) => prev + Number(cur.materialCost),
                        0
                      )) *
                    0.07
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {(
                    (items.reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedMaterialUnitPricex1M / 1000000),
                      0
                    ) +
                      estimatedQuotes.reduce(
                        (prev, cur) => prev + Number(cur.materialCost),
                        0
                      ) +
                      estimatedAllowances.reduce(
                        (prev, cur) => prev + Number(cur.materialCost),
                        0
                      )) *
                    0.07
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
              {estimatedIndirectLabor && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Indirect Labor</td>
                    <td style={{ textAlign: "right" }}></td>
                    <td style={{ textAlign: "right" }}>
                      {Number(estimatedIndirectLabor).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}></td>
                    <td style={{ textAlign: "right" }}>
                      {Number(estimatedIndirectLabor).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                </>
              )}
              <tr style={{ fontWeight: "bold" }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Grand Total</td>
                <td style={{ textAlign: "right" }}>
                  {(
                    items.reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedLaborUnitsx1M / 1000000),
                      0
                    ) +
                    estimatedAllowances.reduce(
                      (prev, cur) =>
                        prev + Number(cur.laborCost) / estimatedLaborRate,
                      0
                    )
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {(
                    items.reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          (cur.estimatedLaborUnitPricex1M / 1000000),
                      0
                    ) +
                    Number(estimatedIndirectLabor) +
                    estimatedAllowances.reduce(
                      (prev, cur) => prev + Number(cur.laborCost),
                      0
                    )
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {(
                    items.reduce(
                      (prev, cur) =>
                        prev +
                        cur.quantityEstimated *
                          ((cur.estimatedMaterialUnitPricex1M / 1000000) *
                            1.07),
                      0
                    ) +
                    (estimatedQuotes.reduce(
                      (prev, cur) => prev + Number(cur.materialCost),
                      0
                    ) +
                      estimatedAllowances.reduce(
                        (prev, cur) => prev + Number(cur.materialCost),
                        0
                      )) *
                      1.07
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ textAlign: "right" }}>
                  {(
                    items.reduce(
                      (prev, cur) =>
                        prev +
                        (cur.quantityEstimated *
                          ((cur.estimatedMaterialUnitPricex1M / 1000000) *
                            1.07) +
                          (cur.quantityEstimated *
                            cur.estimatedLaborUnitPricex1M) /
                            1000000),
                      0
                    ) +
                    (estimatedQuotes.reduce(
                      (prev, cur) => prev + Number(cur.materialCost),
                      0
                    ) *
                      1.07 +
                      estimatedAllowances.reduce(
                        (prev, cur) => prev + Number(cur.materialCost),
                        0
                      ) *
                        1.07) +
                    estimatedAllowances.reduce(
                      (prev, cur) => prev + Number(cur.laborCost),
                      0
                    ) +
                    Number(estimatedIndirectLabor)
                  ).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadEstimate;
