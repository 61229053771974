import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const UploadManufacturers = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [values, setValues] = useState([]);
  const [error, setError] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/manufacturers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setManufacturers(json));
  }, []);

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];
        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(d);
        });
        setValues(
          valuesArray.filter(
            (value) =>
              manufacturers.findIndex((mftr) => mftr.name === value.name) === -1
          )
        );
      },
    });
  };
  const handleSubmit = () => {
    try {
      if (values) {
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        };
        fetch(`${API_URL}/upload/manufacturers`, requestOptions).then(
          (response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            setDoneUploading(true);
            return response.json();
          }
        );
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (doneUploading) {
      navigate("/tool/manufacturers");
    }
  }, [doneUploading]);

  return (
    <div>
      <h1>Upload Tool Manufacturers</h1>
      <ButtonDiv>
        <div className="button-wrap">
          <label className="button" htmlFor="upload">
            Choose File
          </label>
          <input
            type={"file"}
            name="file"
            accept=".csv"
            onChange={(e) => handleFile(e)}
            className="upload"
            id="upload"
          />
        </div>
      </ButtonDiv>
      <div style={{ height: "25px", margin: "20px" }}>
        {error && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <div>
        {values.length > 0 && (
          <Button onClick={handleSubmit}>
            {values.length > 0 && "Add Manufacturer"}
            {values.length > 1 && "s"}
          </Button>
        )}
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {values.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Manufacturer</th>
              </tr>
            </thead>
            <tbody>
              {values.map((value) => (
                <tr key={value.name}>
                  <td>{value.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadManufacturers;
