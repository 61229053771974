import styled from "styled-components";

const TimeCardTable = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 500px) 1fr minmax(0, 500px);
  table {
    grid-column-start: 2;
    border-collapse: collapse;
    tr {
      display: grid;
      height: 100%;
      padding: 0;
      margin: 0;
      td {
        height: 30px;
        width: 100%;
      }
      select {
        text-align: center;
        padding: 0;
        margin: 0;
      }
      select:focus {
        outline: none;
      }
      .job-select {
        select {
          width: 251px;
          height: 34px;
        }
      }
      .task-select {
        select {
          width: 176px;
          height: 34px;
        }
      }
      input {
        width: 100%;
        height: 30px;
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 20px;
      }
      input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      input:focus {
        outline: none;
      }
      .data-cell {
        width: 100px;
      }
      .total-cell {
        width: 100px;
      }
      button {
        width: 34px;
        height: 33px;
        padding: 0;
        margin: 0 3px;
      }
      .delete-button {
        background: red;
        border-style: solid;
        border: 1px solid darkgray;
        color: white;
        cursor: pointer;
        font-size: 20px;
      }
      .delete-button:disabled {
        background: lightgray;
        cursor: default;
      }
    }
    tfoot {
      input {
        cursor: default;
      }
      .add-row-button {
        cursor: pointer;
        background: #032bdf;
        border-color: #032bdf;
        border-style: solid;
        color: transparent;
        text-shadow: 0 0 0 white;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
  }
  .bottom-buttons {
    display: grid;
    grid-column-start: 2;
    grid-template-columns: 1fr;
    button {
      width: 150px;
      margin: 40px;
      height: 40px;
    }
    .submit-button {
      justify-self: right;
      cursor: pointer;
      background: green;
      border-color: green;
      border-style: solid;
      color: white;
      text-transform: uppercase;
      font-size: 20px;
      border-radius: 4px;
    }
    .submit-button:disabled {
      background: lightgray;
      border-color: lightgray;
      cursor: default;
    }
  }
`;

export default TimeCardTable;
