import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../Table/Table";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import { API_URL } from "../../../env";

const columns = [
  ["Job Number", "job.jobNumber"],
  ["Job Name", "job.jobName"],
];

const JobEstimates = () => {
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/estimates/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setEstimates(json);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);

          navigate(`/estimates?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:" + error);
        }
      },
    []
  );

  const filterCategories = [
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    navigate(`/estimate/${id}`);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  return (
    <div>
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Upload Estimate"}
        buttonPath={"/upload/estimate"}
        pageTitle={"Estimates"}
        fetchData={fetchData}
        filterBool={false}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={estimates}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default JobEstimates;
