import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import _ from "lodash";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const UploadJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [updateJobs, setUpdateJobs] = useState([]);
  const [updateAddresses, setUpdateAddresses] = useState([]);
  const [addCustomers, setAddCustomers] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/employees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
  }, []);

  const handleAddCustomers = (value) => {
    setAddCustomers((prevState) => [
      ...prevState,
      {
        customerNumber: parseInt(value.customerNumber.trim()),
        name: value.name,
      },
    ]);
    return parseInt(value.customerNumber.trim());
  };

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(d);
        });

        const data = valuesArray.map((value) => ({
          ...value,
          active: value.active === "A" ? true : false,
          projectManagerId: value.projectManagerId
            ? employees[
                employees.findIndex(
                  (employee) =>
                    value.projectManagerId.toLowerCase() ===
                    employee.firstName.charAt(0).toLowerCase() +
                      employee.lastName.toLowerCase()
                )
              ].id
            : null,
          foremanId:
            value.foremanId !== ""
              ? employees[
                  employees.findIndex((employee) => {
                    return (
                      value.foremanId.toLowerCase() ===
                      `${employee.firstName} ${employee.lastName}`.toLowerCase()
                    );
                  })
                ].id
              : null,
          customerNumber:
            parseInt(value.customerNumber.trim()) !== "" &&
            customers.findIndex(
              (customer) =>
                parseInt(value.customerNumber.trim()) ===
                customer.customerNumber
            ) !== -1
              ? customers[
                  customers.findIndex(
                    (customer) =>
                      parseInt(value.customerNumber.trim()) ===
                      customer.customerNumber
                  )
                ].customerNumber
              : parseInt(value.customerNumber.trim()) !== ""
              ? handleAddCustomers(value)
              : null,
          jobType: value.jobType === "" ? null : value.jobType,
        }));
        const filterJobs = (arr1, arr2) => {
          let newRes = [];
          let updateRes = [];
          let updateJob = [];
          let updateJobAddress = [];
          newRes = arr1.filter((el) => {
            return !arr2.find((element) => element.jobNumber === el.jobNumber);
          });
          updateRes = arr1.filter((el) => {
            const foundJob = arr2.find(
              (element) => element.jobNumber === el.jobNumber
            );
            if (foundJob) {
              const criteria = [
                foundJob.jobNumber === el.jobNumber,
                foundJob.jobName === el.jobName,
                foundJob.active === el.active,
                foundJob.jobAddress === el.jobAddress,
                foundJob.jobAddress2 === el.jobAddress2,
                foundJob.jobCity === el.jobCity,
                foundJob.jobState === el.jobState,
                foundJob.jobZip === el.jobZip,
                foundJob.projectManagerId === el.projectManagerId,
                foundJob.foremanId === el.foremanId,
                foundJob.customerNumber === el.customerNumber,
                foundJob.jobType === el.jobType,
              ];
              return !criteria.every((e) => e === true);
            } else return false;
          });
          updateJob = updateRes.filter((el) => {
            const foundJob = arr2.find(
              (element) => element.jobNumber === el.jobNumber
            );
            if (foundJob) {
              const criteria = [
                foundJob.jobName === el.jobName,
                foundJob.active === el.active,
                foundJob.projectManagerId === el.projectManagerId,
                foundJob.foremanId === el.foremanId,
                foundJob.customerNumber === el.customerNumber,
                foundJob.jobType === el.jobType,
              ];
              return !criteria.every((e) => e === true);
            } else return false;
          });
          updateJobAddress = arr1.filter((el) => {
            const foundJob = arr2.find(
              (element) => element.jobNumber === el.jobNumber
            );
            if (foundJob) {
              const criteria = [
                foundJob.jobAddress === el.jobAddress,
                foundJob.jobAddress2 === el.jobAddress2,
                foundJob.jobCity === el.jobCity,
                foundJob.jobState === el.jobState,
                foundJob.jobZip === el.jobZip,
              ];
              return !criteria.every((e) => e === true);
            } else return false;
          });
          return [newRes, updateRes, updateJob, updateJobAddress];
        };
        const newJobs = filterJobs(data, jobs);
        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        if (newJobs[0].length > 0) {
          const set1 = newJobs[0].map((job) => {
            if (job.projectManagerId === "") {
              delete job.projectManagerId;
            } else if (job.foremanId === "") {
              delete job.foremanId;
            }
            return { ...job };
          });
          setValues(set1);
        }
        if (newJobs[1].length > 0) {
          const set2 = newJobs[1].map((job) => {
            if (job.projectManagerId === "") {
              job.projectManagerId === null;
            } else if (job.foremanId === "") {
              job.foremanId === null;
            }
            return { ...job };
          });
          setUpdates(set2);
        }
        if (newJobs[2].length > 0) {
          const set3 = newJobs[2].map((job) => {
            if (job.projectManagerId === "") {
              job.projectManagerId === null;
            } else if (job.foremanId === "") {
              job.foremanId === null;
            }
            return { ...job };
          });
          setUpdateJobs(set3);
        }
        if (newJobs[3].length > 0) {
          const set4 = newJobs[3].map((job) => {
            if (job.projectManagerId === "") {
              job.projectManagerId === null;
            } else if (job.foremanId === "") {
              job.foremanId === null;
            }
            return { ...job };
          });
          setUpdateAddresses(set4);
        }
      },
    });
  };
  const handleSubmit = () => {
    if (addCustomers.length > 0) {
      const requestOptions = {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(_.uniqBy(addCustomers, "customerNumber")),
      };
      fetch(`${API_URL}/create/customers`, requestOptions)
        .then((res) => res.json())
        .then(() => {
          if (values.length > 0) {
            const requestOptions = {
              method: "POST",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(
                values.map((item) => {
                  delete item.name;
                  return item;
                })
              ),
            };
            fetch(`${API_URL}/upload/jobs`, requestOptions).then((res) =>
              res.json()
            );
          }
          if (updateJobs.length > 0) {
            const requestOptions2 = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(
                updateJobs.map((item) => {
                  delete item.name;
                  return item;
                })
              ),
            };

            fetch(`${API_URL}/update/jobs`, requestOptions2).then((res) =>
              res.json()
            );
          }
          if (updateAddresses.length > 0) {
            const requestOptions2 = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(
                updateAddresses.map((item) => {
                  delete item.name;
                  return item;
                })
              ),
            };
            fetch(`${API_URL}/update/job/addresses`, requestOptions2).then(
              (res) => res.json()
            );
          }
        });
      navigate("/jobs");
    } else {
      if (values.length > 0) {
        const requestOptions = {
          method: "POST",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            values.map((item) => {
              delete item.name;
              return item;
            })
          ),
        };
        fetch(`${API_URL}/upload/jobs`, requestOptions).then((res) =>
          res.json()
        );
      }
      if (updateJobs.length > 0) {
        const requestOptions2 = {
          method: "PUT",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            updateJobs.map((item) => {
              delete item.name;
              return item;
            })
          ),
        };
        fetch(`${API_URL}/update/jobs`, requestOptions2).then((res) =>
          res.json()
        );
      }
      if (updateAddresses.length > 0) {
        const requestOptions2 = {
          method: "PUT",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            updateAddresses.map((item) => {
              delete item.name;
              return item;
            })
          ),
        };
        fetch(`${API_URL}/update/job/addresses`, requestOptions2).then((res) =>
          res.json()
        );
      }
      navigate(
        "/jobs"
        // , {
        //   state: {
        //     new: values.map((value, index) => ({
        //       ...value,
        //       id: index,
        //       customerName: customers.findIndex(
        //         (customer) => customer.customerNumber === value.customerNumber
        //       ).name,
        //       projectManagerName: (
        //         index = employees.findIndex(
        //           (employee) => employee.id === value.projectManagerId
        //         )
        //       ) => `${employees[index].firstName} ${employees[index].lastName}`,
        //       foremanName: (
        //         index = employees.findIndex(
        //           (employee) => employee.id === value.foremanId
        //         )
        //       ) => `${employees[index].firstName} ${employees[index].lastName}`,
        //     })),
        //     updated: updateJobs,
        //   },
        // }
      );
    }
  };

  return (
    <div>
      <h1>Upload Jobs</h1>
      <ButtonDiv>
        <div className="button-wrap">
          <label className="button" htmlFor="upload">
            Choose File
          </label>
          <input
            type={"file"}
            name="file"
            accept=".csv"
            onChange={(e) => handleFile(e)}
            className="upload"
            id="upload"
          />
        </div>
      </ButtonDiv>
      <div>
        {(values.length > 0 || updates.length > 0) && (
          <Button onClick={handleSubmit}>
            {values.length > 0 && "Add"}
            {values.length > 0 && updates.length > 0 && " and "}
            {updates.length > 0 && "Update"} Job
            {(values.length > 1 || updates.length > 1) && "s"}
          </Button>
        )}
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {values && (
          <table>
            <thead>
              <tr>
                {(values.length > 0 || updates.length > 0) && <th>Type</th>}
                {tableRows.map((rows, index) => {
                  return <th key={index}>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {updates
                .sort((a, b) => (a.jobNumber < b.jobNumber ? -1 : 1))
                .map((update) => (
                  <tr key={update.jobNumber}>
                    <td>Update</td>
                    <td>{update.jobNumber}</td>
                    <td>{update.jobName}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {update.active.toString()}
                    </td>
                    <td>{update.jobAddress}</td>
                    <td>{update.jobAddress2}</td>
                    <td>{update.jobCity}</td>
                    <td>{update.jobState}</td>
                    <td>{update.jobZip}</td>
                    <td>
                      {update.projectManagerId
                        ? employees[
                            employees.findIndex(
                              (employee) =>
                                employee.id === update.projectManagerId
                            )
                          ].firstName
                        : ""}
                    </td>
                    <td>
                      {update.foremanId
                        ? employees[
                            employees.findIndex(
                              (employee) => employee.id === update.foremanId
                            )
                          ].firstName
                        : ""}
                    </td>
                    <td>{update.customerNumber}</td>
                    <td>{update.name}</td>
                    <td>{update.jobType}</td>
                  </tr>
                ))}
              {values.map((value) => (
                <tr key={value.jobNumber}>
                  <td>New</td>
                  <td>{value.jobNumber}</td>
                  <td>{value.jobName}</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {value.active.toString()}
                  </td>
                  <td>{value.jobAddress}</td>
                  <td>{value.jobAddress2}</td>
                  <td>{value.jobCity}</td>
                  <td>{value.jobState}</td>
                  <td>{value.jobZip}</td>
                  <td>
                    {value.projectManagerId
                      ? employees[
                          employees.findIndex(
                            (employee) => employee.id === value.projectManagerId
                          )
                        ].firstName
                      : ""}
                  </td>
                  <td>
                    {value.foremanId
                      ? employees[
                          employees.findIndex(
                            (employee) => employee.id === value.foremanId
                          )
                        ].firstName
                      : ""}
                  </td>
                  <td>{value.customerNumber}</td>
                  <td>{value.name}</td>
                  <td>{value.jobType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadJobs;
