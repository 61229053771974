import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {
  TableRow,
  PlaceholderRow,
  TableCell,
  InputCell,
  DummyInputCell,
  SelectCell,
  RowNumberCell,
  selectStyles,
} from "../Styles/MaterialTableStyles";
import styled from "styled-components";

const Material = styled.tbody`
  .context-menu-item {
    background-color: white;
    color: black;
    padding: 8px 12px;
    cursor: pointer;
    transition: font-weight 0.2s;
    box-sizing: border-box;
    width: 120px;
    text-align: center;
    font-weight: normal;
  }
  .context-menu-item:hover {
    font-weight: bold;
  }
  .attachments {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px 0px;
    margin-bottom: 10px;
    h3 {
      margin: 3px 0px;
    }
  }
`;

const MaterialTableBody = ({ loading, uomList, data, setData, columns }) => {
  const [activeCell, setActiveCell] = useState({ row: -1, col: -1 });
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    top: 0,
    left: 0,
  });

  const inputRef = useRef(null);
  const selectRefs = useRef([]);

  const handleContextMenu = (e, rowIndex) => {
    e.preventDefault();
    e.stopPropagation(); // Stop the click event propagation
    // Calculate the position based on the event's coordinates or row data
    const top = e.clientY;
    const left = e.clientX;
    setContextMenu({ visible: true, top, left, rowIndex });
  };

  useEffect(() => {
    // Function to close the context menu
    const closeContextMenu = () => {
      setContextMenu({ visible: false, top: 0, left: 0, rowIndex: null });
    };

    // Attach the click event listener to the document when the context menu is visible
    if (contextMenu.visible) {
      document.addEventListener("click", closeContextMenu);
    }

    // Remove the event listener when the context menu is hidden
    return () => {
      document.removeEventListener("click", closeContextMenu);
    };
  }, [contextMenu]);

  const handleKeyDown = (e) => {
    const { row, col } = activeCell;
    const numRows = data.length;
    const numCols = data[0].length;
    switch (e.key) {
      case "ArrowUp":
        setActiveCell({ row: Math.max(row - 1, 0), col });
        break;
      case "ArrowDown":
        if (activeCell.row === numRows - 1) {
          // Active cell is in the last row, add a new row
          setData((prevItems) => [...prevItems, columns]);
          // Set the active cell to the new row
          setActiveCell({
            row: activeCell.row + 1,
            col: activeCell.col,
          });
        } else {
          // Active cell is not in the last row, move to the next row
          setActiveCell({
            row: activeCell.row + 1,
            col: activeCell.col,
          });
        }
        break;
      case "ArrowLeft":
        setActiveCell({ row, col: Math.max(col - 1, 0) });
        break;
      case "ArrowRight":
        setActiveCell({ row, col: Math.min(col + 1, numCols - 1) });
        break;
      case "Tab":
        e.preventDefault();
        if (activeCell.col === columns.length - 1 && !e.shiftKey) {
          if (activeCell.row === data.length - 1) {
            // Add a new row if the active cell is in the last row
            setData((prevItems) => [...prevItems, columns]);
          }
          // Move to the first column (column 0) of the next row
          setActiveCell({
            row: activeCell.row + 1,
            col: 0,
          });
        } else if (activeCell.col === 0 && e.shiftKey) {
          // Handle shifting focus to the previous column when in the first column of a row
          setActiveCell({
            row: activeCell.row,
            col: columns.length - 1, // Move to the last column of the same row
          });
        } else {
          // Handle navigation to the next/previous column
          setActiveCell({
            row: activeCell.row,
            col: activeCell.col + (e.shiftKey ? -1 : 1),
          });
        }
        break;
      case "Enter":
        setActiveCell({ row: row + 1, col });
        break;
      default:
        break;
    }
  };

  const handleMakeActiveRow = (index) => {
    const numberOfItems = data.length;
    setData((prevState) => {
      const updatedState = [...prevState];
      updatedState.push(columns);
      return updatedState;
    });
    setActiveCell({ row: numberOfItems, col: index });
  };

  const handleDeleteRow = () => {
    // Implement the logic to delete the row based on the rowIndex
    // You can use slice, splice, or any method to remove the selected row from your data.
    // Example:
    if (data.length > 1) {
      const updatedData = [...data];
      updatedData.splice(contextMenu.rowIndex, 1);
      setData(updatedData);
    }

    // Hide the context menu
    setContextMenu({ visible: false, top: 0, left: 0 });
  };

  useEffect(() => {
    if (activeCell.col === 1) {
      selectRefs.current[activeCell.row].focus();
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeCell]);

  const handleValueChange = (e) => {
    setData((prevState) => {
      const updatedData = [...prevState];
      updatedData[activeCell.row][activeCell.col] = e.target.value;
      return updatedData;
    });
  };
  return (
    <Material tabIndex="0" onKeyDown={handleKeyDown}>
      {data.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <RowNumberCell
            active={rowIndex === activeCell.row && -1 === activeCell.col}
            style={{
              background:
                contextMenu.visible && contextMenu.rowIndex === rowIndex
                  ? "darkGray"
                  : "#f0f0f0",
            }}
            onClick={() => setActiveCell({ row: rowIndex, col: -1 })}
            onContextMenu={(e) => handleContextMenu(e, rowIndex)}
          >
            {rowIndex + 1}
          </RowNumberCell>
          {row.map((cell, colIndex) =>
            colIndex === 1 ? (
              <SelectCell
                key={colIndex}
                active={
                  colIndex === activeCell.col && rowIndex === activeCell.row
                }
              >
                <Select
                  ref={(el) => (selectRefs.current[rowIndex] = el)}
                  options={uomList}
                  styles={selectStyles} // Apply custom styles to the react-select component
                  isLoading={loading}
                  name="unitOfMeasure"
                  onChange={(e) =>
                    setData((prevState) => {
                      const updatedData = [...prevState];
                      updatedData[rowIndex][colIndex] = e.value;
                      return updatedData;
                    })
                  }
                  defaultValue={data[rowIndex][1]}
                  value={uomList.find((obj) => obj.value === data[rowIndex][1])}
                  placeholder=""
                  menuPosition="fixed"
                />
              </SelectCell>
            ) : rowIndex === activeCell.row && colIndex === activeCell.col ? (
              <InputCell key={colIndex} active>
                <input
                  ref={inputRef}
                  type="text"
                  value={data[rowIndex][colIndex]}
                  onChange={handleValueChange}
                  autoFocus
                  onFocus={(e) => e.target.select()}
                />
              </InputCell>
            ) : (
              <TableCell
                key={colIndex}
                active={
                  rowIndex === activeCell.row && colIndex === activeCell.col
                }
                onClick={() => {
                  setActiveCell({ row: rowIndex, col: colIndex });
                }}
              >
                {cell}
              </TableCell>
            )
          )}
        </TableRow>
      ))}
      {data[0].length === 3 ? (
        <PlaceholderRow>
          <RowNumberCell onClick={() => handleMakeActiveRow(0)}>
            {data.length + 1}
          </RowNumberCell>
          <DummyInputCell>
            <input
              type="text"
              value={""}
              onChange={handleValueChange}
              onClick={() => handleMakeActiveRow(0)}
            />
          </DummyInputCell>
          <SelectCell onClick={() => handleMakeActiveRow(1)}>
            <Select
              options={uomList}
              styles={selectStyles} // Apply custom styles to the react-select component
              isLoading={loading}
              name="unitOfMeasure"
              defaultValue={""}
              placeholder=""
              menuPosition="fixed"
            />
          </SelectCell>
          <DummyInputCell>
            <input
              type="text"
              value={""}
              onClick={() => handleMakeActiveRow(2)}
              onChange={handleValueChange}
            />
          </DummyInputCell>
        </PlaceholderRow>
      ) : (
        <PlaceholderRow>
          <RowNumberCell onClick={() => handleMakeActiveRow(0)}>
            {data.length + 1}
          </RowNumberCell>
          <DummyInputCell>
            <input
              type="text"
              value={""}
              onChange={handleValueChange}
              onClick={() => handleMakeActiveRow(0)}
            />
          </DummyInputCell>
          <SelectCell onClick={() => handleMakeActiveRow(1)}>
            <Select
              options={uomList}
              styles={selectStyles} // Apply custom styles to the react-select component
              isLoading={loading}
              name="unitOfMeasure"
              defaultValue={""}
              placeholder=""
              menuPosition="fixed"
            />
          </SelectCell>
          <DummyInputCell>
            <input
              type="text"
              value={""}
              onClick={() => handleMakeActiveRow(2)}
              onChange={handleValueChange}
            />
          </DummyInputCell>
          <DummyInputCell>
            <input
              type="text"
              value={""}
              onClick={() => handleMakeActiveRow(3)}
            />
          </DummyInputCell>
          <DummyInputCell>
            <input
              type="text"
              value={""}
              onClick={() => handleMakeActiveRow(4)}
            />
          </DummyInputCell>
        </PlaceholderRow>
      )}
      {contextMenu.visible && (
        <div
          style={{
            position: "absolute",
            top: contextMenu.top,
            left: contextMenu.left,
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
          }}
        >
          <div className="context-menu-item" onClick={handleDeleteRow}>
            Delete Row
          </div>
        </div>
      )}
    </Material>
  );
};

MaterialTableBody.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  uomList: PropTypes.array,
  columns: PropTypes.array,
  setData: PropTypes.func,
};

export default MaterialTableBody;
