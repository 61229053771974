import React, { useState } from "react";
import styled from "styled-components";
import GLCodes from "./GLCodes";
import UnitsOfMeasure from "./UnitsOfMeasure";
import TaxCodes from "./TaxCodes";

const MaterialSettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  .settings-menu {
    border: 1px solid black;
    border-radius: 5px;
    ul {
      padding: 0px;
      list-style-type: none;
      width: 100%;
      li {
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: right;
        outline: 1px solid black;
        margin-top: 1px;
        span {
          margin-right: 5px;
        }
      }
    }
  }
  .settings-page {
  }
`;

const Requisitions = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const settingsPage = (param) => {
    switch (param) {
      case "glcodes":
        return <GLCodes />;
      case "taxcodes":
        return <TaxCodes />;
      case "uom":
        return <UnitsOfMeasure />;
      case null:
        return <h4>Select A Setting</h4>;
    }
  };
  return (
    <MaterialSettingsContainer>
      <div className="settings-menu">
        <h3>Settings</h3>
        <ul>
          <li
            onClick={() => setCurrentPage("glcodes")}
            style={{
              background: currentPage === "glcodes" ? "blue" : "white",
              color: currentPage === "glcodes" ? "white" : "black",
            }}
          >
            <span>GL Codes</span>
          </li>
          <li
            onClick={() => setCurrentPage("taxcodes")}
            style={{
              background: currentPage === "taxcodes" ? "blue" : "white",
              color: currentPage === "taxcodes" ? "white" : "black",
            }}
          >
            <span>Tax Codes</span>
          </li>
          <li
            onClick={() => setCurrentPage("uom")}
            style={{
              background: currentPage === "uom" ? "blue" : "white",
              color: currentPage === "uom" ? "white" : "black",
            }}
          >
            <span>Units of Measure</span>
          </li>
        </ul>
      </div>
      <div className="settings-page">{settingsPage(currentPage)}</div>
    </MaterialSettingsContainer>
  );
};

export default Requisitions;
