const CustomStyles = {
  control: (base) => ({
    ...base,
    height: 32,
    minHeight: 32,
    borderRadius: 0,
  }),
  placeholder: (base) => ({
    ...base,
    height: 20,
    minHeight: 20,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 32,
    minHeight: 32,
  }),
  input: (base) => ({
    ...base,
    height: 27,
    minHeight: 27,
  }),
  container: (provided) => ({
    ...provided,
    fontSize: "12px",
  }),
};

export default CustomStyles;
