import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { Button } from "../../../styles/Style";

const ExportPanel = styled.div`
  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }
  .confirm-panel {
    padding: 50px;
    background-color: white;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: block;
    position: fixed;
    z-index: 25;
  }
  .opaque-panel {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: gray;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
`;

const ConfirmExport = ({ setOnToggleExport, csvHeader, csvItem }) => {
  return (
    <ExportPanel>
      <div className="confirm-panel">
        <h3>CSV Export Download</h3>
        <Button onClick={() => setOnToggleExport(false)}>Cancel</Button>
        <Button style={{ background: "blue", marginLeft: "10px" }}>
          <CSVLink
            data={csvHeader}
            style={{
              background: "blue",
              color: "white",
              padding: "5px",
            }}
            filename={`PO_Header_Export_${dayjs(new Date()).format(
              "MM-DD-YY"
            )}.csv`}
          >
            Download Basic Header File
          </CSVLink>
        </Button>
        <Button style={{ background: "blue", marginLeft: "10px" }}>
          <CSVLink
            data={csvItem}
            style={{
              background: "blue",
              color: "white",
              padding: "5px",
            }}
            filename={`PO_Item_Export_${dayjs(new Date()).format(
              "MM-DD-YY"
            )}.csv`}
          >
            Download Basic Item File
          </CSVLink>
        </Button>
      </div>
      <div className="opaque-panel" onClick={() => setOnToggleExport(false)} />
    </ExportPanel>
  );
};

ConfirmExport.propTypes = {
  setOnToggleExport: PropTypes.func,
  csvHeader: PropTypes.array,
  csvItem: PropTypes.array,
};

export default ConfirmExport;
