import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Button } from "../../styles/Style";
import EmployeeField from "./EmployeeField";
import DateTimePicker from "react-datetime-picker";
import { API_URL } from "../../env";

const TimeRecordForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  width: 1000px;
  margin: 0px auto;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 4fr 4fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    .input-field {
      padding: 7px;
      border: 1px solid lightgray;
    }
    .employees {
      grid-row-start: 1;
      grid-column-start: 1;
    }
    .job {
      grid-row-start: 2;
      grid-column-start: 1;
    }
    .cost-code {
      grid-row-start: 3;
      grid-column-start: 1;
    }
    .start {
      grid-row-start: 1;
      grid-column-start: 2;
    }
    .end {
      grid-row-start: 2;
      grid-column-start: 2;
    }
    .pay-class {
      grid-row-start: 3;
      grid-column-start: 2;
      margin: auto 0px;
      label {
        margin: 0px 10px;
        input {
          margin: 0px 10px;
        }
      }
    }
    .submit-button {
      grid-row-start: 4;
      grid-column-start: 1;
      grid-column-end: 3;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddTimeRecordForm = () => {
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobChange] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserChange] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskId, setTaskChange] = useState("");
  const [startTime, setStartTimeChange] = useState(new Date());
  const [endTime, setEndTimeChange] = useState(new Date());
  const [timeDiff, setTimeDiff] = useState(false);
  // const [clockAction, handleTimeChange] = useState([]);

  let navigate = useNavigate();

  const handleStartTimeChange = (value) => {
    setStartTimeChange(value);
    setTimeDiff(endTime - value > 86400000);
  };

  const handleEndTimeChange = (value) => {
    setEndTimeChange(value);
    setTimeDiff(value - startTime > 86400000);
  };
  const handleJobChange = (e) => {
    setJobChange(e);
  };

  const handleTaskChange = (e) => {
    setTaskChange(e.target.value);
  };

  const handleUserChange = (e) => {
    const userList = e.map((e) => ({ value: e.value, label: e.label }));
    setUserChange(userList);
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activejobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/activeemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUsers(json));
    fetch(`${API_URL}/tasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTasks(json));
  }, []);

  const handleSubmit = () => {
    const userActions = userId.map((user) => [
      {
        userId: user.value,
        jobId: jobId.value,
        taskId: taskId,
        clockIn: startTime,
        clockOut: endTime,
      },
    ]);
    const body = userActions.flat();
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    fetch(`${API_URL}/create/clockactions`, requestOptions).then((response) =>
      response.json()
    );
  };

  const jobOptions = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));

  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Time Records
      </h1>
      <Link to="/create/multipletimerecords">
        <Button>Create Many</Button>
      </Link>
      {timeDiff && (
        <h3
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            color: "red",
            fontSize: "12px",
          }}
        >
          Difference between start time and end time cannot be more than 24
          hours
        </h3>
      )}
      <div>
        <TimeRecordForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/time");
          }}
        >
          <fieldset>
            <div className="fields">
              <label
                htmlFor="user"
                className="employees"
                style={{ width: "300px" }}
              >
                Employee(s)
                <br />
                <EmployeeField
                  employees={users}
                  userId={userId}
                  handleUserChange={handleUserChange}
                />
              </label>
              <label htmlFor="jobs" className="job" style={{ width: "100%" }}>
                Job
                <br />
                <Select
                  options={jobOptions}
                  isSearchable
                  placeholder={"Select Job..."}
                  value={jobId}
                  onChange={(e) => handleJobChange(e)}
                />
              </label>
              <label htmlFor="tasks" className="cost-code">
                Cost Code
                <br />
                <select
                  value={taskId}
                  name="task"
                  id="task"
                  required
                  onChange={handleTaskChange}
                  className="input-field"
                >
                  <option defaultValue>Select A Cost Code</option>
                  {tasks.map((task) => (
                    <option key={task.id} value={task.id}>
                      {task.taskCode} - {task.taskName}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="time-in" className="start">
                Start Date
                <br />
                <DateTimePicker
                  onChange={handleStartTimeChange}
                  value={startTime}
                  calendarType={"US"}
                  disableClock
                />
              </label>
              <br />
              <label htmlFor="time-out" className="end">
                End Date
                <br />
                <DateTimePicker
                  onChange={handleEndTimeChange}
                  value={endTime}
                  calendarType={"US"}
                  disableClock
                />
              </label>
              <div className="pay-class">
                <label>
                  PTO
                  <input type="checkbox" style={{ transform: "scale(1.33)" }} />
                </label>
                <label>
                  HOL
                  <input type="checkbox" style={{ transform: "scale(1.33)" }} />
                </label>
                <label>
                  SICK
                  <input type="checkbox" style={{ transform: "scale(1.33)" }} />
                </label>
              </div>
              <Button
                className="submit-button"
                type="submit"
                disabled={timeDiff}
              >
                Create Time Record
              </Button>
            </div>
          </fieldset>
        </TimeRecordForm>
      </div>
    </div>
  );
};

export default AddTimeRecordForm;
