import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import EditDevicePanel from "./EditDevicePanel";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import { API_URL } from "../../env";

const columns = [
  ["Device Name", "name"],
  ["Responsible", "responsibleUser.firstName"],
  ["Device Code", "code"],
  ["Active", "active"],
];

const Devices = () => {
  const [state, setState] = useState({
    devices: [],
    editDevice: "",
  });
  const [foremen, setForemen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ active: "true" });
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) => setForemen(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/devices/filter?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setState((prevState) => ({
                ...prevState,
                devices: json,
              }));
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/devices?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Active",
      field: "active",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Employee",
      field: "responsibleEmployee",
      options: foremen.map((foreman) => ({
        label: `${foreman.firstName} ${foreman.lastName}`,
        value: foreman.id,
      })),
      isMulti: true,
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    const device = state.devices.filter((device) => device.id === id);
    setState((prevState) => ({
      ...prevState,
      editDevice: device,
    }));
  };

  const onEditDevice = (id, device) => {
    const index = state.devices.findIndex((device) => device.id === id);
    let newState = state.devices;
    newState[index] = {
      ...state.devices[index],
      ...device,
    };
    setState((prevState) => ({
      ...prevState,
      devices: newState,
    }));
  };

  const onDeselectDevice = () => {
    setState((prevState) => ({
      ...prevState,
      editDevice: "",
    }));
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  const { devices, editDevice } = state;
  return (
    <div>
      {editDevice && (
        <EditDevicePanel
          editDevice={editDevice}
          onDeselectDevice={onDeselectDevice}
          onEditDevice={onEditDevice}
        />
      )}
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"Add Device"}
        buttonPath={"/register/device"}
        pageTitle={"Devices"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <Table
          columns={columns}
          data={devices}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
    </div>
  );
};

export default Devices;
