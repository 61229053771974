import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCartShopping,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Logout from "../Auth/Logout";

const Menu = styled.div`
  @media print {
    display: none;
  }
  ul {
    list-style: none;
  }
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
  }
  .menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    margin: 0 auto;
    column-gap: 2rem;
    height: 30px;
    padding: 1.2rem 1.2rem;
  }
  .menu-end-menu {
    position: relative;
  }
  .menu-end-bar li:first-child .dropdown {
    flex-direction: initial;
  }

  .big {
    min-width: 600px;
  }

  .small {
    width: 200px;
  }

  .menu-end-bar li:first-child ul:nth-child(1) {
    border-right: var(--border);
  }

  .menu-end-bar li:nth-child(n + 2) ul:nth-child(1) {
    border-bottom: var(--border);
  }

  .menu-end-bar .dropdown-link-title {
    font-weight: 600;
  }

  .menu-end-bar .menu-end-nav-link {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.6px;
    padding: 30px;
    min-width: 60px;
    margin: 0 0.6rem;
  }

  .nav-start,
  .nav-end,
  .menu-end-bar {
    display: flex;
    align-items: center;
  }
  .dropdown {
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.97) translateY(-5px);
    transition: 0.1s ease-in-out;
    border: 1px solid black;
    background: white;
  }

  .dropdown.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1) translateY(5px);
  }

  .dropdown ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.2rem;
    font-size: 0.95rem;
  }

  .dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.15rem;
    font-weight: bold;
  }

  .dropdown-btn.active {
    background: blue;
    color: white;
  }

  table {
    border-collapse: collapse;
    margin: 5px 0px;
  }

  tr {
    cursor: default;
    td {
    }
  }

  .item-row:hover {
    background-color: lightgray;
  }

  .order-button {
    background: blue;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    padding: 5px 10px;
  }

  .centered-text {
    text-align: center;
  }

  .job-menu {
    display: grid;
    grid-template-rows: 1fr;
    cursor: pointer;
    span {
      background: lightgray;
      font-weight: bold;
    }
    .active {
      background: blue;
      color: white;
    }
  }
`;

const TopMenu = ({
  name,
  loggedInState,
  cart,
  setCart,
  currentCartIndex,
  setCurrentCartIndex,
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleCart, setToggleCart] = useState(false);

  let navigate = useNavigate();

  const uniqueBy = (arr, prop) => {
    const seen = new Set();
    return arr.filter((item) => {
      const value = prop ? item[prop] : item;
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  };

  const uniqueJobSet = uniqueBy(cart, "jobId");

  const CartBody = () => {
    const groupedCartItems = _.chain(cart)
      .groupBy("itemIdNumber")
      .map((cartItem) => cartItem)
      .value();
    return (
      <tbody>
        {cart?.length > 0 ? (
          groupedCartItems.map((groupedItem, index) => (
            <tr
              key={`${groupedItem.itemIdNumber}-${index}`}
              className="item-row"
            >
              <td
                style={{
                  textAlign: "right",
                  width: "15%",
                }}
              >
                {groupedItem.reduce(
                  (prev, cur) => prev + Number(cur.quantity),
                  0
                )}
              </td>
              <td style={{ width: "10%" }}>
                {groupedItem[0].unitOfMeasure.code}
              </td>
              <td
                style={{
                  textAlign: "left",
                  width: "70%",
                }}
              >
                {groupedItem[0].description}
              </td>
              <td
                style={{
                  width: "5%",
                }}
              >
                <button
                  onClick={() =>
                    setCart((prevState) => {
                      const newState = [...prevState];
                      const filteredState = newState.filter(
                        (cartItem) =>
                          groupedItem[0].itemIdNumber !== cartItem.itemIdNumber
                      );
                      return filteredState;
                    })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={3} onClick={() => setCart([])}>
              Cart Empty
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  return (
    <>
      <Menu>
        <div className="menu-container">
          <div className="menu-start"></div>
          <div className="menu-end">
            <nav className="menu-end-nav">
              <div className="menu-end-container">
                <ul className="menu-end-bar">
                  {cart.length > 0 && (
                    <li>
                      <button
                        className={`menu-end-menu-end-nav-link dropdown-btn${
                          toggleCart ? " active" : ""
                        }`}
                        data-dropdown="dropdown1"
                        aria-expanded="false"
                        onClick={() => setToggleCart((prevState) => !prevState)}
                      >
                        {
                          _.chain(
                            cart.filter(
                              (item) =>
                                item.jobId ===
                                uniqueJobSet[currentCartIndex].jobId
                            )
                          )
                            .groupBy("itemIdNumber")
                            .map((cartItem) => [cartItem.itemIdNumber])
                            .value().length
                        }{" "}
                        <FontAwesomeIcon icon={faCartShopping} />
                      </button>
                      <div
                        id="dropdown1"
                        className={`big dropdown${toggleCart ? " active" : ""}`}
                      >
                        <div>
                          <h2
                            style={{
                              textAlign: "center",
                              margin: "15px 0px",
                            }}
                          >
                            Material Cart
                          </h2>

                          <button
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              setCart([]);
                              setCurrentCartIndex(0);
                            }}
                          >
                            Clear Cart
                          </button>
                        </div>
                        <div className="job-menu">
                          {uniqueJobSet.length > 0 &&
                            uniqueJobSet.map((job, index) => (
                              <span
                                style={{
                                  width: `${100 / uniqueJobSet.length}%`,
                                }}
                                className={`${
                                  index === currentCartIndex ? "active" : ""
                                }`}
                                key={job.jobId}
                                onClick={() => setCurrentCartIndex(index)}
                              >{`${job.job.jobNumber}`}</span>
                            ))}
                        </div>
                        <table style={{ padding: "10px" }}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "right", width: "15%" }}>
                                Qty
                              </th>
                              <th style={{ width: "10%" }}>UOM</th>
                              <th style={{ textAlign: "left", width: "70%" }}>
                                Desc
                              </th>
                            </tr>
                          </thead>
                          <CartBody />
                          <tfoot>
                            <tr>
                              <td colSpan={4} style={{ padding: "15px 0px" }}>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                  }}
                                >
                                  <div>
                                    <button
                                      onClick={() => {
                                        navigate("/create/blankrfq", {
                                          state: {
                                            key: "cart",
                                            jobId:
                                              uniqueJobSet[currentCartIndex]
                                                .jobId,
                                          },
                                        });
                                        setToggleCart(false);
                                      }}
                                      className="order-button"
                                    >
                                      Create RFQ From Cart
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        navigate("/create/blankpurchaseorder", {
                                          state: {
                                            key: "cart",
                                            jobId:
                                              uniqueJobSet[currentCartIndex]
                                                .jobId,
                                          },
                                        });
                                        setToggleCart(false);
                                      }}
                                      className="order-button"
                                    >
                                      Create PO From Cart
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </li>
                  )}
                  <li>
                    <button
                      className={`menu-end-menu-end-nav-link dropdown-btn${
                        toggleMenu ? " active" : ""
                      }`}
                      data-dropdown="dropdown2"
                      onClick={() => setToggleMenu((prevState) => !prevState)}
                    >
                      {!toggleMenu ? (
                        <>
                          {name} <FontAwesomeIcon icon={faAngleDown} />
                        </>
                      ) : (
                        <>
                          {name} <FontAwesomeIcon icon={faAngleUp} />
                        </>
                      )}
                    </button>
                    <div
                      id="dropdown2"
                      className={`dropdown${toggleMenu ? " active" : ""}`}
                    >
                      <ul>
                        <li>
                          <Link
                            to="/settings"
                            onClick={() =>
                              setToggleMenu((prevState) => !prevState)
                            }
                          >
                            User Settings
                          </Link>
                        </li>
                        <li>
                          <Logout loggedInState={loggedInState} />
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </Menu>
      {toggleMenu && (
        <div
          className="invisible-div"
          onClick={() => setToggleMenu((prevState) => !prevState)}
        />
      )}
      {toggleCart && (
        <div
          className="invisible-div"
          onClick={() => setToggleCart((prevState) => !prevState)}
        />
      )}
    </>
  );
};

TopMenu.propTypes = {
  loggedInState: PropTypes.func,
  name: PropTypes.string,
  cart: PropTypes.array,
  setCart: PropTypes.func,
  currentCartIndex: PropTypes.number,
  setCurrentCartIndex: PropTypes.func,
};

export default TopMenu;
