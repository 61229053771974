import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { API_URL } from "../../../env";
import RFQDashboard from "./ProjectMaterialComponents/RFQDashboard";
import PODashboard from "./ProjectMaterialComponents/PODashboard";
import REQDashboard from "./ProjectMaterialComponents/REQDashboard";
import DELDashboard from "./ProjectMaterialComponents/DELDashboard";

const MaterialContainer = styled.div`
  .material-menu {
    width: 100%;
    ul {
      border-bottom: 1px solid black;
      display: flex;
      flex-direction: row;
      list-style: none;
      justify-content: space-evenly;
      padding: 0px;
      li {
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        padding: 0.3rem;
      }
      li:hover {
        background: blue;
        color: white;
      }
      .active {
        background: blue;
        color: white;
      }
    }
  }
`;

const ProjectMaterial = ({ jobId }) => {
  const [materialSection, setMaterialSection] = useState("RFQ");
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [requestForQuotes, setRequestForQuotes] = useState([]);
  const [requisitions, setRequisitions] = useState([]);
  const [deliveries, setDeliveries] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/project-dashboard/${jobId}/material`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setPurchaseOrders(json.PurchaseOrder);
        setRequestForQuotes(json.RequestForQuote);
        setDeliveries(json.Deliveries);
        setRequisitions(json.Requisition);
      });
  }, []);

  const determineSection = (param) => {
    switch (param) {
      case "RFQ":
        return (
          <RFQDashboard
            requestForQuotes={requestForQuotes}
            setRequestForQuotes={setRequestForQuotes}
          />
        );
      case "PO":
        return (
          <PODashboard
            purchaseOrders={purchaseOrders}
            setPurchaseOrders={setPurchaseOrders}
          />
        );
      case "REQ":
        return (
          <REQDashboard
            requisitions={requisitions}
            setRequisitions={setRequisitions}
          />
        );
      case "DEL":
        return (
          <DELDashboard deliveries={deliveries} setDeliveries={setDeliveries} />
        );
    }
  };

  return (
    <MaterialContainer>
      <div className="material-menu">
        <ul>
          <li
            className={`material-menu-item ${
              materialSection === "RFQ" ? "active" : ""
            }`}
            onClick={() => setMaterialSection("RFQ")}
          >
            RFQs
          </li>
          <li
            className={`material-menu-item ${
              materialSection === "PO" ? "active" : ""
            }`}
            onClick={() => setMaterialSection("PO")}
          >
            POs
          </li>
          <li
            className={`material-menu-item ${
              materialSection === "REQ" ? "active" : ""
            }`}
            onClick={() => setMaterialSection("REQ")}
          >
            Reqs
          </li>
          <li
            className={`material-menu-item ${
              materialSection === "DEL" ? "active" : ""
            }`}
            onClick={() => setMaterialSection("DEL")}
          >
            Deliveries
          </li>
        </ul>
      </div>
      <div>{determineSection(materialSection)}</div>
    </MaterialContainer>
  );
};

ProjectMaterial.propTypes = {
  jobId: PropTypes.string,
  budget: PropTypes.object,
};

export default ProjectMaterial;
