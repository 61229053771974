import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import _ from "lodash";
import EmployeeField from "../Time/EmployeeField";
import { Button } from "../../styles/Style";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { API_URL } from "../../env";

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
    margin-left: 0px;
    th {
      color: black;
    }
  }
  margin-left: 275px;
  .department {
    margin-bottom: 0px;
    text-decoration: underline;
  }
  .customer {
    margin-top: 0px;
  }
  table {
    width: 1000px;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 5px;
    margin: 0px;
    border: 1px solid black;
  }
`;

const DateContainer = styled.div`
  @media print {
    display: none;
  }
  h2 {
    text-transform: uppercase;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: right;
    display: inline-flex;
    margin-bottom: 25px;

    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const GangSheetReport = () => {
  !localStorage.getItem("startDate") &&
    localStorage.setItem("startDate", start.toISOString());
  !localStorage.getItem("endDate") &&
    localStorage.setItem("endDate", end.toISOString());
  const [gangSheets, setGangSheets] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [firstDate, setFirstDate] = useState(
    new Date(localStorage.getItem("startDate"))
  );
  const [lastDate, setLastDate] = useState(
    new Date(localStorage.getItem("endDate"))
  );
  const [department, setDepartment] = useState("");
  const [reportDeptName, setreportDeptName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activeemployeesdept`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  useEffect(() => {
    document.title = `${department} Gang Sheets WE ${dayjs(lastDate).format(
      "MM-DD-YY"
    )}`;
    return () => {
      document.title = `Tool Time`;
    };
  }, [department, lastDate]);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setFirstDate(new Date(start.toISOString()));
    localStorage.setItem("startDate", start.toISOString());
    setLastDate(new Date(newEnd.toISOString()));
    localStorage.setItem("endDate", newEnd.toISOString());
  };
  const onDateFilter = () => {
    setEndDate(lastDate);
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/gangsheets/${firstDate.toISOString()}/${lastDate.toISOString()}/${department}${
        employeeFilter.length > 0
          ? `/${encodeURIComponent(
              JSON.stringify(employeeFilter.map((employee) => employee.value))
            )}`
          : ""
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then(({ timeRecords, departmentFilter }) => {
        setGangSheets(timeRecords);
        setreportDeptName(departmentFilter);
      });
  };

  const handleDepartmentChange = (e) => {
    const { value } = e.target;
    setDepartment(value);
  };

  const handleUserChange = (e) => {
    const userList = e.map((e) => ({ value: e.value, label: e.label }));
    setEmployeeFilter(userList);
  };

  return (
    <div style={{ paddingBottom: "20px" }}>
      <DateContainer>
        <h2>Gang Sheet Report</h2>
        <div>
          <select
            onChange={handleDepartmentChange}
            value={department}
            style={{ padding: "5px 10px 5px 10px" }}
          >
            <option value="">Select Department</option>
            <option value="RAMS">RAMS</option>
            <option value="CTS">CTS</option>
            <option value="JIR">JIR</option>
            <option value="FIELD">CHAMPION</option>
          </select>
          <br />
          <br />
          <div style={{ width: "500px", margin: "auto" }}>
            <EmployeeField
              disabled={department === ""}
              employees={
                department !== ""
                  ? employees.filter(
                      (employee) => department === employee.department.name
                    )
                  : employees
              }
              userId={employeeFilter}
              handleUserChange={handleUserChange}
            />
          </div>
        </div>
        <br />
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(firstDate, 1), subWeeks(lastDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(firstDate, 1), subDays(lastDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={firstDate}
            endDate={lastDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(firstDate, 1), addDays(lastDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(firstDate, 1), addWeeks(lastDate, 1)])
            }
          >
            {">>"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={onDateFilter}
            disabled={department === ""}
          >
            GO
          </Button>
        </div>
        <div style={{ width: "95%" }}>
          <Button
            style={{ display: "block", marginLeft: "auto" }}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </div>
      </DateContainer>
      <div>
        {gangSheets.length > 0 &&
          gangSheets
            .sort((a, b) => {
              const jobNumberA = a.jobNumber.split("-");
              const jobNumberB = b.jobNumber.split("-");
              return (
                parseInt(jobNumberA[0] + jobNumberA[1], 10) -
                parseInt(jobNumberB[0] + jobNumberB[1], 10)
              );
            })
            .map((job) => {
              const sortEmployee = _.chain(job.timeRecords)
                .groupBy("name")
                .map((value, key) => ({ name: key, timeRecords: value }))
                .value();
              return (
                <PageBreak key={job.jobNumber}>
                  <div style={{ textAlign: "left" }}>
                    <h3 className="department">
                      {reportDeptName === "FIELD" ? "CHAMPION" : reportDeptName}
                    </h3>
                    <h3 className="customer">
                      Customer: Champion Electrical Contracting, LLC
                    </h3>
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      Job# {job.jobNumber}
                    </span>
                    <br />
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      Project: {job.jobName}
                    </span>
                    <br />
                    <span style={{ textTransform: "capitalize" }}>
                      {job.jobAddress2
                        ? `Address: ${job.jobAddress} ${job.jobAddress2}, ${job.jobCity}, ${job.jobState} ${job.jobZip}`
                        : `Address: ${job.jobAddress}, ${job.jobCity}, ${job.jobState} ${job.jobZip}`}
                    </span>
                    <br />
                    <span>
                      GC: {job?.customer?.name ? job.customer.name : "N/A"}
                    </span>
                    <br />
                    <br />
                    <span>
                      Week Ending: {dayjs(endDate).format("MM/DD/YYYY")}
                    </span>
                  </div>
                  <br />
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>EMPLOYEE</th>
                        <th>Branch</th>
                        <th>
                          {dayjs(endDate).subtract(6, "day").format("ddd")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(5, "day").format("ddd")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(4, "day").format("ddd")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(3, "day").format("ddd")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(2, "day").format("ddd")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(1, "day").format("ddd")}
                        </th>
                        <th>{dayjs(endDate).format("ddd")}</th>
                        <th>Total Hrs</th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {dayjs(endDate).subtract(6, "day").format("DD-MMM")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(5, "day").format("DD-MMM")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(4, "day").format("DD-MMM")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(3, "day").format("DD-MMM")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(2, "day").format("DD-MMM")}
                        </th>
                        <th>
                          {dayjs(endDate).subtract(1, "day").format("DD-MMM")}
                        </th>
                        <th>{dayjs(endDate).format("DD-MMM")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortEmployee.map((employee, index) => {
                        const day1Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date ===
                            dayjs(endDate).subtract(6, "day").format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),

                          0
                        );
                        const day2Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date ===
                            dayjs(endDate).subtract(5, "day").format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),
                          0
                        );
                        const day3Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date ===
                            dayjs(endDate).subtract(4, "day").format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),
                          0
                        );
                        const day4Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date ===
                            dayjs(endDate).subtract(3, "day").format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),
                          0
                        );
                        const day5Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date ===
                            dayjs(endDate).subtract(2, "day").format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),
                          0
                        );
                        const day6Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date ===
                            dayjs(endDate).subtract(1, "day").format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),
                          0
                        );
                        const day7Total = employee.timeRecords.reduce(
                          (prev, cur) =>
                            prev +
                            (cur.date === dayjs(endDate).format("M/D/YYYY")
                              ? parseFloat(cur.duration)
                              : 0),
                          0
                        );
                        const grandTotal =
                          day1Total +
                          day2Total +
                          day3Total +
                          day4Total +
                          day5Total +
                          day6Total +
                          day7Total;
                        return (
                          <tr key={employee.name}>
                            <td>{index + 1}</td>
                            <td>{employee.name}</td>
                            <td></td>
                            <td>{day1Total.toFixed(2)}</td>
                            <td>{day2Total.toFixed(2)}</td>
                            <td>{day3Total.toFixed(2)}</td>
                            <td>{day4Total.toFixed(2)}</td>
                            <td>{day5Total.toFixed(2)}</td>
                            <td>{day6Total.toFixed(2)}</td>
                            <td>{day7Total.toFixed(2)}</td>
                            <td>{grandTotal.toFixed(2)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </PageBreak>
              );
            })}
      </div>
    </div>
  );
};

export default GangSheetReport;
