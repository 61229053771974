import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const DeviceForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
      height: 75px;
    }
    input,
    select {
      padding: 7px;
      border: 1px solid lightgray;
    }
    .qr-code {
      display: flex;
      justify-content: center;
      align-content: center;
    }
    .submit-button {
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

class SingleDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      device: {
        ...this.props.editDevice[0],
      },
      updatedDevice: "",
      employees: [],
      error: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
  }
  componentDidMount() {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/employees`, requestOptions)
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          ...this.state,
          employees: data.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)),
          loading: false,
        })
      );
  }

  handleChange(e) {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    this.setState({
      updatedDevice: { ...this.state.updatedDevice, [name]: val },
    });
  }

  handleActiveChange(e) {
    const { checked, name } = e.target;
    this.setState({
      updatedDevice: { ...this.state.updatedDevice, [name]: checked },
    });
  }

  render() {
    const { loading, device, updatedDevice } = this.state;
    return (
      <div>
        {!loading && (
          <div>
            <h4 style={{ textAlign: "center", textTransform: "uppercase" }}>
              Edit Device
            </h4>
            <DeviceForm
              onSubmit={async (e) => {
                e.preventDefault();
                const requestOptions = {
                  method: "PUT",
                  withCredentials: true,
                  credentials: "include",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ ...updatedDevice }),
                };
                await fetch(
                  `${API_URL}/update/device/${device.id}`,
                  requestOptions
                ).then((res) => res.json());
                this.props.onEditDevice(device.id, updatedDevice);
                this.props.onDeselectDevice();
              }}
            >
              <fieldset>
                <div className="fields">
                  <label className="device-name">
                    Device Name
                    <br />
                    <input
                      type="text"
                      name="name"
                      id="name"
                      defaultValue={device.name}
                      onChange={this.handleChange}
                      placeholder="Device Name*"
                      required
                      autoComplete="none"
                    />
                  </label>
                  <label htmlFor="department">
                    Responsible Employee
                    <br />
                    <select
                      name="responsibleUserId"
                      id="responsibleUserId"
                      required
                      defaultValue={
                        updatedDevice.responsibleUserId
                          ? updatedDevice.responsibleUserId
                          : device.responsibleUserId
                      }
                      onChange={this.handleChange}
                    >
                      {this.state.employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {`${employee.firstName} ${employee.lastName}`}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label htmlFor="active">
                    Active
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      defaultChecked={device.active}
                      onChange={this.handleActiveChange}
                    />
                  </label>
                  {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                </div>
                <div className="qr-code">
                  <h4>Device Code</h4>
                  <br />
                  <QRCode
                    size={300}
                    style={{
                      height: "auto",
                      width: "300px",
                    }}
                    value={device.code}
                    viewBox={`0 0 300 300`}
                  />
                  <h5>{device.code}</h5>
                </div>
                <Button className="submit-button" type="submit">
                  Save Changes
                </Button>
              </fieldset>
            </DeviceForm>
          </div>
        )}
      </div>
    );
  }
}

SingleDevice.propTypes = {
  id: PropTypes.string,
  editDevice: PropTypes.array,
  onEditDevice: PropTypes.func,
  onDeselectDevice: PropTypes.func,
};

export default SingleDevice;
