import dayjs from "dayjs";

const getTimeWindowText = (timeWindow, customWindow) => {
  if (timeWindow === "ASAP") {
    return "ASAP";
  } else if (timeWindow === "AMEarly") {
    return "7AM - 10AM";
  } else if (timeWindow === "AM") {
    return "10AM - 12PM";
  } else if (timeWindow === "PM") {
    return "12PM - 3PM";
  } else if (timeWindow === "PMLate") {
    return "3PM - 6PM";
  } else if (timeWindow === "CUSTOM") {
    const parsedCustomWindow = JSON.parse(customWindow);
    return `${dayjs(parsedCustomWindow[0]).format("h:mmA")} - ${dayjs(
      parsedCustomWindow[1]
    ).format("h:mmA")}`;
  }
};

export default getTimeWindowText;
