import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { Button } from "../../../styles/Style";
import { FormContainer, Column } from "../Styles/MaterialFormStyles";
import { API_URL } from "../../../env";
import AttachmentBanner from "../Attachments/AttachmentBanner";
import {
  TableContainer,
  Table,
  TableRow,
  PlaceholderRow,
  TableCell,
  InputCell,
  DummyInputCell,
  SelectCell,
  RowNumberCell,
  TableHead,
  TableRowNumberHeaderCell,
  TableHeaderCell,
  selectStyles,
} from "../Styles/MaterialTableStyles";
import "react-datepicker/dist/react-datepicker.css";

const RFQContainer = styled.div`
  @media (max-width: 768px) {
    .form-group {
      width: 100%; /* Make each column take up 100% width on small screens */
    }
  }
  text-align: left;
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
      /* margin-bottom: 10px; */
      h1 {
        margin: 0px;
      }
      h3 {
        margin-top: 10px;
      }
    }
    .submit-button {
      display: flex;
      justify-content: right;
      button {
        margin: 20px;
        height: 37px;
      }
    }
  }
  .context-menu-item {
    /* Base styles for the menu item */
    background-color: white;
    color: black;
    padding: 8px 12px; /* Adjust padding as needed */
    cursor: pointer;
    transition: font-weight 0.2s; /* Add a smooth transition effect for font-weight */
    box-sizing: border-box; /* Include padding and borders in the total width */
    width: 120px;
    text-align: center;
    /* Set font-weight to normal (or any desired value) by default */
    font-weight: normal;
  }

  .context-menu-item:hover {
    font-weight: bold; /* Change font weight on hover */
  }
  .attachments {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px 0px;
    margin-bottom: 10px;
    h3 {
      margin: 3px 0px;
    }
  }
`;

const CreateRFQ = ({
  user,
  cart,
  setCart,
  currentCartIndex,
  setCurrentCartIndex,
}) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [rfqItems, setRfqItems] = useState([
    { estimatedMaterial: [], rfqItem: ["", "", "", "", ""] },
  ]);
  const [nextRFQ, setNextRFQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [bidByDate, setBidByDate] = useState(new Date());
  const [needByDate, setNeedByDate] = useState(new Date());
  const [needByWindow, setNeedByWindow] = useState("");
  const [billingDetails, setBillingDetails] = useState(
    "2875 Jupiter Park Dr. Ste 500, Jupiter, FL 33458"
  );
  const [generalLedgerCodes, setGeneralLedgerCodes] = useState([]);
  const [generalLedger, setGeneralLedger] = useState("");
  const [taxCodes, setTaxCodes] = useState([]);
  const [taxCode, setTaxCode] = useState("");
  const [attachments] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState("");
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [activeCell, setActiveCell] = useState({ row: 0, col: 0 });
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    top: 0,
    left: 0,
  });

  const inputRef = useRef(null);
  const selectRefs = useRef([]);

  const handleContextMenu = (e, rowIndex) => {
    e.preventDefault(); // Prevent the default context menu
    e.stopPropagation(); // Stop the click event propagation

    // Calculate the position based on the event's coordinates or row data
    const top = e.clientY;
    const left = e.clientX;

    setContextMenu({ visible: true, top, left, rowIndex });
  };

  useEffect(() => {
    // Function to close the context menu
    const closeContextMenu = () => {
      setContextMenu({ visible: false, top: 0, left: 0, rowIndex: null });
    };

    // Attach the click event listener to the document when the context menu is visible
    if (contextMenu.visible) {
      document.addEventListener("click", closeContextMenu);
    }

    // Remove the event listener when the context menu is hidden
    return () => {
      document.removeEventListener("click", closeContextMenu);
    };
  }, [contextMenu]);

  useEffect(() => {
    if (!loading) {
      if (location.state?.key === "cart" && location.state?.jobId) {
        handleSelectJob(location.state.jobId);
        const convertCartToRFQ = _.chain(cart)
          .groupBy("itemIdNumber")
          .map((cartItem) => cartItem)
          .value();
        setRfqItems(
          convertCartToRFQ.map((cartItem) => ({
            estimatedMaterial: cartItem.map((id) => ({
              estimatedMaterialId: id.id,
              estimatedMaterialQuantity: id.quantity,
            })),
            rfqItem: [
              cartItem.reduce((prev, cur) => prev + cur.quantity, 0),
              cartItem[0].unitOfMeasureId,
              cartItem[0].description,
              "",
              "",
            ],
          }))
        );
      }
    }
  }, [location, loading]);

  const handleKeyDown = (e) => {
    const { row, col } = activeCell;
    const numRows = rfqItems.length;
    const numCols = rfqItems.rfqItem[0].length;
    switch (e.key) {
      case "ArrowUp":
        setActiveCell({ row: Math.max(row - 1, 0), col });
        break;
      case "ArrowDown":
        if (activeCell.row === numRows - 1) {
          // Active cell is in the last row, add a new row
          setRfqItems((prevItems) => [
            ...prevItems,
            {
              estimatedMaterial: [],
              rfqItem: [null, null, null, null, null],
            },
          ]);
          // Set the active cell to the new row
          setActiveCell({
            row: activeCell.row + 1,
            col: activeCell.col,
          });
        } else {
          // Active cell is not in the last row, move to the next row
          setActiveCell({
            row: activeCell.row + 1,
            col: activeCell.col,
          });
        }
        break;
      case "ArrowLeft":
        setActiveCell({ row, col: Math.max(col - 1, 0) });
        break;
      case "ArrowRight":
        setActiveCell({ row, col: Math.min(col + 1, numCols - 1) });
        break;
      case "Tab":
        e.preventDefault();
        if (activeCell.col === 4 && !e.shiftKey) {
          if (activeCell.row === rfqItems.length - 1) {
            // Add a new row if the active cell is in the last row
            setRfqItems((prevItems) => [
              ...prevItems,
              [null, null, null, null, null],
            ]);
          }
          // Move to the first column (column 0) of the next row
          setActiveCell({
            row: activeCell.row + 1,
            col: 0,
          });
        } else if (activeCell.col === 0 && e.shiftKey) {
          // Handle shifting focus to the previous column when in the first column of a row
          setActiveCell({
            row: activeCell.row,
            col: 4, // Move to the last column (column 4) of the same row
          });
        } else {
          // Handle navigation to the next/previous column
          setActiveCell({
            row: activeCell.row,
            col: activeCell.col + (e.shiftKey ? -1 : 1),
          });
        }
        break;
      case "Enter":
        setActiveCell({ row: row + 1, col });
        break;
      default:
        break;
    }
  };

  const handleMakeActiveRow = (index) => {
    const numberOfItems = rfqItems.length;
    setRfqItems((prevState) => {
      const updatedState = [...prevState];
      updatedState.push({
        estimatedMaterial: [],
        rfqItem: [null, null, null, null, null],
      });
      return updatedState;
    });
    setActiveCell({ row: numberOfItems, col: index });
  };

  const handleDeleteRow = () => {
    // Implement the logic to delete the row based on the rowIndex
    // You can use slice, splice, or any method to remove the selected row from your data.
    // Example:
    if (rfqItems.length > 1) {
      const updatedData = [...rfqItems];
      updatedData.splice(contextMenu.rowIndex, 1);
      setRfqItems(updatedData);
    }

    // Hide the context menu
    setContextMenu({ visible: false, top: 0, left: 0 });
  };

  useEffect(() => {
    if (activeCell.col === 1) {
      selectRefs.current[activeCell.row].focus();
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeCell]);

  const handleValueChange = (e) => {
    setRfqItems((prevState) => {
      const updatedRfqItems = [...prevState];
      updatedRfqItems[activeCell.row].rfqItem[activeCell.col] = e.target.value;
      return updatedRfqItems;
    });
  };

  //
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activejobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setJobs(json);
      });
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setVendors(json);
      });
    fetch(`${API_URL}/publictasks`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTasks(json);
      });
    fetch(`${API_URL}/material/glcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setGeneralLedgerCodes(json);
        if (json.length === 1) {
          setGeneralLedger(json[0].id);
        }
      });
    fetch(`${API_URL}/material/uoms`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUnitsOfMeasure(json));
    fetch(`${API_URL}/material/taxcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setTaxCodes(json);
      });
    fetch(`${API_URL}/next-rfq`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setNextRFQ(json);
      });
  }, []);

  useEffect(() => {
    jobs.length > 0 && setLoading(false);
  }, [jobs]);

  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));
  const uomList = unitsOfMeasure.map((uom) => ({
    value: uom.id,
    label: uom.code,
  }));
  const vendorList = vendors.map((vendor) => ({
    value: vendor.id,
    label: vendor.vendorName,
  }));
  const glCodeList = generalLedgerCodes.map((gl) => ({
    value: gl.id,
    label: `${gl.generalLedgerCode} - ${gl.generalLedgerName}`,
  }));
  const costCodeList = tasks.map((taskCode) => ({
    value: taskCode.id,
    label: `${taskCode.taskCode} - ${taskCode.taskName}`,
  }));
  const taxCodeList = taxCodes.map((tax) => ({
    value: tax.id,
    label: tax.taxCode,
  }));
  const windowList = [
    { value: "ASAP", label: "ASAP" },
    { value: "AMEarly", label: "7AM - 10AM" },
    { value: "AM", label: "10AM - 12PM" },
    { value: "PM", label: "12PM - 3PM" },
    { value: "PMLate", label: "3PM - 6PM" },
    { value: "CUSTOM", label: "Custom Time Window" },
  ];

  const handleSelectJob = (value) => {
    setJob(value);
    const selectedJob = jobs[jobs.findIndex((job) => job.id === value)];
    setDeliveryAddress(
      `${selectedJob.jobAddress}${
        selectedJob.jobAddress2 ? ` ${selectedJob.jobAddress2} ` : ` `
      }${selectedJob.jobCity}, ${selectedJob.jobState} ${selectedJob.jobZip}`
    );
  };

  const handleVendorChange = (e) => {
    const selectedVendors = e.map((e) => e.value);
    setVendor(selectedVendors);
  };

  const createRFQ = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        details: {
          user: { connect: { id: user.id } },
          job: {
            connect: { id: job },
          },
          vendor: { connect: vendor.map((vendor) => ({ id: vendor })) },
          billingDetails,
          deliveryAddress,
          needByDate,
          bidByDate,
          needByWindow,
          generalLedger: { connect: { id: generalLedger } },
          Task: { connect: { id: task } },
          tax: { connect: { id: taxCode } },
          quotes: {
            createMany: {
              data: vendor.map((vend) => ({ vendorId: vend })),
            },
          },
        },
        items: rfqItems.map((item, index) => ({
          estimatedMaterial: item.estimatedMaterial,
          itemDetails: {
            lineNumber: index + 1,
            description: item.rfqItem[2],
            unitOfMeasure: { connect: { id: item.rfqItem[1] } },
            quantity: parseInt(item.rfqItem[0]),
          },
        })),
      }),
    };
    fetch(`${API_URL}/create/blankrfq`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        navigate(`/rfq/${json.rfq.id}`);
        setCart((prevState) => {
          const newState = [...prevState];
          newState.splice(currentCartIndex, 1);
          return newState;
        });
        setCurrentCartIndex(0);
      });
  };

  return (
    <RFQContainer>
      <div className="order-header">
        <div className="po-title">
          <h1>RFQ# {nextRFQ}</h1>
          <h3>Details</h3>
        </div>
        <div className="submit-button">
          <Button onClick={() => createRFQ()}>Save RFQ</Button>
        </div>
      </div>
      <FormContainer>
        <Column>
          <div className="form-group">
            <label>Requested By</label>
            <input type="text" className="custom-input" value={user.name} />
          </div>
          <div className="form-group">
            <label>Job</label>
            {!loading &&
              jobList.length > 0 &&
              (location?.state?.key === "cart" ? (
                <p style={{ fontWeight: "bold" }}>
                  {
                    jobList[
                      jobList.findIndex(
                        (jobListItem) => jobListItem.value === job
                      )
                    ]?.label
                  }
                </p>
              ) : (
                <Select
                  options={jobList}
                  isLoading={loading}
                  name="jobId"
                  onChange={(e) => handleSelectJob(e.value)}
                  value={job}
                  placeholder="Select Job"
                />
              ))}
          </div>
          <div className="form-group">
            <label>Vendor(s)</label>
            <Select
              options={vendorList}
              isLoading={loading}
              name="vendorId"
              onChange={handleVendorChange}
              defaultValue={vendor}
              placeholder="Select Vendor(s)"
              isMulti
              className="custom-select"
              classNamePrefix="custom-select"
            />
          </div>
        </Column>
        <Column>
          <div className="form-group">
            <label>Bid By Date</label>
            <DatePicker
              className="custom-input"
              selected={bidByDate}
              onChange={(date) => setBidByDate(date)}
            />
          </div>
          <div className="form-group">
            <label>Need By Date</label>
            <DatePicker
              className="custom-input"
              selected={needByDate}
              onChange={(date) => setNeedByDate(date)}
            />
          </div>
          <div className="form-group">
            <label>Need By Window</label>
            <Select
              options={windowList}
              isLoading={loading}
              name="timeWindow"
              onChange={(e) => setNeedByWindow(e.value)}
              defaultValue={needByWindow}
              placeholder="Select Window"
            />
          </div>
          <div className="form-group">
            <label>Delivery Address</label>
            <input
              type="text"
              className="custom-input"
              onChange={(e) => setDeliveryAddress(e.target.value)}
              value={deliveryAddress}
            />
          </div>
        </Column>
        <Column>
          <div className="form-group">
            <label>GL Code</label>
            <Select
              options={glCodeList}
              isLoading={loading}
              name="glCodeId"
              onChange={(e) => setGeneralLedger(e.value)}
              value={glCodeList.find((gl) => gl.value === generalLedger)}
              placeholder="Select GL Code"
            />
          </div>
          <div className="form-group">
            <label>Cost Code</label>
            <Select
              options={costCodeList}
              isLoading={loading}
              name="costCodeId"
              onChange={(e) => setTask(e.value)}
              defaultValue={task}
              placeholder="Select Cost Code"
            />
          </div>
          <div className="form-group">
            <label>Tax Code</label>
            <Select
              options={taxCodeList}
              isLoading={loading}
              name="taxCodeId"
              onChange={(e) => setTaxCode(e.value)}
              defaultValue={taxCode}
              placeholder="Select Tax"
            />
          </div>
          <div className="form-group">
            <label>Billing Details</label>
            <input
              type="text"
              className="custom-input"
              value={billingDetails}
              onChange={(e) => setBillingDetails(e.target.value)}
            />
          </div>
        </Column>
      </FormContainer>

      <AttachmentBanner attachments={attachments} addAttachment={true} />

      <TableContainer tabIndex="0" onKeyDown={handleKeyDown}>
        <Table>
          <TableHead>
            <TableRow>
              <TableRowNumberHeaderCell>#</TableRowNumberHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
              <TableHeaderCell>Unit of Measure</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Unit Price</TableHeaderCell>
              <TableHeaderCell>Extended Price</TableHeaderCell>
            </TableRow>
          </TableHead>
          <tbody>
            {rfqItems.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <RowNumberCell
                  active={rowIndex === activeCell.row && -1 === activeCell.col}
                  style={{
                    background:
                      contextMenu.visible && contextMenu.rowIndex === rowIndex
                        ? "darkGray"
                        : "#f0f0f0",
                  }}
                  onClick={() => setActiveCell({ row: rowIndex, col: -1 })}
                  onContextMenu={(e) => handleContextMenu(e, rowIndex)}
                >
                  {rowIndex + 1}
                </RowNumberCell>
                {row.rfqItem.map((cell, colIndex) =>
                  colIndex === 1 ? (
                    <SelectCell
                      key={colIndex}
                      active={
                        colIndex === activeCell.col &&
                        rowIndex === activeCell.row
                      }
                    >
                      <Select
                        ref={(el) => (selectRefs.current[rowIndex] = el)}
                        options={uomList}
                        styles={selectStyles}
                        isLoading={loading}
                        name="unitOfMeasure"
                        onChange={(e) =>
                          setRfqItems((prevState) => {
                            const updatedRfqItems = [...prevState];
                            updatedRfqItems[rowIndex].rfqItem[colIndex] =
                              e.value;
                            return updatedRfqItems;
                          })
                        }
                        defaultValue={cell}
                        value={uomList.find((obj) => obj.value === cell)}
                        placeholder=""
                        menuPosition="fixed"
                      />
                    </SelectCell>
                  ) : rowIndex === activeCell.row &&
                    colIndex === activeCell.col ? (
                    <InputCell key={colIndex} active>
                      <input
                        ref={inputRef}
                        type="text"
                        value={cell}
                        onChange={handleValueChange}
                        autoFocus
                        onFocus={(e) => e.target.select()}
                      />
                    </InputCell>
                  ) : (
                    <TableCell
                      key={colIndex}
                      active={
                        rowIndex === activeCell.row &&
                        colIndex === activeCell.col
                      }
                      onClick={() => {
                        setActiveCell({ row: rowIndex, col: colIndex });
                      }}
                    >
                      {cell}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
            <PlaceholderRow>
              <RowNumberCell onClick={() => handleMakeActiveRow(0)}>
                {rfqItems.length + 1}
              </RowNumberCell>
              <DummyInputCell>
                <input
                  type="text"
                  value={""}
                  onChange={handleValueChange}
                  onClick={() => handleMakeActiveRow(0)}
                />
              </DummyInputCell>
              <SelectCell onClick={() => handleMakeActiveRow(1)}>
                <Select
                  options={uomList}
                  styles={selectStyles} // Apply custom styles to the react-select component
                  isLoading={loading}
                  name="unitOfMeasure"
                  defaultValue={""}
                  placeholder=""
                  menuPosition="fixed"
                />
              </SelectCell>
              <DummyInputCell>
                <input
                  type="text"
                  value={""}
                  onClick={() => handleMakeActiveRow(2)}
                  onChange={handleValueChange}
                />
              </DummyInputCell>
              <DummyInputCell>
                <input
                  type="text"
                  value={""}
                  onClick={() => handleMakeActiveRow(3)}
                />
              </DummyInputCell>
              <DummyInputCell>
                <input
                  type="text"
                  value={""}
                  onClick={() => handleMakeActiveRow(4)}
                />
              </DummyInputCell>
            </PlaceholderRow>
          </tbody>
        </Table>
      </TableContainer>
      {contextMenu.visible && (
        <div
          style={{
            position: "absolute",
            top: contextMenu.top,
            left: contextMenu.left,
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
          }}
        >
          <div className="context-menu-item" onClick={handleDeleteRow}>
            Delete Row
          </div>
        </div>
      )}
    </RFQContainer>
  );
};

CreateRFQ.propTypes = {
  user: PropTypes.object,
  cart: PropTypes.array,
  setCart: PropTypes.func,
  currentCartIndex: PropTypes.number,
  setCurrentCartIndex: PropTypes.func,
};

export default CreateRFQ;
