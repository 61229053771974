import React, { useState, useEffect } from "react";
import { API_URL } from "../../../env";
import { Button } from "../../../styles/Style";

const GLCodes = () => {
  const [GLCodes, setGLCodes] = useState([]);
  // const [updatedGLCode, setUpdatedGLCode] = useState(null);
  const [GLCode, setGLCode] = useState("");
  const [GLCodeName, setGLCodeName] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/material/glcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => setGLCodes(json));
  }, []);
  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        generalLedgerCode: GLCode,
        generalLedgerName: GLCodeName,
      }),
    };
    fetch(`${API_URL}/create/glcode`, requestOptions).then((res) => res.json());
  };
  return (
    <div>
      <h4>General Ledger Codes</h4>
      <div>
        <button>New</button>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}>
        <ul style={{ listStyle: "none", cursor: "pointer" }}>
          {GLCodes.map((code) => (
            <li
              key={code.id}
              onClick={() => {
                setGLCode(code.generalLedgerCode);
                setGLCodeName(code.generalLedgerName);
              }}
            >
              {`${code.generalLedgerCode}-${code.generalLedgerName}`}
            </li>
          ))}
        </ul>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <fieldset>
            <label>
              GL Code
              <input
                type="text"
                name="GLCode"
                id="GLCode"
                value={GLCode}
                onChange={(e) => {
                  setGLCode(e.target.value);
                }}
                placeholder="GL Code*"
                required
                autoComplete="none"
              />
            </label>
            <label>
              GL Code Name
              <input
                type="text"
                name="GLCodeName"
                id="GLCodeName"
                value={GLCodeName}
                onChange={(e) => setGLCodeName(e.target.value)}
                placeholder="GL Code Name*"
                required
                autoComplete="none"
              />
            </label>
            <Button>Save</Button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default GLCodes;
