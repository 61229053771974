import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { DataTable } from "../../../styles/DataTable";
import { API_URL } from "../../../env";

const Table = (props) => {
  const { columns, sortBy, orderBy, handleSort, data } = props;
  return (
    <DataTable>
      <tbody>
        <tr>
          <td>
            <table className="tableHead">
              <thead>
                <tr className="cell">
                  {columns.map((column) => (
                    <th
                      key={column[0]}
                      onClick={() => {
                        handleSort(column[1]);
                      }}
                    >
                      {column[0]}{" "}
                      {sortBy === undefined ? (
                        ""
                      ) : column[1] === sortBy ? (
                        orderBy === "asc" ? (
                          <FontAwesomeIcon icon={faCaretDown} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretUp} />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </td>
        </tr>
        {data.length > 0 && (
          <tr>
            <td>
              <div className="tableBodyContainer">
                <table className="tableBody">
                  <tbody>
                    {data.map((rowData) => (
                      <tr key={rowData.id} id={rowData.id}>
                        {columns.map((column) => {
                          console.log(column);
                          if (column[0] === "PDF" && column[1] === "id") {
                            return (
                              <td
                                key={`${rowData.id} ${column[1]} link`}
                                style={{ fontSize: "20px" }}
                                onClick={() => {
                                  window.open(
                                    `${API_URL}/form/workorder/pdf/${
                                      rowData[column[1]]
                                    }`,
                                    "_blank"
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                              </td>
                            );
                          } else if (
                            column[1] === "active" ||
                            column[1] === "isPublic" ||
                            column[1] === "operational" ||
                            column[1] === "submitted"
                          ) {
                            if (
                              rowData.active ||
                              rowData.isPublic ||
                              rowData.operational ||
                              rowData.submitted
                            ) {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  &#9745;
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  &#9744;
                                </td>
                              );
                            }
                          } else if (column[1] === "complete") {
                            if (rowData.complete) {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  &#9745;
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  &#9744;
                                </td>
                              );
                            }
                          } else if (column[1] === "coords") {
                            if (rowData.jobLat && rowData.jobLng) {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  &#9745;
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  style={{ fontSize: "20px" }}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  &#9744;
                                </td>
                              );
                            }
                          } else if (column[1] === "date") {
                            if (rowData.date) {
                              const formatDate = new Date(rowData.date);
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {formatDate.toLocaleDateString()}
                                </td>
                              );
                            }
                          } else if (column[1] === "submittedAt") {
                            if (rowData.submittedAt) {
                              const formatDate = new Date(rowData.submittedAt);
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {dayjs(formatDate).format("M/D/YY h:mm A")}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                ></td>
                              );
                            }
                          } else if (column[1] === "purchaseDate") {
                            if (rowData.purchaseDate) {
                              const formatDate = new Date(rowData.purchaseDate);
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {formatDate.toLocaleDateString()}
                                </td>
                              );
                            }
                          } else if (column[1] === "amount") {
                            if (rowData.amount) {
                              return (
                                <td
                                  key={`${rowData.id} ${column[1]}`}
                                  onClick={() =>
                                    window.open(
                                      `/forms/workorders/${rowData.id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  ${rowData.amount.toFixed(2)}
                                </td>
                              );
                            }
                          } else if (column[1].includes(".")) {
                            const multiLevelData = column[1].split(".");
                            return (
                              <td
                                key={`${rowData.id} ${column[1]}`}
                                onClick={() =>
                                  window.open(
                                    `/forms/workorders/${rowData.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                {rowData[multiLevelData[0]][multiLevelData[1]]}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={`${rowData.id} ${column[1]}`}
                                onClick={() =>
                                  window.open(
                                    `/forms/workorders/${rowData.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                {rowData[column[1]]}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </DataTable>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(String),
  data: PropTypes.array,
  handleSort: PropTypes.func,
  sortBy: PropTypes.any,
  orderBy: PropTypes.any,
};

export default Table;
