import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const DeliveryContainer = styled.div`
  text-align: left;
  .order-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .po-title {
      margin-top: 0px;
    }
    .submit-button {
      display: flex;
      justify-content: right;
      height: 50px;
    }
    .order-requisitions {
      button {
        margin-right: 5px;
      }
    }
  }
  .reqDetails {
    display: grid;
    width: 100%;
    table {
      margin: 5px;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
        }
        h3 {
          margin: 3px 0px;
        }
      }
      tbody {
        th {
          text-align: left;
          background: lightgray;
        }
        th,
        td {
          border: 1px solid black;
        }
        td {
          input {
            width: 98%;
            height: 24px;
            margin: 0px 0px 0px 2%;
            padding: 0px;
            border: none;
          }
          input:focus {
            outline: none;
          }
          textarea {
            resize: none;
            height: 100px;
            width: 98%;
            padding: 1%;
            border: none;
            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
            -moz-box-sizing: border-box; /* Firefox, other Gecko */
            box-sizing: border-box;
          }
        }
      }
      td {
        text-align: left;
      }
    }
  }
  .item-table {
    border-collapse: collapse;
    width: 100%;
    h3 {
      margin: 3px 0px;
    }
    th {
      background: darkgray;
      border: 1px solid black;
    }
    td {
      border: 1px solid black;
    }
    table {
      width: 100%;
    }
  }
`;

const SingleDelivery = () => {
  const params = useParams();
  const { id } = params;
  const [delivery, setDelivery] = useState(null);
  // let navigate = useNavigate();
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/delivery/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setDelivery(json);
      });
  }, []);

  return (
    delivery !== null && (
      <DeliveryContainer>
        <div className="order-header">
          <div>
            <h1 className="po-title">Delivery #{delivery.deliveryNumber}</h1>
            <div className="order-requisitions">
              <Link
                to={`/purchaseorder/${delivery.order.id}`}
                key={delivery.order.id}
              >
                <Button>PO# {delivery.order.purchaseOrderNumber}</Button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="reqDetails"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Order Details</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Job</th>
                <td>{`${delivery.job.jobNumber} ${delivery.job.jobName}`}</td>
              </tr>
              <tr>
                <th>Received by</th>
                <td>
                  {`${delivery.user.firstName} ${delivery.user.lastName}`}
                </td>
              </tr>
              <tr>
                <th>Received On</th>
                <td>{dayjs(delivery.createdAt).format("M/D/YYYY")}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>
                  <h3>Accounting Details</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Vendor</th>
                <td>
                  <span>{delivery.vendor.vendorName}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3>Pictures</h3>
          {delivery.attachments.length > 0 &&
            delivery.attachments.map((picture) => (
              <a
                key={picture.id}
                href={picture.url}
                target="_blank"
                rel="noreferrer"
              >
                <img src={picture.url} width="175px" height="225px" />
              </a>
            ))}
        </div>
        <div>
          <table className="item-table">
            <thead>
              <tr>
                <th
                  style={{ background: "black", color: "white" }}
                  colSpan={"100%"}
                >
                  <h3>Items</h3>
                </th>
              </tr>
              <tr>
                <th>Order Qty.</th>
                <th>Rcvd. Qty.</th>
                <th>Unit</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {delivery.order.items.length > 0 &&
                delivery.order.items.map((item) => (
                  <tr key={item.itemId}>
                    <td>{item.quantityOrdered}</td>
                    <td>
                      {delivery.items.findIndex(
                        (deliveredItem) => deliveredItem.itemId === item.itemId
                      ) === -1
                        ? 0
                        : delivery.items[
                            delivery.items.findIndex(
                              (deliveredItem) =>
                                deliveredItem.itemId === item.itemId
                            )
                          ].quantityDelivered}
                    </td>
                    <td>{item.item?.unitOfMeasure?.code}</td>
                    <td>{item.item.description}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </DeliveryContainer>
    )
  );
};

SingleDelivery.propTypes = {
  userId: PropTypes.string,
};

export default SingleDelivery;
