import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Form from "./styles/Form";
import styled from "styled-components";
import { API_URL } from "../../env";

const LoginPageButtons = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;

const Login = (props) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { loggedInState } = props;
  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, password }),
            credentials: "include",
          };
          await fetch(`${API_URL}/login`, requestOptions)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .then((data) =>
              loggedInState(
                true,
                data.permission,
                `${data.firstName} ${data.lastName}`,
                data.id,
                data.favorites,
                {
                  expenseNotification: data.expenseNotification,
                  workOrderNotification: data.workOrderNotification,
                  dailyReportNotification: data.dailyReportNotification,
                }
              )
            );
        } catch (error) {
          setError(error.message);
        }
      }}
    >
      <fieldset>
        <h2 style={{ marginBottom: "0px" }}>Log In</h2>
        <div style={{ height: "25px", margin: "20px" }}>
          {error && <span style={{ color: "red" }}>{error}</span>}
        </div>
        <label htmlFor="email">
          Email
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              error && setError("");
              setEmail(e.target.value);
            }}
          />
        </label>
        <label htmlFor="password">
          Password
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              error && setError("");
              setPassword(e.target.value);
            }}
          />
        </label>
        <LoginPageButtons>
          <button type="submit">Log In</button>
          <span>
            Did you forget your password?{" "}
            <Link
              to="/forgotpassword"
              className="reset"
              style={{ color: "#032bdf" }}
            >
              Reset Here
            </Link>
          </span>
        </LoginPageButtons>
      </fieldset>
    </Form>
  );
};

Login.propTypes = {
  loggedInState: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};

export default Login;
