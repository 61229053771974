import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../Table/Table";
import SearchSortFilterComponent from "../../Search/SearchSortFilterComponent";
import MyFilterComponent from "../../Search/MyFilterComponent";
import Pagination from "../../Search/Pagination";
import { API_URL } from "../../../env";

const columns = [
  ["Req Number", "requisitionNumber"],
  ["Job #", "job.jobNumber"],
  ["Requested By", "user.firstName"],
  ["Items", "_count.items"],
  ["Requested", "createdAt"],
  ["PO#", "order.purchaseOrderNumber"],
  ["Submitted", "submitted"],
];

const Requisitions = ({ userId }) => {
  const [requisitions, setRequisitions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({ userId: userId });

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/joblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/foremen`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/requisitions/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setRequisitions(json.requisitions);
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/material/requisitions?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Job",
      field: "jobId",
      options: jobs.map((job) => ({
        label: `${job.jobNumber}-${job.jobName}`,
        value: job.id,
      })),
    },
    {
      label: "Employee",
      field: "userId",
      options: employees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
    },
    {
      label: "Submitted",
      field: "submitted",
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    // Add more filter categories as needed
  ];

  const onSelect = (id) => {
    navigate(`/material/requisition/${id}`);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  return (
    <div>
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={null}
        buttonPath={""}
        pageTitle={"Material Requisitions"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      <MyFilterComponent
        userId={userId}
        filters={filters}
        setFilters={setFilters}
        itemName={"Reqs"}
      />
      {!loading && (
        <Table
          columns={columns}
          data={requisitions}
          onSelect={onSelect}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      )}
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

Requisitions.propTypes = {
  userId: PropTypes.string,
};

export default Requisitions;
