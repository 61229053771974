import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const CustomerForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    .customer-name {
      grid-row-start: 1;
    }
    button {
      grid-row-start: 2;
      grid-column: 1/3;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

class SingleCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: { ...this.props.editCustomer[0] },
      updatedCustomer: "",
      error: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      updatedCustomer: { ...this.state.updatedCustomer, [name]: value },
    });
  }

  render() {
    const { customer, updatedCustomer } = this.state;
    return (
      <div>
        <div>
          <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Edit Customer
          </h3>
          <CustomerForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...updatedCustomer }),
              };
              await fetch(
                `${API_URL}/update/customer/${customer.id}`,
                requestOptions
              ).then((res) => res.json());
              this.props.onEditCustomer(customer.id, updatedCustomer);
              this.props.onDeselectCustomer();
            }}
          >
            <fieldset>
              <div className="fields">
                <label className="customer-name">
                  Customer Number
                  <br />
                  <input
                    type="number"
                    name="customerNumber"
                    id="customerNumber"
                    defaultValue={customer.customerNumber}
                    onChange={this.handleChange}
                    placeholder="Customer Number*"
                    required
                    autoComplete="none"
                  />
                </label>
                <label className="customer-name">
                  Customer Name
                  <br />
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue={customer.name}
                    onChange={this.handleChange}
                    placeholder="Customer Name*"
                    required
                    autoComplete="none"
                  />
                </label>
                {/* <div>
                  <span>Emails</span>
                </div> */}
                {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                <Button type="submit">Save Changes</Button>
              </div>
            </fieldset>
          </CustomerForm>
        </div>
      </div>
    );
  }
}

SingleCustomer.propTypes = {
  id: PropTypes.string,
  editCustomer: PropTypes.array,
  onEditCustomer: PropTypes.func,
  onDeselectCustomer: PropTypes.func,
};

export default SingleCustomer;
