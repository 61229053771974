import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import CustomStyles from "../../styles/SelectCustomStyle";

const TimeCardRow = ({
  jobs,
  tasks,
  loading,
  handleChange,
  handleJobChange,
  handleTaskChange,
  handleDeleteRow,
  job,
  task,
  hours,
  rowIndex,
  numRows,
}) => {
  const total = hours.reduce((prev, cur) => prev + Number(cur), 0);
  return (
    <tr style={{ gridTemplateColumns: "250px 175px repeat(8, 100px) 34px" }}>
      <td className="job-select">
        <Select
          styles={CustomStyles}
          options={jobs}
          isLoading={loading}
          name="jobId"
          onChange={(e) => handleJobChange(e, rowIndex)}
          defaultValue={job}
          placeholder="Select Job"
        />
      </td>
      <td className="task-select">
        <Select
          styles={CustomStyles}
          options={tasks}
          isLoading={loading}
          name="taskId"
          onChange={(e) => handleTaskChange(e, rowIndex)}
          defaultValue={task}
          placeholder="Select Task"
        />
      </td>
      {hours.map((day, cellIndex) => (
        <td key={cellIndex}>
          <input
            type="number"
            value={day}
            name={day.id}
            onChange={(e) => handleChange(e, rowIndex, cellIndex)}
            className="data-cell"
          />
        </td>
      ))}
      <td>
        <input
          type="text"
          value={isNaN(total) ? "-" : total}
          className="total-cell"
          readOnly
        />
      </td>
      <td>
        <button
          type="button"
          onClick={() => handleDeleteRow(rowIndex)}
          disabled={numRows <= 1}
          className="delete-button"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </td>
    </tr>
  );
};

TimeCardRow.propTypes = {
  jobs: PropTypes.array,
  tasks: PropTypes.array,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleJobChange: PropTypes.func,
  handleTaskChange: PropTypes.func,
  handleDeleteRow: PropTypes.func,
  job: PropTypes.any,
  task: PropTypes.any,
  hours: PropTypes.array,
  rowIndex: PropTypes.number,
  numRows: PropTypes.number,
};

export default TimeCardRow;
