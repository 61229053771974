import React from "react";
import PropTypes from "prop-types";

const REQDashboard = ({ requisitions }) => {
  console.log(requisitions);
  return (
    <div>
      <p>Requisitions</p>
    </div>
  );
};

REQDashboard.propTypes = {
  requisitions: PropTypes.array,
};

export default REQDashboard;
