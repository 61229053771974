import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const VendorForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(auto-fit);
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
      height: 75px;
    }
    input {
      padding: 7px;
      border: 1px solid lightgray;
    }
  }
  .submit-button {
    /* grid-row-start: 5;
    grid-column: 1/4; */
    position: relative;
    text-transform: uppercase;
    margin: 10px;
  }
`;

const SingleVendor = (props) => {
  const [state, setState] = useState({
    loading: true,
    vendor: {
      ...props.editVendor[0],
    },
    updatedVendor: "",
    employees: [],
    error: "",
  });

  const [vendorEmails, setVendorEmails] = useState([{ name: "", email: "" }]);
  const [showVendorURL, setShowVendorURL] = useState(false);

  useEffect(() => {
    if (props.editVendor[0].emails.length > 0) {
      setVendorEmails(
        props.editVendor[0].emails.map((email) => JSON.parse(email))
      );
    }
  }, []);
  const handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    setState((prevState) => ({
      ...prevState,
      updatedVendor: { ...prevState.updatedVendor, [name]: val },
    }));
  };

  const { vendor, updatedVendor } = state;

  return (
    <div>
      {
        <div>
          <h4
            style={{ textAlign: "center", textTransform: "uppercase" }}
            onClick={() => setShowVendorURL((prevState) => !prevState)}
          >
            Edit Vendor
          </h4>
          <VendorForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  ...updatedVendor,
                  emails: vendorEmails.map((contact) =>
                    JSON.stringify(contact)
                  ),
                }),
              };
              await fetch(
                `${API_URL}/update/vendor/${vendor.id}`,
                requestOptions
              ).then((res) => res.json());
              props.onEditVendor(vendor.id, {
                ...updatedVendor,
                emails: vendorEmails.map((contact) => JSON.stringify(contact)),
              });
              props.onDeselectVendor();
            }}
          >
            <fieldset>
              <div className="fields">
                {showVendorURL && (
                  <label>
                    Vendor Pricing URL
                    <br />
                    <input
                      type="text"
                      name="vendorPricingURL"
                      id="vendorPricingURL"
                      defaultValue={
                        updatedVendor.vendorPricingURL
                          ? updatedVendor.vendorPricingURL
                          : vendor.vendorPricingURL
                      }
                      onChange={handleChange}
                      placeholder="Vendor Pricing URL"
                      autoComplete="none"
                    />
                  </label>
                )}
                <label className="vendor-number">
                  Vendor Number
                  <br />
                  <input
                    type="number"
                    name="vendorNumber"
                    id="vendorNumber"
                    defaultValue={
                      updatedVendor.vendorNumber
                        ? updatedVendor.vendorNumber
                        : vendor.vendorNumber
                    }
                    onChange={handleChange}
                    placeholder="Vendor Number*"
                    required
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="vendor-name">
                  Vendor Name
                  <br />
                  <input
                    type="text"
                    name="vendorName"
                    id="vendorName"
                    defaultValue={
                      updatedVendor.vendorName
                        ? updatedVendor.vendorName
                        : vendor.vendorName
                    }
                    onChange={handleChange}
                    placeholder="Vendor Name*"
                    required
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="vendor-emails">
                  Vendor Contacts
                  <br />
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th></th>
                        </tr>
                        {vendorEmails.map((contact, index) => (
                          <tr key={contact.index}>
                            <td>
                              <input
                                value={contact.name}
                                type="text"
                                placeholder="Name"
                                onChange={(e) => {
                                  e.preventDefault();
                                  setVendorEmails((prevState) => {
                                    prevState[index] = {
                                      ...prevState[index],
                                      name: e.target.value,
                                    };
                                    return [...prevState];
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <input
                                value={contact.email}
                                type="text"
                                placeholder="Email"
                                onChange={(e) => {
                                  e.preventDefault();
                                  setVendorEmails((prevState) => {
                                    prevState[index] = {
                                      ...prevState[index],
                                      email: e.target.value,
                                    };
                                    return [...prevState];
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                onClick={() =>
                                  setVendorEmails((prevState) => {
                                    prevState.splice(index, 1);
                                    return [...prevState];
                                  })
                                }
                              >
                                X
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Button
                      type="button"
                      onClick={() =>
                        setVendorEmails((prevState) => [
                          ...prevState,
                          { name: "", email: "" },
                        ])
                      }
                    >
                      Add Contact
                    </Button>
                  </div>
                </label>
                {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                <Button className="submit-button" type="submit">
                  Save Changes
                </Button>
              </div>
            </fieldset>
          </VendorForm>
        </div>
      }
    </div>
  );
};

SingleVendor.propTypes = {
  id: PropTypes.string,
  editVendor: PropTypes.array,
  onEditVendor: PropTypes.func,
  onDeselectVendor: PropTypes.func,
};

export default SingleVendor;
