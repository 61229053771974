import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const ToolForm = styled.form`
  fieldset {
    display: grid;
    grid-template-columns: 2fr 3fr;
    border: none;
  }
  .image {
    grid-column-start: 1;
  }
  .tool-image {
    width: auto;
    max-height: 350px;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 1/2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 65px;
    font-size: 1px;
    color: white;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .image-button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
`;

const AddToolForm = () => {
  const [state, setState] = useState({
    name: "",
    description: "",
    manufacturerId: "",
    model: "",
    serialNumber: "",
    scanCode: "",
    defaultLocationId: "",
    currentLocationId: "",
    responsibleEmployeeId: "",
    toolCategoryId: "",
    image: null,
    purchaseDate: null,
  });
  const [manufacturers, setManufacturers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [employees, setEmployees] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/manufacturers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setManufacturers([...json]));
    fetch(`${API_URL}/locations`, requestOptions)
      .then((res) => res.json())
      .then((json) => setLocations([...json]));
    fetch(`${API_URL}/categories`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCategories([...json]));
    fetch(`${API_URL}/employees`, requestOptions)
      .then((res) => res.json())
      .then((json) =>
        setEmployees(json.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)))
      );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setState((prevState) => ({
          ...prevState,
          image: reader.result,
        }));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setPurchaseDate = (value) => {
    setState((prevState) => ({
      ...prevState,
      purchaseDate: value,
    }));
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...state }),
    };
    fetch(`${API_URL}/create/tool`, requestOptions).then((response) =>
      response.json()
    );
  };
  const {
    name,
    description,
    manufacturerId,
    model,
    serialNumber,
    scanCode,
    defaultLocationId,
    currentLocationId,
    responsibleEmployeeId,
    toolCategoryId,
    purchaseDate,
    image,
  } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Tool
      </h1>
      <div>
        <ToolForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/tools");
          }}
        >
          <fieldset>
            {image ? (
              <div>
                <img src={image} className="tool-image" />
                <ButtonDiv>
                  <div className="button-wrap">
                    <label className="image-button" htmlFor="file">
                      Update Picture
                    </label>
                    <input
                      id="file"
                      type="file"
                      name="myImage"
                      onChange={onChangePicture}
                    />
                  </div>
                </ButtonDiv>
              </div>
            ) : (
              <div>
                <img src={image} className="tool-image" />
                <ButtonDiv>
                  <div className="button-wrap">
                    <label className="image-button" htmlFor="file">
                      Upload Picture
                    </label>
                    <input
                      id="file"
                      type="file"
                      name="myImage"
                      onChange={onChangePicture}
                    />
                  </div>
                </ButtonDiv>
              </div>
            )}
            <div className="fields">
              <label htmlFor="name" className="name">
                Tool Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Tool Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="scanCode" className="scanCode">
                Scan Code
                <br />
                <input
                  type="text"
                  name="scanCode"
                  id="scanCode"
                  value={scanCode}
                  onChange={handleChange}
                  placeholder="Scan Code*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="description" className="description">
                Tool Description
                <br />
                <input
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                  onChange={handleChange}
                  placeholder="Tool Description"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="model" className="model">
                Model
                <br />
                <input
                  type="text"
                  name="model"
                  id="model"
                  value={model}
                  onChange={handleChange}
                  placeholder="Model"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="serialNumber" className="serialNumber">
                Serial Number
                <br />
                <input
                  type="text"
                  name="serialNumber"
                  id="serialNumber"
                  value={serialNumber}
                  onChange={handleChange}
                  placeholder="Serial Number"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="toolCategoryId" className="category">
                Category
                <br />
                <select
                  required
                  name="toolCategoryId"
                  id="toolCategoryId"
                  value={toolCategoryId}
                  onChange={handleChange}
                >
                  <option defaultValue>Select A Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="manufacturerId" className="manufacturer">
                Manufacturer
                <br />
                <select
                  required
                  name="manufacturerId"
                  id="manufacturerId"
                  value={manufacturerId}
                  onChange={handleChange}
                >
                  <option defaultValue>Select A Manufacturer</option>
                  {manufacturers.map((manufacturer) => (
                    <option key={manufacturer.id} value={manufacturer.id}>
                      {manufacturer.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="defaultLocationId" className="defaultLocation">
                Default Location
                <br />
                <select
                  required
                  name="defaultLocationId"
                  id="defaultLocationId"
                  value={defaultLocationId}
                  onChange={handleChange}
                >
                  <option defaultValue>Select A Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="currentLocationId" className="currentLocation">
                Current Location
                <br />
                <select
                  required
                  name="currentLocationId"
                  id="currentLocationId"
                  value={currentLocationId}
                  onChange={handleChange}
                >
                  <option defaultValue>Select A Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </label>
              <label
                htmlFor="responsibleEmployeeId"
                className="currentLocation"
              >
                Responsible Employee
                <br />
                <select
                  required
                  name="responsibleEmployeeId"
                  id="responsibleEmployeeId"
                  value={responsibleEmployeeId}
                  onChange={handleChange}
                >
                  <option defaultValue>Select An Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Purchase Date
                <DatePicker
                  selected={purchaseDate}
                  onChange={setPurchaseDate}
                />
              </label>
              <Button type="submit">Add Tool</Button>
            </div>
          </fieldset>
        </ToolForm>
      </div>
    </div>
  );
};

export default AddToolForm;
