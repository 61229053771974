import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "../../styles/Style";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { API_URL } from "../../env";
import "react-datepicker/dist/react-datepicker.css";

const MenuDiv = styled.div`
  table {
    width: fit-content;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    .company-input {
      width: 500px;
    }
  }
`;

const AdminSettings = ({ permission }) => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [companyCreated, setCompanyCreated] = useState(false);
  const [date, setDate] = useState(new Date());
  const onDateChange = (value) => {
    setDate(value);
  };
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/company`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setCompany(
          json.length > 0
            ? json[0]
            : {
                companyName: "",
                companyPhone: "",
                companyAddress: "",
                companyLicenseNumber: "",
              }
        );
        setCompanyCreated(json.length > 0);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (companyCreated) {
      const updateCompany = company;
      delete updateCompany.id;
      const requestOptions = {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updateCompany),
      };
      fetch(`${API_URL}/company/${company.id}`, requestOptions).then((res) =>
        res.json()
      );
    } else {
      const requestOptions = {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(company),
      };
      fetch(`${API_URL}/create/company`, requestOptions).then((res) =>
        res.json()
      );
    }
  };

  const handleDeleteAllClockActionImages = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/removebase64images/${date}`, requestOptions)
      .then((res) => res.json())
      .then((json) => console.log(json));
  };

  return (
    !loading &&
    permission === "ADMIN" && (
      <MenuDiv>
        <h1>Admin Settings</h1>
        <DatePicker
          selected={date}
          onChange={onDateChange}
          className="date-picker"
        />
        <Button onClick={() => handleDeleteAllClockActionImages()}>
          Delete Images
        </Button>
        <table>
          <tbody>
            <tr>
              <th>Company Name</th>
              <td>
                <input
                  className="company-input"
                  name="companyName"
                  type="text"
                  value={company.companyName}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>
            <tr>
              <th>Company Address</th>
              <td>
                <input
                  className="company-input"
                  name="companyAddress"
                  type="text"
                  value={company.companyAddress}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>
            <tr>
              <th>Company Phone #</th>
              <td>
                <input
                  className="company-input"
                  name="companyPhone"
                  type="text"
                  value={company.companyPhone.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3"
                  )}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>
            <tr>
              <th>Company License #</th>
              <td>
                <input
                  className="company-input"
                  name="companyLicenseNumber"
                  type="text"
                  value={company.companyLicenseNumber}
                  onChange={(e) => handleChange(e)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </MenuDiv>
    )
  );
};

AdminSettings.propTypes = {
  permission: PropTypes.string,
};

export default AdminSettings;
