import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { startOfDay, endOfDay } from "date-fns";
import dayjs from "dayjs";
import styled from "styled-components";
import { Button } from "../../../styles/Style";
import logo from "../../../static/champion_logo_small.png";
import { API_URL } from "../../../env";

const ButtonContainerDiv = styled.div`
  @media print {
    display: none;
  }
  margin: 5px;
  button {
    margin: 5px;
    width: 125px;
  }
`;

const WorkOrderContainer = styled.div`
  @media print {
    button {
      display: none !important;
    }
    .no-border-print,
    select {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      appearance: none !important;
    }
    th {
      background: white !important;
      color: black !important;
    }
  }
  .do-not-print {
    @media print {
      display: none;
      button {
        display: none !important;
      }
      .no-border-print,
      select {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        appearance: none !important;
      }
    }
  }
  .images {
    width: 100%;
    display: flex;
    flex-flow: row;
    td {
      width: 100%;
      img {
        max-width: 50%;
      }
    }
  }
  table {
    margin: 0px auto;
    border-collapse: collapse;
    border-spacing: 0px;
    border: 1px solid black;
    width: 800px;
    input {
      font-size: 14px;
    }
    .champion-logo {
      width: 400px;
    }
    th {
      font-weight: bold;
      background-color: black;
      color: white;
    }
    input,
    select {
      margin-left: 5px;
    }
    .job {
      width: 70%;
    }
    .material {
      width: 20%;
    }
    .complete {
    }
    .description {
      textarea {
        width: 98%;
        border: none;
        resize: none;
        font-size: 14px;
      }
    }
  }
  .bordered-table {
    th,
    td {
      border-collapse: collapse;
      border-spacing: 0px;
      border: 1px solid black;
    }
    .date {
      border: none;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 3fr;
      div {
        input {
          width: 80px;
        }
      }
    }
  }
`;

const EditWorkOrder = ({
  editWorkOrder,
  onEditWorkOrder,
  onDeselectWorkOrder,
  onDeleteWorkOrder,
}) => {
  const [state, setState] = useState({
    workOrder: { ...editWorkOrder[0] },
  });
  const [updatedWorkOrder, setUpdatedWorkOrder] = useState({});
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [parsedMaterial, setParsedMaterial] = useState([]);

  useEffect(() => {
    if (state.workOrder.material) {
      setParsedMaterial(JSON.parse(state.workOrder.material));
    }
  }, [state.workOrder.material]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedWorkOrder((prevState) => ({
      ...prevState.updatedWorkOrder,
      [name]: value,
    }));
  };

  const handleModifiedLaborChange = (e, index) => {
    const { name, value } = e.target;
    setUpdatedWorkOrder((prevState) => {
      const newModifiedLabor = prevState.modifiedLabor;
      newModifiedLabor[index][name] = value;
      return {
        ...prevState.updatedWorkOrder,
        modifiedLabor: newModifiedLabor,
      };
    });
  };

  const handleMaterialChange = (e, index) => {
    const { value } = e.target;
    setParsedMaterial((prevState) => {
      const newArray = prevState;
      newArray[index] = value;
      return newArray;
    });
  };

  const handleGetLabor = (date) => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/clockactions/${startOfDay(date).toISOString()}/${endOfDay(
        date
      ).toISOString()}/${state.workOrder.jobId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setState((prevState) => ({ ...prevState, labor: json })));
  };
  const handleDateChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      date: value,
    }));
    handleGetLabor(value);
  };

  const handleDeleteMaterial = (e, index) => {
    e.preventDefault();
    setParsedMaterial((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...updatedWorkOrder }),
    };
    await fetch(
      `${API_URL}/update/form/workorder/${state.workOrder.id}`,
      requestOptions
    ).then((res) => res.json());
    onEditWorkOrder(state.workOrder.id, state.updatedWorkOrder);
    onDeselectWorkOrder();
  };

  return (
    <WorkOrderContainer>
      <ButtonContainerDiv>
        <Button onClick={onSubmit}>Save</Button>
        <Button onClick={() => onDeleteWorkOrder(state.workOrder.id)}>
          Delete
        </Button>
        <Button onClick={onDeselectWorkOrder}>Cancel</Button>
      </ButtonContainerDiv>
      <table>
        <tbody>
          <tr>
            <td className="title" style={{ textAlign: "left" }}>
              <img src={logo} alt="champion-logo" className="champion-logo" />
              <div style={{ marginLeft: "10px" }}>
                <p
                  style={{
                    fontSize: "12px",
                    margin: "0px",
                    marginTop: "-15px",
                  }}
                >
                  State Electrical License # EC 13005014
                </p>
                <h2
                  style={{
                    textTransform: "uppercase",
                    margin: "0px",
                    marginTop: "10px",
                  }}
                >
                  Field Work Order
                </h2>
              </div>
            </td>
            <td style={{ fontSize: "16px", fontWeight: "bold" }}>
              <span>2875 Jupiter Park Dr. Ste 500</span>
              <br />
              <span>Jupiter, Florida 33458</span>
              <br />
              <span>{"TEL: (561)296-4144"}</span>
              <br />
              <span>{"FAX: (561)296-4166"}</span>
              <br />
              <br />
              <span>W/O# </span>
              <span style={{ textDecoration: "underline" }}>
                <span style={{ color: "red" }}>{state.workOrder.id}</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="bordered-table labor-table">
        <tbody>
          <tr>
            <td className="job">
              Project:
              <select
                value={
                  updatedWorkOrder.jobId
                    ? updatedWorkOrder.jobId
                    : state.workOrder.jobId
                }
                name="jobId"
                id="jobId"
                required
                onChange={handleChange}
              >
                {jobs &&
                  jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.jobNumber} - {job.jobName}
                    </option>
                  ))}
              </select>
            </td>
            <td className="complete">
              Complete:
              <input
                type="checkbox"
                checked={
                  updatedWorkOrder.complete !== undefined
                    ? updatedWorkOrder.complete
                    : state.workOrder.complete
                }
                onChange={() => {
                  setUpdatedWorkOrder((prevState) => ({
                    ...prevState,
                    complete: true,
                  }));
                }}
              />
              Y
              <input
                type="checkbox"
                checked={
                  updatedWorkOrder.complete !== undefined
                    ? updatedWorkOrder.complete
                    : state.workOrder.complete
                }
                onChange={() => {
                  setUpdatedWorkOrder((prevState) => ({
                    ...prevState,
                    complete: false,
                  }));
                }}
              />
              N
            </td>
          </tr>
          <tr>
            <td className="customer">
              Customer:
              <select
                value={
                  updatedWorkOrder.customerId
                    ? updatedWorkOrder.customerId
                    : state.workOrder.customerId
                }
                name="customerId"
                id="customerId"
                onChange={handleChange}
              >
                <option defaultValue>Select a Customer</option>
                {customers &&
                  customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
              </select>
            </td>
            <td className="date">
              Date:
              <DatePicker
                className="no-border-print"
                selected={new Date(state.workOrder.date)}
                onChange={handleDateChange}
              />
            </td>
          </tr>
          <tr>
            <th colSpan={2}>Description of Work</th>
          </tr>
          <tr>
            <td className="description" colSpan={2}>
              <textarea
                type="text"
                value={
                  updatedWorkOrder.workDescription
                    ? updatedWorkOrder.workDescription
                    : state.workOrder.workDescription
                }
                name="workDescription"
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <th>Labor</th>
            <th>Hours</th>
          </tr>
          {/* {console.log(state.workOrder.modifiedLabor)} */}
          {state.workOrder.labor.length > 0
            ? state.workOrder.labor.map((row) => (
                <tr key={row.id}>
                  <td>
                    {row.user.firstName} {row.user.lastName}
                  </td>
                  <td>
                    {Math.round(
                      (
                        dayjs(row.clockOut).diff(dayjs(row.clockIn)) / 3600000
                      ).toFixed(2)
                    )}
                  </td>
                </tr>
              ))
            : JSON.parse(state.workOrder.modifiedLabor).map((row, index) => (
                <tr key={index}>
                  <td>
                    <input
                      name="employee.name"
                      value={row.employee.name}
                      onChange={(e, index) =>
                        handleModifiedLaborChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <input
                      name="hours"
                      value={Number(row.hours).toFixed(2)}
                      onChange={(e, index) =>
                        handleModifiedLaborChange(e, index)
                      }
                    />
                  </td>
                </tr>
              ))}
          {state.workOrder.labor.length > 0 && (
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  backgroundColor: "lightgray",
                }}
              >
                Total
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  backgroundColor: "lightgray",
                }}
              >
                {state.workOrder.labor.length > 1
                  ? state.workOrder.labor
                      .reduce(
                        (a, b) =>
                          Math.round(
                            dayjs(a.clockOut).diff(dayjs(a.clockIn)) / 3600000
                          ) +
                          Math.round(
                            dayjs(b.clockOut).diff(dayjs(b.clockIn)) / 3600000
                          )
                      )
                      .toFixed(2)
                  : Math.round(
                      (
                        dayjs(state.workOrder.labor[0].clockOut).diff(
                          state.workOrder.labor[0].clockIn
                        ) / 3600000
                      ).toFixed(2)
                    )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table className="bordered-table material-table">
        <tbody>
          <tr>
            <th
              style={{
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
              }}
              className="material"
            >
              Quantity
            </th>
            <th
              style={{
                fontWeight: "bold",
                backgroundColor: "black",
                color: "white",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Material
            </th>
          </tr>
          {parsedMaterial.length > 0 ? (
            parsedMaterial.map((material, index) => (
              <tr key={index}>
                <td>
                  <input
                    defaultValue={material[0]}
                    name="material"
                    type="number"
                    style={{
                      height: "95%",
                      margin: "0px",
                      padding: "0px",
                      outline: "none",
                      border: "none",
                      textAlign: "center",
                    }}
                  />
                </td>
                <td style={{ height: "25px" }}>
                  <input
                    defaultValue={material[1]}
                    name="material"
                    type="text"
                    style={{
                      width: "95%",
                      height: "95%",
                      margin: "0px",
                      padding: "0px",
                      outline: "none",
                      border: "none",
                    }}
                    onChange={handleMaterialChange}
                  />
                  <button
                    onClick={(e) => handleDeleteMaterial(e, index)}
                    className="do-not-print"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    x
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
          <tr className="do-not-print">
            <td colSpan={2}>
              <Button
                onClick={() =>
                  setParsedMaterial((prevState) => [...prevState, ["", ""]])
                }
              >
                Add Material
              </Button>
            </td>
          </tr>
          <tr>
            <th colSpan={2}>Images</th>
          </tr>
        </tbody>
      </table>
      <table className="bordered-table">
        <tbody>
          {state.workOrder.images && (
            <tr className="images">
              {state.workOrder.images.map((image, index) => (
                <td key={index}>
                  <img src={image} />
                </td>
              ))}
            </tr>
          )}
          <tr>
            <th colSpan={2}>Signature</th>
          </tr>
          {state.workOrder.signature && (
            <tr>
              <td colSpan={2} style={{ height: "25px" }}>
                <img src={state.workOrder.signature} height="150px" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ButtonContainerDiv>
        <Button onClick={onSubmit}>Save</Button>
        <Button onClick={onDeselectWorkOrder}>Cancel</Button>
      </ButtonContainerDiv>
    </WorkOrderContainer>
  );
};

EditWorkOrder.propTypes = {
  editWorkOrder: PropTypes.array,
  onDeselectWorkOrder: PropTypes.func,
  onDeleteWorkOrder: PropTypes.func,
  onEditWorkOrder: PropTypes.func,
};

export default EditWorkOrder;
