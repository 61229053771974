import React, { useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import _ from "lodash";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import CustomStyles from "../../styles/SelectCustomStyle";
import { API_URL } from "../../env";

const Header = styled.div`
  @media print {
    display: none;
  }
  width: 500px;
  margin: 0px auto 0px auto;
`;

const HourSummaryTable = styled.table`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid black;
    width: 200px;
  }
`;

const JobHoursSummary = () => {
  const [hours, setHours] = useState([]);
  const [jobId, setJobId] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hasUnprocessed, setHasUnprocessed] = useState(false);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/fulljoblist`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
  }, []);
  useEffect(() => {
    if (jobId) {
      setLoading(true);
      setHasUnprocessed(false);
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `${API_URL}/jobhours/summary/${jobId}/${
          startDate === ""
            ? "null"
            : dayjs(startDate).startOf("day").toISOString()
        }/${
          endDate === "" ? "null" : dayjs(endDate).endOf("day").toISOString()
        }`,
        requestOptions
      )
        .then((res) => res.json())
        .then((json) => {
          json.clockActions.length > 0 && setHasUnprocessed(true);
          if (json.clockActions.length > 0 || json.timeRecords.length > 0) {
            const hoursByEmployee = _.chain([
              ...json.timeRecords,
              ...json.clockActions.map((clock) => ({
                clockAction: { task: clock.task },
                clockIn: clock.clockIn,
                clockOut: clock.clockOut,
                id: clock.id,
                job: clock.job,
                payClass: "Unprocessed",
                userId: clock.userId,
                user: clock.user,
                duration:
                  clock.clockOut && clock.clockIn
                    ? parseFloat(
                        dayjs(clock.clockOut).diff(dayjs(clock.clockIn)) /
                          3600000
                      )
                    : 0,
              })),
            ])
              .groupBy("userId")
              .map((value, key) => ({
                user: key,
                value,
              }))
              .value();
            setHours(hoursByEmployee);
            setLoading(false);
          } else {
            setHours(json);
            setLoading(false);
          }
        });
    }
  }, [jobId, startDate, endDate]);
  useEffect(() => {
    jobs.length > 0 > 0 && setLoading(false);
  }, [jobs]);
  const jobList = jobs.map((job) => ({
    value: job.id,
    label: `${job.jobNumber} - ${job.jobName}`,
  }));
  return (
    <div>
      <h1>Job Hours Summary</h1>
      <Header>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            marginBottom: "10px",
          }}
        >
          <div>
            Start Date
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div>
            End Date
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <Select
          styles={CustomStyles}
          options={jobList}
          isLoading={loading}
          name="jobId"
          onChange={(e) => setJobId(e.value)}
          defaultValue={jobId}
          placeholder="Select Job"
        />
      </Header>
      {hours.length > 0 ? (
        <div>
          <HourSummaryTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>ST Hours</th>
                <th>OT Hours</th>
                {hasUnprocessed && <th>Unprocessed Hours</th>}
                <th>Total Hours</th>
              </tr>
            </thead>
            <tbody>
              {hours.length > 0 &&
                hours.map((employeeTime) => (
                  <tr key={employeeTime.user}>
                    <td>
                      {`${employeeTime.value[0].user.firstName} ${employeeTime.value[0].user.lastName}`}
                    </td>
                    <td>
                      {employeeTime.value
                        .filter((time) => time.payClass === "ST")
                        .reduce((prev, cur) => prev + cur.duration, 0)
                        .toFixed(2)}
                    </td>
                    <td>
                      {employeeTime.value
                        .filter((time) => time.payClass === "OT")
                        .reduce((prev, cur) => prev + cur.duration, 0)
                        .toFixed(2)}
                    </td>
                    {hasUnprocessed && (
                      <td>
                        {employeeTime.value
                          .filter((time) => time.payClass === "Unprocessed")
                          .reduce((prev, cur) => prev + cur.duration, 0)
                          .toFixed(2)}
                      </td>
                    )}
                    <td>
                      {employeeTime.value
                        .reduce((prev, cur) => prev + cur.duration, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>
                  {hours
                    .reduce(
                      (prev, cur) =>
                        prev +
                        cur.value
                          .filter((time) => time.payClass === "ST")
                          .reduce((prev2, cur2) => prev2 + cur2.duration, 0),
                      0
                    )
                    .toFixed(2)}
                </td>
                <td>
                  {hours
                    .reduce(
                      (prev, cur) =>
                        prev +
                        cur.value
                          .filter((time) => time.payClass === "OT")
                          .reduce((prev2, cur2) => prev2 + cur2.duration, 0),
                      0
                    )
                    .toFixed(2)}
                </td>
                {hasUnprocessed && (
                  <td>
                    {hours
                      .reduce(
                        (prev, cur) =>
                          prev +
                          cur.value
                            .filter((time) => time.payClass === "Unprocessed")
                            .reduce((prev2, cur2) => prev2 + cur2.duration, 0),
                        0
                      )
                      .toFixed(2)}
                  </td>
                )}
                <td>
                  {hours
                    .reduce(
                      (prev, cur) =>
                        prev +
                        cur.value.reduce(
                          (prev2, cur2) => prev2 + cur2.duration,
                          0
                        ),
                      0
                    )
                    .toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </HourSummaryTable>
          <span>
            {/* {hours?.timeRecords?.length > 0 &&
            hours.timeRecords
              .reduce(
                (prev, cur) =>
                  prev +
                  (cur.clockIn && cur.clockOut
                    ? dayjs(cur.clockOut).diff(dayjs(cur.clockIn)) / 3600000
                    : 0),
                0
              )
              .toFixed(2)} */}
          </span>
        </div>
      ) : (
        !loading &&
        jobId && (
          <div>
            <p>No time found for this criteria</p>
          </div>
        )
      )}
    </div>
  );
};

export default JobHoursSummary;
