import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const possiblePermissions = ["USER", "FOREMAN", "ADMIN"];

const EmployeeForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    input,
    select {
      padding: 7px;
      border: 1px solid lightgray;
    }
    .employee-name {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    button {
      grid-row-start: 4;
      grid-column-start: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddUserForm = () => {
  const [state, setState] = useState({
    departments: [],
    employeeNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    departmentId: "",
    pinNumber: "",
    email: "",
    password: "",
    permission: "USER",
    isSalary: false,
  });

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    setState((prevState) => ({ ...prevState, [name]: val }));
  };

  const handleActiveChange = (e) => {
    const { name, checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handlePermissionChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      permission: e.target.value,
    }));
  };

  const handleDepartmentChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      departmentId: e.target.value,
    }));
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/departments`, requestOptions)
      .then((res) => res.json())
      .then((json) => setState({ ...state, departments: json }));
  }, []);

  const handleSubmit = () => {
    const body = state;
    delete body.departments;
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...body }),
    };
    fetch(`${API_URL}/create/employee`, requestOptions).then((response) =>
      response.json()
    );
  };
  const {
    departments,
    employeeNumber,
    firstName,
    middleName,
    lastName,
    departmentId,
    pinNumber,
    email,
    password,
    permission,
    isSalary,
  } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Employee
      </h1>
      <div>
        <EmployeeForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/employees");
          }}
        >
          <fieldset>
            <div className="fields">
              <div>
                <label htmlFor="isSalary">
                  Salary
                  <input
                    type="checkbox"
                    name="isSalary"
                    id="isSalary"
                    checked={isSalary}
                    onChange={handleActiveChange}
                  />
                </label>
              </div>
              <label htmlFor="employeeNumber">
                Employee Number
                <br />
                <input
                  type="number"
                  name="employeeNumber"
                  id="employeeNumber"
                  value={employeeNumber}
                  onChange={handleChange}
                  placeholder="Eg. 392"
                />
              </label>
              <label htmlFor="firstName" className="employee-name">
                Name
                <br />
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={firstName}
                  onChange={handleChange}
                  placeholder="First Name*"
                  required
                  autoComplete="none"
                />
                <input
                  type="text"
                  name="middleName"
                  id="middleName"
                  value={middleName}
                  onChange={handleChange}
                  placeholder="Middle Name"
                  autoComplete="none"
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={lastName}
                  onChange={handleChange}
                  placeholder="Last Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <label htmlFor="department">
                Department
                <br />
                <select
                  value={departmentId}
                  name="department"
                  id="department"
                  required
                  onChange={handleDepartmentChange}
                >
                  <option defaultValue>Select A Department</option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="pinNumber">
                Pin Number
                <br />
                <input
                  type="text"
                  name="pinNumber"
                  id="pinNumber"
                  value={pinNumber}
                  onChange={handleChange}
                  placeholder="Last 4 of SS#"
                  autoComplete="none"
                />
              </label>
              <label htmlFor="email">
                Email
                <br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="someone@example.com"
                />
              </label>
              <label htmlFor="password">
                Password
                <br />
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </label>
              <label>
                Permissions
                <br />
                <div id="permission-labels" className="permission-labels">
                  {possiblePermissions.map((permissionItem) => (
                    <label
                      htmlFor={`${permissionItem}-permission`}
                      key={permissionItem}
                    >
                      {permissionItem}
                      <input
                        type="checkbox"
                        checked={permission.includes(permissionItem)}
                        value={permissionItem}
                        name={permissionItem}
                        id={permissionItem}
                        onChange={handlePermissionChange}
                      />
                    </label>
                  ))}
                </div>
              </label>
              <Button type="submit">Create User</Button>
            </div>
          </fieldset>
        </EmployeeForm>
      </div>
    </div>
  );
};

export default AddUserForm;
