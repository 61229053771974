import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Button } from "../../styles/Style";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../env";
import styled from "styled-components";

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 15px;
    font-size: 17px;
    color: black;
    text-indent: initial;
  }
  .button-wrap {
    position: relative;
  }
  .button {
    display: inline-block;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: #032bdf;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
`;

const UploadVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [error, setError] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/vendors`, requestOptions)
      .then((res) => res.json())
      .then((json) => setVendors(json));
  }, []);

  const handleFile = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(d);
        });

        const data = valuesArray.map((value) => ({
          vendorNumber: parseInt(value.vendorNumber),
          vendorName: value.vendorName,
        }));
        const filterVendors = (arr1, arr2) => {
          let resNew = [];
          let resUpdate = [];
          resNew = arr1.filter((el) => {
            return !arr2.find((element) => {
              return element.vendorNumber === el.vendorNumber;
            });
          });
          resUpdate = arr1.filter((el) => {
            return arr2.find((element) => {
              const criteria = [
                element.vendorNumber === el.vendorNumber,
                element.vendorName !== el.vendorName,
              ];
              return criteria.some((e) => e === true);
            });
          });
          return [resNew, resUpdate];
        };

        const newTasks = filterVendors(data, vendors);
        // Filtered Column Names
        setTableRows(rowsArray[0]);
        // Filtered Values
        setValues(newTasks[0]);
        setUpdates(newTasks[1]);
      },
    });
  };
  const handleSubmit = () => {
    try {
      const requestOptions = {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };
      fetch(`${API_URL}/upload/vendors`, requestOptions).then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      });
      if (updates.length > 0) {
        const requestOptions2 = {
          method: "PUT",
          withCredentials: true,
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updates),
        };
        fetch(`${API_URL}/update/vendors`, requestOptions2).then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          return response.json();
        });
      }
      setDoneUploading(true);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (doneUploading) {
      navigate("/vendors");
    }
  }, [doneUploading]);

  return (
    <div>
      <h1>Upload Vendors</h1>
      <ButtonDiv>
        <div className="button-wrap">
          <label className="button" htmlFor="upload">
            Choose File
          </label>
          <input
            type={"file"}
            name="file"
            accept=".csv"
            onChange={(e) => handleFile(e)}
            className="upload"
            id="upload"
          />
        </div>
      </ButtonDiv>
      <div style={{ height: "25px", margin: "20px" }}>
        {error && <span style={{ color: "red" }}>{error}</span>}
      </div>
      <div>
        {(values.length > 0 || updates.length > 0) && (
          <Button onClick={handleSubmit}>
            {values.length > 0 && "Add"}
            {values.length > 0 && updates.length > 0 && " and "}
            {updates.length > 0 && "Update"} Vendor
            {values.length > 1 && "s"}
          </Button>
        )}
      </div>
      <br />
      <div style={{ margin: "auto 0px", display: "inline-block" }}>
        {values && (
          <table>
            <thead>
              <tr>
                {(values.length > 1 || updates.length > 1) && <th>Type</th>}
                {tableRows.map((rows, index) => {
                  return <th key={index}>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {updates.map((update) => (
                <tr key={update.vendorNumber}>
                  <td>Update</td>
                  <td>{update.vendorNumber}</td>
                  <td>{update.vendorName}</td>
                </tr>
              ))}
              {values.map((value) => (
                <tr key={value.vendorNumber}>
                  <td>New</td>
                  <td>{value.vendorNumber}</td>
                  <td>{value.vendorName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UploadVendors;
