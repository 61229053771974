import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faFile } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../../../env";

const RFQTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  .header {
    background: black;
    color: white;
  }
  .rfq-row:hover {
    background-color: gainsboro;
  }
  .rfq-row:nth-child(even) {
    background-color: #f0f0f0;
  }
  .rfq-row:nth-child(even):hover {
    background-color: gainsboro;
  }

  .flag {
    opacity: 20%;
  }
  .flagged {
    opacity: 100%;
  }
  .flag:hover {
    opacity: 100%;
  }
  .sub-header {
    background: #bed2ff;
    cursor: pointer;
  }
  .quote-row:hover {
    background-color: #dee8ff;
  }
  .quote-row:nth-child(even) {
    background-color: #e9f0ff;
  }
  .quote-row:nth-child(even):hover {
    background-color: #dee8ff;
  }
`;

const RFQDashboard = ({ requestForQuotes, setRequestForQuotes }) => {
  const [expandedRFQ, setExpandedRFQ] = useState([]);

  const handleFlagChange = (purchaseOrderId, prevFlagState, index) => {
    setRequestForQuotes((prevState) => {
      const newState = [...prevState];
      newState[index].flagged = !prevFlagState;
      return newState;
    });
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ flagged: !prevFlagState }),
    };
    fetch(
      `${API_URL}/requestforquotes/${purchaseOrderId}/flag`,
      requestOptions
    ).then((res) => {
      {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
      }
    });
  };

  const handleExpandRow = (id) => {
    if (expandedRFQ.includes(id)) {
      setExpandedRFQ((prevState) => {
        const newState = [...prevState];
        newState.splice(expandedRFQ.indexOf(id), 1);
        return newState;
      });
    } else {
      setExpandedRFQ((prevState) => [...prevState, id]);
    }
  };
  return (
    <div>
      <RFQTable>
        <thead className="header">
          <tr>
            <th>RFQ#</th>
            <th>Status</th>
            <th>Requested</th>
            <th>Bid By</th>
            <th>Quotes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {requestForQuotes.map((requestForQuote, index) => (
            <Fragment key={requestForQuote.id}>
              <tr className="rfq-row" style={{ cursor: "pointer" }}>
                <td
                  style={{ width: "10%" }}
                  onClick={() =>
                    window.open(
                      `/purchaseorder/${requestForQuote.id}`,
                      "_blank"
                    )
                  }
                >
                  {requestForQuote.requestForQuoteNumber}
                </td>
                <td
                  style={{ width: "10%", textTransform: "capitalize" }}
                  onClick={() =>
                    window.open(`/rfq/${requestForQuote.id}`, "_blank")
                  }
                >
                  {requestForQuote.orderState.replace("_", " ").toLowerCase()}
                </td>
                <td
                  style={{ width: "10%" }}
                  onClick={() =>
                    window.open(`/rfq/${requestForQuote.id}`, "_blank")
                  }
                >
                  {dayjs(requestForQuote.createdAt).format("MM/DD/YYYY")}
                </td>
                <td
                  style={{ width: "15%" }}
                  onClick={() =>
                    window.open(`/rfq/${requestForQuote.id}`, "_blank")
                  }
                >
                  {`${dayjs(requestForQuote.bidByDate).format("MM/DD/YYYY")}`}
                </td>
                <td
                  style={{
                    width: "10%",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                  onClick={() => handleExpandRow(requestForQuote.id)}
                >
                  {requestForQuote.quotes.length > 0 &&
                    `${
                      expandedRFQ.includes(requestForQuote.id) ? "Hide" : "View"
                    } ${requestForQuote.quotes.length} Quote${
                      requestForQuote.quotes.length > 1 ? "s" : ""
                    }`}
                </td>
                <td
                  style={{
                    width: "5%",
                  }}
                  className={`${requestForQuote.flagged ? "flagged" : "flag"}`}
                  onClick={() =>
                    handleFlagChange(
                      requestForQuote.id,
                      requestForQuote.flagged,
                      index
                    )
                  }
                >
                  <FontAwesomeIcon icon={faFlag} />
                </td>
              </tr>
              {expandedRFQ.includes(requestForQuote.id) && (
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td colSpan={6}>
                    <RFQTable>
                      <thead
                        className="sub-header"
                        onClick={() => handleExpandRow(requestForQuote.id)}
                      >
                        <tr>
                          <th>Vendor</th>
                          <th>Total</th>
                          <th>Attachment</th>
                          <th>PO#</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestForQuote.quotes.map((quote) => (
                          <tr
                            key={quote.id}
                            className="quote-row"
                            onClick={() =>
                              window.open(
                                `/rfq/${requestForQuote.id}`,
                                "_blank"
                              )
                            }
                          >
                            <td style={{ width: "25%", cursor: "pointer" }}>
                              {quote.vendor.vendorName}
                            </td>
                            <td style={{ width: "25%", cursor: "pointer" }}>
                              {quote?.total}
                            </td>
                            <td style={{ width: "25%", cursor: "pointer" }}>
                              {quote.attachments && (
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  icon={faFile}
                                />
                              )}
                            </td>
                            <td style={{ width: "25%", cursor: "pointer" }}>
                              {quote?.order?.purchaseOrderNumber}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </RFQTable>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </RFQTable>
    </div>
  );
};

RFQDashboard.propTypes = {
  requestForQuotes: PropTypes.array,
  setRequestForQuotes: PropTypes.func,
};

export default RFQDashboard;
