import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import MultipleTime from "./MultipleTime";
import SingleTime from "./SingleTime";
import AllocateTime from "./AllocateTime";

const SinglePanel = styled.div`
  .visible-panel {
    @keyframes moveup {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    @keyframes moveupinvisible {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    width: calc(83.33% + 15px);
    min-height: 55vh;
    border: 1px solid black;
    padding-bottom: 20px;
    border-bottom: none;
    background-color: white;
    bottom: -100%;
    margin: 0 auto;
    display: block;
    position: fixed;
    animation-name: moveup;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 10;
    .close-button {
      background-color: gray;
      border: 1px gray solid;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      color: white;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .invisible-panel {
    display: block;
    position: fixed;
    left: 0;
    color: transparent;
    width: 100%;
    height: 100%;
    animation-name: moveupinvisible;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 5;
    cursor: pointer;
  }
`;
const MultiplePanel = styled.div`
  .visible-panel {
    @keyframes moveup {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    @keyframes moveupinvisible {
      from {
        bottom: -100%;
      }
      to {
        bottom: 0;
      }
    }
    width: 84%;
    min-height: 55vh;
    border: 1px solid black;
    border-bottom: none;
    background-color: white;
    bottom: -100%;
    margin: 0 auto;
    display: block;
    position: fixed;
    animation-name: moveup;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 10;
    .close-button {
      margin-left: calc(100% - 31px);
      background-color: gray;
      border: 1px gray solid;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      color: white;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .invisible-panel {
    display: block;
    position: fixed;
    left: 0;
    color: transparent;
    width: 100%;
    height: 100%;
    animation-name: moveupinvisible;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    z-index: 5;
    cursor: pointer;
  }
`;

const EditTimePanel = ({
  editTime,
  onDeselectTime,
  onDeselectBatch,
  onEditTime,
  onDeleteTime,
  editBatch,
  onEditBatch,
  setOnToggleConfirm,
  allocateBatch,
  onAllocateBatch,
  onSubmitAllocate,
  jobs,
  tasks,
}) => {
  return editTime ? (
    <SinglePanel>
      <div className="visible-panel">
        <button onClick={onDeselectTime} className="close-button">
          <FontAwesomeIcon icon={faX} />
        </button>
        {allocateBatch.length > 0 ? (
          <AllocateTime
            allocateBatch={allocateBatch}
            onAllocateBatch={onAllocateBatch}
            onSubmitAllocate={onSubmitAllocate}
            onDeselectTime={onDeselectTime}
            jobs={jobs}
            tasks={tasks}
          />
        ) : (
          <SingleTime
            editTime={editTime}
            onAllocateBatch={onAllocateBatch}
            onDeselectTime={onDeselectTime}
            onEditTime={onEditTime}
            onDeleteTime={onDeleteTime}
            jobs={jobs}
            tasks={tasks}
          />
        )}
      </div>
      <div onClick={onDeselectTime} className="invisible-panel" />
    </SinglePanel>
  ) : (
    <MultiplePanel>
      <div className="visible-panel">
        <button onClick={onDeselectBatch} className="close-button">
          <FontAwesomeIcon icon={faX} />
        </button>
        {allocateBatch.length > 0 ? (
          <AllocateTime
            allocateBatch={allocateBatch}
            onAllocateBatch={onAllocateBatch}
            onSubmitAllocate={onSubmitAllocate}
            onDeselectTime={onDeselectTime}
            jobs={jobs}
            tasks={tasks}
          />
        ) : (
          <MultipleTime
            editBatch={editBatch}
            onAllocateBatch={onAllocateBatch}
            onDeselectBatch={onDeselectBatch}
            onEditBatch={onEditBatch}
            setOnToggleConfirm={setOnToggleConfirm}
            jobs={jobs}
            tasks={tasks}
          />
        )}
      </div>
      <div onClick={onDeselectBatch} className="invisible-panel" />
    </MultiplePanel>
  );
};

EditTimePanel.propTypes = {
  editTime: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  editBatch: PropTypes.array,
  onDeselectTime: PropTypes.func,
  onDeselectBatch: PropTypes.func,
  onEditTime: PropTypes.func,
  onEditBatch: PropTypes.func,
  onDeleteTime: PropTypes.func,
  setOnToggleConfirm: PropTypes.func,
  onAllocateBatch: PropTypes.func,
  onSubmitAllocate: PropTypes.func,
  allocateBatch: PropTypes.array,
  jobs: PropTypes.array,
  tasks: PropTypes.array,
};

export default EditTimePanel;
