import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const ToolCategoryForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      grid-row: 4;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddToolCategoryForm = () => {
  const [state, setState] = useState({
    name: "",
  });

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ [name]: value });
  };

  const handleSubmit = () => {
    const formData = state;
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...formData }),
    };
    fetch(`${API_URL}/category`, requestOptions).then((response) =>
      response.json()
    );
  };
  const { name } = state;

  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Category
      </h1>
      <div>
        <ToolCategoryForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/tool/categories");
          }}
        >
          <fieldset>
            <div className="fields">
              <label htmlFor="name" className="name">
                Tool Category Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Tool Category Name*"
                  required
                  autoComplete="none"
                />
              </label>
              <Button type="submit">Add Tool Category</Button>
            </div>
          </fieldset>
        </ToolCategoryForm>
      </div>
    </div>
  );
};

export default AddToolCategoryForm;
