import styled from "styled-components";

const ListContainer = styled.div`
  .page-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    h3 {
      font-size: 1.5rem;
    }
    p {
      font-weight: bold;
    }
  }
`;

const List = styled.table`
  width: 100%;
  th {
    height: 40px;
    background: gray;
    color: white;
    text-align: center;
  }
  td {
    text-align: center;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  a {
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const Button = styled.button`
  width: auto;
  background: #032bdf;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  :disabled {
    background: lightgray;
    color: gray;
    cursor: default;
  }
`;

const RedButton = styled.button`
  width: auto;
  background: red;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  :disabled {
    background: lightgray;
    color: gray;
    cursor: default;
  }
`;

const BlackButton = styled.button`
  width: auto;
  background: black;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  :disabled {
    background: lightgray;
    color: gray;
    cursor: default;
  }
`;

export { ListContainer, List, Button, RedButton, BlackButton };
