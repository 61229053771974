import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
// import { startOfDay, endOfDay } from "date-fns";
import dayjs from "dayjs";
import styled from "styled-components";
import { Button } from "../../../styles/Style";
import logo from "../../../static/champion_logo_small.png";
import { API_URL } from "../../../env";

const ButtonContainerDiv = styled.div`
  @media print {
    display: none;
  }
  margin: 5px;
  button {
    margin: 5px;
    width: 125px;
  }
`;

const WorkOrderContainer = styled.div`
  @media print {
    button {
      display: none !important;
    }
    .no-border-print,
    select {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      appearance: none !important;
    }
    th {
      background: white !important;
      color: black !important;
    }
  }
  .do-not-print {
    @media print {
      display: none;
      button {
        display: none !important;
      }
      .no-border-print,
      select {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        appearance: none !important;
      }
    }
  }
  .images {
    width: 100%;
    display: flex;
    flex-flow: row;
    td {
      width: 100%;
      img {
        max-width: 50%;
      }
    }
  }
  table {
    margin: 0px auto;
    border-collapse: collapse;
    border-spacing: 0px;
    border: 1px solid black;
    width: 800px;
    input {
      font-size: 14px;
    }
    .champion-logo {
      width: 400px;
    }
    th {
      font-weight: bold;
      background-color: black;
      color: white;
    }
    input,
    select {
      margin-left: 5px;
    }
    .job {
      width: 70%;
    }
    .material {
      width: 20%;
    }
    .complete {
    }
    .description {
      textarea {
        width: 98%;
        border: none;
        resize: none;
        font-size: 14px;
      }
    }
  }
  .bordered-table {
    th,
    td {
      border-collapse: collapse;
      border-spacing: 0px;
      border: 1px solid black;
    }
    .date {
      border: none;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 3fr;
      div {
        input {
          width: 80px;
        }
      }
    }
  }
`;

const EditWorkOrder = () => {
  const [state, setState] = useState({
    workOrder: "",
  });
  const [updatedWorkOrder, setUpdatedWorkOrder] = useState({});
  const [combinedWorkOrderNumber, setCombinedWorkOrderNumber] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [parsedMaterial, setParsedMaterial] = useState([]);
  const [showRelated, setShowRelated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [workordersToCombine, setWorkOrdersToCombine] = useState([]);
  const [addWorkOrder, setAddWorkOrder] = useState("");

  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (state.workOrder.material) {
      setParsedMaterial(JSON.parse(state.workOrder.material));
    }
  }, [state.workOrder.material]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/workorders/id/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => setState({ workOrder: json }))
      .then(() => setLoading(false));
    fetch(`${API_URL}/jobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
    fetch(`${API_URL}/customers`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCustomers(json));
    fetch(`${API_URL}/form/workorder/findcombined/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => setCombinedWorkOrderNumber(json));
  }, [id]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    state.workOrder.jobId &&
      fetch(`${API_URL}/workorders/${state.workOrder.jobId}`, requestOptions)
        .then((res) => res.json())
        .then((json) => setWorkOrdersToCombine(json.FieldWorkOrder));
  }, [state.workOrder.jobId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedWorkOrder((prevState) => ({
      ...prevState.updatedWorkOrder,
      [name]: value,
    }));
  };

  const handleAddWorkOrder = () => {
    setUpdatedWorkOrder((prevState) => {
      const test = prevState;
      const newState = {
        ...test,
        relatedWorkOrders:
          test?.relatedWorkOrders?.length > 0
            ? [...test.relatedWorkOrders, Number(addWorkOrder)]
            : [...state.workOrder.relatedWorkOrders, Number(addWorkOrder)],
      };
      return newState;
    });
    setAddWorkOrder("");
  };

  const handleModifiedLaborChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value);
    setUpdatedWorkOrder((prevState) => {
      const modifiedLabor = prevState?.modifiedLabor
        ? prevState.modifiedLabor
        : JSON.parse(state.workOrder.modifiedLabor);
      modifiedLabor[index][name] = value;
      return {
        ...prevState.updatedWorkOrder,
        modifiedLabor: modifiedLabor,
      };
    });
  };
  const handleMaterialChange = (e, index, type) => {
    const { value } = e.target;
    setParsedMaterial((prevState) => {
      const newArray = prevState;
      newArray[index][type] = value;
      return [...newArray];
    });
  };
  // const handleGetLabor = (date) => {
  //   const requestOptions = {
  //     method: "GET",
  //     withCredentials: true,
  //     credentials: "include",
  //     headers: { "Content-Type": "application/json" },
  //   };
  //   fetch(
  //     `${API_URL}/clockactions/${startOfDay(date).toISOString()}/${endOfDay(
  //       date
  //     ).toISOString()}/${state.workOrder.jobId}`,
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((json) => setState((prevState) => ({ ...prevState, labor: json })));
  // };
  const handleDateChange = (value) => {
    setUpdatedWorkOrder((prevState) => ({
      ...prevState,
      date: new Date(value).toISOString(),
    }));
  };

  const handleSubmittedAtDateChange = (value) => {
    setUpdatedWorkOrder((prevState) => ({ ...prevState, submittedAt: value }));
  };

  const handleDeleteMaterial = (e, index) => {
    e.preventDefault();
    setParsedMaterial((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });
  };

  const onDeleteWorkOrder = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(`${API_URL}/delete/form/workorder/${id}`, requestOptions)
        .then((res) => {
          {
            if (res.status !== 200) {
              throw new Error(res.statusText);
            }
            return res.json();
          }
        })
        .then(navigate("/forms/workorders"));
    } catch (error) {
      console.log(error.message);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...updatedWorkOrder,
        material: JSON.stringify(parsedMaterial),
      }),
    };
    await fetch(
      `${API_URL}/edit/form/workorder/${state.workOrder.id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(navigate("/forms/workorders"));
  };

  return (
    !loading && (
      <WorkOrderContainer>
        <ButtonContainerDiv>
          <Button onClick={onSubmit}>Save</Button>
          <Button onClick={() => onDeleteWorkOrder(state.workOrder.id)}>
            Delete
          </Button>
          <Button
            onClick={() => {
              window.open(
                `${API_URL}/form/workorder/pdf/${state.workOrder.id}`,
                "_blank"
              );
            }}
          >
            View PDF
          </Button>
          {combinedWorkOrderNumber && (
            <Button
              onClick={() =>
                navigate(`/forms/workorder/pdf/${combinedWorkOrderNumber.id}`)
              }
            >
              {`Combo WO# ${combinedWorkOrderNumber.id}`}
            </Button>
          )}
        </ButtonContainerDiv>
        <table>
          <tbody>
            <tr>
              <td className="title" style={{ textAlign: "left" }}>
                <img src={logo} alt="champion-logo" className="champion-logo" />
                <div style={{ marginLeft: "10px" }}>
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "0px",
                      marginTop: "-15px",
                    }}
                  >
                    State Electrical License # EC 13005014
                  </p>
                  <h2
                    style={{
                      textTransform: "uppercase",
                      margin: "0px",
                      marginTop: "10px",
                    }}
                  >
                    Field Work Order
                  </h2>
                </div>
              </td>
              <td style={{ fontSize: "16px", fontWeight: "bold" }}>
                <span>2875 Jupiter Park Dr. Ste 500</span>
                <br />
                <span>Jupiter, Florida 33458</span>
                <br />
                <span>{"TEL: (561)296-4144"}</span>
                <br />
                <span>{"FAX: (561)296-4166"}</span>
                <br />
                <br />
                <span>W/O# </span>
                <span style={{ textDecoration: "underline" }}>
                  <span style={{ color: "red" }}>{state.workOrder.id}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="bordered-table labor-table">
          <tbody>
            <tr>
              <td className="job">
                Project:
                <select
                  value={
                    updatedWorkOrder.jobId
                      ? updatedWorkOrder.jobId
                      : state.workOrder.jobId
                  }
                  name="jobId"
                  id="jobId"
                  required
                  onChange={handleChange}
                >
                  {jobs &&
                    jobs.map((job) => (
                      <option key={job.id} value={job.id}>
                        {job.jobNumber} - {job.jobName}
                      </option>
                    ))}
                </select>
              </td>
              <td className="complete">
                Complete:
                <input
                  type="checkbox"
                  checked={
                    updatedWorkOrder.complete !== undefined
                      ? updatedWorkOrder.complete === true
                      : state.workOrder.complete === true
                  }
                  onChange={() => {
                    setUpdatedWorkOrder((prevState) => ({
                      ...prevState,
                      complete: true,
                    }));
                  }}
                />
                Y
                <input
                  type="checkbox"
                  checked={
                    updatedWorkOrder.complete !== undefined
                      ? updatedWorkOrder.complete === false
                      : state.workOrder.complete === false
                  }
                  onChange={() => {
                    setUpdatedWorkOrder((prevState) => ({
                      ...prevState,
                      complete: false,
                    }));
                  }}
                />
                N
              </td>
            </tr>
            <tr>
              <td className="customer">
                Customer:
                <select
                  value={
                    updatedWorkOrder.customerId
                      ? updatedWorkOrder.customerId
                      : state.workOrder.customerId
                  }
                  name="customerId"
                  id="customerId"
                  onChange={handleChange}
                >
                  <option defaultValue>Select a Customer</option>
                  {customers &&
                    customers.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                </select>
              </td>
              <td className="date">
                Date:
                <DatePicker
                  className="no-border-print"
                  selected={
                    updatedWorkOrder.date
                      ? new Date(updatedWorkOrder.date)
                      : new Date(state.workOrder.date)
                  }
                  onChange={handleDateChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                Submitted At Date:
                <DatePicker
                  className="no-border-print"
                  selected={
                    updatedWorkOrder?.submittedAt
                      ? updatedWorkOrder.submittedAt
                      : new Date(state.workOrder.submittedAt)
                  }
                  onChange={handleSubmittedAtDateChange}
                />
              </td>
              <td>
                Submitted:{" "}
                <input
                  type="checkbox"
                  checked={
                    updatedWorkOrder?.submitted !== undefined
                      ? updatedWorkOrder.submitted
                      : state.workOrder.submitted
                  }
                  onChange={(e) => {
                    setUpdatedWorkOrder((prevState) => {
                      return {
                        ...prevState,
                        submitted: e.target.checked,
                        submittedAt: null,
                      };
                    });
                  }}
                />
              </td>
            </tr>
            <tr style={{ cursor: "pointer" }}>
              <th onClick={() => setShowRelated(!showRelated)} colSpan={2}>
                Related Work Orders
              </th>
            </tr>
            {showRelated && (
              <tr>
                <td>
                  Combined Work Order{" "}
                  <input
                    type="checkbox"
                    checked={
                      updatedWorkOrder?.combinedWorkOrder !== undefined
                        ? updatedWorkOrder.combinedWorkOrder
                        : state.workOrder.combinedWorkOrder
                    }
                    onChange={(e) => {
                      setUpdatedWorkOrder((prevState) => {
                        return {
                          ...prevState,
                          combinedWorkOrder: e.target.checked,
                        };
                      });
                    }}
                  />
                  {(updatedWorkOrder?.combinedWorkOrder !== undefined
                    ? updatedWorkOrder.combinedWorkOrder
                    : state.workOrder.combinedWorkOrder) && (
                    <div>
                      <select
                        value={addWorkOrder}
                        onChange={(e) => setAddWorkOrder(e.target.value)}
                      >
                        <option defaultValue>Add A WO</option>
                        {workordersToCombine &&
                          workordersToCombine.map((wo) => (
                            <option key={wo.id} value={wo.id}>
                              {wo.id}
                            </option>
                          ))}
                      </select>
                      <button onClick={() => handleAddWorkOrder()}>
                        Add WO
                      </button>
                    </div>
                  )}
                </td>
                <td>
                  <ul style={{ listStyle: "none" }}>
                    {updatedWorkOrder.relatedWorkOrders
                      ? updatedWorkOrder.relatedWorkOrders.map(
                          (related, index) => (
                            <li key={index}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={true}
                                  onChange={() => {
                                    setUpdatedWorkOrder((prevState) => {
                                      const updatedState = [
                                        ...prevState.relatedWorkOrders,
                                      ];
                                      updatedState.splice(index, 1);
                                      return {
                                        ...prevState,
                                        relatedWorkOrders: updatedState,
                                      };
                                    });
                                  }}
                                />
                                <span>{related}</span>
                              </div>
                            </li>
                          )
                        )
                      : state.workOrder.relatedWorkOrders.map(
                          (related, index) => (
                            <li key={index}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={true}
                                  onChange={() => {
                                    setUpdatedWorkOrder((prevState) => {
                                      const updatedState = [
                                        ...state.workOrder.relatedWorkOrders,
                                      ];
                                      updatedState.splice(index, 1);
                                      return {
                                        ...prevState,
                                        relatedWorkOrders: updatedState,
                                      };
                                    });
                                  }}
                                />
                                <span>{related}</span>
                              </div>
                            </li>
                          )
                        )}
                  </ul>
                </td>
              </tr>
            )}
            <tr>
              <th colSpan={2}>Description of Work</th>
            </tr>

            <tr>
              <td className="description" colSpan={2}>
                <textarea
                  type="text"
                  value={
                    updatedWorkOrder.workDescription
                      ? updatedWorkOrder.workDescription
                      : state.workOrder.workDescription
                  }
                  name="workDescription"
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th>Labor</th>
              <th>Hours</th>
            </tr>
            {state.workOrder.labor.length > 0
              ? state.workOrder.labor.map((row) => (
                  <tr key={row.id}>
                    <td>
                      {row.user.firstName} {row.user.lastName}
                    </td>
                    <td>
                      {Math.round(
                        (
                          dayjs(row.clockOut).diff(dayjs(row.clockIn)) / 3600000
                        ).toFixed(2)
                      )}
                    </td>
                  </tr>
                ))
              : updatedWorkOrder?.modifiedLabor
              ? updatedWorkOrder.modifiedLabor.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        name="employee.name"
                        value={row.employee.name}
                        onChange={(e) => handleModifiedLaborChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        name="hours"
                        value={row.hours}
                        onChange={(e) => handleModifiedLaborChange(e, index)}
                      />
                    </td>
                  </tr>
                ))
              : JSON.parse(state.workOrder.modifiedLabor).map((row, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        name="employee.name"
                        value={row.employee.name}
                        onChange={(e) => handleModifiedLaborChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        name="hours"
                        value={Number(row.hours).toFixed(2)}
                        onChange={(e) => handleModifiedLaborChange(e, index)}
                      />
                    </td>
                  </tr>
                ))}
            {state.workOrder.labor.length > 0 && (
              <tr>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "lightgray",
                  }}
                >
                  Total
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "lightgray",
                  }}
                >
                  {state.workOrder.labor.length > 1
                    ? state.workOrder.labor
                        .reduce(
                          (a, b) =>
                            Math.round(
                              dayjs(a.clockOut).diff(dayjs(a.clockIn)) / 3600000
                            ) +
                            Math.round(
                              dayjs(b.clockOut).diff(dayjs(b.clockIn)) / 3600000
                            )
                        )
                        .toFixed(2)
                    : Math.round(
                        (
                          dayjs(state.workOrder.labor[0].clockOut).diff(
                            state.workOrder.labor[0].clockIn
                          ) / 3600000
                        ).toFixed(2)
                      )}
                </td>
              </tr>
            )}
            <tr className="do-not-print">
              <td colSpan={2}>
                <Button
                  onClick={() =>
                    setUpdatedWorkOrder(() => {
                      const test = JSON.parse(state.workOrder.modifiedLabor);
                      return {
                        modifiedLabor: [
                          ...test,
                          { employee: { id: "", name: "" }, hours: "0" },
                        ],
                      };
                      /* else {
                        return {
                          ...prevState,
                          modifiedLabor: [
                            ...prevState.modifiedLabor,
                            {
                              employee: {
                                id: "",
                                name: "",
                              },
                              hours: "",
                            },
                          ],
                        };
                      } */
                    })
                  }
                >
                  Add Labor
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="bordered-table material-table">
          <tbody>
            <tr>
              <th
                style={{
                  fontWeight: "bold",
                  backgroundColor: "black",
                  color: "white",
                }}
                className="material"
              >
                Quantity
              </th>
              <th
                style={{
                  fontWeight: "bold",
                  backgroundColor: "black",
                  color: "white",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                Material
              </th>
            </tr>
            {parsedMaterial.length > 0 ? (
              parsedMaterial.map((material, index) => (
                <tr key={index}>
                  <td>
                    <input
                      onChange={(e) => handleMaterialChange(e, index, 0)}
                      defaultValue={material[0]}
                      name="material"
                      type="number"
                      style={{
                        height: "95%",
                        margin: "0px",
                        padding: "0px",
                        outline: "none",
                        border: "none",
                        textAlign: "center",
                      }}
                    />
                  </td>
                  <td style={{ height: "25px" }}>
                    <input
                      onChange={(e) => handleMaterialChange(e, index, 1)}
                      defaultValue={material[1]}
                      name="material"
                      type="text"
                      style={{
                        width: "95%",
                        height: "95%",
                        margin: "0px",
                        padding: "0px",
                        outline: "none",
                        border: "none",
                      }}
                    />
                    <button
                      onClick={(e) => handleDeleteMaterial(e, index)}
                      className="do-not-print"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      x
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
            <tr className="do-not-print">
              <td colSpan={2}>
                <Button
                  onClick={() =>
                    setParsedMaterial((prevState) => [...prevState, ["", ""]])
                  }
                >
                  Add Material
                </Button>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Images</th>
            </tr>
          </tbody>
        </table>
        <table className="bordered-table">
          <tbody>
            {state.workOrder.images && (
              <tr className="images">
                {state.workOrder.images.map((image, index) => (
                  <td key={index}>
                    <img src={image} />
                  </td>
                ))}
              </tr>
            )}
            <tr>
              <th colSpan={2}>Signature</th>
            </tr>
            {state.workOrder.signature && (
              <tr>
                <td colSpan={2} style={{ height: "25px" }}>
                  <img src={state.workOrder.signature} height="150px" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ButtonContainerDiv>
          <Button onClick={onSubmit}>Save</Button>
        </ButtonContainerDiv>
      </WorkOrderContainer>
    )
  );
};

EditWorkOrder.propTypes = {
  onDeselectWorkOrder: PropTypes.func,
  onDeleteWorkOrder: PropTypes.func,
  onEditWorkOrder: PropTypes.func,
};

export default EditWorkOrder;
