import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import { API_URL } from "../../../env";

const EstimateContainer = styled.div`
  margin: 0px 50px;
  margin-bottom: 50px;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  .estimate-table {
    th,
    td {
      text-align: left;
      border: 1px solid black;
    }
    tr {
      height: 50px;
    }
  }

  .itemizedTable {
    border: 1px solid black;
    border-bottom-width: 0px;
    th,
    td {
      border: 1px solid black;
      border-bottom-width: 0px;
    }
  }

  .segmentFooter {
    border: 1px solid black;
    border-bottom-width: 0px;
    font-weight: bold;
  }

  .segmentFooterTitle {
    text-align: right;
    padding-right: 20px;
  }

  .groupedFooter {
    border: 1px solid black;
    font-weight: bold;
  }

  .groupedFooterTitle {
    text-align: right;
    padding-right: 20px;
  }

  .nested-table-1st-col-td {
  }
  .nested-table-2nd-col-td {
  }
  .nested-table-3rd-col-td {
  }
`;

const ProjectBudget = ({ estimateId }) => {
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [listFormat, setListFormat] = useState("SEGMENT");
  const [groupedMaterials, setGroupedMaterials] = useState([]);
  const [costCodedMaterials, setCostCodedMaterials] = useState([]);
  const [expandedRows, setExpandedRows] = useState({ phases: [] });
  const [expandedMaterialRows, setExpandedMaterialRows] = useState([]);
  const [expandedCostCodeRows, setExpandedCostCodeRows] = useState([]);

  useEffect(() => {
    if (estimateId) {
      const requestOptions = {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      };
      fetch(`${API_URL}/estimate/${estimateId}`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setEstimate(json);
          setGroupedMaterials(
            _.chain(json.materials)
              .groupBy("itemIdNumber")
              .map((value) => {
                const quantityEstimated = value.reduce(
                  (prev, cur) => prev + cur.quantityEstimated,
                  0
                );
                const materialTotalUnitCost = value.reduce(
                  (prev, cur) =>
                    prev +
                    cur.quantityEstimated *
                      (cur.estimatedMaterialUnitPricex1M / 1000000),
                  0
                );
                const laborTotalUnitCost = value.reduce(
                  (prev, cur) =>
                    prev +
                    cur.quantityEstimated *
                      (cur.estimatedLaborUnitsx1M / 1000000),
                  0
                );
                return {
                  itemIdNumber: value[0].itemIdNumber,
                  quantityEstimated,
                  quantity: "",
                  orderedItems: value
                    .map((item) => [...item.bookedEstimatedMaterial])
                    .flat(),
                  estimatedLaborUnitsx1M: laborTotalUnitCost,
                  estimatedMaterialUnitPricex1M: materialTotalUnitCost,
                  unitOfMeasureId: value[0].unitOfMeasureId,
                  unitOfMeasure: value[0].unitOfMeasure,
                  description: value[0].description,
                  items: value.map((element) => ({ ...element, quantity: "" })),
                };
              })
              .value()
          );
          setCostCodedMaterials(
            _.chain(json.materials)
              .groupBy("taskId")
              .map((value) => {
                const materialTotalUnitCost = value.reduce(
                  (prev, cur) =>
                    prev +
                    cur.quantityEstimated *
                      (cur.estimatedMaterialUnitPricex1M / 1000000),
                  0
                );
                const laborTotalUnitCost = value.reduce(
                  (prev, cur) =>
                    prev +
                    cur.quantityEstimated *
                      (cur.estimatedLaborUnitsx1M / 1000000),
                  0
                );
                return {
                  task: `${value[0]?.task?.taskCode}-${value[0]?.task?.taskName}`,
                  taskId: value[0].taskId,
                  orderedItems: value
                    .map((item) => [...item.bookedEstimatedMaterial])
                    .flat(),
                  estimatedLaborUnitsx1M: laborTotalUnitCost,
                  estimatedMaterialUnitPricex1M: materialTotalUnitCost,
                  items: value,
                };
              })
              .value()
          );
          setLoading(false);
        });
    }
  }, [estimateId]);

  const handleRowClick = (type, id) => {
    setExpandedRows((prevExpandedRows) => {
      const updatedExpandedRows = { ...prevExpandedRows }; // Create a copy

      // Initialize updatedExpandedRows[type] if it's undefined
      if (!updatedExpandedRows[type]) {
        updatedExpandedRows[type] = [];
      }

      // Handle removal efficiently using filter
      if (updatedExpandedRows[type].includes(id)) {
        updatedExpandedRows[type] = updatedExpandedRows[type].filter(
          (itemId) => itemId !== id
        );
      } else {
        // Add the ID if not already present (assuming this is the desired behavior)
        updatedExpandedRows[type] = [...updatedExpandedRows[type], id];
      }

      return updatedExpandedRows;
    });
  };

  const handleMaterialRowClick = (id) => {
    setExpandedMaterialRows((prevExpandedRows) => {
      let updatedExpandedRows = [...prevExpandedRows]; // Create a copy

      // Handle removal efficiently using filter
      if (updatedExpandedRows.includes(id)) {
        updatedExpandedRows = updatedExpandedRows.filter(
          (itemId) => itemId !== id
        );
      } else {
        // Add the ID if not already present (assuming this is the desired behavior)
        updatedExpandedRows = [...updatedExpandedRows, id];
      }

      return updatedExpandedRows;
    });
  };

  const handleCostCodeRowClick = (id) => {
    setExpandedCostCodeRows((prevExpandedRows) => {
      let updatedExpandedRows = [...prevExpandedRows]; // Create a copy

      // Handle removal efficiently using filter
      if (updatedExpandedRows.includes(id)) {
        updatedExpandedRows = updatedExpandedRows.filter(
          (itemId) => itemId !== id
        );
      } else {
        // Add the ID if not already present (assuming this is the desired behavior)
        updatedExpandedRows = [...updatedExpandedRows, id];
      }

      return updatedExpandedRows;
    });
  };

  const renderSegment = (segmentType, segment, handleRowClick) => {
    if (!segment) return null;
    const nextSegmentType =
      segmentType === "phases"
        ? "subPhases"
        : segmentType === "subPhases"
        ? "levels"
        : segmentType === "levels"
        ? "subLevels"
        : segmentType === "subLevels"
        ? "areas"
        : "";

    return (
      <div
        key={segment.id}
        style={{ marginLeft: segmentType != "phases" ? "20px" : "0px" }}
      >
        <div
          style={{
            cursor: "pointer",
            outline: "1px solid black",
            margin: "1px",
            textAlign: "left",
            padding: "15px 20px",
          }}
          onClick={() => handleRowClick(segmentType, segment.id)}
        >
          <h3 style={{ margin: "0px" }}>{segment.name}</h3>
        </div>
        {expandedRows[segmentType]?.includes(segment.id) &&
          (segment[nextSegmentType].length > 0 ? (
            segment[nextSegmentType]?.map((child) =>
              renderSegment(nextSegmentType, child, handleRowClick)
            )
          ) : (
            <div style={{ marginLeft: "20px" }}>
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        textAlign: "center",
                        width: "10%",
                      }}
                    >
                      Qty Estimated
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        textAlign: "center",
                        width: "10%",
                      }}
                    >
                      Qty Ordered
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        textAlign: "center",
                        width: "5%",
                      }}
                    >
                      UOM
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        width: "40%",
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        width: "10%",
                      }}
                    >
                      Est Matl Cost
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        width: "10%",
                      }}
                    >
                      Est Labor Cost
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        width: "10%",
                      }}
                    >
                      Ordered Amt
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        borderTopWidth: "0px",
                        width: "15%",
                      }}
                    >
                      Amt Remaining
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {segment.materials.map((material) => (
                    <tr key={material.id}>
                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          borderBottomWidth: "0px",
                          width: "10%",
                        }}
                      >
                        {material.quantityEstimated.toLocaleString("en-US")}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          borderBottomWidth: "0px",
                          width: "10%",
                        }}
                      >
                        {material.orderedItems.reduce(
                          (prev, cur) =>
                            prev +
                            cur?.purchaseOrderItems.reduce(
                              (prev1, cur1) => prev1 + cur1.quantityOrdered,
                              0
                            ),
                          0
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          borderBottomWidth: "0px",
                          width: "5%",
                        }}
                      >
                        {material.unitOfMeasure.code}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          borderBottomWidth: "0px",
                          width: "40%",
                        }}
                      >
                        {material.description}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          borderBottomWidth: "0px",
                          width: "10%",
                        }}
                      >
                        {(
                          (material.estimatedMaterialUnitPricex1M / 1000000) *
                          material.quantityEstimated
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          borderBottomWidth: "0px",
                          width: "10%",
                        }}
                      >
                        {(
                          material.quantityEstimated *
                          ((material.estimatedLaborUnitsx1M / 1000000) *
                            Number(estimate.estimatedLaborRate))
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          borderBottomWidth: "0px",
                          width: "10%",
                        }}
                      >
                        0
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          borderBottomWidth: "0px",
                          width: "15%",
                        }}
                      >
                        0
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="segmentFooter segmentFooterTitle"
                      colSpan={4}
                    >
                      {`${segment.name} Total`}
                    </td>
                    <td className="segmentFooter">
                      {segment.materials
                        .reduce(
                          (prev, cur) =>
                            prev +
                            (cur.estimatedMaterialUnitPricex1M / 1000000) *
                              cur.quantityEstimated,
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </td>
                    <td className="segmentFooter">
                      {segment.materials
                        .reduce(
                          (prev, cur) =>
                            prev +
                            (cur.estimatedLaborUnitsx1M / 1000000) *
                              estimate.estimatedLaborRate *
                              cur.quantityEstimated,
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </td>
                    <td className="segmentFooter"></td>
                    <td className="segmentFooter"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ))}
      </div>
    );
  };

  return (
    !loading && (
      <EstimateContainer>
        <div>
          <div>
            <div>
              <button onClick={() => setListFormat("SEGMENT")}>
                View by Segment
              </button>
              <button onClick={() => setListFormat("ITEM")}>
                View by Item
              </button>
              <button onClick={() => setListFormat("TASK")}>
                View by Cost Code
              </button>
            </div>
          </div>
          <div>
            <h5>
              Estimate Total:{" "}
              {(
                estimate.phases.reduce(
                  (prev, cur) =>
                    prev +
                    cur.materials.reduce(
                      (prev1, cur1) =>
                        prev1 +
                        ((cur1.estimatedLaborUnitsx1M / 1000000) *
                          cur1.quantityEstimated *
                          Number(estimate.estimatedLaborRate) +
                          (cur1.estimatedMaterialUnitPricex1M / 1000000) *
                            1.07 *
                            cur1.quantityEstimated),
                      0
                    ),
                  0
                ) +
                JSON.parse(estimate.quotes).reduce(
                  (prev, cur) => prev + Number(cur.materialCost) * 1.07,
                  0
                ) +
                JSON.parse(estimate.allowances).reduce(
                  (prev, cur) =>
                    prev +
                    (Number(cur.materialCost) * 1.07 + Number(cur.laborCost)),
                  0
                ) +
                Number(estimate.estimatedIndirectLabor)
              ).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </h5>
          </div>
          <div style={{ textAlign: "right" }}>
            <button
              onClick={() =>
                listFormat === "SEGMENT"
                  ? setExpandedRows([])
                  : listFormat === "ITEM"
                  ? setExpandedMaterialRows([])
                  : setExpandedCostCodeRows([])
              }
            >
              Collapse All
            </button>
          </div>
        </div>
        <br />
        <div>
          {listFormat === "SEGMENT"
            ? estimate?.phases?.length > 0 &&
              estimate.phases.map((phase) =>
                renderSegment("phases", phase, handleRowClick)
              )
            : listFormat === "ITEM"
            ? groupedMaterials?.length > 0 && (
                <div>
                  <table>
                    <thead
                      style={{
                        position: "sticky",
                        top: "0",
                        background: "white",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "5%",
                          }}
                        >
                          Qty Est.
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "5%",
                          }}
                        >
                          Qty Ordered
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "5%",
                          }}
                        >
                          Order
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            width: "5%",
                          }}
                        >
                          UOM
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "10px",
                            border: "1px solid black",
                            width: "35%",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "10%",
                          }}
                        >
                          Est Matl Cost
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "10%",
                          }}
                        >
                          Est Labor Cost
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "10%",
                          }}
                        >
                          Ordered Amt
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "20%",
                          }}
                        >
                          Amt Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedMaterials
                        .sort((a, b) =>
                          a.description < b.description
                            ? -1
                            : a.description > b.description
                            ? 1
                            : 0
                        )
                        .map((material, index) => (
                          <Fragment key={material.itemIdNumber}>
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleMaterialRowClick(material.itemIdNumber)
                              }
                            >
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "5%",
                                }}
                              >
                                {material.quantityEstimated.toLocaleString(
                                  "en-US"
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "5%",
                                }}
                              >
                                {material?.orderedItems?.reduce(
                                  (prev, cur) =>
                                    prev +
                                    cur?.purchaseOrderItems.reduce(
                                      (prev1, cur1) =>
                                        prev1 + cur1.quantityOrdered,
                                      0
                                    ),
                                  0
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "5%",
                                }}
                              >
                                {material.quantity !== "" && (
                                  <>
                                    {`${material.items.reduce(
                                      (prev, cur) =>
                                        prev + Number(cur.quantity),
                                      0
                                    )}/`}{" "}
                                  </>
                                )}
                                <input
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    padding: "0px",
                                    border: "0px",
                                    outline: "none",
                                  }}
                                  type="text"
                                  value={material.quantity}
                                  onChange={(e) =>
                                    setGroupedMaterials((prevState) => {
                                      const newState = [...prevState];
                                      newState[index].quantity = e.target.value;
                                      return newState;
                                    })
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                  borderBottomWidth: "0px",
                                  width: "5%",
                                }}
                              >
                                {material.unitOfMeasure.code}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "35%",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                {material.description}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {material.estimatedMaterialUnitPricex1M.toLocaleString(
                                  "en-US",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {(
                                  material.estimatedLaborUnitsx1M *
                                  Number(estimate.estimatedLaborRate)
                                ).toLocaleString("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                0
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "20%",
                                }}
                              >
                                0
                              </td>
                            </tr>
                            {expandedMaterialRows.includes(
                              material.itemIdNumber
                            ) && (
                              <tr>
                                <td colSpan={9} style={{ paddingLeft: "20px" }}>
                                  <table className="itemizedTable">
                                    <thead
                                      style={{
                                        position: "sticky",
                                        top: "44px",
                                        background: "white",
                                      }}
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            textAlign: "left",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          Location
                                        </th>
                                        <th>Qty Est.</th>
                                        <th>Qty Ordered</th>
                                        <th>Est Mtl Cost</th>
                                        <th>Est Lab Cost</th>
                                        <th>Ordered Cost</th>
                                        <th>Amt Remaining</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {_.sortBy(material.items, (element) => [
                                        element?.phase?.name,
                                        element?.subPhase?.name,
                                        element?.level?.name,
                                        element?.subLevel?.name,
                                        element?.area?.name,
                                      ]).map((item, itemIndex) => (
                                        <tr
                                          key={`${item.itemIdNumber}-${itemIndex}`}
                                          style={{ cursor: "copy" }}
                                        >
                                          <td
                                            style={{
                                              textAlign: "left",
                                              paddingLeft: "10px",
                                            }}
                                          >{`${
                                            item?.phase?.name
                                              ? `${item.phase.name}`
                                              : ""
                                          }${
                                            item?.subPhase?.name
                                              ? ` > ${item.subPhase.name}`
                                              : ""
                                          }${
                                            item?.level?.name
                                              ? ` > ${item.level.name}`
                                              : ""
                                          }${
                                            item?.subLevel?.name
                                              ? ` > ${item.subLevel.name}`
                                              : ""
                                          }${
                                            item?.area?.name
                                              ? ` > ${item.area.name}`
                                              : ""
                                          }`}</td>
                                          <td>
                                            {item.quantityEstimated.toLocaleString(
                                              "en-US"
                                            )}
                                          </td>
                                          <td>0</td>
                                          <td>
                                            {(
                                              item.quantityEstimated *
                                              (item.estimatedMaterialUnitPricex1M /
                                                1000000)
                                            ).toLocaleString("en-US", {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            })}
                                          </td>
                                          <td>
                                            {(
                                              item.quantityEstimated *
                                              (item.estimatedLaborUnitsx1M /
                                                1000000) *
                                              Number(
                                                estimate.estimatedLaborRate
                                              )
                                            ).toLocaleString("en-US", {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            })}
                                          </td>
                                          <td>0</td>
                                          <td>0</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          className="groupedFooter groupedFooterTitle"
                          colSpan={5}
                        >
                          Total
                        </td>
                        <td className="groupedFooter">
                          {groupedMaterials
                            .reduce(
                              (prev, cur) =>
                                prev + cur.estimatedMaterialUnitPricex1M,
                              0
                            )
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                        </td>
                        <td className="groupedFooter">
                          {groupedMaterials
                            .reduce(
                              (prev, cur) =>
                                prev +
                                cur.estimatedLaborUnitsx1M *
                                  estimate.estimatedLaborRate,
                              0
                            )
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                        </td>
                        <td className="groupedFooter"></td>
                        <td className="groupedFooter"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )
            : costCodedMaterials?.length > 0 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            textAlign: "left",
                            paddingLeft: "10px",
                            width: "20%",
                          }}
                        >
                          Cost Code
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "20%",
                          }}
                        >
                          Est Matl Cost
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "20%",
                          }}
                        >
                          Est Labor Cost
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "20%",
                          }}
                        >
                          Ordered Amt
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "20%",
                          }}
                        >
                          Amt Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {costCodedMaterials
                        .sort((a, b) =>
                          a.task < b.task ? -1 : a.task > b.task ? 1 : 0
                        )
                        .map((material) => (
                          <Fragment key={material.taskId}>
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleCostCodeRowClick(material.taskId)
                              }
                            >
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {material.task}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {material.estimatedMaterialUnitPricex1M.toLocaleString(
                                  "en-US",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                {(
                                  material.estimatedLaborUnitsx1M *
                                  Number(estimate.estimatedLaborRate)
                                ).toLocaleString("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "10%",
                                }}
                              >
                                0
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  borderBottomWidth: "0px",
                                  width: "20%",
                                }}
                              >
                                0
                              </td>
                            </tr>
                            {expandedCostCodeRows.includes(material.taskId) && (
                              <tr>
                                <td colSpan={8} style={{ paddingLeft: "20px" }}>
                                  <table className="itemizedTable">
                                    <thead>
                                      <tr>
                                        <th>Qty Est.</th>
                                        <th>Qty Ordered</th>
                                        <th>Description</th>
                                        <th>Est Mtl Cost</th>
                                        <th>Est Lab Cost</th>
                                        <th>Ordered Cost</th>
                                        <th>Amt Remaining</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {material.items.map((item, index) => (
                                        <tr key={`${item.taskId}-${index}`}>
                                          <td>
                                            {item.quantityEstimated.toLocaleString(
                                              "en-US"
                                            )}
                                          </td>
                                          <td>0</td>
                                          <td>{item.description}</td>
                                          <td>
                                            {(
                                              item.quantityEstimated *
                                              (item.estimatedMaterialUnitPricex1M /
                                                1000000)
                                            ).toLocaleString("en-US", {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            })}
                                          </td>
                                          <td>
                                            {(
                                              item.quantityEstimated *
                                              (item.estimatedLaborUnitsx1M /
                                                1000000) *
                                              Number(
                                                estimate.estimatedLaborRate
                                              )
                                            ).toLocaleString("en-US", {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            })}
                                          </td>
                                          <td>0</td>
                                          <td>0</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="groupedFooter groupedFooterTitle">
                          Total
                        </td>
                        <td className="groupedFooter">
                          {costCodedMaterials
                            .reduce(
                              (prev, cur) =>
                                prev + cur.estimatedMaterialUnitPricex1M,
                              0
                            )
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                        </td>
                        <td className="groupedFooter">
                          {costCodedMaterials
                            .reduce(
                              (prev, cur) =>
                                prev +
                                cur.estimatedLaborUnitsx1M *
                                  estimate.estimatedLaborRate,
                              0
                            )
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                        </td>
                        <td className="groupedFooter"></td>
                        <td className="groupedFooter"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}
        </div>
      </EstimateContainer>
    )
  );
};

ProjectBudget.propTypes = {
  estimateId: PropTypes.string,
};

export default ProjectBudget;
