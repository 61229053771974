import styled from "styled-components";

const SearchDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 27px;
  margin-bottom: 10px;
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
    input {
      width: 250px;
      padding: 0 5px;
      border-style: solid;
      border-width: 1px;
      height: 33px;
    }
    input:focus {
      padding: 0 5px;
      outline: none;
      border: 1px solid #032bdf;
    }
    .lens {
      border: 1px solid #032bdf;
      padding: 0 10px;
      background: #032bdf;
      color: white;
      justify-content: center;
      align-content: center;
      font-size: 20px;
      cursor: pointer;
    }
    .active-filter {
      border: 1px solid #e00000;
      padding: 0 10px;
      background: #e00000;
      color: white;
      justify-content: center;
      align-content: center;
      font-size: 20px;
      cursor: pointer;
    }
    .filter {
      margin-left: 1px;
    }
  }
  .add-button {
    margin-left: auto;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: right;
    display: inline-flex;
    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const TimeSearchDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 27px;
  margin-bottom: 10px;
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
    input {
      width: 250px;
      padding: 0 5px;
      border-style: solid;
      border-width: 1px;
      height: 33px;
    }
    input:focus {
      padding: 0 5px;
      outline: none;
      border: 1px solid #032bdf;
    }
    .lens {
      border: 1px solid #032bdf;
      padding: 0 10px;
      background: #032bdf;
      color: white;
      justify-content: center;
      align-content: center;
      font-size: 20px;
      cursor: pointer;
    }
    .active-filter {
      border: 1px solid #e00000;
      padding: 0 10px;
      background: #e00000;
      color: white;
      justify-content: center;
      align-content: center;
      font-size: 20px;
      cursor: pointer;
    }
    .filter {
      margin-left: 1px;
    }
  }
  .add-button {
    margin-left: auto;
  }
  .action-buttons {
    margin-right: 1px;
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: right;
    display: inline-flex;
    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const FilterDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  width: 400px;
  background-color: white;
  padding: 10px;
  text-align: left;
  h3 {
    font-size: 25px;
    margin: 10px 0px;
  }
  .filter-item-div {
    margin: 4px 0px;
  }
  label {
    text-align: left;
    p {
      font-weight: bold;
      padding: 0px;
      margin: 0px;
    }
  }
  z-index: 99;
  border: 1px solid black;
  .clear-button {
    background-color: #032bdf;
    color: white;
    border: none;
    cursor: pointer;
    padding: 6px 0;
    margin-top: 5px;
  }
  .active-filter {
    border: 1px solid #032bdf;
    padding: 0 10px;
    background: green;
    color: white;
    justify-content: center;
    align-content: center;
    font-size: 20px;
    cursor: pointer;
  }
`;

const FilterBG = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  animation-name: moveupinvisible;
  animation-duration: 0.1s;
  animation-fill-mode: both;
  z-index: 5;
  cursor: pointer;
`;

export { SearchDiv, TimeSearchDiv, FilterBG, FilterDiv };
