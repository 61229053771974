import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const DepartmentForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    .department-name {
      grid-row-start: 1;
    }
    button {
      grid-row-start: 2;
      grid-column: 1/3;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const SingleDepartment = ({
  editDepartment,
  onEditDepartment,
  onDeselectDepartment,
}) => {
  const [state, setState] = useState({
    department: { ...editDepartment[0] },
    updatedDepartment: "",
    error: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      updatedDepartment: { ...state.updatedDepartment, [name]: value },
    });
  };

  const handleActiveChange = (e) => {
    const { checked, name } = e.target;
    setState({
      updatedDepartment: { ...state.updatedDepartment, [name]: checked },
    });
  };

  const { department, updatedDepartment } = state;
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Edit Department
        </h3>
        <DepartmentForm
          onSubmit={async (e) => {
            e.preventDefault();
            const requestOptions = {
              method: "PUT",
              withCredentials: true,
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...updatedDepartment }),
            };
            await fetch(
              `${API_URL}/update/department/${department.id}`,
              requestOptions
            ).then((res) => res.json());
            onEditDepartment(department.id, updatedDepartment);
            onDeselectDepartment();
          }}
        >
          <fieldset>
            <div className="fields">
              <div>
                <label htmlFor="active">
                  Active
                  <input
                    type="checkbox"
                    name="active"
                    id="active"
                    defaultChecked={department.active}
                    onChange={handleActiveChange}
                  />
                </label>
              </div>
              <label className="department-name">
                Department Name
                <br />
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={department.name}
                  onChange={handleChange}
                  placeholder="Department Name*"
                  required
                  autoComplete="none"
                />
              </label>
              {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
              <Button type="submit">Save Changes</Button>
            </div>
          </fieldset>
        </DepartmentForm>
      </div>
    </div>
  );
};

SingleDepartment.propTypes = {
  id: PropTypes.string,
  editDepartment: PropTypes.array,
  onEditDepartment: PropTypes.func,
  onDeselectDepartment: PropTypes.func,
};

export default SingleDepartment;
