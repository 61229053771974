import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { API_URL } from "../../env";
import { Button } from "../../styles/Style";

const BenefitAdmin = () => {
  const [eligibleEmployees, setEligibleEmployees] = useState([]);
  const [benefitPlans, setBenefitPlans] = useState(null);
  const [openEnrollmentPlans, setOpenEnrollmentPlans] = useState([]);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/benefit-eligible-employees`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        setEligibleEmployees(json.eligibleEmployees);
        setBenefitPlans(json.benefitPlanInfo);
      });
    fetch(`${API_URL}/benefit-plans/open-enrollment`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        setOpenEnrollmentPlans(json);
      });
  }, []);

  return (
    <div>
      <h3>Benefit Enrollment Settings</h3>
      <div>
        <h4>Current Plans With Open Enrollment</h4>
        <table style={{ margin: "auto" }}>
          <thead>
            <tr>
              <th>Plan</th>
              <th>Open Enroll Start</th>
              <th>Open Enroll End</th>
              <th>Coverage Period</th>
            </tr>
          </thead>
          <tbody>
            {openEnrollmentPlans.map((plan) => (
              <tr key={plan.id}>
                <td>{plan.planName}</td>
                <td>{dayjs(plan.openEnrollStart).format("M/D/YYYY")}</td>
                <td>{dayjs(plan.openEnrollEnd).format("M/D/YYYY")}</td>
                <td>{`${dayjs(plan.planStartDate).format(
                  "M/D/YYYY"
                )} to ${dayjs(plan.planEndDate).format("M/D/YYYY")}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ margin: "10px" }}>
          <Button>Send Open Enrollment Emails</Button>
        </div>
      </div>
      <div>
        <h4>{`${eligibleEmployees.reduce(
          (prev, cur) =>
            prev +
            ((dayjs(cur.hireDate).add(
              benefitPlans.eligibleDaysAfterHire,
              "days"
            ).date === 1
              ? dayjs(cur.hireDate)
                  .add(benefitPlans.eligibleDaysAfterHire, "days")
                  .startOf("month")
              : dayjs(cur.hireDate)
                  .add(benefitPlans.eligibleDaysAfterHire, "days")
                  .add(1, "month")
                  .startOf("month")
            ).isBefore(dayjs())
              ? 1
              : 0),
          0
        )} Eligible Employees`}</h4>
        <div>
          <table style={{ margin: "auto", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "0px 20px" }}>Name</th>
                <th style={{ padding: "0px 20px" }}>Eligible</th>
                <th style={{ padding: "0px 20px" }}>Enrolled</th>
                <th style={{ padding: "0px 20px" }}>Waived</th>
                <th style={{ padding: "0px 20px" }}>Eligible Date</th>
                <th style={{ padding: "0px 20px" }}></th>
              </tr>
            </thead>
            <tbody>
              {eligibleEmployees.map((employee) => {
                const eligibleDate =
                  dayjs(employee.hireDate).add(
                    benefitPlans.eligibleDaysAfterHire,
                    "days"
                  ).date === 1
                    ? dayjs(employee.hireDate)
                        .add(benefitPlans.eligibleDaysAfterHire, "days")
                        .startOf("month")
                        .format("M/D/YYYY")
                    : dayjs(employee.hireDate)
                        .add(benefitPlans.eligibleDaysAfterHire, "days")
                        .add(1, "month")
                        .startOf("month")
                        .format("M/D/YYYY");
                return (
                  <Fragment key={employee.id}>
                    <tr style={{ height: "5px" }} />
                    <tr
                      style={{
                        background: `${
                          dayjs(eligibleDate).diff(dayjs(), "days") > 0 &&
                          dayjs(eligibleDate).diff(dayjs(), "days") < 30
                            ? "yellow"
                            : "white"
                        }`,
                      }}
                    >
                      <td
                        style={{ padding: "0px 20px" }}
                      >{`${employee.firstName} ${employee.lastName}`}</td>
                      <td style={{ padding: "0px 10px" }}>
                        {(dayjs(employee.hireDate).add(
                          benefitPlans.eligibleDaysAfterHire,
                          "days"
                        ).date === 1
                          ? dayjs(employee.hireDate)
                              .add(benefitPlans.eligibleDaysAfterHire, "days")
                              .startOf("month")
                          : dayjs(employee.hireDate)
                              .add(benefitPlans.eligibleDaysAfterHire, "days")
                              .add(1, "month")
                              .startOf("month")
                        ).isBefore(dayjs())
                          ? "Yes"
                          : "No"}
                      </td>
                      <td>
                        {employee.employeeBenefits.reduce(
                          (prev, cur) => prev + (cur.waived === false ? 1 : 0),
                          0
                        )}
                      </td>
                      <td>
                        {employee.employeeBenefits.reduce(
                          (prev, cur) => prev + (cur.waived === true ? 1 : 0),
                          0
                        )}
                      </td>
                      <td
                        style={{
                          padding: "0px 20px",
                        }}
                      >
                        {eligibleDate}
                      </td>
                      <td style={{ padding: "0px 20px" }}>
                        {employee.employeeBenefits.length === 0 && (
                          <Button>Send Enrollment Email</Button>
                        )}
                      </td>
                    </tr>
                    <tr style={{ height: "5px" }} />
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

BenefitAdmin.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
};

export default BenefitAdmin;
