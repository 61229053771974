import React, { useEffect, useState, Fragment } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { addDays, addWeeks, subDays, subWeeks, endOfDay } from "date-fns";
import _ from "lodash";
import { Button } from "../../styles/Style";
import HourlyTimeCardRow from "./HourlyTimeCardRow";
import SingleTimeCardRow from "../TimeCards/SingleTimeCardRow";
import TimeCardTable from "../TimeCards/styles/TimeCardStyles";
import EmployeeField from "../Time/EmployeeField";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { API_URL } from "../../env";
import logo from "../../static/champion_logo_small.png";

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
    margin: 0px 100px;
    th {
      color: black;
    }
  }
  margin-left: 275px;
  .logo {
    width: 1000px;
    display: flex;
    justify-content: center;
    margin: 0px 0px 10px 0px;
    img {
      width: 225px;
    }
  }
  .form-header {
    display: grid;
    width: 1000px;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
    span {
      padding: 0px 5px 0px 5px;
    }
  }
  table {
    width: 1000px;
    border-collapse: collapse;
    thead {
      background: darkgray;
    }
    .total {
      font-weight: bold;
      background: gainsboro;
    }
    .invisible {
      border: none;
      background: white;
    }
  }
  th,
  td {
    padding: 5px;
    margin: 0px;
    border: 1px solid black;
  }
  td {
    white-space: nowrap;
  }
`;

const PageBreak2 = styled.div`
  @media print {
    page-break-after: always;
    margin-top: 100px;
    th {
      color: black;
    }
  }
  margin-bottom: 10px;
`;

const DateContainer = styled.div`
  @media print {
    display: none;
  }
  h2 {
    text-transform: uppercase;
  }
  .dept-checkbox {
    margin-right: 15px;
    font-weight: bold;
    input {
      margin-left: 10px;
      transform: scale(1.33);
    }
  }
  .date-buttons {
    grid-column-start: 2;
    justify-content: right;
    display: inline-flex;
    margin-bottom: 25px;

    .button-margin-1 {
      margin-left: 1px;
    }
    .react-datepicker-wrapper {
      width: auto;

      .react-datepicker__input-container,
      input {
        height: 100%;
        padding: 0px;
        border: none;
        outline: 1px solid black;
        outline-offset: -1px;
        text-align: center;
      }
    }
  }
`;

const start = dayjs().startOf("week").subtract(1, "week").add(1, "day");
const end = dayjs()
  .endOf("week")
  .subtract(1, "week")
  .add(1, "day")
  .endOf("day");

const HourlyTimeCardReport = () => {
  !localStorage.getItem("startDate") &&
    localStorage.setItem("startDate", start.toISOString());
  !localStorage.getItem("endDate") &&
    localStorage.setItem("endDate", end.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(localStorage.getItem("startDate"))
  );
  const [endDate, setEndDate] = useState(
    new Date(localStorage.getItem("endDate"))
  );
  const [hourlyTimeCards, setHourlyTimeCards] = useState([]);
  const [salaryTimeCards, setSalaryTimeCards] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activeemployeesdept`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  useEffect(() => {
    document.title = `Timecards WE ${dayjs(endDate).format("MM-DD-YY")}`;
    return () => {
      document.title = `Tool Time`;
    };
  }, [endDate]);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    const newEnd = end != null ? endOfDay(end) : end;
    setStartDate(start);
    localStorage.setItem("startDate", start.toISOString());
    setEndDate(newEnd);
    localStorage.setItem("endDate", newEnd.toISOString());
  };

  const onDateFilter = () => {
    setEndDate(endDate);
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${API_URL}/timerecords/timecards/${startDate.toISOString()}/${endDate.toISOString()}/${departments}${
        employeeFilter.length > 0
          ? `/${encodeURIComponent(
              JSON.stringify(employeeFilter.map((employee) => employee.value))
            )}`
          : ""
      }`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => setHourlyTimeCards(json));
    if (departments.includes("FIELD")) {
      fetch(
        `${API_URL}/timecards/${startDate.toISOString()}/${endDate.toISOString()}${
          employeeFilter.length > 0
            ? `/${encodeURIComponent(
                JSON.stringify(employeeFilter.map((employee) => employee.value))
              )}`
            : ""
        }`,
        requestOptions
      )
        .then((res) => res.json())
        .then((json) => setSalaryTimeCards(json));
    }
  };
  const handleDepartmentChange = (e) => {
    let newArray = [...departments, e.target.id];
    if (departments.includes(e.target.id)) {
      newArray = newArray.filter((dept) => dept !== e.target.id);
    }
    setDepartment(newArray);
  };

  const handleUserChange = (e) => {
    const userList = e.map((e) => ({ value: e.value, label: e.label }));
    setEmployeeFilter(userList);
  };

  return (
    <div style={{ paddingBottom: "20px" }}>
      <DateContainer>
        <h2>Time Card Report</h2>
        <div>
          <label className="dept-checkbox">
            RAMS
            <input
              type="checkbox"
              name="RAMS"
              checked={departments.includes("RAMS")}
              value="RAMS"
              id="RAMS"
              onChange={handleDepartmentChange}
            />
          </label>
          <label className="dept-checkbox">
            CTS
            <input
              type="checkbox"
              name="CTS"
              checked={departments.includes("CTS")}
              value="CTS"
              id="CTS"
              onChange={handleDepartmentChange}
            />
          </label>
          <label className="dept-checkbox">
            PREMIER
            <input
              type="checkbox"
              name="PREMIER"
              checked={departments.includes("PREMIER")}
              value="PREMIER"
              id="PREMIER"
              onChange={handleDepartmentChange}
            />
          </label>
          <label style={{ fontWeight: "bold" }}>
            CHAMPION
            <input
              type="checkbox"
              name="FIELD"
              checked={departments.includes("FIELD")}
              value="FIELD"
              id="FIELD"
              onChange={handleDepartmentChange}
              style={{ transform: "scale(1.33)", marginLeft: "10px" }}
            />
          </label>
        </div>
        <br />
        <div style={{ width: "500px", margin: "auto" }}>
          <EmployeeField
            disabled={departments.length === 0}
            employees={
              departments.length > 0
                ? employees.filter((employee) =>
                    departments.includes(employee.department.name)
                  )
                : employees
            }
            userId={employeeFilter}
            handleUserChange={handleUserChange}
          />
        </div>
        <br />
        <div className="date-buttons">
          <Button
            onClick={() =>
              onDateChange([subWeeks(startDate, 1), subWeeks(endDate, 1)])
            }
          >
            {"<<"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([subDays(startDate, 1), subDays(endDate, 1)])
            }
          >
            {"<"}
          </Button>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
            className="date-picker"
          />
          <Button
            onClick={() =>
              onDateChange([addDays(startDate, 1), addDays(endDate, 1)])
            }
          >
            {">"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={() =>
              onDateChange([addWeeks(startDate, 1), addWeeks(endDate, 1)])
            }
          >
            {">>"}
          </Button>
          <Button
            className="button-margin-1"
            onClick={onDateFilter}
            disabled={departments.length === 0}
          >
            GO
          </Button>
        </div>
        <div style={{ width: "95%" }}>
          <Button
            style={{ display: "block", marginLeft: "auto" }}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </div>
      </DateContainer>
      <div>
        <div>
          {salaryTimeCards.length > 0 &&
            salaryTimeCards.map((timeCard) => {
              const mondayTotal = timeCard.timeCardItems
                .map((day) => day.hours[0])
                .reduce((prev, cur) => prev + Number(cur), 0);

              const tuesdayTotal = timeCard.timeCardItems
                .map((day) => day.hours[1])
                .reduce((prev, cur) => prev + Number(cur), 0);
              const wednesdayTotal = timeCard.timeCardItems
                .map((day) => day.hours[2])
                .reduce((prev, cur) => prev + Number(cur), 0);
              const thursdayTotal = timeCard.timeCardItems
                .map((day) => day.hours[3])
                .reduce((prev, cur) => prev + Number(cur), 0);
              const fridayTotal = timeCard.timeCardItems
                .map((day) => day.hours[4])
                .reduce((prev, cur) => prev + Number(cur), 0);
              const saturdayTotal = timeCard.timeCardItems
                .map((day) => day.hours[5])
                .reduce((prev, cur) => prev + Number(cur), 0);
              const sundayTotal = timeCard.timeCardItems
                .map((day) => day.hours[6])
                .reduce((prev, cur) => prev + Number(cur), 0);
              const grandTotal =
                mondayTotal +
                tuesdayTotal +
                wednesdayTotal +
                thursdayTotal +
                fridayTotal +
                saturdayTotal +
                sundayTotal;
              return (
                <PageBreak2 key={timeCard.id}>
                  <h2>
                    {timeCard.user.firstName} {timeCard.user.lastName}
                  </h2>
                  <h3>
                    Week Ending: {dayjs(timeCard.weekEnding).format("MM/DD/YY")}
                  </h3>
                  <TimeCardTable>
                    <table>
                      <thead>
                        <tr
                          style={{
                            gridTemplateColumns: "200px 150px repeat(8, 100px)",
                            backgroundColor: "black",
                            color: "white",
                          }}
                        >
                          <th></th>
                          <th></th>
                          <th>Monday</th>
                          <th>Tuesday</th>
                          <th>Wednesday</th>
                          <th>Thursday</th>
                          <th>Friday</th>
                          <th>Saturday</th>
                          <th>Sunday</th>
                          <th></th>
                        </tr>
                        <tr
                          style={{
                            gridTemplateColumns: "200px 150px repeat(8, 100px)",
                            backgroundColor: "black",
                            color: "white",
                          }}
                        >
                          <th>Job</th>
                          <th>Cost Code</th>
                          <th>
                            {dayjs(timeCard.weekEnding)
                              .subtract(6, "day")
                              .format("MM/DD/YY")}
                          </th>
                          <th>
                            {dayjs(timeCard.weekEnding)
                              .subtract(5, "day")
                              .format("MM/DD/YY")}
                          </th>
                          <th>
                            {dayjs(timeCard.weekEnding)
                              .subtract(4, "day")
                              .format("MM/DD/YY")}
                          </th>
                          <th>
                            {dayjs(timeCard.weekEnding)
                              .subtract(3, "day")
                              .format("MM/DD/YY")}
                          </th>
                          <th>
                            {dayjs(timeCard.weekEnding)
                              .subtract(2, "day")
                              .format("MM/DD/YY")}
                          </th>
                          <th>
                            {dayjs(timeCard.weekEnding)
                              .subtract(1, "day")
                              .format("MM/DD/YY")}
                          </th>
                          <th>
                            {dayjs(timeCard.weekEnding).format("MM/DD/YY")}
                          </th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeCard.timeCardItems &&
                          timeCard.timeCardItems.map((row, rowIndex) => (
                            <SingleTimeCardRow
                              key={rowIndex}
                              job={`${row.job.jobNumber} - ${row.job.jobName}`}
                              task={`${row.task.taskCode} - ${row.task.taskName}`}
                              hours={row.hours}
                            />
                          ))}
                      </tbody>
                      <tfoot>
                        <tr
                          style={{
                            gridTemplateColumns: "200px 150px repeat(8, 100px)",
                          }}
                        >
                          <td>
                            <input type="text" value="" readOnly />
                          </td>
                          <td>
                            <input type="text" value="Total" readOnly />
                          </td>
                          <td>
                            <input type="text" value={mondayTotal} readOnly />
                          </td>
                          <td>
                            <input type="text" value={tuesdayTotal} readOnly />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={wednesdayTotal}
                              readOnly
                            />
                          </td>
                          <td>
                            <input type="text" value={thursdayTotal} readOnly />
                          </td>
                          <td>
                            <input type="text" value={fridayTotal} readOnly />
                          </td>
                          <td>
                            <input type="text" value={saturdayTotal} readOnly />
                          </td>
                          <td>
                            <input type="text" value={sundayTotal} readOnly />
                          </td>
                          <td>
                            <input type="text" value={grandTotal} readOnly />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </TimeCardTable>
                </PageBreak2>
              );
            })}
        </div>
        <div>
          {hourlyTimeCards.length > 0 &&
            hourlyTimeCards.map((employee, index) => {
              const sortDay = _.chain(employee.timeRecords)
                .groupBy("date")
                .map((value, key) => ({ date: key, timeRecords: value }))
                .value();
              const totalST = employee.timeRecords.reduce(
                (prev, cur) =>
                  cur.payClass === "ST" ? prev + cur.duration : prev + 0,
                0
              );
              const totalOT = employee.timeRecords.reduce(
                (prev, cur) =>
                  cur.payClass === "OT" ? prev + cur.duration : prev + 0,
                0
              );
              return (
                <PageBreak key={employee.id}>
                  <div className="logo">
                    <img src={logo} alt="champion-logo" />
                  </div>
                  <div className="form-header">
                    <span style={{ textAlign: "left" }}>
                      Employee Time Card
                    </span>
                    <span style={{ textAlign: "center" }}>{`${dayjs(
                      startDate
                    ).format("MMM D, YYYY")} to ${dayjs(endDate).format(
                      "MMM D, YYYY"
                    )}`}</span>
                    <span style={{ textAlign: "right" }}>{`Page ${
                      index + 1
                    } of ${hourlyTimeCards.length}`}</span>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          style={{ textAlign: "left" }}
                        >{`Employee Name: ${employee.firstName} ${employee.lastName}`}</th>
                        <th
                          colSpan={5}
                          style={{ textAlign: "left" }}
                        >{`Employee Number: ${employee.employeeNumber}`}</th>
                      </tr>
                      <tr>
                        <th>Day</th>
                        <th>Job</th>
                        <th>Cost Code</th>
                        <th>In Time</th>
                        <th>Out Time</th>
                        <th>Pay Class</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortDay.map((data, index) => {
                        const dayTotal = data.timeRecords.reduce(
                          (prev, cur) => prev + parseFloat(cur.duration),
                          0
                        );
                        return (
                          <Fragment key={index}>
                            <HourlyTimeCardRow data={data} />
                            <tr className="total">
                              <td colSpan={6} style={{ textAlign: "right" }}>
                                Day Total:
                              </td>
                              <td>{dayTotal.toFixed(2)}</td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr className="total">
                        <td className="invisible"></td>
                        <td className="invisible"></td>
                        <td className="invisible"></td>
                        <td className="invisible"></td>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          Regular Total:
                        </td>
                        <td>{totalST.toFixed(2)}</td>
                      </tr>
                      <tr className="total">
                        <td className="invisible"></td>
                        <td className="invisible"></td>
                        <td className="invisible"></td>
                        <td className="invisible"></td>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          Overtime Total:
                        </td>
                        <td>{totalOT.toFixed(2)}</td>
                      </tr>
                    </tfoot>
                  </table>
                </PageBreak>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HourlyTimeCardReport;
