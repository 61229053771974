import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { API_URL } from "../../env";
import { Button } from "../../styles/Style";
import "react-datepicker/dist/react-datepicker.css";

const BenefitPlanContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  .plan-menu {
    border: 1px solid black;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      .active {
        background: lightblue !important;
      }
      li {
        height: 30px;
        cursor: pointer;
        span {
          vertical-align: middle;
          font-weight: bold;
        }
      }
      li:nth-child(odd) {
        background: lightgray;
      }
      .button-span {
        background: blue;
        color: white;
        width: 100%;
        height: 30px;
        border-color: white;
        cursor: pointer;
      }
    }
  }
  .fields {
    border: 1px solid black;
    border-left: 0px;
  }
  fieldset {
    outline: none;
    border: none;
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 3fr;
    .label {
      text-align: right;
      margin-right: 15px;
    }
    .title {
      font-weight: bold;
      border-bottom: 1px solid black;
    }
    .field {
      text-align: left;
      .name {
        width: 300px;
      }
      .pay-fields {
        display: flex;
        flex-direction: row;
        column-gap: 25px;
      }
    }
    .select-field {
      width: 500px;
    }
    .submit {
      grid-column: 1/3;
    }
  }
`;

const coverageOptionsOptions = [
  { value: "Employee", label: "Employee Only" },
  { value: "EmployeeSpouse", label: "Employee + Spouse" },
  { value: "EmployeeChildren", label: "Employee + Children" },
  { value: "EmployeeFamily", label: "Employee + Family" },
];

const employmentTypeOptions = [
  { value: "FullTime", label: "Full-Time" },
  { value: "PartTime", label: "Part-Time" },
];

const benefitPlanTypeOptions = [
  { value: "Medical", label: "Medical" },
  { value: "Dental", label: "Dental" },
  { value: "Vision", label: "Vision" },
];

const BenefitPlans = () => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/benefit-plans`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        setPlans(
          json.map((plan) => {
            const totalCostPerWeek = plan.totalCostPerWeekx100.map((tcpw) =>
              (tcpw / 100).toFixed(2)
            );
            const companyPaysPerWeek = plan.companyPaysPerWeekx100.map((cppw) =>
              (cppw / 100).toFixed(2)
            );
            delete plan.companyPaysPerWeekx100;
            delete plan.companyPaysPerWeekx100;
            return {
              ...plan,
              totalCostPerWeek,
              companyPaysPerWeek,
              planType: { label: plan.planType, value: plan.planType },
              planStartDate: new Date(plan.planStartDate),
              planEndDate: new Date(plan.planEndDate),
              openEnrollStart: new Date(plan.openEnrollStart),
              openEnrollEnd: new Date(plan.openEnrollEnd),
              coverageOptions: plan.coverageOptions.map((cOption) => ({
                value: cOption,
                label:
                  coverageOptionsOptions[
                    coverageOptionsOptions.findIndex(
                      (coo) => coo.value === cOption
                    )
                  ].label,
              })),
              eligibleEmploymentType: plan.eligibleEmploymentType.map(
                (eOption) => ({
                  value: eOption,
                  label:
                    employmentTypeOptions[
                      employmentTypeOptions.findIndex(
                        (eto) => eto.value === eOption
                      )
                    ].label,
                })
              ),
            };
          })
        );
      });
  }, []);

  const handleNewPlan = () => {
    const numberOfPlans = plans.length;
    setPlans((prevState) => [
      ...prevState,
      {
        planName: "",
        planType: "",
        planDescription: "",
        planStartDate: null,
        planEndDate: null,
        openEnrollStart: null,
        openEnrollEnd: null,
        coverageOptions: [],
        totalCostPerWeek: [],
        companyPaysPerWeek: [],
        eligibleDaysAfterHire: "",
        eligibleEmploymentType: [],
      },
    ]);
    setSelectedPlan(numberOfPlans);
  };
  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...plans[selectedPlan],
        eligibleDaysAfterHire: parseInt(
          plans[selectedPlan].eligibleDaysAfterHire,
          10
        ),
        planType: plans[selectedPlan].planType.value,
        coverageOptions: plans[selectedPlan].coverageOptions.map(
          (cOption) => cOption.value
        ),
        eligibleEmploymentType: plans[selectedPlan].eligibleEmploymentType.map(
          (eOption) => eOption.value
        ),
      }),
    };
    fetch(`${API_URL}/create/benefit-plan`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => console.log(json));
  };
  return (
    <div>
      <BenefitPlanContainer>
        <div className="plan-menu">
          <h2>Benefit Plans</h2>
          <ul>
            {plans.length > 0 ? (
              plans.map((plan, index) => (
                <li
                  key={plan.planName}
                  className={`${selectedPlan === index ? "active" : ""}`}
                  onClick={() => setSelectedPlan(index)}
                >
                  <span>{plan.planName}</span>
                </li>
              ))
            ) : (
              <li>
                <span>No Benefit Plans</span>
              </li>
            )}
            <li>
              <button className="button-span" onClick={() => handleNewPlan()}>
                New
              </button>
            </li>
          </ul>
        </div>
        <div className="fields">
          <h2>Plan Details</h2>
          {selectedPlan !== null && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <fieldset>
                <div className="label">
                  <label>Plan Name</label>
                </div>
                <div className="field">
                  <input
                    required
                    className="name"
                    value={plans[selectedPlan]?.planName}
                    onChange={(e) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].planName = e.target.value;
                        return newState;
                      })
                    }
                  />
                </div>
                <div className="label">
                  <label>Plan Type</label>
                </div>
                <div className="field select-field">
                  <Select
                    required
                    options={benefitPlanTypeOptions}
                    isSearchable
                    onChange={(e) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].planType = e;
                        return newState;
                      })
                    }
                    placeholder={"Select Plan Type"}
                    value={plans[selectedPlan]?.planType}
                    key={`planType-${selectedPlan}`}
                  />
                </div>
                <div className="label">
                  <label>Plan Description</label>
                </div>
                <div className="field">
                  <textarea
                    required
                    className="name"
                    value={plans[selectedPlan]?.planDescription}
                    onChange={(e) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].planDescription = e.target.value;
                        return newState;
                      })
                    }
                  />
                </div>
                <div className="label">
                  <label>Plan Start Date</label>
                </div>
                <div className="field">
                  <DatePicker
                    required
                    selected={plans[selectedPlan].planStartDate}
                    onChange={(date) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].planStartDate = date;
                        return newState;
                      })
                    }
                    preventOpenOnFocus={true}
                  />
                </div>
                <div className="label">
                  <label>Plan End Date</label>
                </div>
                <div className="field">
                  <DatePicker
                    required
                    selected={plans[selectedPlan].planEndDate}
                    onChange={(date) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].planEndDate = date;
                        return newState;
                      })
                    }
                    preventOpenOnFocus={true}
                  />
                </div>
                <div className="label">
                  <label>Coverage Options</label>
                </div>
                <div className="field select-field">
                  <Select
                    required
                    options={coverageOptionsOptions}
                    isSearchable
                    isMulti
                    onChange={(e) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].coverageOptions = e;
                        newState[selectedPlan].totalCostPerWeek = e.map(
                          (element) => (element.label ? "0" : "")
                        );
                        newState[selectedPlan].companyPaysPerWeek = e.map(
                          (element) => (element.label ? "0" : "")
                        );
                        return newState;
                      })
                    }
                    placeholder={"Select Coverage Options"}
                    value={plans[selectedPlan].coverageOptions}
                    key={`coverageOptions-${selectedPlan}`}
                  />
                </div>
                {plans[selectedPlan].coverageOptions.map(
                  (coverageOption, index) => (
                    <Fragment key={`${coverageOption.value}-${index}`}>
                      <div className="label title">
                        <label>{coverageOption.label}</label>
                      </div>
                      <div className="field title"></div>
                      <div className="label">
                        <label>Total Cost Per Week</label>
                      </div>
                      <div className="field">
                        {"$"}
                        <input
                          required
                          value={plans[selectedPlan].totalCostPerWeek[index]}
                          onChange={(e) =>
                            setPlans((prevState) => {
                              const newState = [...prevState];
                              newState[selectedPlan].totalCostPerWeek[index] =
                                e.target.value;
                              return newState;
                            })
                          }
                        />
                      </div>
                      <div className="label">
                        <label>Company Pays Per Week</label>
                      </div>
                      <div className="field">
                        <div className="pay-fields">
                          <div>
                            {"$"}
                            <input
                              required
                              value={
                                plans[selectedPlan].companyPaysPerWeek[index]
                              }
                              onChange={(e) =>
                                setPlans((prevState) => {
                                  const newState = [...prevState];
                                  newState[selectedPlan].companyPaysPerWeek[
                                    index
                                  ] = e.target.value;
                                  return newState;
                                })
                              }
                            />
                          </div>
                          <div>
                            <span>{`Employee Pays: $${(
                              Number(
                                plans[selectedPlan].totalCostPerWeek[
                                  index
                                ].replace(/[$,]/g, "")
                              ) -
                              Number(
                                plans[selectedPlan].companyPaysPerWeek[
                                  index
                                ].replace(/[$,]/g, "")
                              )
                            ).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}`}</span>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )
                )}
                <div className="label">
                  <label>Eligible first day of month after </label>
                </div>
                <div className="field">
                  <input
                    required
                    value={plans[selectedPlan].eligibleDaysAfterHire}
                    onChange={(e) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].eligibleDaysAfterHire =
                          e.target.value;
                        return newState;
                      })
                    }
                  />
                  {" days"}
                </div>
                <div className="label">
                  <label>Eligible Employment Type</label>
                </div>
                <div className="field select-field">
                  <Select
                    required
                    options={employmentTypeOptions}
                    isSearchable
                    isMulti
                    onChange={(e) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].eligibleEmploymentType = e;
                        return newState;
                      })
                    }
                    placeholder={"Select Coverage Options"}
                    value={plans[selectedPlan].eligibleEmploymentType}
                    key={`employmentType-${selectedPlan}`}
                  />
                </div>
                <div className="label">
                  <label>Open Enrollment Start Date</label>
                </div>
                <div className="field">
                  <DatePicker
                    required
                    selected={plans[selectedPlan].openEnrollStart}
                    onChange={(date) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].openEnrollStart = date;
                        return newState;
                      })
                    }
                    preventOpenOnFocus={true}
                  />
                </div>
                <div className="label">
                  <label>Open Enrollment End Date</label>
                </div>
                <div className="field">
                  <DatePicker
                    required
                    selected={plans[selectedPlan].openEnrollEnd}
                    onChange={(date) =>
                      setPlans((prevState) => {
                        const newState = [...prevState];
                        newState[selectedPlan].openEnrollEnd = date;
                        return newState;
                      })
                    }
                    preventOpenOnFocus={true}
                  />
                </div>
                <div className="submit">
                  <Button type="submit">Save Benefit Plan</Button>
                </div>
              </fieldset>
            </form>
          )}
        </div>
      </BenefitPlanContainer>
    </div>
  );
};

export default BenefitPlans;
