import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const ToolCategoryForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  fieldset {
    grid-column-start: 2;
    border: none;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-column: 2;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

class SingleToolCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolCategory: { ...this.props.editToolCategory[0] },
      updatedToolCategory: "",
      error: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      updatedToolCategory: { ...this.state.updatedToolCategory, [name]: value },
    });
  }

  render() {
    const { toolCategory, updatedToolCategory } = this.state;
    return (
      <div>
        <div>
          <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Edit Tool Category
          </h3>
          <ToolCategoryForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...updatedToolCategory }),
              };
              await fetch(
                `${API_URL}/update/category/${toolCategory.id}`,
                requestOptions
              ).then((res) => res.json());
              this.props.onEditToolCategory(
                toolCategory.id,
                updatedToolCategory
              );
              this.props.onDeselectToolCategory();
            }}
          >
            <fieldset>
              <div className="fields">
                <div>
                  <label className="toolCategory-name">
                    Tool Category Name
                    <br />
                    <input
                      type="text"
                      name="name"
                      id="name"
                      defaultValue={toolCategory.name}
                      onChange={this.handleChange}
                      placeholder="Tool Category Name*"
                      required
                      autoComplete="none"
                    />
                  </label>
                </div>
                {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
                <Button type="submit">Save Changes</Button>
              </div>
            </fieldset>
          </ToolCategoryForm>
        </div>
      </div>
    );
  }
}

SingleToolCategory.propTypes = {
  id: PropTypes.string,
  editToolCategory: PropTypes.array,
  onEditToolCategory: PropTypes.func,
  onDeselectToolCategory: PropTypes.func,
};

export default SingleToolCategory;
