import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button } from "../../../styles/Style";
import { API_URL } from "../../../env";

const DailyReportForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  table {
    width: 500px;
    th,
    td {
      outline: 1px solid black;
    }
  }
  fieldset {
    grid-column-start: 2;
    border: 1px solid lightgray;
  }
  .fields {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
    label {
      font-weight: bold;
      text-align: center;
    }
    button {
      grid-row-start: 5;
      text-transform: uppercase;
      margin: 10px;
    }
  }
`;

const AddDailyReportForm = () => {
  const [state, setState] = useState({
    date: new Date(),
    jobId: "",
    description: "",
  });

  const handleDateChange = (value) => {
    setState((prevState) => ({ ...prevState, date: value }));
  };

  const [jobs, setJobs] = useState([]);
  const [weather, setWeather] = useState(null);
  const [jobCoord, setJobCoord] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/activejobs`, requestOptions)
      .then((res) => res.json())
      .then((json) => setJobs(json));
  }, []);

  useEffect(() => {
    if (state.jobId !== "") {
      const foundJob = jobs.filter((job) => state.jobId === job.id);
      // console.log(`${foundJob[0].jobLat},${foundJob[0].jobLng}`);
      setJobCoord(`${foundJob[0].jobLat},${foundJob[0].jobLng}`);
    }
  }, [weather, state.jobId]);

  const getWeather = async () => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    await fetch(
      `${API_URL}/weather/${jobId}/${new Date(state.date).toISOString()}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        setWeather(json.locations[jobCoord].values);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...state }),
    };
    fetch(`${API_URL}/create/dailyReport`, requestOptions).then((response) =>
      response.json()
    );
  };

  const { date, jobId, description } = state;
  return (
    <div>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Create Daily Report
      </h1>
      <div>
        <DailyReportForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            navigate("/forms/daily");
          }}
        >
          <fieldset>
            <div className="fields">
              <label>
                Date
                <DatePicker selected={date} onChange={handleDateChange} />
              </label>
              <label htmlFor="jobs" className="job">
                Job
                <br />
                <select
                  value={jobId}
                  name="jobId"
                  id="jobId"
                  required
                  onChange={handleChange}
                  className="input-field"
                >
                  <option>Select Job</option>
                  {jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.jobNumber} - {job.jobName}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Weather
                <br />
                {weather && (
                  <table>
                    <thead>
                      <tr>
                        <td>Time</td>
                        {weather.map((wthr) => (
                          <th key={wthr.datetime}>
                            {new Date(wthr.datetime).getUTCHours() > 12
                              ? new Date(wthr.datetime).getUTCHours() - 12
                              : new Date(wthr.datetime).getUTCHours()}
                            {":"}
                            {new Date(wthr.datetime).getMinutes() < 10
                              ? "0"
                              : ""}
                            {new Date(wthr.datetime).getMinutes()}
                            {new Date(wthr.datetime).getUTCHours() >= 12
                              ? "pm"
                              : "am"}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tr>
                      <td>Temp</td>
                      {weather.locations[jobCoord].values.map((wthr, i) => (
                        <td key={i}>{wthr.temp}F</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Condition</td>
                      {weather.locations[jobCoord].values.map((wthr, i) => (
                        <td key={i + 100}>{wthr.conditions}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Precip</td>
                      {weather.locations[jobCoord].values.map((wthr, i) => (
                        <td key={i + 100}>
                          {wthr.precip}
                          &#34;
                        </td>
                      ))}
                    </tr>
                  </table>
                )}
                <Button
                  type="button"
                  disabled={state.jobId === ""}
                  onClick={getWeather}
                >
                  Get Weather
                </Button>
              </label>
              <label>
                Work Performed
                <br />
                <input
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                  onChange={handleChange}
                  autoComplete="none"
                />
              </label>
              <Button type="submit">Create Daily Report</Button>
            </div>
          </fieldset>
        </DailyReportForm>
      </div>
    </div>
  );
};

export default AddDailyReportForm;
