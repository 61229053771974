import React, { useState, useEffect } from "react";
import { API_URL } from "../../../env";
import { Button } from "../../../styles/Style";

const TaxCodes = () => {
  const [taxCodes, setTaxCodes] = useState([]);
  // const [updatedTaxCode, setUpdatedTaxCode] = useState(null);
  const [state, setState] = useState({
    taxCode: "",
    taxCodeDescription: "",
    countyTaxRate: "",
    countyTaxLimit: "",
    stateTaxRate: "",
  });

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/material/taxcodes`, requestOptions)
      .then((res) => res.json())
      .then((json) => setTaxCodes(json));
  }, []);
  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    };
    fetch(`${API_URL}/create/taxcode`, requestOptions).then((res) =>
      res.json()
    );
  };
  const {
    taxCode,
    taxCodeDescription,
    countyTaxRate,
    countyTaxLimit,
    stateTaxRate,
  } = state;
  return (
    <div>
      <h4>Tax Codes</h4>
      <div>
        <button>New</button>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}>
        <ul style={{ listStyle: "none", cursor: "pointer" }}>
          {taxCodes.map((code) => (
            <li key={code.id} onClick={() => setState(code)}>
              {code.taxCode}
            </li>
          ))}
        </ul>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <fieldset style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <label>
              Tax Code
              <input
                type="text"
                name="TaxCode"
                id="TaxCode"
                value={taxCode}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    taxCode: e.target.value,
                  }));
                }}
                placeholder="Tax Code*"
                required
                autoComplete="none"
              />
            </label>
            <label>
              Tax Code Desc.
              <input
                type="text"
                name="TaxCodeDesc"
                id="TaxCodeDesc"
                value={taxCodeDescription}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    taxCodeDescription: e.target.value,
                  }));
                }}
                placeholder="Tax Code Desc.*"
                required
                autoComplete="none"
              />
            </label>
            <label>
              County Tax
              <input
                type="number"
                name="CountyRate"
                id="CountyRate"
                value={countyTaxRate}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    countyTaxRate: parseFloat(e.target.value),
                  }));
                }}
                placeholder="County Tax %*"
                required
                autoComplete="none"
              />
              %
            </label>
            <label>
              County Tax Limit
              <input
                type="number"
                name="CountyLimit"
                id="CountyLimit"
                value={countyTaxLimit}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    countyTaxLimit: parseInt(e.target.value),
                  }));
                }}
                placeholder="County Tax Limit*"
                required
                autoComplete="none"
              />
            </label>
            <label>
              State Tax
              <input
                type="text"
                name="StateRate"
                id="StateRate"
                value={stateTaxRate}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    stateTaxRate: parseFloat(e.target.value),
                  }));
                }}
                placeholder="State Tax %*"
                required
                autoComplete="none"
              />
              %
            </label>
            <Button>Save</Button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default TaxCodes;
