import React from "react";
import dayjs from "dayjs";

const HourlyTimeCardRow = ({ data }) => {
  return data.timeRecords.map((timerecord) => (
    <tr key={timerecord.id}>
      <td style={{ textAlign: "left" }}>
        {dayjs(timerecord.date).format("ddd MMM D, YYYY")}
      </td>
      <td
        style={{ textAlign: "left" }}
      >{`${timerecord.job.jobNumber} ${timerecord.job.jobName}`}</td>
      <td
        style={{ textAlign: "left" }}
      >{`${timerecord.clockAction.task.taskCode} ${timerecord.clockAction.task.taskName}`}</td>
      <td>{dayjs(timerecord.clockIn).format("h:mm A")}</td>
      <td>{dayjs(timerecord.clockOut).format("h:mm A")}</td>
      <td>{timerecord.payClass}</td>
      <td>{timerecord.duration.toFixed(2)}</td>
    </tr>
  ));
};

export default HourlyTimeCardRow;
