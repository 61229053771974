import React, { useState, useEffect } from "react";
import { API_URL } from "../../../env";
import { Button } from "../../../styles/Style";

const UnitsOfMeasure = () => {
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  // const [updatedTaxCode, setUpdatedTaxCode] = useState(null);
  const [state, setState] = useState({
    code: "",
    description: "",
  });

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/material/uoms`, requestOptions)
      .then((res) => res.json())
      .then((json) => setUnitsOfMeasure(json));
  }, []);

  const handleSubmit = () => {
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    };
    fetch(`${API_URL}/create/uom`, requestOptions).then((res) => res.json());
  };
  const { code, description } = state;
  return (
    <div>
      <h4>Units Of Measure</h4>
      <div>
        <button>New</button>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}>
        <ul style={{ listStyle: "none", cursor: "pointer" }}>
          {unitsOfMeasure.map((code) => (
            <li key={code.id} onClick={() => setState(code)}>
              {code.code}
            </li>
          ))}
        </ul>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <fieldset style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <label>
              UOM Code
              <input
                type="text"
                name="TaxCode"
                id="TaxCode"
                value={code}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    code: e.target.value,
                  }));
                }}
                placeholder="UOM Code*"
                required
                autoComplete="none"
              />
            </label>
            <label>
              UOM Code Desc.
              <input
                type="text"
                name="TaxCodeDesc"
                id="TaxCodeDesc"
                value={description}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }));
                }}
                placeholder="Tax Code Desc.*"
                required
                autoComplete="none"
              />
            </label>
            <Button>Save</Button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default UnitsOfMeasure;
