import React from "react";
import fileDownload from "js-file-download";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const AppDownload = () => {
  const handleDownload = (e, type, filename) => {
    e.preventDefault();
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/octet-stream",
        "Content-Disposition": "attachment",
        filename,
      },
    };
    fetch(`${API_URL}/download/${type}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => fileDownload(blob, filename));
  };

  return (
    <div>
      <h2>Download App</h2>
      <Button
        onClick={(e) =>
          handleDownload(e, "android", "app-universal-release.apk")
        }
      >
        Download Android App
      </Button>
      {/* <br />
      <br />
      <Button onClick={(e) => handleDownload(e, "ios", "tooltimeios.ipa")}>
        Download iOS
      </Button> */}
    </div>
  );
};
export default AppDownload;
