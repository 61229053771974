import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../styles/Style";

const ConfirmDeletePanel = styled.div`
  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }
  .confirm-panel {
    padding: 50px;
    background-color: white;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: block;
    position: fixed;
    z-index: 25;
  }
  .opaque-panel {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    background: gray;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
`;

const ConfirmDelete = ({
  setOnToggleConfirm,
  onDeleteBatch,
  selectedLength,
}) => {
  return (
    <ConfirmDeletePanel>
      <div className="confirm-panel">
        <h3>{`Are you sure you want to delete ${selectedLength} records?`}</h3>
        <Button onClick={() => setOnToggleConfirm(false)}>Cancel</Button>
        <Button
          onClick={() => {
            onDeleteBatch();
            setOnToggleConfirm(false);
          }}
          style={{ background: "red", marginLeft: "10px" }}
        >
          Delete
        </Button>
      </div>
      <div className="opaque-panel" />
    </ConfirmDeletePanel>
  );
};

ConfirmDelete.propTypes = {
  setOnToggleConfirm: PropTypes.func,
  onDeleteBatch: PropTypes.func,
  selectedLength: PropTypes.number,
};

export default ConfirmDelete;
