import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TimeCardTable from "./TimeCardTable";
import SearchSortFilterComponent from "../Search/SearchSortFilterComponent";
import Pagination from "../Search/Pagination";
import { API_URL } from "../../env";

const columns = [
  ["Week Ending", "weekEnding"],
  ["First Name", "user.firstName"],
  ["Middle Name", "user.middleName"],
  ["Last Name", "user.lastName"],
  ["Total Hours", "sum"],
];

const TimeCards = () => {
  const [timeCards, setTimeCards] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const pageParam = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/salaryemployees`, requestOptions)
      .then((res) => res.json())
      .then((json) => setEmployees(json));
  }, []);

  const fetchData = useMemo(
    () =>
      async ({ searchTerm, sortBy, orderBy, page, pageSize, filters }) => {
        try {
          // Your data fetching logic using axios or fetch
          const requestOptions = {
            method: "GET",
            withCredentials: true,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          };
          fetch(
            `${API_URL}/timecards/paginated?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}&filters=${JSON.stringify(
              filters
            )}`,
            requestOptions
          )
            .then((res) => res.json())
            .then((json) => {
              setTimeCards(json.timeCards);
              setPages(json.pages);
              setLoading(false);
            });

          // Update the URL with the current page and other parameters
          queryParams.set("page", page);
          queryParams.set("searchTerm", searchTerm);
          queryParams.set("sortBy", sortBy);
          queryParams.set("orderBy", orderBy);
          queryParams.set("pageSize", pageSize);
          queryParams.set("filters", JSON.stringify(filters));

          navigate(`/timecards?${queryParams.toString()}`, {
            replace: true,
          }); // Use replace to avoid adding to history
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    []
  );

  const filterCategories = [
    {
      label: "Employee",
      field: "userId",
      options: employees.map((employee) => ({
        label: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      })),
    },
    // Add more filter categories as needed
  ];

  const handleSort = (column) => {
    if (column === sortBy) {
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    } else {
      setSortBy(column);
      setOrderBy("asc");
    }
  };

  return (
    <div>
      <SearchSortFilterComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilters={setFilters}
        buttonText={"New TimeCard"}
        buttonPath={"/create/timecard"}
        pageTitle={"Timecards"}
        fetchData={fetchData}
        filterCategories={filterCategories}
        pageParam={pageParam}
        sortBy={sortBy}
        orderBy={orderBy}
      />
      {!loading && (
        <TimeCardTable
          columns={columns}
          data={timeCards}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
          navigate={navigate}
        />
      )}
      <Pagination
        pageParam={pageParam}
        pages={pages}
        queryParams={queryParams}
      />
    </div>
  );
};

export default TimeCards;
