import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { Button } from "../../styles/Style";
import { API_URL } from "../../env";

const possiblePermissions = ["USER", "PM", "FOREMAN", "ADMIN"];

const EmployeeForm = styled.form`
  fieldset {
    border: none;
    .submit-button {
      text-transform: uppercase;
      margin: 50px 10px;
    }
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 10px;
    label {
      font-weight: bold;
      text-align: center;
    }
    input,
    select {
      padding: 7px;
      border: 1px solid lightgray;
    }
    .employee-name {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

const SingleUser = ({ editUser, onEditEmployee, onDeselectEmployee }) => {
  const [state, setState] = useState({
    loading: true,
    user: {
      ...editUser[0],
      hireDate: editUser[0]?.hireDate ? new Date(editUser[0].hireDate) : null,
    },
    updatedUser: "",
    password: "",
    departments: [],
    error: "",
  });
  const [showPayRate, setShowPayRate] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    fetch(`${API_URL}/departments`, requestOptions)
      .then((res) => res.json())
      .then((data) =>
        setState((prevState) => ({
          ...prevState,
          departments: data,
          loading: false,
        }))
      );
  }, []);

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    setState((prevState) => ({
      ...prevState,
      updatedUser: { ...prevState.updatedUser, [name]: val },
    }));
  };

  const handleDateChange = (date) => {
    setState((prevState) => ({
      ...prevState,
      updatedUser: { ...prevState.updatedUser, hireDate: date },
    }));
  };

  const handleActiveChange = (e) => {
    const { checked, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      updatedUser: { ...prevState.updatedUser, [name]: checked },
    }));
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    setInviting(true);
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: state.user.id,
        email: state.updatedUser.email
          ? state.updatedUser.email
          : state.user.email,
      }),
    };
    try {
      await fetch(`${API_URL}/invite`, requestOptions)
        .then((res) => {
          if (res.status !== 202) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(() => {
          setInviting(false);
          setInviteSuccess(true);
        });
    } catch (error) {
      setState((prevState) => ({ ...prevState, error: error.message }));
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setResetting(true);
    const requestOptions = {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: state.updatedUser.email
          ? state.updatedUser.email
          : state.user.email,
      }),
    };
    try {
      await fetch(`${API_URL}/forgotpassword`, requestOptions)
        .then((res) => {
          if (res.status !== 202) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(() => {
          setResetting(false);
          setResetSuccess(true);
        });
    } catch (error) {
      setState((prevState) => ({ ...prevState, error: error.message }));
    }
  };

  const { loading, user, updatedUser } = state;
  return (
    <div>
      {!loading && (
        <div>
          <h3
            onClick={() => setShowPayRate(!showPayRate)}
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
          >
            Edit Employee
          </h3>
          <EmployeeForm
            onSubmit={async (e) => {
              e.preventDefault();
              const requestOptions = {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...updatedUser }),
              };
              await fetch(
                `${API_URL}/update/employee/${user.id}`,
                requestOptions
              ).then((res) => res.json());
              onEditEmployee(user.id, updatedUser);
              onDeselectEmployee();
            }}
          >
            <fieldset>
              <div className="fields">
                <div>
                  <label htmlFor="active">
                    Active
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      defaultChecked={user.active}
                      onChange={(e) => handleActiveChange(e)}
                    />
                  </label>
                  <label htmlFor="isSalary">
                    Salary
                    <input
                      type="checkbox"
                      name="isSalary"
                      id="isSalary"
                      defaultChecked={user.isSalary}
                      onChange={(e) => handleActiveChange(e)}
                    />
                  </label>
                </div>
                <label htmlFor="hireDate">
                  Hire Date
                  <br />
                  <DatePicker
                    selected={
                      updatedUser.hireDate
                        ? updatedUser.hireDate
                        : user.hireDate
                    }
                    onChange={(date) => handleDateChange(date)}
                  />
                </label>
                <label htmlFor="employmentType">
                  Employment Type
                  <br />
                  <select
                    name="employmentType"
                    id="employmentType"
                    value={
                      updatedUser.employmentType
                        ? updatedUser.employmentType
                        : user.employmentType
                    }
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="FullTime">Full-Time</option>
                    <option value="PartTime">Part-Time</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Terminated">Terminated</option>
                  </select>
                </label>
                <label htmlFor="employeeNumber">
                  Employee Number
                  <br />
                  <input
                    type="number"
                    name="employeeNumber"
                    id="employeeNumber"
                    defaultValue={user.employeeNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder="Eg. 392"
                  />
                </label>
                {showPayRate && (
                  <label htmlFor="payRate">
                    Pay Rate
                    <br />
                    <input
                      type="number"
                      step=".01"
                      name="payRate"
                      id="payRate"
                      defaultValue={user.payRate}
                      onChange={(e) => handleChange(e)}
                    />
                  </label>
                )}
                <label htmlFor="department">
                  Department
                  <br />
                  <select
                    name="departmentId"
                    id="departmentId"
                    required
                    value={
                      updatedUser.departmentId
                        ? updatedUser.departmentId
                        : user.departmentId
                    }
                    onChange={(e) => handleChange(e)}
                  >
                    {state.departments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="employee-name">
                  Name
                  <br />
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    defaultValue={user.firstName}
                    onChange={(e) => handleChange(e)}
                    placeholder="First Name*"
                    required
                    autoComplete="none"
                  />
                  <input
                    type="text"
                    name="middleName"
                    id="middleName"
                    defaultValue={user.middleName}
                    onChange={(e) => handleChange(e)}
                    placeholder="Middle Name"
                    autoComplete="none"
                  />
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    defaultValue={user.lastName}
                    onChange={(e) => handleChange(e)}
                    placeholder="Last Name*"
                    required
                    autoComplete="none"
                  />
                </label>
                <label htmlFor="pinNumber">
                  Pin Number
                  <br />
                  <input
                    type="text"
                    name="pinNumber"
                    id="pinNumber"
                    defaultValue={user.pinNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder="Last 4 of SS#"
                    autoComplete="none"
                  />
                </label>
                <div>
                  <label htmlFor="email">
                    Email
                    <br />
                    {user.email ? (
                      <>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          defaultValue={user.email}
                          onChange={(e) => handleChange(e)}
                          placeholder="someone@example.com"
                        />
                        {resetSuccess ? (
                          <Button disabled>Reset Password Sent</Button>
                        ) : (
                          <Button
                            onClick={(e) => handlePasswordReset(e)}
                            disabled={resetting}
                          >
                            {resetting
                              ? "Sending Reset Password"
                              : "Send Reset Password"}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          defaultValue={user.email}
                          onChange={(e) => handleChange(e)}
                          placeholder="someone@example.com"
                        />
                        {inviteSuccess ? (
                          <Button disabled>Invite Sent</Button>
                        ) : (
                          <Button
                            onClick={(e) => handleInvite(e)}
                            disabled={
                              inviting || updatedUser.email
                                ? !updatedUser.email.match(/.+@.+\..+/)
                                : user.email
                                ? !user.email.match(/.+@.+\..+/)
                                : true
                            }
                          >
                            {inviting ? "Sending Invite" : "Send Invite"}
                          </Button>
                        )}
                      </>
                    )}
                  </label>
                  <br />
                  <label htmlFor="password">
                    Update Password
                    <br />
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={
                        updatedUser.password
                          ? updatedUser.password
                          : state.password
                      }
                      onChange={(e) => handleChange(e)}
                      autoComplete="new-password"
                    />
                  </label>
                </div>

                <label htmlFor="phone-number">
                  Phone #
                  <br />
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={
                      updatedUser?.phoneNumber
                        ? updatedUser.phoneNumber.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          )
                        : user?.phoneNumber
                        ? user.phoneNumber.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          )
                        : ""
                    }
                    onChange={(e) => handleChange(e)}
                    autoComplete="none"
                  />
                </label>
                <label>
                  Permissions
                  <br />
                  <div className="permission-labels">
                    {possiblePermissions.map((permission) => (
                      <label
                        htmlFor={`${permission}-permission`}
                        key={permission}
                      >
                        {permission}
                        <input
                          type="checkbox"
                          checked={
                            updatedUser.permission
                              ? permission === updatedUser.permission
                              : permission === user.permission
                          }
                          value={permission}
                          name={"permission"}
                          id={permission}
                          onChange={(e) => handleChange(e)}
                        />
                      </label>
                    ))}
                  </div>
                </label>
                <label>
                  Features
                  <br />
                  <div className="permission-labels">
                    <label>
                      Crew Clock
                      <input
                        type="checkbox"
                        checked={
                          updatedUser.crewClockAccess
                            ? updatedUser.crewClockAccess
                            : user.crewClockAccess
                        }
                        value={
                          updatedUser.crewClockAccess
                            ? updatedUser.crewClockAccess
                            : user.crewClockAccess
                        }
                        name={"crewClockAccess"}
                        id={"crewClockAccess"}
                        onChange={(e) => handleActiveChange(e)}
                      />
                    </label>
                    <label>
                      Material Access
                      <input
                        disabled={
                          (updatedUser.phoneNumber === undefined ||
                            updatedUser.phoneNumber.length < 10) &&
                          user.phoneNumber === null
                        }
                        type="checkbox"
                        checked={
                          updatedUser.materialAccess
                            ? updatedUser.materialAccess
                            : user.materialAccess
                        }
                        value={
                          updatedUser.materialAccess
                            ? updatedUser.materialAccess
                            : user.materialAccess
                        }
                        name={"materialAccess"}
                        id={"materialAccess"}
                        onChange={(e) => handleActiveChange(e)}
                      />
                    </label>
                    <br />
                    <label>
                      Order From Vendor
                      <input
                        type="checkbox"
                        disabled={
                          (updatedUser.materialAccess === undefined ||
                            updatedUser.materialAccess === false) &&
                          user.materialAccess === false
                        }
                        checked={
                          updatedUser.orderFromVendor
                            ? updatedUser.orderFromVendor
                            : user.orderFromVendor
                        }
                        value={
                          updatedUser.orderFromVendor
                            ? updatedUser.orderFromVendor
                            : user.orderFromVendor
                        }
                        name={"orderFromVendor"}
                        id={"orderFromVendor"}
                        onChange={(e) => handleActiveChange(e)}
                      />
                    </label>
                  </div>
                </label>
                {/* <Button type="submit">Sav{loading ? "ing" : "e"} Changes</Button> */}
              </div>
              <Button className="submit-button" type="submit">
                Save Changes
              </Button>
            </fieldset>
          </EmployeeForm>
        </div>
      )}
    </div>
  );
};

SingleUser.propTypes = {
  id: PropTypes.string,
  editUser: PropTypes.array,
  onEditEmployee: PropTypes.func,
  onDeselectEmployee: PropTypes.func,
};

export default SingleUser;
